import Lottie from "lottie-react";
import searchIcon from "../icons/searchIcon.json";
import { useTranslation } from "react-i18next";

export const EmptyState = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center justify-content-center flex-column w-100">
      {/* <Lottie animationData={searchIcon} loop={true} style={{ width: 100 }} /> */}
      <h5 className="mt-2">{t("empty_state.no_result")}</h5>
      <p className="text-muted">{t("empty_state.not_find_data")}</p>
    </div>
  );
};
