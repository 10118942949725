import { PageTemplate } from "../../shared/layout/PageTemplate";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { Form } from "react-bootstrap";
import _debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { ApiService } from "./service";
import { List } from "./components/List";
import { useNavigate } from "react-router";

const initialState = {
  name: "",
};

export const Api = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [brandsList, setBrandsList] = useState<any[] | undefined>();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [currentBrand, setCurrentBrand] = useState<any | undefined>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { accessRights } = useSelector((state: IAllStates) => state.auth);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPagination({
        page: 1,
        perPage: 10,
        [name]: value,
      });
    },
    200
  );

  const closeHandler = () => {
    setIsMenuOpened(false);
    setCurrentBrand(initialState as any);
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (currentBrand?.id) {
      ApiService.updateById(currentBrand?.id, currentBrand)
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_updated")} ${response?.name}`);
          setCurrentBrand(initialState as any);
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
        })
        .catch((error) => ErrorToast(error));
    } else {
      ApiService.create(currentBrand as any)
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_created")} ${response?.name}`);
          setCurrentBrand(initialState as any);
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
        })
        .catch((error) => ErrorToast(error));
    }
  };

  const editHandler = (brandId: number) => {
    navigate(`/admin/api-update/${brandId}`);
  };

  useEffect(() => {
    ApiService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setTotalPages(Math.ceil(response?.count / response?.perPage));
        setBrandsList(data);
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, triggerUpdate, isMenuOpened]);
  return (
    <>
      <div className="col-xl-12 mb-3">
        {accessRights?.includes("api_can_create") && !isMenuOpened && (
          <div className="col-12">
            <div className="card">
              <div className="card-body d-flex align-items-center justify-content-between">
                <h4 className="card-title mg-b-0">{t("api.create_new_api")}</h4>
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/admin/api-create")}
                >
                  {t("global.add")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="col-xl-12">
        <List
          accessRights={accessRights}
          brandsList={brandsList}
          page={page}
          totalPages={totalPages}
          handlePages={handlePages}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
          changeFilterHandler={changeFilterHandler}
          state={currentBrand}
          setState={setCurrentBrand}
        />
      </div>
    </>
  );
};
