import { useTranslation } from "react-i18next";
import { ICompany } from "../../../model/authentification/IRegister";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { getCorrectLanguage } from "../../../shared/functions/Functions";

export interface ICOmpanyFieldsProps {
  handleChangeCompany: (
    event: React.ChangeEvent<HTMLInputElement>,
    isContact?: boolean
  ) => void;
  state: ICompany;
  handleSelect2: (
    selectedSingle: SingleValue<{
      label: string;
      value: number | undefined | string;
    }>,
    inputKey: string
  ) => void;
}

export const CompanyFields = ({
  handleChangeCompany,
  state,
  handleSelect2,
}: ICOmpanyFieldsProps) => {
  const { t } = useTranslation();
  const { country } = useSelector((state: IAllStates) => state.enums);
  return (
    <div className="p-2 mt-4">
      <h5>{t("register.company")}</h5>
      <div className="mb-3">
        <label htmlFor="title" className="form-label">
          {t("register.title")}
        </label>
        <input
          name="title"
          onChange={handleChangeCompany}
          type="text"
          value={state?.title}
          className="form-control"
          id="title"
          placeholder={t("register.title")}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          {t("register.email")}
        </label>
        <input
          value={state?.email}
          name="email"
          onChange={handleChangeCompany}
          type="email"
          className="form-control"
          id="email"
          placeholder={t("register.email")}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="phone" className="form-label">
          {t("register.phone")}
        </label>
        <input
          value={state.contact?.phone}
          name="phone"
          onChange={(e) => handleChangeCompany(e, true)}
          type="tel"
          className="form-control"
          id="phone"
          placeholder={t("register.phone")}
        />
      </div>
      <div>
        <label htmlFor="country" className="form-label">
          {t("clients.country")}
        </label>
        <Select
          theme={themeSelect}
          className="mb-3"
          isClearable
          placeholder={`${t("clients.selectCountry")}`}
          options={country?.map((singleCountry) => ({
            value: singleCountry,
            label: getCorrectLanguage(singleCountry, t),
          }))}
          onChange={(e) => {
            handleSelect2(
              e as SingleValue<{ label: string; value: string }>,
              "country"
            );
          }}
        />
      </div>
      <div className="mb-3">
        <Row>
          <Col>
            <label htmlFor="city" className="form-label">
              {t("register.city")}
            </label>
            <input
              value={state.contact?.city}
              name="city"
              onChange={(e) => handleChangeCompany(e, true)}
              type="text"
              className="form-control"
              id="city"
              placeholder={t("register.city")}
            />
          </Col>
          <Col>
            <label htmlFor="zip" className="form-label">
              {t("register.zip")}
            </label>
            <input
              value={state.contact?.zip}
              name="zip"
              onChange={(e) => handleChangeCompany(e, true)}
              type="number"
              className="form-control"
              id="zip"
              placeholder={t("register.zip")}
            />
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <label htmlFor="address" className="form-label">
          {t("register.address")}
        </label>
        <input
          value={state.contact?.address}
          name="address"
          onChange={(e) => handleChangeCompany(e, true)}
          type="text"
          className="form-control"
          id="address"
          placeholder={t("register.address")}
        />
      </div>
    </div>
  );
};
