import React, { useEffect, useState } from "react";
import { PageTemplate } from "../../shared/layout/PageTemplate";
import { t } from "i18next";
import { AccessRightsList } from "./components/AccessRightsList";
import { AccessService } from "./service";
import { IAccessRights } from "./dto/IAccessRights";
import { AnyNaptrRecord } from "dns";
import { Col, Form } from "react-bootstrap";
import { AccessForm } from "./components/AccessForm";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { CompanyService } from "../companies/service";
import { ICompaniesDTO } from "../companies/dto/ICompanies";

export const AccessRights = () => {
  const [accessList, setAccessList] = useState<any>(undefined);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [companiesList, setCompaniesList] = useState<ICompaniesDTO[]>([]);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [accessRightsList, setAccessRightsList] = useState([] as any);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [currentAccess, setCurrentAccess] = useState<any>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    AccessService.createRight({
      name: currentAccess?.name,
      company: currentAccess?.company,
      rights: accessRightsList,
    })
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_updated")}`);
        setTriggerUpdate((prev) => !prev);
        setIsMenuOpened((prev) => !prev);
        setCurrentAccess({});
        setAccessRightsList([]);
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    AccessService.getAllAccessRights(pagination).then((response) => {
      setTotalPages(Math.ceil(response?.count / response?.perPage));
      setAccessList(response?.data);
    });
  }, [triggerUpdate, pagination]);
  useEffect(() => {
    CompanyService.getAllCompanies({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCompaniesList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    // <PageTemplate title={`${t("side_bar.access_rights")}`}>
    <Col xs={12}>
      {!isMenuOpened && (
        <div className="card ">
          <div className="card-body d-flex align-items-center justify-content-between">
            <h4 className="card-title mg-b-0">
              {t("access_rights.create_new_role")}
            </h4>
            <button
              className="btn btn-primary"
              onClick={() => setIsMenuOpened(true)}
            >
              {t("global.add")}
            </button>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <Form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-body">
              <AccessForm
                state={currentAccess}
                setState={setCurrentAccess}
                setAccessRightsList={setAccessRightsList}
                accessRightsList={accessRightsList}
                companiesList={companiesList}
              />
              <div className="mt-2 d-flex justify-content-end">
                <button
                  className="btn btn-info me-2"
                  onClick={() => {
                    setIsMenuOpened((prev: boolean) => !prev);
                    setCurrentAccess({} as any);
                  }}
                >
                  {t("global.cancel")}
                </button>
                <button className="btn btn-primary" type="submit">
                  {t("global.create")}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}

      <AccessRightsList
        companiesList={companiesList}
        accessList={accessList}
        setTriggerUpdate={setTriggerUpdate}
        page={page}
        totalPages={totalPages}
        handlePages={handlePages}
        setPagination={setPagination}
        //   editHandler={editHandler}
      />
    </Col>
    // </PageTemplate>
  );
};
