import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { PageTemplate } from "../../../shared/layout/PageTemplate";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { AccessService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { Col } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import authSlice from "../../../store/auth/authRedux";
import { ICompaniesDTO } from "../../companies/dto/ICompanies";
import { CompanyService } from "../../companies/service";

export const AccessRightsEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const [currentRole, setCurrentRole] = useState({} as any);
  const [accessRightsList, setAccessRightsList] = useState([]);
  const dispatch = useDispatch();
  const isAdmin = currentUser?.roles?.some(
    (role: string) => role === "ROLE_ADMIN"
  );
  const [companiesList, setCompaniesList] = useState<ICompaniesDTO[]>([]);
  const { accessRights } = useSelector((state: IAllStates) => state.enums);
  useEffect(() => {
    if (id) {
      AccessService.getRightsById(Number(id)).then((response) => {
        setCurrentRole(response);
        setAccessRightsList(response?.rights);
      });
    }
  }, [id]);
  const reloadPage = () => {
    window.location.reload();
  };
  const selectRoleHandler = (event: any) => {
    let newItem: any[] = [];
    let newArray = [];
    if (accessRightsList.includes(event.target.value as never) === false) {
      newItem = [event.target.value];
      newArray = accessRightsList.concat(newItem as any);
      setAccessRightsList(newArray);
    } else {
      newItem = event.target.value;
      newArray = accessRightsList.filter((item) => item !== (newItem as any));
      setAccessRightsList(newArray);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (id) {
      AccessService.updateRightsById(
        {
          name: currentRole?.name,
          rights: accessRightsList,
          company: currentRole?.company,
        },
        Number(id)
      )
        .then((response) => {
          if (response) {
            reloadPage();
          }
          SuccessToast(`${t("toasts.successfully_updated")}`);
          dispatch(
            authSlice.actions.setAuth((prev: any) => ({
              ...prev,
              accessRights: response?.rights,
            }))
          );
          // navigate('/admin/access-rights');

          // reloadPage()
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setCurrentRole((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const groupAccesRights = (): any => {
    const keys = Object.values(AccessRight);
    const temp: any = {};
    keys?.forEach((value: string) => {
      temp[value] = accessRights?.filter((right: string) => {
        if (right.includes("product") && right.includes("attribute")) {
          return value === "product";
        }
        if (right.includes("category_attribute")) {
          return value === "category_attribute";
        }
        if (right.includes("can_translate")) {
          return value === "can_translate";
        }
        if (right.includes("workflow_content")) {
          return value === "workflow_content";
        } else {
          return right.includes(value);
        }
      });
    });
    return temp;
  };
  useEffect(() => {
    CompanyService.getAllCompanies({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCompaniesList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  function handleSelectSingle(
    selectedSingle: SingleValue<{
      label: string;
      value: number | undefined | string;
    }>,
    inputKey: string
  ) {
    setCurrentRole((prev: any) => ({
      ...prev,
      [inputKey]: selectedSingle?.value,
    }));
  }
  return (
    // <PageTemplate title={`${t("access_rights.access_rights_edit")}`}>
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <Col md={4} className="mb-3">
              <label htmlFor="client" className="required-field">{`${t(
                "access_rights.role_name"
              )}`}</label>
              <input
                onChange={changeHandler}
                id="client"
                className="form-control w-100"
                name="name"
                value={currentRole?.name as string}
                placeholder={`${t("clients.enterName")}...`}
                type="text"
                maxLength={30}
              />
            </Col>
            <div className="col-md-4">
              <label htmlFor="title" className="required-field">{`${t(
                "clients.company"
              )}`}</label>
              <Select
                className="react-select-field"
                theme={themeSelect}
                isDisabled={true}
                key={currentRole?.id}
                options={(companiesList as unknown as ICompaniesDTO[])?.map(
                  (company) => {
                    return {
                      value: company.id,
                      label: company.title,
                    };
                  }
                )}
                defaultValue={{
                  label: currentRole?.company?.title,
                  value: currentRole?.company?.id,
                }}
                onChange={(e) => {
                  handleSelectSingle(e, "company");
                }}
              />
            </div>
          </div>
          <div className="row">
            {Object.keys(groupAccesRights() as any)?.map((right) => {
              return (
                <div key={right} className="col-md-3 my-1">
                  {/* <h5>{right}</h5> */}
                  <h4> {`${t(`access_rights.${right}`)}`}</h4>
                  {((groupAccesRights() as any)[right as any] as any)?.map(
                    (rights: string) => {
                      return (
                        <div className="form-check mb-2" key={rights}>
                          <input
                            className="form-check-input m-0"
                            key={rights}
                            //   id={role}
                            value={rights}
                            checked={accessRightsList?.includes(
                              rights as never
                            )}
                            type="checkbox"
                            onChange={selectRoleHandler}
                          />
                          <label
                            className="ms-2 form-check-label"
                            htmlFor="formCheck1"
                          >
                            {`${t(`access_rights.${rights}`)}`}
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
              );
            })}
            {/* {accessRights?.map((rights) => {
          return (
            <div className="col-md-3 my-1">
              <div className="form-check mb-2">
                <input
                  className="form-check-input m-0"
                  key={rights}
                  //   id={role}
                  value={rights}
                  checked={accessRightsList?.includes(rights as never)}
                  type="checkbox"
                  onChange={selectRoleHandler}
                />
                <label className="ms-2 form-check-label" htmlFor="formCheck1">
                  {`${t(`access_rights.${rights}`)}`}
                </label>
              </div>
            </div>
          );
        })} */}
          </div>
          {/* <div className="row">
              {accessRights?.map((rights) => {
                return (
                  <div className="col-md-3 my-1">
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input m-0"
                        key={rights}
                        id={id}
                        value={rights}
                        checked={accessRightsList?.includes(rights as never)}
                        type="checkbox"
                        onChange={selectRoleHandler}
                      />
                      <label
                        className="ms-2 form-check-label"
                        htmlFor="formCheck1"
                      >
                        {`${t(`access_rights.${rights}`)}`}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div> */}
          <div className="row">
            <div className="d-flex justify-content-end mt-3">
              <button className="btn btn-info" onClick={() => navigate(-1)}>
                {t("products.close")}
              </button>
              <button
                className="btn btn-primary ms-2"
                onClick={(e) => handleSubmit(e)}
              >
                {t("products.save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
    // </PageTemplate>
  );
};
export enum AccessRight {
  //Dashboard
  DASHBOARD = "dashboard",

  // Category & attribute
  CATEGORY_ATTRIBUTE = "category_attribute",

  // Category
  CATEGORY = "category",

  // Attributes

  ATTRIBUTE = "attribute",

  //Product
  PRODUCT = "product",

  //Translate section
  TRANSLATE = "translate",

  //Glossary section
  GLOSSARY_CAN = "glossary_can",

  //Content section
  CONTENT_PAGE_CAN = "content_page_can",

  //Import files section
  IMPORT_FILE_SECTION = "import_file_section",

  //Export files section
  EXPORT_CAN = "export_can",

  //Company details section
  COMPANY_DETAILS = "company_details",

  //User section
  USER = "user",

  //Client section
  CLIENT = "client",

  //Chat gpt prompts
  CHAT_GPT = "chat_gpt",

  //Workflow prompts
  WORKFLOW = "workflow",
  //Content workflow
  WORKFLOW_CONTENT = "workflow_content",

  //Brand prompts
  BRAND = "brand",

  //Api prompts
  API = "api",

  //Block prompts
  BLOCK = "block",
  //Overview prompts
  OVERVIEW = "overview",
  WORKFLOW_TRANSLATE = "can_translate",
}
