import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { IAllStates } from "../../store/rootReducer";

export const SideBarItemsWithLabels = ({
  sidebarItem,
  location,
  t,
  isUserAdmin,
}: any) => {
  // const { accessRights } = JSON.parse(
  //   localStorage.getItem("currentUser") ?? ""
  // );
  const { accessRights } = useSelector((state: IAllStates) => state.auth);

  const getCorrectSidebarLinks = (links: any) => {
    if (isUserAdmin) {
      // if user is admin, don't check
      return links;
    }
    return links?.filter(
      (link: {
        access: any;
        linkTo: string;
        isNested: boolean;
        children: any;
      }) => {
        if (link.isNested) {
          // if we have nested elements, return true to get childrens
          return !!link.children.filter((child: any) => {
            return accessRights?.includes(child.access);
          }).length;
        }

        return accessRights?.includes(link.access) || link.access === true; // if not, return only link with have access right
      }
    );
  };
  return (
    <>
      <li
        className={`menu-title ${
          !!getCorrectSidebarLinks(sidebarItem?.links).length ? "" : "d-none"
        }`}
      >
        <span data-key="t-menu">{t(sidebarItem?.title)}</span>
      </li>

      {getCorrectSidebarLinks(sidebarItem?.links)?.map(
        (
          { linkTo, translation, icon, isNested, children }: any,
          index: number
        ) => {
          return !isNested ? (
            <li className="nav-item" key={index}>
              <Link
                className={`nav-link menu-link ${
                  location?.pathname?.includes(linkTo) && "active"
                }`}
                to={linkTo}
              >
                {icon()}
                <span data-key="t-dashboards">{t(`${translation}`)}</span>
              </Link>
            </li>
          ) : (
            <li className="nav-item" key={linkTo}>
              <a
                className={`nav-link menu-link collapsed`}
                href={`#sidebarMultilevel-${index}`}
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="true"
                aria-controls={`sidebarMultilevel-${index}`}
              >
                {icon()}
                <span data-key="t-multi-level">{t(translation)}</span>
              </a>
              <div
                className={`menu-dropdown collapse show`}
                id={`sidebarMultilevel-${index}`}
              >
                <ul className="nav nav-sm flex-column">
                  {children
                    .filter((child: any) =>
                      accessRights?.includes(child.access)
                    ) // filter only childrens with correct access rights
                    ?.map((item: any, index: number) => {
                      return (
                        <li className={`nav-item `} key={index}>
                          <NavLink
                            to={item?.linkTo}
                            state={{ pagination: null }}
                            className={`nav-link`}
                            data-key="t-level-1.1"
                          >
                            {t(item.translation)}
                          </NavLink>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </li>
          );
        }
      )}
    </>
  );
};
