import { IExport, IExportQuery } from "./dto/IExports";
import { BaseModel } from "../../model/shared/BaseModel";
import { requests } from "../../utils/helpers/api.services";
import { CLIENT_EXPORT_URL } from "../../utils/helpers/api.routes";

interface IExportProductInterface {
  products: number[];
  clientExport: number;
}

export const ExportService = {
  getAllExports: (body: IExportQuery): Promise<BaseModel<IExport[]>> =>
    requests.get(`${CLIENT_EXPORT_URL}`, body),
  getAllExportProducts: (
    body: IExportQuery,
    id: number
  ): Promise<BaseModel<IExport[]>> =>
    requests.get(`${CLIENT_EXPORT_URL}/product-export/${id}`, body),
  getExportById: (id: number) => requests.get(`${CLIENT_EXPORT_URL}/${id}`),
  createExport: (body: IExport | undefined) =>
    requests.post(`${CLIENT_EXPORT_URL}`, body!),
  exportProducts: (body: IExportProductInterface) =>
    requests.post(`${CLIENT_EXPORT_URL}/products`, body!),
  updateExport: (body: IExport | undefined, id: number) =>
    requests.put(`${CLIENT_EXPORT_URL}/${id}`, body!),
  formatExport: (format: string, view: string, id: number) =>
    requests.put(`${CLIENT_EXPORT_URL}/export/${format}/${view}/${id}`),
  exportProduct: (client: any, id: number) => requests.put(`${CLIENT_EXPORT_URL}/product/${id}`, client),
  deleteExport: (id: number) => requests.delete(`${CLIENT_EXPORT_URL}/${id}`),
  exportVariation: (clientExport: any, id: number) => requests.put(`${CLIENT_EXPORT_URL}/variation/${id}`, { clientExport: clientExport }),
  removeExportProduct: (productId: any, exportId: number,) => requests.delete(`${CLIENT_EXPORT_URL}/export-product/${exportId}`, { product: productId }),
  removeVariationProduct: (productId: any, variationId: any, exportId: number,) => requests.delete(`${CLIENT_EXPORT_URL}/export-product/${exportId}`, { product: productId, variation: variationId }),
  getAllProductsByExport: (
    params: IExportQuery,
    id: String
  ): Promise<BaseModel<IExport[]>> =>
    requests.get(`${CLIENT_EXPORT_URL}/product-export/${id}`, params),
  getAllVariationsByExport: (
    params: IExportQuery,
    id: String
  ): Promise<BaseModel<IExport[]>> =>
    requests.get(`${CLIENT_EXPORT_URL}/variation-export/${id}`, params),
};

