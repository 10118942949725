import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IAllStates } from "../../../store/rootReducer";

export const DashboardCard = ({ cardStats, isAdmin }: any) => {
  const { t } = useTranslation();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);

  const showLink = (value: string) => {
    switch (value) {
      case "totalExports":
        if (accessRights?.includes("export_can_view")) {
          return true;
        } else {
          return false;
        }

      case "totalUsers":
        if (accessRights?.includes("user_can_view")) {
          return true;
        } else {
          return false;
        }
      case "totalClients":
        if (accessRights?.includes("client_can_view")) {
          return true;
        } else {
          return false;
        }
      case "totalBrands":
        if (isAdmin) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  };
  const getCorrectCardLink = (value: string) => {
    switch (value) {
      case "totalExports":
        return `/app/export`;
      case "totalUsers":
        return `/app/users`;
      case "totalClients":
        return `/app/clients`;
      case "totalBrands":
        return `/admin/brand`;
      default:
        return "";
    }
  };

  const getCorrectCardIcon = (value: string) => {
    switch (value) {
      case "totalExports":
        return <i className="ri-swap-box-line display-6 text-muted" />;
      case "totalUsers":
        return <i className="ri-user-search-line display-6 text-muted" />;
      case "totalClients":
        return <i className="ri-briefcase-5-fill display-6 text-muted" />;
      case "totalBrands":
        return <i className="ri-vip-crown-fill display-6 text-muted" />;
      default:
        return null;
    }
  };

  return (
    <div className={`col col-md-${isAdmin ? 3 : 4} border-end`}>
      <div className="py-4 px-3">
        <h5 className="text-muted text-uppercase fs-13">
          {t(`dashboard.cards.${cardStats[0]}`)}
        </h5>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            {getCorrectCardIcon(cardStats[0])}
          </div>
          <div className="flex-grow-1 ms-3">
            <h2 className="mb-0">
              <span className="counter-value">
                <CountUp
                  start={0}
                  key={cardStats[0]}
                  duration={1}
                  end={cardStats[1]?.value}
                  className="counter-value"
                  data-target={cardStats[1]?.value}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                    </div>
                  )}
                </CountUp>
              </span>
            </h2>
          </div>
        </div>
        {/* {showLink(cardStats[0]) && (
          <div className="d-flex justify-content-end">
            <Link
              to={getCorrectCardLink(cardStats[0]) as string}
              className="text-decoration-underline"
            >
              {t(`dashboard.link.${cardStats[0]}`)}
            </Link>
          </div>
        )} */}
      </div>
    </div>
  );
};
