import { BaseModel } from "../../model/shared/BaseModel";
import { ATTRIBUTES_URL, VALUE_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";
import { IProductsList, IProductsQuery } from "../products/dto/IProducts";
import { IAttributes, IAttributesQuery } from "./names/dto/IAttributes";
import {
  IAttributeValue,
  IAttributeValueList,
  IAttributeValueQuery,
} from "./values/dto/IAttributeValue";

export const AttributesService = {
  getAllAttributes: (
    params: IAttributesQuery
  ): Promise<BaseModel<IAttributes[]>> =>
    requests.get(`${ATTRIBUTES_URL}`, params),
  getAttributeById: (id: number) => requests.get(`${ATTRIBUTES_URL}/${id}`),
  updateAttributeById: (id: number, params: IAttributes) =>
    requests.put(`${ATTRIBUTES_URL}/${id}`, params),
  createAttribute: (params: IAttributes) =>
    requests.post(`${ATTRIBUTES_URL}/`, params),
  createAttributeValue: (params: IAttributeValue) =>
    requests.post(`${VALUE_URL}`, params),
  getAttributeValueById: (id: number) => requests.get(`${VALUE_URL}/${id}`),
  getAllAttributeValues: (
    params: IAttributeValueQuery
  ): Promise<BaseModel<IAttributeValueList[]>> =>
    requests.get(`${VALUE_URL}`, params),
  deleteAttributeValue: (id: number) => requests.delete(`${VALUE_URL}/${id}`),
  updateAttributeValue: (params: IAttributeValue, id: number) =>
    requests.put(`${VALUE_URL}/${id}`, params),
  getAllProductsByAttributes: (
    params: IProductsQuery,
    id: String
  ): Promise<BaseModel<IProductsList[]>> =>
    requests.get(`${ATTRIBUTES_URL}/product-attributes/${id}`, params),
  getAllAttributesWithValues: () =>
    requests.get(`${ATTRIBUTES_URL}/attribute-values`),
  multiAssign: (params: any) =>
    requests.put(`${ATTRIBUTES_URL}/multi-assign`, params),
  multiDelete: (params: any) =>
    requests.post(`${ATTRIBUTES_URL}/attribute-value-multi-delete`, params),
};
