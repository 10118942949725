import { CONTENT_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";
import { IProductTranslation } from "../products/dto/IProducts";
import { IContentCreate, IContentEdit, IContentVariationList, IContentVariationParams } from "./dto/IContent";



export const ContentService = {
    getAllContents: (params: IContentVariationList) => requests.get(`${CONTENT_URL}`, params),
    createContent: (params: IContentCreate) => requests.post(`${CONTENT_URL}`, params),
    getContent: (id: number) => requests.get(`${CONTENT_URL}/${id}`),
     getContentView: (id: number) => requests.get(`${CONTENT_URL}/view/${id}`),
    updateContent: (params: IContentEdit, id: number) => requests.put(`${CONTENT_URL}/${id}`, params),
    deleteContent: (id: number) => requests.delete(`${CONTENT_URL}/${id}`),
    createVariation: (params: IContentVariationParams) => requests.post(`${CONTENT_URL}/variation`, params),
    getAllVariations: (params: IContentVariationList) => requests.get(`${CONTENT_URL}/variation/list`, params),
    getVariation: (id: number) => requests.get(`${CONTENT_URL}/variation/${id}`),
    updateVariation: (params: IContentVariationParams, id: number) => requests.put(`${CONTENT_URL}/variation/${id}`, params),
    deleteVariation: (id: number) => requests.delete(`${CONTENT_URL}/variation/${id}`),
    addNewVaraiton: (id: number) => requests.put(`${CONTENT_URL}/variation/chat-gpt/${id}`),
    addCloneVaraiton: (id: number) => requests.put(`${CONTENT_URL}/variation/clone-content-variation/${id}`),
    addNewAiVaraiton: (id: number) => requests.put(`${CONTENT_URL}/variation/generate-content-variation/${id}`),
    postTranslation: (params: IProductTranslation) => requests.post(`${CONTENT_URL}/translation`, params),
}