import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


export const ProductsForm = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body d-flex align-items-center justify-content-between">
                        <h4 className="card-title mg-b-0">{t("products.create_new_product")}</h4>
                        <button className="btn btn-primary"
                            onClick={() => navigate("/app/products/create")}>{t("global.add")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}