import { createSlice } from "@reduxjs/toolkit";

const step4PaginationSlice = createSlice({
  name: "step4Pagination",
  initialState: {},

  reducers: {
    setStep4Pagination: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export default step4PaginationSlice;
