import { IProductsList } from "../dto/IProducts";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { CustomImageHandler } from "../../../shared/components/CustomImageHandler";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";

interface IProductsListProps {
  nameOfTable?: string;
  product: IProductsList;
  deleteHandler: (
    product: IProductsList,
    selectedProduct: IProductsList
  ) => void;
  onEdit: (e: any, id: number) => void;
  onView: (id: number) => void;
}

export const ProductsCardView = ({
  nameOfTable,
  product,
  deleteHandler,
  onEdit,
  onView,
}: IProductsListProps) => {
  const { t } = useTranslation();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState<IProductsList>();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const editDisabledBtn = () => {
    if (
      nameOfTable === "baseData" &&
      accessRights?.includes("product_base_data_can_edit")
    ) {
      return false;
    } else if (
      nameOfTable === "attributes" &&
      accessRights?.includes("product_attributes_can_edit")
    ) {
      return false;
    } else if (
      nameOfTable === "content" &&
      accessRights?.includes("product_content_can_edit")
    ) {
      return false;
    } else if (nameOfTable === "pending") {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <div className="card h-100">
        <Carousel className={`${!editDisabledBtn() && "cursor-pointer"}`}>
          {product?.productPictures?.length ? (
            product?.productPictures?.map((productImage) => {
              return (
                <Carousel.Item key={productImage?.id}>
                  {!editDisabledBtn() ? (
                    <div onClick={(e) => onEdit(e, product?.id as number)}>
                      <CustomImageHandler
                        path={`${productImage?.path}`}
                        classes="product-slider-image "
                        alt="Product"
                      />
                    </div>
                  ) : (
                    <div>
                      <CustomImageHandler
                        path={`${productImage?.path}`}
                        classes="product-slider-image "
                        alt="Product"
                      />
                    </div>
                  )}
                </Carousel.Item>
              );
            })
          ) : (
            <img
              onClick={(e) => onEdit(e, product?.id as number)}
              className="product-slider-image "
              src="/images/No-Image-Placeholder.png"
              alt="something"
            />
          )}
        </Carousel>
        <div className="card-body ">
          <div className="d-flex justify-content-between align-items-center mb-2">
            {!editDisabledBtn() ? (
              <h4
                className="card-title mb-0 cursor-pointer w-100"
                onClick={(e) => onEdit(e, product?.id as number)}
              >
                {product?.title}
              </h4>
            ) : (
              <h4 className="card-title mb-0 w-100">{product?.title}</h4>
            )}

            <div
              id={`toggle-${product?.id}`}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="ri-settings-2-line text-muted cursor-pointer"></i>
            </div>
            <ul
              className="dropdown-menu"
              aria-labelledby={`toggle-${product?.id}`}
            >
              <li onClick={() => onView(product?.id as number)}>
                <span className="dropdown-item">
                  <i className="ri-eye-fill text-primary align-middle me-1" />
                  {t("global.view")}
                </span>
              </li>
              {!editDisabledBtn() && (
                <li onClick={(e) => onEdit(e, product?.id as number)}>
                  <span className="dropdown-item">
                    <i className="ri-edit-2-fill text-warning align-middle me-1" />
                    {t("global.edit")}
                  </span>
                </li>
              )}
              {accessRights?.includes("product_can_delete") && (
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      setSelectedProduct(product);
                      handleShow();
                    }}
                  >
                    <span>
                      <i className="ri-delete-bin-fill text-danger align-middle me-1" />
                      {t("global.delete")}
                    </span>
                  </a>
                </li>
              )}
            </ul>
          </div>
          <p
            className="card-text cursor-pointer text-long-height"
            onClick={(e) => onEdit(e, product?.id as number)}
          >
            {product?.category?.title ? product?.category?.title : "-"}
          </p>
        </div>
      </div>
      <ConfirmDelete
        show={show}
        setShow={setShow}
        itemName={selectedProduct?.title}
        deleteHandler={deleteHandler}
        selectedProduct={selectedProduct}
      />
    </>
  );
};
