import {BaseModel} from '../../model/shared/BaseModel';
import {requests} from '../../utils/helpers/api.services';
import {CHATGPT_URL, PRODUCT_URL, VARIATION_URL, WORKFLOW_URL} from '../../utils/helpers/api.routes';
import {
    IChatGptMessage,
    IProductBlock,
    IProductsList,
    IProductsQuery,
    IProductStatus,
    IProductTranslation,
    IProductVariationList,
    IProductVariationParams,
    IProductVariationUpdate,
    ITranslateAll
} from './dto/IProducts';

export const ProductsService = {
    getAllProducts: (params: IProductsQuery, tab: String): Promise<BaseModel<IProductsList[]>> => requests.get(`${PRODUCT_URL}/${tab}`, params),
    createProduct: (params: FormData) => requests.post(`${PRODUCT_URL}`, params),
    updateProductById: (params: FormData, id: number, isWorkflowUpdate = false, workflowId?: number) => requests.put(isWorkflowUpdate ? `${WORKFLOW_URL}/base-data/update-product/${id}/${workflowId}` : `${PRODUCT_URL}/${id}`, params),
    updateProductAttributesById: (params: FormData, id: number, isWorkflowUpdate = false, workflowId?: number) => requests.put(isWorkflowUpdate ? `${WORKFLOW_URL}/attributes/update-product/${id}/${workflowId}` : `${PRODUCT_URL}/attribute/${id}`, params),
    updateProductContentById: (params: FormData, id: number, isWorkflowUpdate = false, workflowId?: number) => requests.put(isWorkflowUpdate ? `${WORKFLOW_URL}/copywriting/product-update/${workflowId}/${id}` : `${PRODUCT_URL}/content/${id}`, params),
    deleteProductById: (id: number) => requests.delete(`${PRODUCT_URL}/${id}`),
    getProductById: (id: number, tab: String, isWorkflowUpdate = false, workflowId?: number) => requests.get(isWorkflowUpdate ? `${WORKFLOW_URL}/copywriting/product-data/${id}/${workflowId}` : `${PRODUCT_URL}/edit/${id}/${tab}`),
    getProductLangById: (id: number, tab: String, lng: string) => requests.get(`${PRODUCT_URL}/edit/${id}/${tab}/${lng}`),
    deleteImageById: (id: number) => requests.delete(`${PRODUCT_URL}/picture/${id}`),
    getAllVariations: (params: IProductVariationList) => requests.get(`${VARIATION_URL}/list`, params),
    createVariation: (params: IProductVariationParams) => requests.post(VARIATION_URL, params),
    updateVariation: (params: IProductVariationUpdate, id: number) => requests.put(`${VARIATION_URL}/${id}`, params),
    getVariation: (id: number) => requests.get(`${VARIATION_URL}/${id}`),
    deleteVariation: (id: number) => requests.delete(`${VARIATION_URL}/${id}`),
    addNewVaraiton: (id: number) => requests.put(`${PRODUCT_URL}/chat-gpt/${id}`),
    addCloneVaraiton: (id: number) => requests.put(`${PRODUCT_URL}/clone-variation/${id}`),
    updateProductBlockById: (params: IProductBlock, id: number) => requests.put(`${PRODUCT_URL}/block/${id}`, params),
    postTranslation: (params: IProductTranslation) => requests.post(`${PRODUCT_URL}/translation`, params),
    getViewProductsById: (id: number) => requests.get(`${PRODUCT_URL}/view/${id}`),
    updateProductStatus: (params: IProductStatus, id: number) => requests.put(`${PRODUCT_URL}/status/${id}`, params),
    addNewAiVaraiton: (id: number) => requests.put(`${PRODUCT_URL}/generate-variation/${id}`),
    chatGptMessages: (params: IChatGptMessage) => requests.post(`${CHATGPT_URL}`, params),
    translateAll: (id: number, params: ITranslateAll) => requests.put(`${PRODUCT_URL}/multiple-languages/translation/${id}`, params),
    cloneProductContent: (id: number) => requests.put(`${PRODUCT_URL}/clone-product-content/${id}`),

}