import React, { useCallback, useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IAllStates } from "../../store/rootReducer";
import { useSelector } from "react-redux";
import { IWorkflow } from "../workflow/dto/IWorkflow";
import { useNavigate } from "react-router";
import { ContentWorkflowService } from "./service";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { ContentWorkflowCreate } from "./components/ContentWorkflowCreate";
import { ICategoryChildren } from "../category/values/dto/ICategory";
import { formatDate, getCorrectStatus } from "../../shared/functions/Functions";
import { ConfirmDelete } from "../../shared/components/ConfirmDelete";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../shared/components/Select2";
import { ClientService } from "../clients/service";
import _debounce from "lodash/debounce";
import { Pagination } from "../../shared/paginations/Paginations";
import { EmptyState } from "../../shared/components/EmptyState";

export const handleStepContentNavigate = (
  status: string,
  id: number,
  navigate: any
) => {
  switch (status) {
    case "SETTING_UP":
      return navigate(`/app/content-workflow/setting-up/${id}`);
    case "BASE":
      return navigate(`/app/content-workflow/base/${id}`);
    case "COPY_WRITING":
      return navigate(`/app/content-workflow/copywriting/${id}`);
    case "TRANSLATION":
      return navigate(`/app/content-workflow/translation/${id}`);
    case "EXPORT":
      return navigate(`/app/content-workflow/export/${id}`);
    default:
      return navigate(`/app/content-workflow/setting-up/${id}`);
  }
};
const initialPagination = {
  page: 1,
  perPage: 10,
  client: null,
  status: undefined,
  fromDeadline: null,
  toDeadline: null,
};

export const ContentWorkflow = () => {
  const navigate = useNavigate();
  const categories = useSelector((state: IAllStates) => state.category);
  const [selectedWorkflow, setselectedWorkflow] = useState<any>();
  const [createField, setCreateField] = useState(false);
  const [completeTab, setCompleteTab] = useState(false);
  const { t } = useTranslation();
  const [clientList, setClientList] = useState([]);
  const { workflowContentStatus } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [firstStap, setFirstStap] = useState<IWorkflow | undefined>();
  const [workflowList, setWorkflowList] = useState<IWorkflow[] | undefined>();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [completedList, setCompletedList] = useState<IWorkflow[] | undefined>();
  const [currentWorkflow, setCurrentWorkflow] = useState<
    IWorkflow | undefined
  >();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser.roles.includes("ROLE_COMPANY_ADMIN");
  const [paginationCompleted, setPaginationCompleted] = useState({
    page: 1,
    perPage: 10,
    client: null,
    workflowStatus: undefined,
    fromDeadline: null,
    toDeadline: null,
  });
  const [pageCompleted, setPageCompleted] = useState(1);
  const [totalPagesCompleted, setTotalPagesCompleted] = useState(0);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [selectedMainCategory, setSelectedMainCategory] = useState<
    ICategoryChildren[]
  >([]);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    client: null,
    status: undefined,
    fromDeadline: null,
    toDeadline: null,
  });
  const deleteHandler = () => {
    selectedWorkflow?.id &&
      ContentWorkflowService.deleteWorkflowById(selectedWorkflow?.id)
        .then((response) => {
          if (response === "") {
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const [page, setPage] = useState(1);
  const [totalCompletedPages, setTotalCompletedPages] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const statusColor = (status: string) => {
    switch (status) {
      case "SETTING_UP":
        return ["#ffc4e8", "#870B56", "1px solid #870B56"];
      case "BASE":
        return ["#F5F19F", "#9E9E34", "1px solid #9E9E34"];
      case "BASE_DATA":
        return ["#F5CA9F", "#994409", "1px solid #994409"];
      case "ATTRIBUTES":
        return ["#6AEEFF", "#266C6C", "1px solid #266C6C"];
      case "COPY_WRITING":
        return ["#BFF5AD", "#1B7405", "1px solid #1B7405"];
      case "TRANSLATION":
        return ["#ADE6F5", "#0C516F", "1px solid #0C516F"];
      case "OVERVIEW":
        return ["#ebc8fa", "#651387", "1px solid #651387"];
      case "EXPORT":
        return ["#EE9898", "#7C1616", "1px solid #7C1616"];
      default:
        return ["#c2eff0", "#0B6D6F", "1px solid #0B6D6F"];
    }
  };
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const handlePaginationStatus = (obj: any) => {
    setPagination((prev) => ({ ...prev, status: obj?.value }));
  };

  const handlePaginationClient = (obj: any) => {
    if (!completeTab) {
      setPagination((prev) => ({ ...prev, client: obj?.value }));
    } else {
      setPaginationCompleted((prev) => ({ ...prev, client: obj?.value }));
    }
  };

  const handlePaginationFromDate = (e: any) => {
    setPagination((prev) => ({
      ...prev,
      fromDeadline: e,
    }));
  };

  const handlePaginationToDate = (e: any) => {
    setPagination((prev) => ({
      ...prev,
      toDeadline: e,
    }));
  };

  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      if (!completeTab) {
        setPagination((prev) => ({ ...prev, [name]: value }));
      } else {
        setPaginationCompleted((prev) => ({ ...prev, [name]: value }));
      }
    },
    200
  );
  const proceedHandler = (workflowId: number, status: string, tabs: any) => {
    const linkStatus = tabs?.some((tab: string) => tab === status);
    handleStepContentNavigate(
      linkStatus === true ? status : tabs[0],
      Number(workflowId),
      navigate
    );
  };
  const EditorWithContent = useCallback(() => {
    return (
      <ContentFilters
        changeFilterHandler={changeFilterHandler}
        t={t}
        pagination={pagination}
        workflowContentStatus={workflowContentStatus}
        handlePaginationStatus={handlePaginationStatus}
        clientList={clientList}
        handlePaginationClient={handlePaginationClient}
        completeTab={completeTab}
      />
    );
  }, [completeTab, clientList]);
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (currentWorkflow?.id) {
      ContentWorkflowService.updateWorkflowById(
        currentWorkflow?.id,
        currentWorkflow as unknown as IWorkflow
      )
        .then((response) => {
          if (response?.id) {
            SuccessToast(
              `${t("toasts.successfully_updated")} ${response.name}`
            );
          }
        })
        .catch((error) => ErrorToast(error));
    } else {
      ContentWorkflowService.createWorkflow({
        ...(firstStap as any),
        deadline: (firstStap?.deadlineData as any)
          ?.toISOString()
          ?.split("T")[0],
      })
        .then((response) => {
          if (response?.id) {
            setFirstStap({} as any);
            SuccessToast("Success create workflow");
            setCreateField(false);
          }
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    ContentWorkflowService.getAllWorkflows(pagination)
      .then((response) => {
        const { data } = response;
        setTotalPages(Math.ceil(response?.count / response?.perPage));
        setWorkflowList(data);
        setPage(response?.page);
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, triggerUpdate, createField]);
  useEffect(() => {
    ClientService.getAllClients(pagination)
      .then((response) => {
        const { data } = response;
        setClientList(data as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    if (completeTab) {
      setPagination(initialPagination);
      ContentWorkflowService.getAllWorkflows({
        ...paginationCompleted,
        status: "COMPLETED",
      })
        .then((response) => {
          const { data } = response;
          setTotalCompletedPages(
            Math.ceil(response?.count / response?.perPage)
          );
          setCompletedList(data);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [paginationCompleted, completeTab, triggerUpdate, createField]);

  function timeUntilDeadline(deadline: any) {
    const currentDate = new Date();
    const deadlineDate = new Date(deadline);
    const timeDifference = (deadlineDate as any) - (currentDate as any);
    if (timeDifference <= 0) {
      return "danger";
    }
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return `success`;
  }
  return (
    <>
      {accessRights?.includes("workflow_content_can_create") &&
        !createField && (
          <div className="card ">
            <div className="card-body d-flex align-items-center justify-content-between">
              <h4 className="card-title mg-b-0">
                {t("content_workflow.new_workflow")}
              </h4>
              <button
                className="btn btn-primary"
                onClick={() => setCreateField(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        )}
      {createField && (
        <Form onSubmit={submitHandler}>
          <div className="card ">
            <div className="card-body">
              <ContentWorkflowCreate
                firstStap={firstStap}
                setFirstStap={setFirstStap}
                workflowStatus={workflowContentStatus}
                selectedMainCategory={selectedMainCategory}
                setSelectedMainCategory={setSelectedMainCategory}
                categories={categories}
              />
              <div className="mt-2 d-flex justify-content-end">
                <button
                  className="btn btn-info me-2"
                  onClick={() => {
                    setCreateField(false);
                    // setCurrentClient({} as any);
                  }}
                >
                  {t("global.cancel")}
                </button>
                <button className="btn btn-primary" type="submit">
                  {t("global.create")}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
      <>
        <div className="card">
          <EditorWithContent />
          <div className="card-body">
            <ul className="nav nav-tabs mb-3" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-selected="true"
                  tabIndex={-1}
                  onClick={() => {
                    setCompleteTab(false);
                  }}
                >
                  {t("global.active")}
                </a>
              </li>
              {(isAdmin || isCompanyAdmin || isManager) && (
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    onClick={() => {
                      setCompleteTab(true);
                    }}
                    data-bs-toggle="tab"
                    href="#product1"
                    role="tab"
                    aria-selected="false"
                  >
                    {t("global.completed")}
                  </a>
                </li>
              )}
            </ul>
            {/* Tab panes */}
            <div className="tab-content  text-muted">
              <div className="tab-pane active" id="home" role="tabpanel">
                {totalPages ? (
                  <>
                    <p className="mb-0">
                      <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">{`${t("global.id")}`}</th>
                              <th scope="col">{`${t("workflow.name")}`}</th>
                              <th scope="col">{`${t("global.manager")}`}</th>
                              <th scope="col">{`${t("global.created_at")}`}</th>
                              <th scope="col">{`${t("workflow.deadline")}`}</th>
                              <th scope="col">{`${t("users.status")}`}</th>
                              {(accessRights?.includes(
                                "workflow_content_can_update"
                              ) ||
                                accessRights?.includes(
                                  "workflow_content_can_delete"
                                )) && (
                                <th scope="col">{`${t("global.actions")}`}</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {workflowList?.map((workflow: any) => {
                              return (
                                <tr>
                                  <td>
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => {
                                        accessRights?.includes(
                                          "workflow_can_update"
                                        ) &&
                                          proceedHandler(
                                            workflow?.id,
                                            workflow?.status,
                                            workflow?.tabs
                                          );
                                      }}
                                    >
                                      #{workflow?.id}
                                    </span>
                                  </td>
                                  <td className="text-start">
                                    {workflow?.name}
                                  </td>
                                  <td>
                                    {workflow?.copywriters
                                      ?.map((writer: any) =>
                                        writer.user
                                          ? writer.user.firstName.concat(
                                              ` ${writer.user.lastName}`
                                            )
                                          : "-"
                                      )
                                      .join(", ")}
                                  </td>
                                  <td>{formatDate(workflow?.createdAt)}</td>
                                  <td>
                                    <span
                                      className={`text-${timeUntilDeadline(
                                        workflow?.deadline
                                      )}`}
                                    >
                                      {workflow?.deadline}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className="badge badge-outline-warning badge-soft-warning"
                                      style={{
                                        backgroundColor: statusColor(
                                          workflow?.status
                                        )[0],
                                        color: statusColor(workflow?.status)[1],
                                        border: statusColor(
                                          workflow?.status
                                        )[2],
                                      }}
                                    >
                                      {getCorrectStatus(workflow?.status, t)}
                                    </span>
                                  </td>
                                  {/* <td>
                                                                backend
                                                            </td> */}
                                  {(accessRights?.includes(
                                    "workflow_content_can_update"
                                  ) ||
                                    accessRights?.includes(
                                      "workflow_content_can_delete"
                                    )) && (
                                    <td>
                                      <div className="d-flex align-items-center justify-content-center">
                                        {accessRights?.includes(
                                          "workflow_content_can_update"
                                        ) && (
                                          // true
                                          <button
                                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                            onClick={() =>
                                              proceedHandler(
                                                workflow?.id,
                                                workflow?.status,
                                                (workflow as any)?.tabs
                                              )
                                            }
                                          >
                                            {t("workflow.proceed")}
                                          </button>
                                        )}
                                        {accessRights?.includes(
                                          "workflow_content_can_delete"
                                        ) && (
                                          <button
                                            className="btn btn-sm btn-danger d-flex align-items-center"
                                            onClick={() => {
                                              handleShow();
                                              setselectedWorkflow(workflow);
                                              console.log("delete in progress");
                                            }}
                                          >
                                            <i className="ri-delete-bin-fill me-1" />
                                            {`${t("global.delete")}`}
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                  {/* 8 actions */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </p>
                    <div className="d-flex justify-content-end">
                      {totalPages! <= 1 ? (
                        ""
                      ) : (
                        <Pagination
                          page={page!}
                          totalPages={totalPages!}
                          handlePagination={handlePages!}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <EmptyState />
                )}
              </div>
              <div className="tab-pane " id="product1" role="tabpanel">
                {totalCompletedPages ? (
                  <>
                    <p className="mb-0">
                      <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">{`${t("global.id")}`}</th>
                              <th scope="col">{`${t("workflow.name")}`}</th>
                              <th scope="col">{`${t("global.manager")}`}</th>
                              <th scope="col">{`${t("global.created_at")}`}</th>
                              <th scope="col">{`${t("workflow.deadline")}`}</th>
                              <th scope="col">{`${t("users.status")}`}</th>
                              <th scope="col">{`${t(
                                "workflow.content_completed"
                              )}`}</th>
                              <th scope="col">{`${t(
                                "workflow.translation_completed"
                              )}`}</th>
                              <th scope="col">{`${t(
                                "workflow.completed"
                              )}`}</th>
                              {(accessRights?.includes(
                                "workflow_content_can_update"
                              ) ||
                                accessRights?.includes(
                                  "workflow_content_can_delete"
                                )) && (
                                <th scope="col">{`${t("global.actions")}`}</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {completedList?.map((workflow: any) => {
                              return (
                                <tr>
                                  <td>
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => {
                                        // (accessRights?.includes(
                                        //   "workflow_can_update"
                                        // ) || isDashboard === true)
                                        true &&
                                          proceedHandler(
                                            workflow?.id,
                                            workflow?.status,
                                            workflow?.tabs
                                          );
                                      }}
                                    >
                                      #{workflow?.id}
                                    </span>
                                  </td>
                                  {/* 1 id */}
                                  <td className="text-start">
                                    {workflow?.name}
                                  </td>
                                  {/* 2 name */}

                                  <td>
                                    {workflow?.copywriters
                                      ?.map((writer: any) =>
                                        writer.user
                                          ? writer.user.firstName.concat(
                                              ` ${writer.user.lastName}`
                                            )
                                          : "-"
                                      )
                                      .join(", ")}
                                  </td>
                                  {/* 3 copywriter */}
                                  <td>{formatDate(workflow?.createdAt)}</td>
                                  {/* 4 createdAt */}
                                  <td>
                                    <span
                                      className={`text-${timeUntilDeadline(
                                        workflow?.deadline
                                      )}`}
                                    >
                                      {workflow?.deadline}
                                    </span>
                                  </td>
                                  {/* 5 deadlineData */}
                                  <td>
                                    <span className="badge badge-outline-success badge-soft-success">
                                      {getCorrectStatus(workflow?.status, t)}
                                    </span>
                                  </td>
                                  {/* 6 status */}
                                  <td>
                                    {
                                      workflow?.completedWorkflowStats
                                        ?.totalProducts
                                    }
                                  </td>
                                  <td>
                                    {
                                      workflow?.completedWorkflowStats
                                        ?.translationData
                                    }
                                  </td>
                                  <td>
                                    {" "}
                                    {workflow?.completedAt
                                      ? formatDate(workflow?.completedAt)
                                      : null}
                                  </td>
                                  {/* 7 current step */}
                                  {(accessRights?.includes(
                                    "workflow_content_can_update"
                                  ) ||
                                    accessRights?.includes(
                                      "workflow_content_can_delete"
                                    )) && (
                                    <td>
                                      <div className="d-flex align-items-center justify-content-center">
                                        {
                                          // (accessRights?.includes(
                                          //   "workflow_can_update"
                                          // ) || isDashboard === true)
                                          true && (
                                            <button
                                              className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                              onClick={() =>
                                                proceedHandler(
                                                  workflow?.id,
                                                  workflow?.status,
                                                  (workflow as any)?.tabs
                                                )
                                              }
                                            >
                                              {t("workflow.proceed")}
                                            </button>
                                          )
                                        }
                                        {accessRights?.includes(
                                          "workflow_content_can_delete"
                                        ) &&
                                          //   !isDashboard
                                          true && (
                                            <button
                                              className="btn btn-sm btn-danger d-flex align-items-center"
                                              onClick={() => {
                                                handleShow();
                                                setselectedWorkflow(workflow);
                                                console.log(
                                                  "delete in progress"
                                                );
                                              }}
                                            >
                                              <i className="ri-delete-bin-fill me-1" />
                                              {`${t("global.delete")}`}
                                            </button>
                                          )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </p>
                    <div className="d-flex justify-content-end">
                      {totalCompletedPages! <= 1 ? (
                        ""
                      ) : (
                        <Pagination
                          page={page!}
                          totalPages={totalCompletedPages!}
                          handlePagination={handlePages!}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <EmptyState />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
      <ConfirmDelete
        show={show}
        setShow={setShow}
        itemName={selectedWorkflow?.name}
        deleteHandler={deleteHandler}
        product={selectedWorkflow}
        selectedProduct={selectedWorkflow}
      />
    </>
  );
};

const ContentFilters = ({
  changeFilterHandler,
  t,
  pagination,
  workflowContentStatus,
  handlePaginationStatus,
  clientList,
  handlePaginationClient,
  completeTab,
}: any) => {
  return (
    <>
      <div className="row">
        <div className="col-md-4 my-2">
          <input
            className="form-control me-1"
            name={"name"}
            onChange={changeFilterHandler}
            placeholder={`${t("export_client.searchName")}...`}
            type="text"
          />
        </div>
        <div className="col-md-4 my-2">
          <Select
            className="react-select-field"
            isClearable={true}
            placeholder={t("products.status")}
            theme={themeSelect}
            isDisabled={completeTab}
            defaultValue={
              completeTab
                ? { label: "COMPLETED", value: "COMPLETED" }
                : pagination?.status
            }
            options={
              workflowContentStatus
                ?.filter((status: string) => status !== "COMPLETED")
                .map((status: string) => ({
                  label: status.replace("_", " "),
                  value: status,
                })) as any
            }
            onChange={(e) => {
              handlePaginationStatus(
                e as SingleValue<{ label: string; value: string }>
              );
            }}
          />
        </div>
        <div className="col-md-4 my-2">
          <Select
            className="react-select-field"
            isClearable={true}
            placeholder={t("global.client")}
            theme={themeSelect}
            options={clientList?.map((item: any) => ({
              label: item?.name,
              value: item?.id,
            }))}
            onChange={(e) => {
              handlePaginationClient(
                e as SingleValue<{ label: string; value: string }>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};
