import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IBrand } from "../dto/IBrand";
import React, { SetStateAction, useState } from "react";
import { BrandService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { Pagination } from "../../../shared/paginations/Paginations";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { EmptyState } from "../../../shared/components/EmptyState";
import { LoadingAnimation } from "../../../shared/components/Loading";

interface IBrandsListProps {
  brandsList: IBrand[] | undefined;
  changeFilterHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  editHandler: (brandId: number) => void;
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  page: number;
  totalPages: number;
  handlePages: (updateNumber: number) => void;
  setState: React.Dispatch<SetStateAction<IBrand | undefined>>;
  state: IBrand | undefined;
  accessRights: string[];
}

export const BrandList = ({
  accessRights,
  brandsList,
  changeFilterHandler,
  editHandler,
  page,
  totalPages,
  handlePages,
  setTriggerUpdate,
}: IBrandsListProps) => {
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState<IBrand | undefined>();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const deleteHandler = () => {
    selectedClient?.id &&
      BrandService.deleteBrandById(selectedClient?.id)
        .then((response) => {
          if (response === "") {
            SuccessToast(
              `${t("toasts.successfully_deleted")} ${selectedClient.name}`
            );
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">{`${t("brand.listOfBrands")}`}</h4>
        </div>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-end">
          <div className="col-12 col-sm-6 col-md-4">
            <input
              className="form-control mb-3"
              name={"name"}
              onChange={changeFilterHandler}
              placeholder={`${t("brand.searchName")}...`}
              type="text"
              maxLength={30}
            />
          </div>
        </div>
        {brandsList == undefined ? (
          <LoadingAnimation />
        ) : brandsList.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">{`${t("global.id")}`}</th>
                  <th scope="col">{`${t("global.name")}`}</th>
                  {(accessRights?.includes("brand_can_update") ||
                    accessRights?.includes("brand_can_delete")) && (
                    <th scope="col">{`${t("global.actions")}`}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {brandsList?.map((brand) => {
                  return (
                    <tr key={brand?.id}>
                      <td>
                        <Link to="#" className="fw-medium">
                          #{brand?.id}
                        </Link>
                      </td>
                      <td>{brand?.name}</td>
                      {(accessRights?.includes("brand_can_update") ||
                        accessRights?.includes("brand_can_delete")) && (
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            {accessRights?.includes("brand_can_update") && (
                              <button
                                className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                // onClick={() => editHandler(content?.id!)}
                                onClick={() => editHandler(brand?.id as number)}
                              >
                                <i className="ri-edit-2-fill me-1" />
                                {`${t("global.edit")}`}
                              </button>
                            )}
                            {accessRights?.includes("brand_can_delete") && (
                              <button
                                className="btn btn-sm btn-danger d-flex align-items-center"
                                onClick={() => {
                                  setSelectedClient(brand);
                                  handleShow();
                                }}
                              >
                                <i className="ri-delete-bin-fill me-1" />
                                {`${t("global.delete")}`}
                              </button>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedClient?.name}
          deleteHandler={deleteHandler}
          selectedProduct={selectedClient}
        />
      </div>
    </div>
  );
};
