import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import React, { SetStateAction, useState } from "react";
import { IAttributeValue, IAttributeValueList } from "../dto/IAttributeValue";
import { Link } from "react-router-dom";
import { AttributesService } from "../../service";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import { ConfirmDelete } from "../../../../shared/components/ConfirmDelete";
import { Pagination } from "../../../../shared/paginations/Paginations";
import { EmptyState } from "../../../../shared/components/EmptyState";
import { LoadingAnimation } from "../../../../shared/components/Loading";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";

interface IAttributeValueListProps {
  valueList: IAttributeValueList[] | undefined;
  deleteHandler: (id: number, title: string) => void;
  setCurrentValue: React.Dispatch<SetStateAction<IAttributeValue | undefined>>;
  setIsMenuOpened: React.Dispatch<SetStateAction<boolean>>;
  page: number;
  totalPages: number;
  handlePages: (updateNumber: number) => void;
}

export const AttributeValueList = ({
  valueList,
  deleteHandler,
  setCurrentValue,
  totalPages,
  page,
  handlePages,
  setIsMenuOpened,
}: IAttributeValueListProps) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<IAttributeValueList>();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const editHandler = (id: number) => {
    AttributesService.getAttributeValueById(id)
      .then((response) => {
        setCurrentValue(response);
        setIsMenuOpened(true);
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">
            {`${t("attributes.listOfValues")}`}
          </h4>
        </div>
      </div>
      <div className="card-body">
        {valueList == undefined ? (
          <LoadingAnimation />
        ) : valueList.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">{`${t("global.id")}`}</th>
                  <th scope="col">{`${t("global.name")}`}</th>
                  <th scope="col">
                    {accessRights?.includes("attribute_can_delete")
                      ? `${t("global.actions")}`
                      : `${t("global.action")}`}
                  </th>
                </tr>
              </thead>
              <tbody>
                {valueList?.map((value) => (
                  <tr key={value?.id}>
                    <td>
                      <Link to="#" className="fw-medium">
                        #{value?.id}
                      </Link>
                    </td>
                    <td>{value?.title ?? "-"}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        {accessRights?.includes("attribute_can_update") && (
                          <button
                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                            onClick={() => editHandler(value?.id)}
                          >
                            <i className="ri-edit-2-fill me-1" />
                            {`${t("global.edit")}`}
                          </button>
                        )}

                        {accessRights?.includes("attribute_can_delete") && (
                          <button
                            className="btn btn-sm btn-danger d-flex align-items-center"
                            onClick={() => {
                              setSelectedValue(value);
                              handleShow();
                            }}
                          >
                            <i className="ri-delete-bin-fill me-1" />
                            {`${t("global.delete")}`}
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedValue?.title}
          deleteHandler={() =>
            deleteHandler(
              selectedValue?.id as number,
              selectedValue?.title as string
            )
          }
          selectedProduct={selectedValue}
        />
      </div>
    </div>
  );
};
