import { PROMPT_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";
import { IChatGptPromptCreate, IChatGptPromptList } from "./dto/IChatGptPrompt";



export const ChatGptPromptService = {
    getAllPrompts: (params: IChatGptPromptList) => requests.get(`${PROMPT_URL}`, params),
    createPrompt: (params: IChatGptPromptCreate) => requests.post(`${PROMPT_URL}`, params),
    getPrompt: (id: number) => requests.get(`${PROMPT_URL}/${id}`),
    updatePrompt: (params: IChatGptPromptCreate, id: number) => requests.put(`${PROMPT_URL}/${id}`, params),
    deletePrompt: (id: number) => requests.delete(`${PROMPT_URL}/${id}`),
}