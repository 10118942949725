import { ChangeEvent, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { ContentEditableEvent } from "react-simple-wysiwyg";
import { PageTemplate } from "../../../../shared/layout/PageTemplate";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import ProductVariationForm from "../../../products/components/ProductVariationForm";
import { ContentActions } from "../../../products/content/components/ContentActions";
import VariationUpdateForm from "../../../products/content/components/VariationUpdateForm";
import {
  IProductsList,
  IProductVariation,
} from "../../../products/dto/IProducts";
import { ProductsService } from "../../../products/service";
import ProductVariation from "../../../products/components/ProductVariation";
import { Pagination } from "../../../../shared/paginations/Paginations";
import { Link } from "react-router-dom";
import GalleryCardPreview from "../../../../shared/gallery/GalleryCardPreview";
import ImageContainer from "../../../../shared/gallery/ImageContainer";
import { BlockService } from "../../../block/service";

const variationInitial = {
  title: null,
  lang: null,
  bullets: null,
  introText: null,
  subtitle: null,
  blocks: {},
};

const getAllVariations = ({
  variationPagination,
  id,
  setVariationList,
  setTotalPages,
}: any) => {
  ProductsService.getAllVariations({
    ...variationPagination,
    product: Number(id),
  })
    .then((response) => {
      setVariationList(response.data);
      setTotalPages(Math.ceil(response?.count / response?.perPage));
    })
    .catch((error) => ErrorToast(error));
};

export const EditContentCrawled = () => {
  const { state: pagination }: any = useLocation();
  const [allBlocks, setAllBlocks] = useState([] as any);
  const location = useLocation();
  const { id } = useParams();
  const tab = "BASE_DATA";
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [variationPagination, setVariationPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [variationList, setVariationList] = useState<IProductVariation[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentVariation, setCurrentVariation] = useState<IProductVariation>(
    variationInitial as any
  );
  const [showVariationModal, setShowVariationModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<IProductsList>();
  const [certainBlocks, setCertainBlocks] = useState([]);
  const handleClose = () => {
    setShowVariationModal(false);
    setCurrentVariation(variationInitial as any);
  };

  const handleShow = () => {
    setShowVariationModal(true);
    setCertainBlocks(currentProduct?.block as any);
  };

  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setVariationPagination({ ...variationPagination, page: updatePage });
    setPage(updatePage);
  };
  const navBaseData = () => {
    navigate(`/app/pending-products/crawled/base-data/${id}`, {
      state: pagination,
    });
  };
  const navAttributes = () => {
    navigate(`/app/pending-products/crawled/attributes/${id}`, {
      state: pagination,
    });
  };

  const [currentVariationTranslation, setCurrentVariationTranslation] =
    useState<IProductVariation>();
  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | ContentEditableEvent
    >,
    language: string
  ) => {
    // @ts-ignore
    const { value, name } = event.target;
    setCurrentProduct((prev: any) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [language]: {
          ...prev?.translation?.[language],
          [name]: value,
        },
      },
    }));
  };
  const changeVariationHandler = (
    event: React.ChangeEvent<HTMLInputElement | ContentEditableEvent>
  ) => {
    const { value, name } = event.target as HTMLInputElement;
    setCurrentVariation((prevState) => ({ ...prevState, [name]: value }));
  };
  const changeBlocknHandler = (value: string, blockId: number) => {
    setCurrentVariation((prevState) => ({
      ...prevState,
      blocks: {
        ...prevState.blocks,
        [blockId]: value,
      },
    }));
  };
  const handleCloseContent = () => {
    navigate("/app/pending-products/crawled", {
      state: (location.state as any)?.pagination,
    });
    // navigate("/app/pending-products/imported", { state: { pagination: pagination?.pagination ?? {} } });
  };
  const handleSubmit = (event: React.FormEvent, language: string) => {
    event.preventDefault();
    ProductsService.updateProductContentById(
      {
        lang: language,
        bullets: currentProduct?.translation?.[language]?.bullets,
        introText: currentProduct?.translation?.[language]?.introText,
        description: currentProduct?.translation?.[language]?.description,
      } as any,
      Number(id)
    )
      .then((response) => {
        // navigate(-1);
        SuccessToast(`${t("toasts.successfully_updated")} ${response.title}`);
      })
      .catch((error) => ErrorToast(error));
  };

  const handleSaveVariation = () => {
    if (currentVariation?.id) {
      ProductsService.updateVariation(
        {
          lang: "en",
          title: currentVariation?.title,
          subtitle: currentVariation?.subtitle,
          bullets: currentVariation?.bullets,
          introText: currentVariation?.introText,
        } as any,
        Number(currentVariation?.id)
      )
        .then((response) => {
          if (response?.variation?.id) {
            SuccessToast(
              `${t("toasts.successfully_updated")} ${
                response?.translation?.en?.title
              }`
            );
            setCurrentVariation(variationInitial as any);
            getAllVariations({
              variationPagination: variationPagination,
              id: id,
              setVariationList: setVariationList,
              setTotalPages: setTotalPages,
            });
            handleClose();
          }
        })
        .catch((error) => ErrorToast(error));
    } else {
      ProductsService.createVariation({
        product: currentProduct?.id as number,
        lang: "en",
        title: currentVariation?.title,
        subtitle: currentVariation?.subtitle,
        bullets: currentVariation?.bullets,
        introText: currentVariation?.introText,
        blocks: currentVariation?.blocks,
      })
        .then((response) => {
          if (response?.id) {
            SuccessToast(
              `${t("toasts.successfully_created")} ${
                response?.translation?.en?.title
              }`
            );
            setCurrentVariation(variationInitial as any);
            getAllVariations({
              variationPagination: variationPagination,
              id: id,
              setVariationList: setVariationList,
              setTotalPages: setTotalPages,
            });
            handleClose();
          }
        })
        .catch((error) => ErrorToast(error));
    }
  };

  useEffect(() => {
    id &&
      tab &&
      ProductsService.getProductById(+id, tab)
        .then((response) => {
          setCurrentProduct(response);
        })
        .catch((error) => {
          ErrorToast(error);
          //novo
          navigate(-1);
        });
  }, [id]);
  useEffect(() => {
    BlockService.getAllBlocks({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setAllBlocks(data as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    id &&
      getAllVariations({
        variationPagination: variationPagination,
        id: id,
        setVariationList: setVariationList,
        setTotalPages: setTotalPages,
      });
  }, [id, JSON.stringify(variationPagination), triggerUpdate]);
  return (
    // <PageTemplate title={`${t("side_bar.crawled_product")}`}>
    <div className="col-md-12">
      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
        <div className="d-flex align-itmes-end">
          <ul className="nav nav-pills nav-fill">
            <li onClick={() => navBaseData()}>
              <span className="nav-link cursor-pointer text-dark">
                {t("products.base_data_update")}
              </span>
            </li>
            <li onClick={() => navAttributes()}>
              <span className="nav-link cursor-pointer text-dark">
                {t("products.attributes_update")}
              </span>
            </li>
            {/* <li className="nav-item">
              <Link
                className="nav-link"
                to={`/app/pending-products/crawled/base-data/${id}`}
              >
                {t("products.base_data_update")}
              </Link>
            </li>
            <li>
              <Link
                className="nav-link"
                to={`/app/pending-products/crawled/attributes/${id}`}
              >
                {t("products.attributes_update")}
              </Link>
            </li> */}
            <li>
              <span className="nav-link active">
                {t("products.content_update")}
              </span>
            </li>
          </ul>
        </div>
        <div
          className="alert alert-warning alert-dismissible shadow fade show m-0 pe-3"
          role="alert"
        >
          {currentProduct?.status}
        </div>
        <>
          <ul className="nav nav-pills">
            <li className="nav-item">
              <a
                rel="noreferrer"
                target="_blank"
                className="nav-link active"
                href={`${currentProduct?.crawlerUrl}`}
              >
                {t("crawlers.url")}
              </a>
            </li>
          </ul>
        </>
      </div>
      <>
        {!currentVariation?.id ? (
          <Modal
            show={showVariationModal}
            onHide={handleClose}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {t("products.variation.createVariation")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProductVariationForm
                changeBlocknHandler={
                  changeBlocknHandler as (value: string) => void
                }
                setCurrentVariation={setCurrentVariation}
                currentVariation={currentVariation}
                certainBlocks={certainBlocks}
                changeVariationHandler={
                  changeVariationHandler as (
                    event: ContentEditableEvent | ChangeEvent<HTMLInputElement>
                  ) => void
                }
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="info" onClick={handleClose}>
                {t("global.cancel")}
              </Button>
              <Button variant="primary" onClick={handleSaveVariation}>
                {t("category.save")}
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <VariationUpdateForm
            currentVariation={currentVariationTranslation}
            variationId={currentVariation?.id}
            setCurrentVariation={setCurrentVariationTranslation}
            showVariationModal={showVariationModal}
            handleClose={handleClose}
            certainBlocks={certainBlocks}
            setTriggerUpdate={setTriggerUpdate}
          />
        )}
        <div className="col-md-12 mb-3 d-flex align-items-center justify-content-between">
          <button
            onClick={() => handleCloseContent()}
            className="btn btn-info d-flex align-items-center"
          >
            <i className="ri-arrow-left-line"></i>
            {t("products.back")}
          </button>
          <button
            onClick={handleShow}
            className="btn btn-success d-flex align-items-center"
          >
            <i className="ri-play-list-add-line me-1" />
            {t("products.createVariation")}
          </button>
        </div>
        <div className="d-flex align-items-center mb-3">
          <div className="d-flex gap-3 align-items-center">
            <ImageContainer img={currentProduct?.productPictures ?? []} />
            {/* <GalleryCardPreview
              img={
                currentProduct?.productPictures[0]?.path ||
                "/images/jk-placeholder-image.jpg"
              }
            /> */}
            {/* <CustomImageHandler
            classes="avatar-lg rounded-circle me-2"
            path={currentProduct?.productPictures[1]?.path ?? ""}
            alt="User logo"
          /> */}
            <div>
              <h2>{currentProduct?.title}</h2>
              <p>{currentProduct?.eanCode}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <ContentActions
              allBlocks={allBlocks}
              handleCloseContent={handleCloseContent}
              setTriggerUpdate={setTriggerUpdate}
              currentProduct={currentProduct as IProductsList}
              setCurrentProduct={setCurrentProduct}
              changeHandler={changeHandler}
              handleSubmit={handleSubmit}
            />
          </div>
          <div className="col-md-4">
            {variationList?.map((variation) => (
              <ProductVariation
                setCurrentVariationTranslation={setCurrentVariationTranslation}
                setCurrentVariation={setCurrentVariation}
                setVariationList={setVariationList}
                handleShow={handleShow}
                variation={variation}
                key={variation?.id}
              />
            ))}
            <div className="col-12 d-flex justify-content-end ">
              {totalPages <= 1 ? (
                ""
              ) : (
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              )}
            </div>
          </div>
        </div>
      </>
    </div>
    // </PageTemplate>
  );
};
