import { useTranslation } from "react-i18next";
import { PageTemplate } from "../../../shared/layout/PageTemplate";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _debounce from "lodash/debounce";
import { EmptyState } from "../../../shared/components/EmptyState";
import { useSelector, useDispatch } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { CategoryServicee } from "../../../store/category/categoryService";
import { ErrorToast } from "../../../shared/toasters/toasters";
import categorySlice from "../../../store/category/categoryRedux";
import { AttributesService } from "../../attributes/service";
import { IAttributes } from "../../attributes/names/dto/IAttributes";

export const CategoryAttributes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [attributesList, setAttributesList] = useState<IAttributes[]>([]);
  const categoryList = useSelector((state: IAllStates) => state.category);
  const [filterParams, setFilterParams] = useState({
    title: "",
  });

  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFilterParams((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    200
  );

  useEffect(() => {
    CategoryServicee.getAllCategories(filterParams as any)
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, [filterParams]);

  return (
    // <PageTemplate title={`${t("side_bar.categoryAttributes")}`}>
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-end flex-column">
            <input
              type="text"
              placeholder="Search title"
              onChange={changeFilterHandler}
              className="form-control w-25 mb-3"
              name="title"
            />
          </div>

          <div className="list-group col nested-list nested-sortable">
            {!!categoryList?.length ? (
              categoryList?.map((category1) => {
                return (
                  <div
                    key={category1?.id}
                    className="list-group-item nested-1"
                  >
                    <div className="d-flex align-items-center justify-content-between fw-bold">
                      {category1?.title ?? "-"}
                    </div>
                    {!!category1?.children?.length && (
                      <div className="list-group nested-list nested-sortable">
                        {category1?.children
                          ?.filter((item) => !!item.children.length)
                          ?.map((category2) => {
                            return (
                              <div
                                className="list-group-item nested-2 "
                                key={category2?.id}
                              >
                                <div className="d-flex align-items-center justify-content-between">
                                  {category2?.title ?? "-"}
                                </div>
                                {category2?.children?.map((category3) => {
                                  return (
                                    <Link
                                      key={category3?.id}
                                      className="list-group cursor-pointer nested-list nested-sortable"
                                      to={`/admin/category/attributes/edit/${category3?.id}`}
                                    >
                                      <div className="list-group-item nested-3 d-flex align-items-center justify-content-between fw-light">
                                        {category3?.title ?? "-"}
                                      </div>
                                    </Link>
                                  );
                                })}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <EmptyState />
            )}
          </div>
        </div>
      </div>
    </div>
    // </PageTemplate>
  );
};
