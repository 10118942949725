import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { SetStateAction, useEffect, useState } from "react";
import {
  formatDate,
  getCorrectLanguage,
} from "../../../shared/functions/Functions";
import { Pagination } from "../../../shared/paginations/Paginations";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { EmptyState } from "../../../shared/components/EmptyState";
import { IExport } from "../../exports/dto/IExports";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { ContentService } from "../service";
import { IContent } from "../dto/IContent";
import { LoadingAnimation } from "../../../shared/components/Loading";

interface IExportListProps {
  accessRights: string[];
  contentsList: any;
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  totalPages: number;
  page: number;
  handlePages: (page: number) => void;
  changeClientHandler: (value: string) => void;
  clientList: IExport[];
  changeFilterClientHandler: (
    selectedSingle: SingleValue<IExport | null>,
    inputKey: string
  ) => void;
  changeFilterHandler: (e: any) => void;
}

export const ContentList = ({
  contentsList,
  clientList,
  totalPages,
  page,
  handlePages,
  changeFilterClientHandler,
  changeClientHandler,
  setTriggerUpdate,
  changeFilterHandler,
  accessRights,
}: IExportListProps) => {
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [selectedContent, setselectedContent] = useState<IContent>();
  const deleteHandler = () => {
    selectedContent?.id &&
      ContentService.deleteContent(selectedContent?.id)
        .then((response) => {
          if (response === "") {
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }

  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">
            {`${t("content.listOfContents")}`}
          </h4>
        </div>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-start align-items-center mb-1">
          <div className="col-md-3 my-2">
            <input
              type="text"
              name="title"
              required
              placeholder={t("products.term")}
              onChange={changeFilterHandler}
              className="form-control"
            />
          </div>
          <Select
            theme={themeSelect}
            isClearable
            placeholder={t("export_client.client")}
            className={`${
              windowWidth?.width > 767 ? `w-25` : `w-50`
            } ms-1 react-select-field`}
            onChange={(e) => {
              changeFilterClientHandler(
                e as SingleValue<IExport | null>,
                "client"
              );
            }}
            options={clientList as any}
          />
        </div>
        {contentsList == undefined ? (
          <LoadingAnimation />
        ) : contentsList?.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">{`${t("global.id")}`}</th>
                  <th scope="col">{`${t("global.title")}`}</th>
                  <th scope="col">{`${t("export_client.client")}`}</th>
                  <th scope="col">{`${t("products.createdAt")}`}</th>
                  {(accessRights?.includes("content_page_can_edit") ||
                    accessRights?.includes("content_page_can_delete")) && (
                    <th scope="col">{`${t("global.actions")}`}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {contentsList?.map((content: any) => {
                  return (
                    <tr key={content?.id}>
                      <td>
                        <Link to="#" className="fw-medium">
                          #{content?.id}
                        </Link>
                      </td>
                      <td>{content?.title ?? "-"}</td>
                      <td>{content?.client?.name ?? "-"}</td>
                      <td>{formatDate(content?.createdAt)}</td>
                      {(accessRights?.includes("content_page_can_edit") ||
                        accessRights?.includes("content_page_can_delete")) && (
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            {accessRights?.includes(
                              "content_page_can_edit"
                            ) && (
                              <button
                                className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                onClick={() =>
                                  navigate(`/app/page-content/${content?.id}`)
                                }
                              >
                                <i className="ri-edit-2-fill me-1" />
                                {`${t("global.edit")}`}
                              </button>
                            )}
                            {accessRights?.includes(
                              "content_page_can_delete"
                            ) && (
                              <button
                                className="btn btn-sm btn-danger d-flex align-items-center"
                                onClick={() => {
                                  handleShow();
                                  setselectedContent(content);
                                }}
                              >
                                <i className="ri-delete-bin-fill me-1" />
                                {`${t("global.delete")}`}
                              </button>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedContent?.title}
          deleteHandler={deleteHandler}
          product={selectedContent}
          selectedProduct={selectedContent}
        />
      </div>
    </div>
  );
};
