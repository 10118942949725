import React from "react";
import { t } from "i18next";
import { Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

interface IMappingStepper {
  step: string;
}
export const ApiStepper = ({ step }: IMappingStepper) => {
  const { id } = useParams();
  const tabsList = ["SETTING_UP", "EXPORT", "API"];
  const handleStepSteper = (status: string, id: number) => {
    switch (status) {
      case "SETTING_UP":
        return `/admin/api-update/${id}`;
      case "EXPORT":
        return `/admin/api-export/${id}`;
      case "API":
        return `/admin/api-test/${id}`;
      default:
        return `/admin/api-update/${id}`;
    }
  };

  return (
    <Col md={12}>
      <ul className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
        {tabsList?.map((stepName: string) => {
          return (
            <li className="nav-item" key={stepName}>
              <Link
                className={`nav-link ${step === stepName ? "active" : ""}`}
                to={handleStepSteper(stepName, Number(id))}
              >
                {t(`mapping.${stepName}`)}
              </Link>
            </li>
          );
        })}
      </ul>
    </Col>
  );
};
