import React, { ChangeEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AuthService } from "../service";
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";
import { IRegister } from "../../model/authentification/IRegister";
import { UserFields } from "./components/UserFields";
import { CompanyFields } from "./components/CompanyFields";
import { SingleValue } from "react-select";

export interface iRegister {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export function Signup() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [registerState, setRegisterState] = useState<IRegister>({
    user: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      contact: {
        phone: "",
        country: "",
        city: "",
        zip: "",
        address: "",
      },
    },
    company: {
      title: "",
      email: "",
      contact: {
        phone: "",
        country: "",
        city: "",
        zip: "",
        address: "",
      },
    },
  });
  const [visibilityPassword, setVisibility] = useState(false);
  const [step, setStep] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });

  function handleChange(
    event: ChangeEvent<HTMLInputElement>,
    isContact = false
  ) {
    const { value, name } = event.target;
    if (isContact) {
      setRegisterState({
        ...registerState,
        user: {
          ...registerState.user,
          contact: {
            ...registerState.user.contact,
            [name]: value,
          },
        },
      });
    } else {
      setRegisterState({
        ...registerState,
        user: {
          ...registerState.user,
          [name]: value,
        },
      });
    }
  }

  function handleSelect2(
    selectedSingle: SingleValue<{
      label: string;
      value: number | undefined | string;
    }>,
    inputKey: string
  ) {
    setPagination((prev: { page: number; perPage: number }) => ({
      ...prev,
      [inputKey]: selectedSingle?.value,
    }));
    setRegisterState({
      ...registerState,
      user: {
        ...registerState.user,
        contact: {
          ...registerState.user.contact,
          [inputKey]: selectedSingle?.value,
        },
      },
    });
  }
  function handleSelectCompany(
    selectedSingle: SingleValue<{
      label: string;
      value: number | undefined | string;
    }>,
    inputKey: string
  ) {
    setPagination((prev: { page: number; perPage: number }) => ({
      ...prev,
      [inputKey]: selectedSingle?.value,
    }));
    setRegisterState({
      ...registerState,
      company: {
        ...registerState.company,
        contact: {
          ...registerState.company.contact,
          [inputKey]: selectedSingle?.value,
        },
      },
    });
  }

  const handleChangeCompany = (
    event: ChangeEvent<HTMLInputElement>,
    isContact = false
  ) => {
    const { value, name } = event.target;
    if (isContact) {
      setRegisterState({
        ...registerState,
        company: {
          ...registerState.company,
          contact: {
            ...registerState.company.contact,
            [name]: value.trim(),
          },
        },
      });
    } else {
      setRegisterState({
        ...registerState,
        company: {
          ...registerState.company,
          [name]: value.trim(),
        },
      });
    }
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    AuthService.registerUser(registerState)
      .then((response) => {
        if (response) {
          SuccessToast(`${t("register.toasts.successfully_register")}`);
          navigate("/");
        }
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    <div className="auth-page-wrapper pt-5">
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div className="bg-overlay" />

        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
          >
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
          </svg>
        </div>
      </div>

      <div className="auth-page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <Image src="/images/logo/ciib.svg" alt="" height="70" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-12 col-xl-5">
              <div className="card mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">
                      {t("register.pleaseRegister")}!
                    </h5>
                    <Link to="/">
                      <p className="text-muted">
                        {t("register.alreadyRegister")}?
                      </p>
                    </Link>
                  </div>
                  {step === 0 ? (
                    <UserFields
                      handleChange={handleChange}
                      state={registerState?.user}
                      setVisibility={setVisibility}
                      handleSelect2={handleSelect2}
                      visibilityPassword={visibilityPassword}
                    />
                  ) : (
                    <CompanyFields
                      handleChangeCompany={handleChangeCompany}
                      state={registerState?.company}
                      handleSelect2={handleSelectCompany}
                    />
                  )}
                  <div className="d-flex align-items-center justify-content-between">
                    <button
                      className="btn btn-info"
                      onClick={() => {
                        step === 0 ? setStep(1) : setStep(0);
                      }}
                    >
                      {step === 0
                        ? `${t("register.nextStep")}`
                        : `${t("register.back")}`}
                    </button>

                    {step === 1 && (
                      <button
                        className="btn btn-success"
                        type="submit"
                        onClick={submitHandler}
                      >
                        {t("register.signIn")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
