import { PageTemplate } from "../../shared/layout/PageTemplate";
import { DashboardCard } from "./components/DashboardCard";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ErrorToast } from "../../shared/toasters/toasters";
import { DashboardService } from "./service";
import CountUp from "react-countup";
import { CategoryServicee } from "../../store/category/categoryService";
// import ReactApexChart from "react-apexcharts";
import categorySlice from "../../store/category/categoryRedux";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { ProfileDetailsService } from "../profile/service";
import authSlice from "../../store/auth/authRedux";
import { IProfile } from "../profile/dto/IProfile";
import expOrAttPaginationSlice from "../../store/category/expOrAttPagination";
import { WorkflowList } from "../workflow/components/WorkflowList";
import { IWorkflow } from "../workflow/dto/IWorkflow";
import { handleStepNavigate } from "../workflow/components/WorkflowStep2";
import { useNavigate } from "react-router";
import { handleContentStepNavigate } from "../contentWorkflow/components/ContentStepper";

export const Dashboard = () => {
  const { t } = useTranslation();
  const [currentUserAccess, setCurrentUserAccess] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  let canView = currentUserAccess?.some(
    (role: string) => role === "dashboard_can_view"
  );
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const dispatch = useDispatch();
  const isAdmin = currentUser?.roles?.some(
    (role: string) => role === "ROLE_ADMIN"
  );
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [isNumberStatsEmpty, setIsNumberStatsEmpty] = useState(false);

  dispatch(expOrAttPaginationSlice.actions.setExpOrAttPagination({}));
  const [dashboardTables, setDashboardTables] = useState<{
    copywriterWorkflows: IWorkflow[];
    translatorWorkflows: IWorkflow[];
    translatorContentWorkflows: IWorkflow[];
    copywriterContentWorkflows: IWorkflow[];
  }>();
  const [dashboardProducts, setDashboardProducts] = useState({
    totalImported: {
      value: 0,
    },
    totalCrawled: {
      value: 0,
    },
    totalApproved: {
      value: 0,
    },
  });
  const navigate = useNavigate();
  const [dashboardNumbersFirst, setDashboardNumbersFirst] = useState({
    totalUsers: {
      value: 0,
    },
    totalExports: {
      value: 0,
    },
    totalClients: {
      onlyAdmin: true,
      value: 0,
    },
    totalBrands: {
      value: 0,
    },
  });
  const [dashboardNumberSecond, setDashboardNumbersSecond] = useState({
    // totalBrands: {
    //   value: 0,
    // },
    totalCategories: {
      value: 0,
    },
    totalVariations: {
      value: 0,
    },
    totalVariationTranslation: {
      value: 0,
    },
  });
  useEffect(() => {
    ProfileDetailsService.getProfileDetails()
      .then((data: any) => {
        setCurrentUserAccess(data?.accessRights);
        dispatch(authSlice.actions.setAuth(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    CategoryServicee.getAllCategories()
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    DashboardService.getAllDashboardData()
      .then((response) => {
        if (!Object.keys(response.statistics).length) {
          setIsNumberStatsEmpty(true);
        }
        setDashboardTables(response?.workflows);

        setDashboardNumbersFirst({
          totalClients: {
            onlyAdmin: true,
            value: response.statistics.totalClients,
          },
          totalExports: {
            value: response.statistics.totalExports,
          },
          totalUsers: {
            value: response.statistics.totalUsers,
          },
          totalBrands: {
            value: response.statistics.totalBrands,
          },
        });
        setDashboardNumbersSecond({
          totalCategories: {
            value: response.statistics.totalCategories,
          },
          totalVariations: {
            value: response.statistics.totalVariations,
          },
          totalVariationTranslation: {
            value: response.statistics.totalVariationTranslation,
          },
        });
        setDashboardProducts({
          totalImported: {
            value: response.statistics.totalImportedProducts,
          },
          totalCrawled: {
            value: response.statistics.totalCrawledProducts,
          },
          totalApproved: {
            value: response.statistics.totalApprovedProducts,
          },
        });
      })
      .catch((error) => ErrorToast(error));
  }, [canView]);

  const proceedHandler = (workflowId: number, status: string, tabs: any) => {
    const linkStatus = tabs?.some((tab: string) => tab === status);
    handleStepNavigate(
      linkStatus === true ? status : tabs[0],
      Number(workflowId),
      navigate
    );
  };
  const proceedHandlerContent = (
    workflowId: number,
    status: string,
    tabs: any
  ) => {
    const linkStatus = tabs?.some((tab: string) => tab === status);
    handleContentStepNavigate(
      linkStatus === true ? status : tabs[0],
      Number(workflowId),
      navigate
    );
  };
  const getCorrectCardIcon = (value: string) => {
    switch (value) {
      case "totalBrands":
        return <i className="ri-vip-crown-fill display-6 text-muted" />;
      case "totalVariationTranslation":
        return <i className="ri-translate display-6 text-muted" />;
      case "totalCategories":
        return <i className="ri-stack-fill display-6 text-muted" />;
      case "totalVariations":
        return <i className="ri-file-text-line display-6 text-muted" />;
      default:
        return <i className="ri-shopping-bag-3-line display-6 text-muted" />;
    }
  };

  return (
    <>
      {isNumberStatsEmpty &&
      !dashboardTables?.copywriterWorkflows.length &&
      !dashboardTables?.translatorWorkflows.length ? (
        <>
          <div className="d-flex justify-content-center align-items-center">
            <div className="auth-page-wrapper pt-5">
              <div
                className="auth-one-bg-position auth-one-bg"
                id="auth-particles"
              >
                <div className="bg-overlay" />

                <div className="shape">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120"
                  >
                    <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center pt-4">
                <div>
                  <div className="mt-n4">
                    <h2 className="display-1 fw-medium text-white">
                      {t("login.welcome_to_ciib")}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {!isNumberStatsEmpty && (
            <>
              <div className="col-12">
                <div className="card crm-widget">
                  <div className="card-body p-0">
                    <div className="row row-cols-md-4 row-cols-1">
                      {Object.entries(dashboardProducts)?.map((item) => {
                        return (
                          <div
                            className="col col-md-4 border-end"
                            key={item[0]}
                          >
                            <div className="py-4 px-3">
                              <h5 className="text-muted text-uppercase fs-13">
                                {t(`dashboard.cards.${item[0]}`)}
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  {getCorrectCardIcon(item[0])}
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h2 className="mb-0">
                                    <span className="counter-value">
                                      <CountUp
                                        className="counter-value"
                                        start={0}
                                        duration={1}
                                        end={item[1].value}
                                      />
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card crm-widget">
                  <div className="card-body p-0">
                    <div className="row row-cols-md-3 row-cols-1">
                      {Object.entries(dashboardNumbersFirst)
                        .filter(
                          (cardStats) =>
                            isAdmin === !!(cardStats[1] as any)?.onlyAdmin ||
                            isAdmin
                        )
                        ?.map((cardStats) => (
                          <DashboardCard
                            key={cardStats[0]}
                            cardStats={cardStats}
                            isAdmin={isAdmin}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card crm-widget">
                  <div className="card-body p-0">
                    <div className="row row-cols-md-4 row-cols-1">
                      {Object.entries(dashboardNumberSecond)?.map((item) => {
                        return (
                          <div
                            className="col col-md-4 mb-1 border-end"
                            key={item[0]}
                          >
                            <div className="py-4 px-3">
                              <h5 className="text-muted text-uppercase fs-13">
                                {t(`dashboard.cards.${item[0]}`)}
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  {getCorrectCardIcon(item[0])}
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h2 className="mb-0">
                                    <span className="counter-value">
                                      <CountUp
                                        className="counter-value"
                                        start={0}
                                        duration={1}
                                        end={item[1].value}
                                      />
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {!!dashboardTables?.copywriterWorkflows.length && (
            <WorkflowList
              accessRights={accessRights}
              list={
                dashboardTables?.copywriterWorkflows as IWorkflow[] | undefined
              }
              //  changeFilterHandler={changeFilterHandler}
              proceedHandler={proceedHandler}
              setTriggerUpdate={setTriggerUpdate}
              tableName="Copywriter"
              isDashboard={true}
            />
          )}
          {!!dashboardTables?.translatorWorkflows.length && (
            <WorkflowList
              accessRights={accessRights}
              list={
                dashboardTables?.translatorWorkflows as IWorkflow[] | undefined
              }
              //  changeFilterHandler={changeFilterHandler}
              proceedHandler={proceedHandler}
              setTriggerUpdate={setTriggerUpdate}
              tableName="Translator"
              isDashboard={true}
            />
          )}
          {!!dashboardTables?.copywriterContentWorkflows.length && (
            <WorkflowList
              accessRights={accessRights}
              list={
                dashboardTables?.copywriterContentWorkflows as
                  | IWorkflow[]
                  | undefined
              }
              //  changeFilterHandler={changeFilterHandler}
              proceedHandler={proceedHandlerContent}
              setTriggerUpdate={setTriggerUpdate}
              tableName="Content copywriter"
              isDashboard={true}
            />
          )}
          {!!dashboardTables?.translatorContentWorkflows.length && (
            <WorkflowList
              accessRights={accessRights}
              list={
                dashboardTables?.translatorContentWorkflows as
                  | IWorkflow[]
                  | undefined
              }
              //  changeFilterHandler={changeFilterHandler}
              proceedHandler={proceedHandlerContent}
              setTriggerUpdate={setTriggerUpdate}
              tableName="Content translator"
              isDashboard={true}
            />
          )}
        </>
      )}
    </>
  );
};
