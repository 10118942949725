import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({

    name: 'auth',
    initialState: {
        id: 0,
        firstName: "",
        lastName: "",
        profilePicture: {
            path: ""
        }
    },

    reducers: {
        setAuth: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export default authSlice;
