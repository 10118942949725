import { PageTemplate } from "../../../shared/layout/PageTemplate";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { useNavigate, useParams } from "react-router-dom";
import { AttributesService } from "../../attributes/service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { CategoryEditAttributesSwitcher } from "./components/CategoryEditAttributesSwitcher";
import { CategoryService } from "../service";
import { IAttributes } from "../../attributes/names/dto/IAttributes";

export const CategoryAttributesEdit = () => {
  const { id } = useParams();
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const [attributesList, setAttributesList] = useState<IAttributes[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const changeHandler = (selected: number[]) => {
    setSelectedValues(selected);
  };

  const submitHandler = () => {
    CategoryService.createCategoryAttributes({
      category: Number(id),
      attributes: selectedValues,
    })
      .then((response) => {
        SuccessToast(t("category.toasts.successfullyCreatedCategoryAttribute"));
        navigate(-1);
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    AttributesService.getAllAttributes({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        setAttributesList(
          response?.data?.map((attribute) => ({
            ...attribute,
            value: attribute.id,
            label: attribute.title ?? "-",
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    !!Number(id) &&
      CategoryService.getCategoryAttributes(+id!)
        .then((response) => {
          setSelectedValues(response.attributes);
        })
        .catch((error) => ErrorToast(error));
  }, [id]);

  return (
    // <PageTemplate title={`${t("side_bar.categoryAttributes")}`}>
    <div className="col-12">
      <button
        className="btn btn-info mb-3 d-flex align-items-center"
        onClick={() => navigate(-1)}
      >
        <i className="ri-arrow-left-line me-1" />
        {t("attributes.back")}
      </button>
      <div className="card">
        <div className="card-body">
          <CategoryEditAttributesSwitcher
            attributesList={attributesList}
            changeHandler={changeHandler}
            selectedValues={selectedValues}
          />
          <div className="d-flex justify-content-end mt-3">
            <button className="btn btn-primary" onClick={submitHandler}>
              {t("global.submit")}
            </button>
          </div>
        </div>
      </div>
    </div>
    // </PageTemplate>
  );
};
