import { createSlice } from "@reduxjs/toolkit";

const step6PaginationSlice = createSlice({
  name: "step6Pagination",
  initialState: {
    page: 1,
    perPage: 10,
    status: "",
    assigned: "",
    addedWay: "",
  },

  reducers: {
    setStep6Pagination: (state: any, action) => {
      state = action.payload;
      return state;
    },
  },
});

export default step6PaginationSlice;
