import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Form } from "react-bootstrap";

import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";

import { useTranslation } from "react-i18next";
import { CreateForm } from "./CreateForm";
import { ApiService } from "../service";
// import { AdminNewsThemeService } from "../service";

export const CreateApi = () => {
  const navigate = useNavigate();
  const checkBoxBaseData = ["exportBaseData", "baseDataSkipIfNotMapped"];
  const checkBoxContent = ["exportContent", "contentSkipIfNotMapped"];
  const checkBoxAttributes = ["exportAttributes", "attributesSkipIfNotMapped"];
  const { t } = useTranslation();
  const [state, setState] = useState<any>({
    id: 0,
    name: "",
    workflow: 0,
    clientProfile: 0,
    language: [],
  });

  const submitHandler = (event: any) => {
    ApiService.create({
      ...state,
      workflow: state?.workflow?.value,
      clientProfile: state?.clientProfile?.value,
    })
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_created")}`);
        setState({});
        navigate("/admin/api");
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="card">
      <div className="card-body">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submitHandler(e);
          }}
        >
          <CreateForm state={state} setState={setState} />
        </Form>
      </div>
    </div>
  );
};
