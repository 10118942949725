import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ICategoryChildren } from "../../category/values/dto/ICategory";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { IBrand } from "../../brand/dto/IBrand";
import GroupedMultiselect from "../../../shared/components/GroupedMultiselect";
import { IAttributes } from "../../attributes/names/dto/IAttributes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router";

interface IProductsFiltersProps {
  listWithoutCat?: boolean;
  productsTable?: boolean;
  handleSelectBrandsExpAtt?: (selectedSingle: any, title: string) => void;
  handleSelectAllCategoryExpAtt?: (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => void;
  expOrAttPagination?: any;
  categories: any;
  mainTable?: boolean;
  handleSelectMainCategory: (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => void;
  handleSelectStatus: (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => void;
  changeFilterHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setPagination?: any;
  brandsList: IBrand[];
  handleSelectBrand: (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => void;
  // handleSelectBrands: (
  //   selectedSingle: SingleValue<{ label: string; value: number }>, title: string
  // ) => void;
  categoryFilterId?: number;
  listAttribute?: IAttributes[];
  handleChangeCategory?: (name: string, value: string) => void;
  handleChangeDate?: (...args: any) => void;
  showDate?: boolean;
  pagination?: any;
  setTriggerUpdate?: any;
  triggerUpdate?: boolean;
}

export const ProductsFilters = ({
  // handleSelectBrands,
  listWithoutCat = false,
  productsTable = false,
  handleSelectBrandsExpAtt,
  handleSelectAllCategoryExpAtt,
  expOrAttPagination,
  changeFilterHandler,
  handleSelectStatus,
  handleChangeCategory,
  handleSelectMainCategory,
  handleSelectBrand,
  categories,
  mainTable,
  setPagination,
  brandsList,
  categoryFilterId,
  listAttribute,
  handleChangeDate,
  showDate,
  pagination,
  setTriggerUpdate,
  triggerUpdate,
}: IProductsFiltersProps) => {
  const withoutCat = { id: 0, title: "Without category", children: null };
  const { t } = useTranslation();
  const location = useLocation();
  const [businessUnit, setBusinessUnit] = useState();
  const [selectedMainCategory, setSelectedMainCategory] = useState<
    ICategoryChildren[]
  >([]);
  const newCategories = listWithoutCat ? [...categories, withoutCat] : null; // used this instead of push
  const [selectBrand, setSelecetBrand] =
    useState<SingleValue<{ label: string; value: number } | null>>(null);
  const [selected, setSelected] = useState<number[]>([]);
  const status = ["Imported", "Crawled", "Manually"];

  function handleSelectBrands(selectedSingle: any, title: string) {
    const arr = selectedSingle?.map((value: any) => value?.value);
    //
    const brandsDefaultValue = selectedSingle;
    setPagination((prev: any) => ({
      ...prev,
      page: 1,
      [title]: arr,
      brandsDefaultValue: brandsDefaultValue,
    }));
  }
  //
  const handleSelectAllCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value === 0) {
      setPagination((prev: any) => ({
        ...prev,
        category: 0,
        mainCategory: { label: "Without category", value: 0 },
      }));
    } else if (!selectedSingle && (selectedSingle as any)?.value !== 0) {
      // handleSelectMainCategory(null);
      setSelectedMainCategory(null as any);
      setPagination((prev: any) => ({
        ...prev,
        mainCategory: null,
        category: null,
        categoryData: null,
      }));
      // setPagination({ ...pagination, mainCategory: null, category: null, categoryData: null });
    } else {
      setPagination((prev: any) => ({
        ...prev,
        page: 1,
        mainCategory: selectedSingle,
        category: null,
        categoryData: null,
      }));
      // setPagination({ ...pagination, mainCategory: selectedSingle, category: null, categoryData: null });
      const selected = categories?.find(
        (category: { id: number | undefined }) =>
          category?.id === (selectedSingle?.value as number)
      )?.children;
      setBusinessUnit(selected);
      selected && setSelectedMainCategory(selected);
    }
  };

  useEffect(() => {
    if (!!pagination?.category) {
      const selected = categories?.find(
        (category: { id: number | undefined }) =>
          category?.id === pagination?.mainCategory?.value
      )?.children;
      setSelectedMainCategory(selected);
    }
    if (!!pagination?.mainCategory) {
      const selected = categories?.find(
        (category: { id: number | undefined }) =>
          category?.id === pagination?.mainCategory?.value
      )?.children;
      setSelectedMainCategory(selected);
    }
  }, [pagination, categories]);
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          {listWithoutCat && (
            <div className="col-md-12 my-2">
              <input
                type="text"
                name="eanCode"
                required
                defaultValue={pagination?.eanCode}
                placeholder={t("global.aenCode")}
                onChange={changeFilterHandler}
                className="form-control"
              />
            </div>
          )}
          {productsTable && (
            <>
              <div className="col-md-2 my-2">
                <input
                  type="text"
                  name="id"
                  required
                  defaultValue={pagination?.id}
                  placeholder={t("global.id")}
                  onChange={changeFilterHandler}
                  className="form-control"
                />
              </div>
            </>
          )}
          {/* <div className="col-md-2 my-2">
            <input
              type="text"
              name="id"
              required
              defaultValue={pagination?.id}
              placeholder={t("global.id")}
              onChange={changeFilterHandler}
              className="form-control"
            />
          </div> */}
          {!listWithoutCat && (
            <div className="col-md-2 my-2">
              <input
                type="text"
                name="eanCode"
                required
                defaultValue={pagination?.eanCode}
                placeholder={t("global.aenCode")}
                onChange={changeFilterHandler}
                className="form-control"
              />
            </div>
          )}

          <div className="col-md-2 my-2">
            <input
              type="text"
              name="title"
              required
              defaultValue={pagination?.title}
              placeholder={t("products.term")}
              onChange={changeFilterHandler}
              className="form-control"
            />
          </div>
          {mainTable && (
            <div className="col-md-2 my-2">
              <Select
                className="react-select-field"
                isClearable={true}
                placeholder={t("products.status")}
                theme={themeSelect}
                defaultValue={
                  pagination?.addedWay && {
                    label: pagination?.addedWay,
                    value: pagination?.addedWay,
                  }
                }
                options={status?.map((item: string) => ({
                  label: item,
                  value: item,
                }))}
                onChange={(e) => {
                  handleSelectStatus(
                    e as SingleValue<{ label: string; value: string }>
                  );
                }}
              />
            </div>
          )}
          <div className="col-md-2 my-2">
            <Select
              className="react-select-field"
              isClearable={true}
              isMulti={true}
              placeholder={t("products.brand")}
              theme={themeSelect}
              defaultValue={
                pagination?.brandsDefaultValue?.map((brand: any) => ({
                  label: brand?.label ?? "-",
                  value: brand?.id,
                })) ?? []
              }
              options={brandsList?.map((item: any) => ({
                label: item?.name,
                value: item?.id,
              }))}
              onChange={(e) =>
                handleSelectBrandsExpAtt
                  ? handleSelectBrandsExpAtt(e as any, "brands")
                  : handleSelectBrands(e as any, "brands")
              }
            />
          </div>
          <div className="col-md-2 my-2">
            <Select
              className="react-select-field"
              theme={themeSelect}
              isClearable={true}
              placeholder={t("products.select_main_category")}
              value={pagination?.mainCategory}
              options={(newCategories ?? (categories as any))?.map(
                (category: { title: any; id: any }) => ({
                  label: category?.title ?? "-",
                  value: category?.id,
                })
              )}
              onChange={(e) =>
                handleSelectAllCategoryExpAtt
                  ? handleSelectAllCategoryExpAtt(e as any)
                  : handleSelectAllCategory(e as any)
              }
            />
          </div>
          {!!selectedMainCategory?.length && (
            // businessUnit &&
            <div className="col-md-2 my-2">
              <Select
                className="react-select-field"
                theme={themeSelect}
                isClearable={true}
                placeholder={t("products.select_category")}
                value={pagination?.categoryData}
                options={selectedMainCategory?.map((mainCat) => ({
                  label: mainCat?.title,
                  options: mainCat?.children?.map((subCat) => ({
                    label: subCat?.title ?? "-",
                    value: subCat?.id,
                  })),
                }))}
                onChange={(e) => {
                  handleSelectMainCategory(
                    e as SingleValue<{ label: string; value: number }>
                  );
                }}
              />
            </div>
          )}

          {!!showDate && (
            <>
              <div className="col-md-2 my-2">
                <div className="datepicker-container">
                  <DatePicker
                    placeholderText="Date from"
                    autoComplete="off"
                    className="date-picker"
                    name="dateFrom"
                    isClearable
                    selected={pagination?.dateFrom!}
                    onChange={(date) =>
                      handleChangeDate?.(date as any, "dateFrom")
                    }
                  />
                </div>
              </div>
              <div className="col-md-2 my-2">
                <div className="datepicker-container">
                  <DatePicker
                    placeholderText="Date to"
                    className="date-picker"
                    name="dateTo"
                    autoComplete="off"
                    isClearable
                    selected={pagination?.dateTo!}
                    onChange={(date) =>
                      handleChangeDate?.(date as any, "dateTo")
                    }
                  />
                </div>
              </div>
            </>
          )}
          {!!selectedMainCategory?.length &&
            mainTable &&
            // businessUnit &&
            !handleSelectAllCategoryExpAtt &&
            pagination?.category && (
              <div className="col-md-2 my-2">
                <GroupedMultiselect
                  name="attributeValues"
                  items={pagination?.listOfSpecificAtt ?? listAttribute}
                  handleChangeCategory={handleChangeCategory}
                  pagination={pagination}
                  // setSelected={setSelected}
                  // selected={selected}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
