// @ts-nocheck
// import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentEditableEvent, DefaultEditor } from "react-simple-wysiwyg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// eslint-disable-next-line @typescript-eslint/no-redeclare
import Editor from "ckeditor5-custom-build/build/ckeditor";
import _debounce from "lodash/debounce";
import "../../../ckeditor.css";
// kreiranje variacije

interface ICounts {
  [nameField: string]: {
    words: number;
    characters: number;
  };
}
interface IEditorCounter {
  [key: string]: ICounts;
}

interface CategoryVariationFormProps {
  currentVariation: any;
  setCurrentVariation: any;
}
const editorConfigurationCustom = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "findAndReplace",
      "alignment",
      "heading",
      "textPartLanguage",
      "|",
      "bold",
      "italic",
      "underline",
      "link",
      "|",
      "horizontalLine",
      "fontColor",
      "fontBackgroundColor",
      "fontSize",
      "highlight",
      "selectAll",
      "pageBreak",
      "strikethrough",
      "specialCharacters",
      "restrictedEditingException",
      "numberedList",
      "bulletedList",
      "indent",
      "outdent",
      "removeFormat",
      "subscript",
      "superscript",
      "HtmlEmbed",
      "sourceEditing",
      "insertTable",
      "|",
      "glossary-scan",
      // "glossary-ignore",
      "glossary-change",
    ],
    shouldNotGroupWhenFull: true, // optional, to prevent grouping when the toolbar is full
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
      "toggleTableCaption",
    ],
  },
  removePlugins: ["Title", "Markdown"], // remove the title plugin from the editor
};
export const ContentVariationForm = ({
  currentVariation,
  setCurrentVariation,
}: CategoryVariationFormProps) => {
  const { t } = useTranslation();
  const language = "en";
  const [wordCount, setWordCount] = useState<IEditorCounter>();
  const updateWordsCound = _debounce(
    ({ wordsCount, charactersCount, language }, nameField) => {
      setWordCount((prev) => ({
        ...prev,
        [language]: {
          ...prev?.[language],
          [nameField]: {
            ...prev?.[language]?.[nameField],
            words: wordsCount,
            characters: charactersCount,
          },
        },
      }));
    },
    500
  );
  // const changeVariationHandler = (
  //   event: React.ChangeEvent<HTMLInputElement | ContentEditableEvent>
  // ) => {
  //   console.log(currentVariation);
  //   const { value, name } = event.target as HTMLInputElement;
  //   setCurrentVariation((prevState: any) => ({ ...prevState, [name]: value }));
  // };
  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | ContentEditableEvent
    >
  ) => {
    // @ts-ignore
    const { value, name } = event.target;
    setCurrentVariation((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const changeHandlerEditor = (value: string, eventName: string) => {
    // @ts-ignore
    setCurrentVariation((prev: any) => ({
      ...prev,
      [eventName]: value,
    }));
  };

  return (
    <div className="row gy-3">
      <div className="col-md-8 mb-2">
        <label className="m-0 required-field" htmlFor="content">
          {t("global.title")}
        </label>
        <input
          type="text"
          name="title"
          className="form-control"
          value={currentVariation?.title ?? ""}
          required
          onChange={(e) => changeHandler(e)}
        />
      </div>
      <div className="col-12 ">
        <label htmlFor="content">{t("products.content")}</label>
        <CKEditor
          // config={{ removePlugins: ["Title"] }}
          config={editorConfigurationCustom}
          editor={Editor}
          data={currentVariation?.text ?? ""}
          onReady={(editor) => {
            editor.ui.view.element.setAttribute(
              "id",
              String(Math.random().toString(16).slice(2))
            );
            const wordsCount = editor.plugins.get("WordCount").words;
            const charactersCount = editor.plugins.get("WordCount").characters;
            setWordCount((prev) => ({
              ...prev,
              [language]: {
                ...prev?.[language],
                text: {
                  ...prev?.[language]?.["text"],
                  words: wordsCount,
                  characters: charactersCount,
                },
              },
            }));
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            changeHandlerEditor(data as string, "text");
            const wordsCount = editor.plugins.get("WordCount").words;
            const charactersCount = editor.plugins.get("WordCount").characters;
            updateWordsCound(
              {
                wordsCount,
                charactersCount,
                language,
              },
              "text"
            );
          }}
        />
        <div className="d-flex justify-content-start text-align-center mt-3">
          <div className="ck ck-word-count">
            <div className="ck-word-count__words">
              Words: {wordCount?.[language]?.["text"]?.words}
            </div>
            <div className="ck-word-count__characters">
              Characters: {wordCount?.[language]?.["text"]?.characters}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
