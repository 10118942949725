import { BaseModel } from "../../model/shared/BaseModel";
import { requests } from "../../utils/helpers/api.services";
import { PRODUCT_URL } from "../../utils/helpers/api.routes";
import { IProductsQuery, IProductsList } from "../products/dto/IProducts";
import { IImportedStatus } from "./dto/IProductImported";

export const ProductsPendingService = {
    getAllImportedProducts: (params: IProductsQuery): Promise<BaseModel<IProductsList[]>> => requests.get(`${PRODUCT_URL}/imported`, params),
    getAllCrawledProducts: (params: IProductsQuery): Promise<BaseModel<IProductsList[]>> => requests.get(`${PRODUCT_URL}/crawled`, params),
    getImportedProductsById: (id: number) => requests.get(`${PRODUCT_URL}/productFromExcel/${id}`),
    postMultiselectProduct: (params: IImportedStatus) => requests.post(`${PRODUCT_URL}/multiselect-product-status`, params)
}