export const themeSelect = (theme: any) => {
    return {
        ...theme,
        borderRadius: 5,
        minHeight: 40,
        colors: {
            ...theme.colors,
            text: 'white',
            primary25: 'var(--vz-topbar-user-bg)',
            primary: 'var(--vz-input-border)',
        }
    };
};