import { createSlice } from '@reduxjs/toolkit';

const parentCategoryIdSlice = createSlice({

    name: 'parentCategoryId',
    initialState: 0,

    reducers: {
        setParentCategoryId: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});
export default parentCategoryIdSlice;