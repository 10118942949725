import { GLOSSARY_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";
import { IGlossaryClient, IGlossaryCreate, IGlossaryPagination, IGlossarytList } from "./dto/IGlossary";



export const GlossaryService = {
    getAllGlossary: (params: IGlossaryPagination) => requests.get(`${GLOSSARY_URL}`, params),
    getClientGlossary: (params: IGlossaryClient) => requests.get(`${GLOSSARY_URL}/formatted`, params),
    createGlossary: (params: IGlossaryCreate) => requests.post(`${GLOSSARY_URL}`, params),
    getGlossary: (id: number) => requests.get(`${GLOSSARY_URL}/${id}`),
    updateGlossary: (params: IGlossaryCreate, id: number) => requests.put(`${GLOSSARY_URL}/${id}`, params),
    deleteGlossary: (id: number) => requests.delete(`${GLOSSARY_URL}/${id}`),
}