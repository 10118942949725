

const waitForTooltip = (parentEl,editor) => {
    let check = true
    let seconds = 0;
    const data = editor.getData()
    while (check) {
        
        var discardEl = document.getElementsByClassName("discard")[0];
         var changeEl = document.getElementsByClassName("change")[0]
         var closeTool = document.getElementsByClassName("right-title")[0]
        
       
          //seconds mora jer ukoliko drugi put stisnes na tooltip, on nestaje iz DOM-a samim tim bi uslo u infinity loop
                if (discardEl || seconds>25 ) {
                    check = false
            }
            seconds++

        
               
    }
    discardEl?.addEventListener('click', async () => {
          const idFilter = editor.ui.view.element.getAttribute("id");
  
     
        const newData = ignoreThis(document.getElementById(`${idFilter}`).getElementsByClassName("ck-content")[0].innerHTML);
        
       
        editor.setData(newData)
        const element = document.querySelector('[role="tooltip"]');
        element.remove();
        console.log(newData);
    
    });

    changeEl?.addEventListener('click', async () => {
        const idFilter = editor.ui.view.element.getAttribute("id");
        const newData = changeThis(document.getElementById(`${idFilter}`).getElementsByClassName("ck-content")[0].innerHTML); 
        editor.setData(newData)
        const element = document.querySelector('[role="tooltip"]');
        element.remove();
        console.log(newData);
    });
    closeTool?.addEventListener('click', async () => {
        const element = document.querySelector('[role="tooltip"]');
       console.log(element)
        element.remove();
    });
    
}
 export function ignoreThis(html) {
     const divElement = document.createElement('div');
     divElement.innerHTML = html.trim();
     const element = divElement.querySelector("[aria-describedby]"); 
     console.log(element);
         if (element) {
             element.classList.remove('ckeditor-glossary-detected');
             element.removeAttribute("aria-describedby");
             element.classList.add('ckeditor-glossary-ignore')
             element.classList.add('focus-ignore'); 
     }
      console.log(divElement.innerHTML);
     return divElement.innerHTML

               
 }

export  function ignoreChanges(html) {
    console.log(html, 'html profa');
   
    const divElement = document.createElement('div');
    divElement.innerHTML = html.trim();
    const elements = divElement.getElementsByClassName('ckeditor-glossary');
  
    for (const element of elements) {
        console.log(element.classList.contains('ckeditor-glossary-detected'));
        if (element.classList.contains('ckeditor-glossary-detected')) {
            element.classList.remove('ckeditor-glossary-detected');
            element.classList.add('ckeditor-glossary-ignore')
        }
    }
      console.log(divElement.innerHTML);

   // return divElement.innerHTML;
    return divElement.innerHTML
}

export function showTooltip(parentEl,editor) {
    //mora timeout jer se tooltip pojavi u domu tek poslije nekoliko milisekundi
    //ukoliko ne bi bio timeout doslo bi do infinity loop-a
    setTimeout(() => {

          waitForTooltip(parentEl,editor)
    }, 300);
   }

export function replaceData(html) {
    const divElement = document.createElement('div');
    divElement.innerHTML = html.trim();

    // Svesno je uradjno da je po klasi ckeditor-glossary, a ne po ckeditor-glossary-detected
    // A zatim u for petlji je if...
    const elements = divElement.getElementsByClassName('ckeditor-glossary');

    for (const element of elements) {
        if (element.classList.contains('ckeditor-glossary-detected')) {
            element.classList.remove('ckeditor-glossary-detected');
            element.classList.add('ckeditor-glossary-changed')

            const word = element.dataset.glossary_word;
            const alternative = element.dataset.glossary_alternative;

            element.innerHTML = element.innerHTML.replace(word, alternative);
        }

    }

    return divElement.innerHTML;
}

export function changeThis(html) {
    const divElement = document.createElement('div');
    divElement.innerHTML = html.trim();

    // Svesno je uradjno da je po klasi ckeditor-glossary, a ne po ckeditor-glossary-detected
    // A zatim u for petlji je if...
      const element = divElement.querySelector("[aria-describedby]"); 
         if (element) {
            element.classList.remove('ckeditor-glossary-detected');
            element.removeAttribute("aria-describedby");
            element.classList.add('ckeditor-glossary-changed')

            const word = element.dataset.glossary_word;
            const alternative = element.dataset.glossary_alternative;

            element.innerHTML = element.innerHTML.replace(word, alternative);
    }
    return divElement.innerHTML;

    }
    // export function closeTooltip(html) {
    //     const divElement = document.createElement('div');
    //     divElement.innerHTML = html.trim();
    
    //     // Svesno je uradjno da je po klasi ckeditor-glossary, a ne po ckeditor-glossary-detected
    //     // A zatim u for petlji je if...
    //       const element = divElement.querySelector("[aria-describedby]"); 
    //          if (element) {
    //             element.removeAttribute("aria-describedby");
    //     }
    //     return divElement.innerHTML;
    
    //     }

    
