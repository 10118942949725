import { createSlice } from "@reduxjs/toolkit";

const step5PaginationSlice = createSlice({
  name: "step5Pagination",
  initialState: {},

  reducers: {
    setStep5Pagination: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export default step5PaginationSlice;
