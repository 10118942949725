interface ICustomImageHandlerProps {
  path: string;
  alt?: string;
  classes?: string;
  style?: any;
}

export const CustomImageHandler = ({
  path,
  alt,
  classes,
  style,
}: ICustomImageHandlerProps) => {
  return (
    <img
      src={!!path?.length ? path : `/images/placeholderImage.png`}
      alt={alt}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = "/images/placeholderImage.png";
      }}
      className={classes}
      style={style}
    />
  );
};
