import { createSlice } from '@reduxjs/toolkit';

const contentWorkflowSlice = createSlice({
    name: 'contentWorkflow',
    initialState: {},
    reducers: {
        setContentWorkflow: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export default contentWorkflowSlice;