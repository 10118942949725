import { IBrand, IBrandQuery } from "./dto/IBrand";
import { BaseModel } from "../../model/shared/BaseModel";
import { BRAND_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";


export const BrandService = {
    createBrand: (params: IBrand) => requests.post(`${BRAND_URL}`, params),
    getAllBrands: (params: IBrandQuery): Promise<BaseModel<IBrand[]>> => requests.get(`${BRAND_URL}`, params),
    getBrandById: (id: number) => requests.get(`${BRAND_URL}/${id}`),
    updateBrandById: (id: number, params: IBrand) => requests.put(`${BRAND_URL}/${id}`, params),
    deleteBrandById: (id: number) => requests.delete(`${BRAND_URL}/${id}`)
}