import React, { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICurrentClient } from "../../../model/authentification/IRegister";
import { themeSelect } from "../../../shared/components/Select2";
import { IClientsDTO } from "../dto/IClients";
import Select, { SingleValue } from "react-select";
import { Col } from "react-bootstrap";
import { ICompaniesDTO } from "../../companies/dto/ICompanies";
import { CompanyService } from "../../companies/service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import i18n from "../../../utils/translations/i18next";

interface IClientFormProps {
  clientsList: IClientsDTO[] | undefined;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  state: ICurrentClient;
  setState: React.Dispatch<SetStateAction<ICurrentClient | undefined>>;
  setTotalPages?: any;
}

export const ClientForm = ({
  handleChange,
  state,
  clientsList,
  setState,
  setTotalPages,
}: IClientFormProps) => {
  const { t } = useTranslation();

  const [companiesList, setCompaniesList] = useState<ICompaniesDTO[]>([]);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isAdmin = currentUser?.roles?.some(
    (role: string) => role === "ROLE_ADMIN"
  );
  const [paginate, setPaginate] = useState({
    page: 1,
    perPage: 10,
  });

  function handleSelectSingle(
    selectedSingle: SingleValue<{
      label: string;
      value: number | undefined | string;
    }>,
    inputKey: string
  ) {
    setState((prev: any) => ({
      ...prev,
      [inputKey]: selectedSingle?.value,
    }));
  }

  useEffect(() => {
    isAdmin &&
      CompanyService.getAllCompanies({
        page: 1,
        perPage: 0,
      })
        .then((response) => {
          const { data } = response;
          setCompaniesList(data);
          // setTotalPages(Math.ceil(response?.count / response?.perPage));
        })
        .catch((error) => ErrorToast(error));
  }, [JSON.stringify(paginate), clientsList]);

  const { country } = useSelector((state: IAllStates) => state.enums);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <Col md={isAdmin ? 4 : 6} className="my-2">
            <label htmlFor="client" className="required-field">{`${t(
              "clients.client"
            )}`}</label>
            <input
              onChange={handleChange}
              id="client"
              className="form-control w-100"
              name={"name"}
              value={state?.name}
              placeholder={`${t("clients.enterName")}...`}
              type="text"
              maxLength={30}
            />
          </Col>
          <Col md={isAdmin ? 4 : 6} className="my-2">
            <label htmlFor="client" className="required-field">{`${t(
              "clients.country"
            )}`}</label>
            <Select
              className="react-select-field"
              theme={themeSelect}
              options={country?.map((singleCountry) => ({
                value: singleCountry,
                label: getCorrectLanguage(singleCountry, t),
              }))}
              key={state?.id}
              defaultValue={
                state?.country && {
                  label: getCorrectLanguage(state?.country, t),
                  value: state?.country,
                }
              }
              onChange={(e) => {
                handleSelectSingle(
                  e as SingleValue<{ label: string; value: string }>,
                  "country"
                );
              }}
            />
          </Col>
          {isAdmin && (
            <div className="col-md-4 my-2">
              <label htmlFor="title" className="required-field">{`${t(
                "clients.company"
              )}`}</label>
              <Select
                className="react-select-field"
                theme={themeSelect}
                options={(companiesList as unknown as ICompaniesDTO[])?.map(
                  (company) => {
                    return {
                      value: company.id,
                      label: company.title,
                    };
                  }
                )}
                key={state?.id}
                defaultValue={{
                  label: state?.company?.title,
                  value: state?.company?.id,
                }}
                onChange={(e) => {
                  handleSelectSingle(e, "company");
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
