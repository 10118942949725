import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { ErrorToast } from "../toasters/toasters";
import { useTranslation } from "react-i18next";
import "moment/locale/de";
import "moment/locale/en-gb";
import { formatDate, formatRoles } from "../functions/Functions";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { CustomImageHandler } from "../components/CustomImageHandler";
import { ProfileDetailsService } from "../../app/profile/service";
import authSlice from "../../store/auth/authRedux";
import { useDispatch } from "react-redux";
import { ExitService } from "../../authentication/exitPage/exitService";
import lightOrDarkModeSlice from "../../store/category/lightOrDarkMode";
import { handleStepNavigate } from "../../app/workflow/components/WorkflowStep2";
import { handleStepContentNavigate } from "../../app/contentWorkflow";

const publicRoutes = ["/forgot"];

export function Header() {
  const currentUser = useSelector((state: IAllStates) => state.auth);
  const roles = (currentUser?.roles as any[]) ?? [];
  const customRolesArray =
    currentUser?.customRoles?.map((role: any) => role?.name as string) ?? [];
  const allRoles = roles.concat(customRolesArray);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const selectedTheme = localStorage.getItem("currentTheme");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [allNotifications, setAllNotifications] = useState();
  const [numberOfAllNotif, setNumberOfAllNotif] = useState(0);
  const [theme, setTheme] = useState<"light" | "dark">("light");
  const [languages, setLanguages] = useState<string[]>([]);
  const body = document.querySelector("body")!;
  dispatch(lightOrDarkModeSlice.actions.setLightOrDarkMode(theme));
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [perPage, setPerPage] = useState(10);
  const [viewAllBtn, setViewAllBtn] = useState(true);
  var unreadMessRed = useSelector(
    (state: any) => state?.auth?.notificationData
  );
  const changeSeenStatus = (notificationId: number) => {
    ProfileDetailsService.changeSeenStatus(notificationId)
      .then((data) => {})
      .catch((error) => ErrorToast(error));
  };

  const linkForMainWorkflow = (
    id: number,
    e: any,
    keyForLink: string,
    workflow: any
  ) => {
    e.preventDefault();
    changeSeenStatus(id);
    if (keyForLink?.includes("finished")) {
      const stringForLink = keyForLink.split("_")[1];
      switch (stringForLink) {
        case "setting":
          return navigate(`/app/workflow/step1/${workflow?.id}`);
        case "import":
          return navigate(`/app/workflow/step2/${workflow?.id}`);
        case "base":
          return navigate(`/app/workflow/step3/${workflow?.id}`);
        case "attributes":
          return navigate(`/app/workflow/step4/${workflow?.id}`);
        case "copywriting":
          return navigate(`/app/workflow/step5/${workflow?.id}`);
        case "translation":
          return navigate(`/app/workflow/step6/${workflow?.id}`);
        case "overview":
          return navigate(`/app/workflow/step7/${workflow?.id}`);
        case "export":
          return navigate(`/app/workflow/step8/${workflow?.id}`);
        default:
          return navigate(`/app/workflow/step1/${workflow?.id}`);
      }
    } else if (keyForLink?.includes("assigned")) {
      const stringForLink = keyForLink.split("_")[1];
      switch (stringForLink) {
        case "copywriter":
          return navigate(`/app/workflow/step5/${workflow?.id}`);
        case "translator":
          return navigate(`/app/workflow/step6/${workflow?.id}`);
        default:
          return navigate(`/app/workflow/step1/${workflow?.id}`);
      }
    } else {
      const linkStatus = workflow?.tabs?.some(
        (tab: string) => tab === workflow?.status
      );
      handleStepNavigate(
        linkStatus === true ? workflow?.status : workflow?.tabs[0],
        Number(workflow?.id),
        navigate
      );
    }
  };

  const linkForContentWorkflow = (
    id: number,
    e: any,
    keyForLink: string,
    workflow: any
  ) => {
    e.preventDefault();
    changeSeenStatus(id);
    if (keyForLink?.includes("finished")) {
      const stringForLink = keyForLink.split("_")[2];
      switch (stringForLink) {
        case "setting":
          return navigate(`/app/content-workflow/setting-up/${workflow?.id}`);
        case "base":
          return navigate(`/app/content-workflow/base/${workflow?.id}`);
        case "copywriting":
          return navigate(`/app/content-workflow/copywriting/${workflow?.id}`);
        case "translation":
          return navigate(`/app/content-workflow/translation/${workflow?.id}`);
        case "export":
          return navigate(`/app/content-workflow/export/${workflow?.id}`);
        default:
          return navigate(`/app/content-workflow/setting-up/${workflow?.id}`);
      }
    } else if (keyForLink?.includes("assigned")) {
      const stringForLink = keyForLink.split("_")[1];
      switch (stringForLink) {
        case "copywriter":
          return navigate(`/app/content-workflow/copywriting/${workflow?.id}`);
        case "translator":
          return navigate(`/app/content-workflow/translation/${workflow?.id}`);
        default:
          return navigate(`/app/content-workflow/setting-up/${workflow?.id}`);
      }
    } else {
      const linkStatus = workflow?.tabs?.some(
        (tab: string) => tab === workflow?.status
      );
      handleStepContentNavigate(
        linkStatus === true ? workflow?.status : workflow?.tabs[0],
        Number(workflow?.id),
        navigate
      );
    }
  };
  const changeTheme = (currTheme: "dark" | "light") => {
    if (currTheme === "light") {
      localStorage.setItem("currentTheme", "dark");
      setTheme("dark");
      document.documentElement.setAttribute("data-layout-mode", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("currentTheme", "light");
      document.documentElement.setAttribute("data-layout-mode", "light");
    }
  };

  function menuToggle() {
    setMenuOpen(!menuOpen);
    body.classList.add("vertical-sidebar-enable");
  }

  const logoutUser = () => {
    localStorage.clear();
    navigate("/");
  };
  const showAllNotifications = (e: any) => {
    e.stopPropagation();
    setViewAllBtn((prev: boolean) => !prev);
    setPerPage(0);
    ProfileDetailsService.getAllNotifications({ perPage: 0 } as any)
      .then((response) => {
        unreadMessRed = { unread: 0 };
        const { count } = response;
        const { data } = response;
        setAllNotifications(data);
        setNumberOfAllNotif(count);
      })
      .catch((error) => ErrorToast(error));
  };
  // const showRoleName = () => {
  // if (currentUser?.roles?.length) {
  //   return formatRoles(currentUser?.roles);
  // } else {
  //   return formatRoles(customRoles);
  // }
  // };
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token === null && !publicRoutes.includes(pathname)) {
      navigate("/");
    }
  }, []);

  const changeLanguageHandler = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
    window.location.reload();
  };

  useEffect(() => {
    !publicRoutes.includes(pathname) &&
      ProfileDetailsService.getProfileDetails()
        .then((data) => {
          dispatch(authSlice.actions.setAuth(data));
        })
        .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    if (translationLanguageEnum) {
      const copy: string[] = [...translationLanguageEnum];
      copy.push("en");
      setLanguages(copy);
    }
  }, [JSON.stringify(translationLanguageEnum)]);

  // useEffect(() => {
  //   CategoryServicee.getAllCategories()
  //     .then((data) => {
  //       dispatch(categorySlice.actions.setCategory(data));
  //     })
  //     .catch((error) => ErrorToast(error));
  // }, []);

  const getAllNotifications = () => {
    ProfileDetailsService.getAllNotifications({ perPage: perPage } as any)
      .then((response) => {
        unreadMessRed = { unread: 0 };
        const { count } = response;
        const { data } = response;
        setAllNotifications(data);
        setNumberOfAllNotif(count);
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    const handleTabClose = (e: any) => {
      e.preventDefault();
      ExitService.putExit().then(() => {
        console.log("beforeunload event triggered");
      });
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
  useEffect(() => {
    document.documentElement.setAttribute(
      "data-layout-mode",
      selectedTheme ?? "light"
    );
    localStorage.setItem("currentTheme", selectedTheme ?? "light");
    setTheme((selectedTheme as "light" | "dark") ?? "light");
  }, []);
  const NumberOfNewNotifications = useCallback(() => {
    return <RedNumberNotifitacions unreadMessRed={unreadMessRed} />;
  }, [unreadMessRed]);

  //   function playSound(url: string) {
  //     const audio = new Audio(url);
  //     audio.play();
  //   }
  //   <button onClick={(e) => playSound("https://your-file.mp3")}>
  //   Play
  // </button>
  return (
    <>
      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <a href="/dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img
                      src="../../assets/images/logo-sm.png"
                      alt=""
                      height="22"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="../../assets/images/logo-dark.png"
                      alt=""
                      height="17"
                    />
                  </span>
                </a>
                <a href="/dashboard" className="logo logo-light">
                  <span className="logo-sm">
                    <img
                      src="../../assets/images/logo-sm.png"
                      alt=""
                      height="22"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="../../assets/images/logo-light.png"
                      alt=""
                      height="17"
                    />
                  </span>
                </a>
              </div>

              <button
                onClick={menuToggle}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className="d-flex align-items-center justify-content-end w-50">
              <div className="dropdown ms-1 topbar-head-dropdown header-item">
                <button
                  type="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <img
                    id="header-lang-img"
                    src={`/images/flags/${i18n.language}.svg`}
                    alt="Header Language"
                    height="20"
                    className="rounded"
                  />
                </button>
                <div className="ms-1 header-item d-none d-sm-flex">
                  <button
                    onClick={() => changeTheme(theme)}
                    type="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none"
                  >
                    {theme === "dark" ? (
                      <i className="bx bx-sun fs-22"></i>
                    ) : (
                      <i className="bx bx-moon fs-22"></i>
                    )}
                  </button>
                </div>
                <div
                  className="dropdown-menu dropdown-menu-end  position-absolute m-0"
                  data-popper-placement="bottom-end"
                  id="languageSwitcher"
                  style={{
                    inset: "0px 0px auto auto",
                    transform: "translate(0px, 58px)",
                  }}
                >
                  {translationLanguageEnum?.map((language) => {
                    return (
                      <div
                        key={language}
                        className="dropdown-item notify-item language py-2"
                        id={language}
                        onClick={() => {
                          changeLanguageHandler(language);
                        }}
                        data-lang={language}
                        title={language}
                      >
                        <img
                          src={`/images/flags/${language}.svg`}
                          alt="country-image"
                          className="me-2 rounded"
                          height="18"
                        />
                        <span className="align-middle">
                          {getCorrectLanguageTitle(language)}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="dropdown topbar-head-dropdown ms-1 header-item">
                <button
                  onClick={() => getAllNotifications()}
                  type="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  id="page-header-notifications-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bx bx-bell fs-22" />
                  <NumberOfNewNotifications />
                </button>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  aria-labelledby="page-header-notifications-dropdown"
                >
                  <div className="dropdown-head bg-primary bg-pattern rounded-top">
                    <div className="p-3">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="m-0 fs-16 fw-semibold text-white">
                            {" "}
                            {t("header.notifications.notification")}{" "}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="px-2 pt-2">
                      <ul
                        className="nav nav-tabs dropdown-tabs nav-tabs-custom"
                        data-dropdown-tabs="true"
                        id="notificationItemsTab"
                        role="tablist"
                      >
                        <li className="nav-item waves-effect waves-light">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#all-noti-tab"
                            role="tab"
                            aria-selected="true"
                          >
                            {t("header.notifications.all")}{" "}
                            {numberOfAllNotif ?? 0}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="tab-content position-relative"
                    id="notificationItemsTabContent"
                  >
                    <div
                      className="tab-pane fade show active py-2 ps-2"
                      id="all-noti-tab"
                      role="tabpanel"
                    >
                      <div
                        data-simplebar="init"
                        style={{ maxHeight: "300px" }}
                        className="pe-2 simplebar-scrollable-y"
                      >
                        <div
                          className="simplebar-wrapper"
                          style={{ margin: "0px -8px 0px 0px" }}
                        >
                          <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div>
                          </div>
                          <div className="simplebar-mask">
                            <div
                              className="simplebar-offset"
                              style={{ right: "0px", bottom: " 0px" }}
                            >
                              <div
                                className="simplebar-content-wrapper"
                                tabIndex={0}
                                role="region"
                                aria-label="scrollable content"
                                style={{
                                  height: "auto",
                                  overflow: "hidden scroll",
                                }}
                              >
                                <div
                                  className="simplebar-content"
                                  style={{ padding: "0px 8px 0px 0px" }}
                                >
                                  {/* ================================================================================================= */}
                                  {(allNotifications as any)?.map(
                                    (notification: any) => {
                                      return (
                                        <div
                                          className="text-reset notification-item d-block dropdown-item position-relative mb-1"
                                          key={notification?.id}
                                          style={{
                                            backgroundColor: `${
                                              notification?.seen
                                                ? ``
                                                : `#ebedf0`
                                            }`,
                                          }}
                                          onClick={(e) => {
                                            notification?.notificationType?.includes(
                                              "workflow_content"
                                            )
                                              ? linkForContentWorkflow(
                                                  notification?.id,
                                                  e as any,
                                                  notification?.notificationType as string,
                                                  notification?.workflowContent
                                                )
                                              : linkForMainWorkflow(
                                                  notification?.id,
                                                  e as any,
                                                  notification?.notificationType as string,
                                                  notification?.workflow
                                                );
                                          }}
                                        >
                                          <div className="d-flex">
                                            <div className="avatar-xs me-3 flex-shrink-0">
                                              <span
                                                className="avatar-title text-info rounded-circle fs-16"
                                                style={{
                                                  backgroundColor: `${
                                                    notification?.notificationType?.includes(
                                                      "finished"
                                                    )
                                                      ? "rgb(173, 230, 245)"
                                                      : "rgb(191, 245, 173)"
                                                  }`,
                                                }}
                                              >
                                                <i className="bx bx-badge-check"></i>
                                              </span>
                                            </div>
                                            <div className="flex-grow-1">
                                              <a
                                                href="#!"
                                                className="stretched-link"
                                              >
                                                <h6 className="mt-0 mb-2 lh-base">
                                                  {notification?.message}
                                                </h6>
                                                {/* <h6 className="mt-0 mb-2 lh-base">
                                                  Your <b>Elite</b> author
                                                  Graphic Optimization{" "}
                                                  <span className="text-secondary">
                                                    reward
                                                  </span>{" "}
                                                  is ready!
                                                </h6> */}
                                              </a>
                                              <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                <span>
                                                  <i className="mdi mdi-clock-outline"></i>{" "}
                                                  {formatDate(
                                                    notification?.createdAt
                                                  )}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                {viewAllBtn && numberOfAllNotif > 10 && (
                                  <div
                                    className="my-3 text-center view-all"
                                    style={{ display: "block" }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-soft-success waves-effect waves-light"
                                      onClick={(e) =>
                                        showAllNotifications(e as any)
                                      }
                                    >
                                      View All Messages{" "}
                                      <i className="ri-arrow-right-line align-middle"></i>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="simplebar-placeholder"
                            style={{ width: "312px", height: "510px" }}
                          ></div>
                        </div>
                        <div
                          className="simplebar-track simplebar-horizontal"
                          style={{ visibility: "hidden" }}
                        >
                          <div
                            className="simplebar-scrollbar"
                            style={{ width: " 0px", display: "none" }}
                          ></div>
                        </div>
                        <div
                          className="simplebar-track simplebar-vertical"
                          style={{ visibility: "visible" }}
                        >
                          <div
                            className="simplebar-scrollbar"
                            style={{
                              height: "176px",
                              display: "block",
                              transform: "translate3d(0px, 0px, 0px)",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade py-2 ps-2"
                      id="messages-tab"
                      role="tabpanel"
                      aria-labelledby="messages-tab"
                    >
                      <div
                        data-simplebar="init"
                        style={{ maxHeight: "300px" }}
                        className="pe-2"
                      >
                        <div
                          className="simplebar-wrapper"
                          style={{ margin: "0px -8px 0px 0px" }}
                        >
                          <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div>
                          </div>
                          <div className="simplebar-mask">
                            <div
                              className="simplebar-offset"
                              style={{ right: " 0px", bottom: "0px" }}
                            >
                              <div
                                className="simplebar-content-wrapper"
                                tabIndex={0}
                                role="region"
                                aria-label="scrollable content"
                                style={{ height: "auto", overflow: "hidden" }}
                              >
                                <div
                                  className="simplebar-content"
                                  style={{ padding: "0px 8px 0px 0px" }}
                                >
                                  <div className="text-reset notification-item d-block dropdown-item">
                                    <div className="d-flex">
                                      <img
                                        src="assets/images/users/avatar-3.jpg"
                                        className="me-3 rounded-circle avatar-xs"
                                        alt="user-pic"
                                      />
                                      <div className="flex-grow-1">
                                        <a href="#!" className="stretched-link">
                                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                            James Lemire
                                          </h6>
                                        </a>
                                        <div className="fs-13 text-muted">
                                          <p className="mb-1">
                                            We talked about a project on
                                            linkedin.
                                          </p>
                                        </div>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                          <span>
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            30 min ago
                                          </span>
                                        </p>
                                      </div>
                                      <div className="px-2 fs-15">
                                        <div className="form-check notification-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="messages-notification-check01"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="messages-notification-check01"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-reset notification-item d-block dropdown-item">
                                    <div className="d-flex">
                                      <img
                                        src="assets/images/users/avatar-2.jpg"
                                        className="me-3 rounded-circle avatar-xs"
                                        alt="user-pic"
                                      />
                                      <div className="flex-grow-1">
                                        <a href="#!" className="stretched-link">
                                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                            Angela Bernier
                                          </h6>
                                        </a>
                                        <div className="fs-13 text-muted">
                                          <p className="mb-1">
                                            Answered to your comment on the cash
                                            flow forecast's graph 🔔.
                                          </p>
                                        </div>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                          <span>
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            2 hrs ago
                                          </span>
                                        </p>
                                      </div>
                                      <div className="px-2 fs-15">
                                        <div className="form-check notification-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="messages-notification-check02"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="messages-notification-check02"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-reset notification-item d-block dropdown-item">
                                    <div className="d-flex">
                                      <img
                                        src="assets/images/users/avatar-6.jpg"
                                        className="me-3 rounded-circle avatar-xs"
                                        alt="user-pic"
                                      />
                                      <div className="flex-grow-1">
                                        <a href="#!" className="stretched-link">
                                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                            Kenneth Brown
                                          </h6>
                                        </a>
                                        <div className="fs-13 text-muted">
                                          <p className="mb-1">
                                            Mentionned you in his comment on 📃
                                            invoice #12501.
                                          </p>
                                        </div>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                          <span>
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            10 hrs ago
                                          </span>
                                        </p>
                                      </div>
                                      <div className="px-2 fs-15">
                                        <div className="form-check notification-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="messages-notification-check03"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="messages-notification-check03"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-reset notification-item d-block dropdown-item">
                                    <div className="d-flex">
                                      <img
                                        src="assets/images/users/avatar-8.jpg"
                                        className="me-3 rounded-circle avatar-xs"
                                        alt="user-pic"
                                      />
                                      <div className="flex-grow-1">
                                        <a href="#!" className="stretched-link">
                                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                            Maureen Gibson
                                          </h6>
                                        </a>
                                        <div className="fs-13 text-muted">
                                          <p className="mb-1">
                                            We talked about a project on
                                            linkedin.
                                          </p>
                                        </div>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                          <span>
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            3 days ago
                                          </span>
                                        </p>
                                      </div>
                                      <div className="px-2 fs-15">
                                        <div className="form-check notification-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="messages-notification-check04"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="messages-notification-check04"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="my-3 text-center view-all"
                                    style={{ display: "block" }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-soft-success waves-effect waves-light"
                                    >
                                      View All Messages{" "}
                                      <i className="ri-arrow-right-line align-middle"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="simplebar-placeholder"
                            style={{ width: "0px", height: "0px" }}
                          ></div>
                        </div>
                        <div
                          className="simplebar-track simplebar-horizontal"
                          style={{ visibility: "hidden" }}
                        >
                          <div
                            className="simplebar-scrollbar"
                            style={{ width: "0px", display: "none" }}
                          ></div>
                        </div>
                        <div
                          className="simplebar-track simplebar-vertical"
                          style={{ visibility: "hidden" }}
                        >
                          <div
                            className="simplebar-scrollbar"
                            style={{ height: "0px", display: "none" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade p-4"
                      id="alerts-tab"
                      role="tabpanel"
                      aria-labelledby="alerts-tab"
                    >
                      <div className="empty-notification-elem">
                        {" "}
                        <div className="w-25 w-sm-50 pt-3 mx-auto">
                          {" "}
                          <img
                            src="assets/images/svg/bell.svg"
                            className="img-fluid"
                            alt="user-pic"
                          />{" "}
                        </div>{" "}
                        <div className="text-center pb-5 mt-2">
                          {" "}
                          <h6 className="fs-18 fw-semibold lh-base">
                            Hey! You have no any notifications{" "}
                          </h6>{" "}
                        </div>{" "}
                      </div>
                    </div>

                    {/* <div
                      className="notification-actions"
                      id="notification-actions"
                    >
                      <div className="d-flex text-muted justify-content-center">
                        Select{" "}
                        <div
                          id="select-content"
                          className="text-body fw-semibold px-1"
                        >
                          0
                        </div>{" "}
                        Result{" "}
                        <button
                          type="button"
                          className="btn btn-link link-danger p-0 ms-3"
                          data-bs-toggle="modal"
                          data-bs-target="#removeNotificationModal"
                        >
                          Remove
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/*END NOTIFI*/}
                <div className="dropdown ms-sm-3 header-item topbar-user">
                  <button
                    type="button"
                    className="btn"
                    id="page-header-user-dropdown"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="d-flex align-items-center">
                      <CustomImageHandler
                        alt="user-profile-image"
                        path={currentUser?.profilePicture?.path as string}
                        classes="rounded-circle header-profile-user object-cover"
                      />
                      <span className="text-start ms-xl-2">
                        <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                          {currentUser?.firstName} {currentUser?.lastName}
                        </span>
                        <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                          {/* {showRoleName()} */}
                          {formatRoles(allRoles)}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    <h6 className="dropdown-header">
                      {t("header.profile.welcome")} {currentUser?.firstName}
                    </h6>
                    <Link className="dropdown-item" to="/app/profile">
                      <CustomImageHandler
                        alt="user-profile-image"
                        path={currentUser?.profilePicture?.path as string}
                        classes="rounded-circle align-middle me-1"
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="align-middle">
                        {t("header.profile.profile")}
                      </span>
                    </Link>
                    <div className="dropdown-divider"></div>
                    <div className="dropdown-item" onClick={logoutUser}>
                      <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                      <span className="align-middle" data-key="t-logout">
                        {t("header.profile.logout")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

const getCorrectLanguageTitle = (args: string) => {
  switch (args) {
    case "de":
      return "German";
    case "fr":
      return "French";
    case "en":
      return "English";
    case "it":
      return "Italian";
    default:
      return `NO KEY ${args}`;
  }
};
const RedNumberNotifitacions = ({ unreadMessRed }: any) => {
  return (
    <>
      {unreadMessRed?.unread !== 0 && (
        <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
          {unreadMessRed?.unread}
          <span className="visually-hidden">unread messages</span>
        </span>
      )}
    </>
  );
};
