import { ActualFileObject } from "filepond";
import React, { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import GalleryCardPreview from "../../../../shared/gallery/GalleryCardPreview";
import { Pagination } from "../../../../shared/paginations/Paginations";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { IExport } from "../../../exports/dto/IExports";
import { ExportService } from "../../../exports/service";
import { IProducts, IProductsList, IProductValue } from "../../dto/IProducts";
import { ProductsService } from "../../service";
import { ProductsAttributesActions } from "./ProductsAttributesActions";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import ImageContainer from "../../../../shared/gallery/ImageContainer";
import changeLocationSlice from "../../../../store/category/changeLocation";

interface Props {
  isWorkflowUpdate?: boolean;
  selectedProductId?: number;
  setSelectedProductId?: React.Dispatch<SetStateAction<number>> | undefined;
}

export const ProductsAttributesUpdate = ({
  isWorkflowUpdate = false,
  selectedProductId,
  setSelectedProductId,
}: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const [variationPagination, setVariationPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const location = useLocation();
  const tab = "BASE_DATA";
  const { state: close }: any = useLocation();
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const [currentProduct, setCurrentProduct] = useState<IProductsList>();
  const [files, setFiles] = useState<ActualFileObject[]>([]);
  const [exportsList, setExportsList] = useState<IExport[]>([]);
  const [page, setPage] = useState(1);
  const secondViewPagination = useSelector(
    (state: IAllStates) => state.secondViewPagination as any
  );
  const { state: pagination }: any = useLocation();
  const handlePages = (updatePage: number) => {
    setVariationPagination({ ...variationPagination, page: updatePage });
    setPage(updatePage);
  };
  const [exportData, setExportData] = useState<{
    clientExport: number;
  }>();
  const handleSelectMainCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>,
    inputKey: string
  ) => {
    dispatch(changeLocationSlice.actions.setChangeLocation(true));
    setCurrentProduct((prev: any) => ({
      ...prev,
      [inputKey]: selectedSingle?.value,
    }));
  };
  const checkHref = () => {
    if (
      currentProduct?.sourceUrl === null ||
      currentProduct?.sourceUrl === "" ||
      currentProduct?.sourceUrl === undefined
    ) {
      return "/403";
    } else if (
      currentProduct?.sourceUrl?.includes("https://") ||
      currentProduct?.sourceUrl?.includes("http://")
    ) {
      return currentProduct?.sourceUrl;
    } else {
      return `https://${currentProduct?.sourceUrl}`;
    }
  };
  const changeValuesHandler = (values: any, attributeId: number) => {
    let tempArr: IProductValue[] = currentProduct?.values ?? [];
    const indexToReplace = tempArr?.findIndex(
      (attribute) => attribute.attribute === attributeId
    );
    const addId = values?.find((item: { isAdd: boolean }) => item.isAdd)
      ?.value as number;

    if (indexToReplace === -1) {
      tempArr?.push({
        attribute: attributeId,
        value: values
          ?.map((value: { value: number }) => value.value)
          .filter((item: number) => item !== addId),
      });
    } else {
      tempArr[indexToReplace] = {
        ...tempArr[indexToReplace],
        value: values
          ?.map((value: { value: number }) => value.value)
          .filter((item: number) => item !== addId),
      };
    }
    dispatch(changeLocationSlice.actions.setChangeLocation(true));
    setCurrentProduct((prev: any) => ({ ...prev, values: tempArr }));
  };
  const selectExportHandler = (e: any) => {
    setExportData({ clientExport: +e.value });
  };

  const exportClickHandler = () => {
    ExportService.exportProduct(exportData, +id!)
      .then((res) => {
        SuccessToast(`${t("toasts.successfully_exported")}`);
      })
      .catch((err) => console.log(err));
  };
  const handleClose = () => {
    if (isWorkflowUpdate) {
      navigate(`/app/workflow/step4/${id}`);
    } else {
      navigate("/app/products/attributes", {
        state:
          (location.state as any)?.pagination ??
          secondViewPagination?.pagination,
      });
    }
    // navigate("/app/pending-products/imported", { state: { pagination: pagination?.pagination ?? {} } });
  };

  useEffect(() => {
    ExportService.getAllExports({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setExportsList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    id &&
      tab &&
      ProductsService.getProductById(
        isWorkflowUpdate ? Number(selectedProductId) : +id,
        tab
      )
        .then((response) => {
          setCurrentProduct(response);
        })
        .catch((error) => {
          ErrorToast(error);
          //novo
          navigate(-1);
        });
  }, [id, selectedProductId, isWorkflowUpdate]);
  const navContent = () => {
    if (close?.close === "export") {
      navigate(`/app/products/edit-content/${id}`, {
        state: {
          close: "export",
          idExport: (location.state as any).idExport,
          headerName: (location.state as any).headerName,
        },
      });
    } else {
      navigate(`/app/products/edit-content/${id}`, { state: pagination });
    }
  };
  const navBaseData = () => {
    if (close?.close === "export") {
      navigate(`/app/products/base-data/${id}`, {
        state: {
          close: "export",
          idExport: (location.state as any).idExport,
          headerName: (location.state as any).headerName,
        },
      });
    } else {
      navigate(`/app/products/base-data/${id}`, { state: pagination });
    }
  };
  const backHandle = () => {
    if (close?.close === "export") {
      navigate(`/app/export/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
      //  navigate(-1)
    } else if (close?.close === "attributes") {
      navigate(`/admin/attributes/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
    } else if (handleClose) {
      handleClose();
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      {isWorkflowUpdate && (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <button
              onClick={() => backHandle()}
              className="btn btn-info d-flex align-items-start justify-content-start mb-3"
            >
              <i className="ri-arrow-left-line me-1"></i>
              {t("products.back")}
            </button>
            <a
              rel="noreferrer"
              target="_blank"
              href={checkHref()}
              // href={`${currentProduct?.sourceUrl === null ? "/403" : `${currentProduct?.sourceUrl}`} `}
            >
              <button className="btn btn-primary">
                {t("products.source_url")}
              </button>
            </a>
          </div>

          <div className="d-flex  align-items-center mb-3">
            <div className="d-flex gap-3 align-items-center">
              <ImageContainer img={currentProduct?.productPictures ?? []} />
              {/* <GalleryCardPreview
                img={
                  currentProduct?.productPictures[0]?.path ||
                  "/images/jk-placeholder-image.jpg"
                }
              /> */}
              {/* <CustomImageHandler
            classes="avatar-lg rounded-circle me-2"
            path={currentProduct?.productPictures[1]?.path ?? ""}
            alt="User logo"
          /> */}
              <div>
                <h2>{currentProduct?.title}</h2>
                <p>{currentProduct?.eanCode}</p>
              </div>
            </div>
          </div>
        </>
      )}
      {!isWorkflowUpdate && (
        <>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-sm-flex align-items-center justify-content-between mb-3">
                  <ul className="nav nav-pills nav-fill">
                    <li onClick={() => navBaseData()}>
                      <span className="nav-link cursor-pointer text-dark">
                        {t("products.base_data_update")}
                      </span>
                    </li>
                    <li>
                      <span className="nav-link active">
                        {t("products.attributes_update")}
                      </span>
                    </li>
                    <li onClick={() => navContent()}>
                      <span className="nav-link cursor-pointer text-dark">
                        {t("products.content_update")}
                      </span>
                    </li>
                  </ul>
                  <div className="row  align-items-end" style={{ width: 500 }}>
                    <div className="col-9">
                      <h6 className="m-0 p-0 mb-2">{t("global.export")}:</h6>

                      <Select
                        className="react-select-field"
                        onChange={selectExportHandler}
                        options={exportsList.map((exportItem) => ({
                          label: exportItem.name,
                          value: exportItem.id,
                        }))}
                      />
                    </div>
                    <button
                      className="btn btn-primary word-wrap col-3"
                      onClick={exportClickHandler}
                    >
                      {t("global.add_to_export")}
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <button
                    onClick={() => backHandle()}
                    className="btn btn-info d-flex align-items-end mb-3"
                  >
                    <i className="ri-arrow-left-line me-1"></i>
                    {t("products.back")}
                  </button>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={checkHref()}
                    // href={`${currentProduct?.sourceUrl === null ? "/403" : `${currentProduct?.sourceUrl}`} `}
                  >
                    <button className="btn btn-primary">
                      {t("products.source_url")}
                    </button>
                  </a>
                </div>
                <div className="col-md-12 mb-3 d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center mb-3">
                    <div className="d-flex gap-3 align-items-center">
                      <ImageContainer
                        img={currentProduct?.productPictures ?? []}
                      />
                      {/* <GalleryCardPreview
                  img={
                    currentProduct?.productPictures[0]?.path ||
                    "/images/jk-placeholder-image.jpg"
                  }
                /> */}

                      {/* <CustomImageHandler
            classes="avatar-lg rounded-circle me-2"
            path={currentProduct?.productPictures[1]?.path ?? ""}
            alt="User logo"
          /> */}
                      <div>
                        <h2>{currentProduct?.title}</h2>
                        <p>{currentProduct?.eanCode}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ProductsAttributesActions
        handleClose={handleClose}
        isWorkflowUpdate={
          location?.pathname?.includes("workflow/step4/") ? true : false
        }
        setSelectedProductId={setSelectedProductId}
        selectedProductId={selectedProductId}
        currentProduct={currentProduct as IProductsList}
        setCurrentProduct={
          setCurrentProduct as React.Dispatch<
            SetStateAction<IProductsList | IProducts>
          >
        }
        handleSelectMainCategory={handleSelectMainCategory}
        changeValuesHandler={changeValuesHandler}
      />
      <div className="col-9 d-flex justify-content-end ">
        {totalPages <= 1 ? (
          ""
        ) : (
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePagination={handlePages}
          />
        )}
      </div>
    </>
    // </PageTemplate>
  );
};
