import { showTooltip } from "./actions";


export function contentParser(html, data) {
    let divElement = document.createElement('div');
    divElement.innerHTML = html.trim();
    html = parseDoom(divElement, data);
    html = html.substring(5, html.length - 6)

    return html;
}

function parseDoom(element, data) {
    let html = "";
    for (const pElement of element.childNodes) {
               
        if (pElement && pElement.nodeType === Node.TEXT_NODE) {
     
            html += textParser(pElement.textContent, data)
          
        } else if (pElement && !pElement.classList.contains('glossary')) {
      
            html += parseDoom(pElement, data)
            
        } else if (pElement && pElement.classList.contains('glossary')) {
              
            html += pElement.outerHTML;
        }
    }

    element.innerHTML = html
    return element.outerHTML.trim();
}

function textParser(text, data) {
    var elem = '<div class="well"><a href="google.com">Message one, From someone.</a></div>'+
    '<div class="well"><a href="google.com">Message one, From someone.</a></div>'+
    '<button id="close-popover" class="btn btn-small btn-primary pull-right">Close please!</button>';
    if (!text) {
        return '';
    }
    
    for (const [key, value] of Object.entries(data)) {
        //    text = text.replaceAll(key,
        //        `<span class='ckeditor-glossary ckeditor-glossary-detected tooltip-custom' data-glossary_id='${makeid(8)}' data-glossary_word='${key}' data-glossary_alternative='${value}'>${key} <span class='tooltiptext-custom'>Tooltip text</span>
        //       </span>`
        // )

        text = text.replaceAll(key,
            `<span class="ckeditor-glossary ckeditor-glossary-detected" data-glossary_id="${makeid(8)}" data-glossary_word="${key}" data-glossary_alternative="${value}" data-bs-toggle="popover" data-bs-title="
            <span class='left-title'>Glossary</span>
            <span class='right-title custom-cursor'>x</span>
            " 
            data-bs-placement="right" data-bs-html="true" data-bs-content="
            <p>Change word ${key} to ${value}</p>
            <span class='left-btn discard custom-cursor btm btn-secondary p-1'>Discard</span>
            <span class='right-btn change custom-cursor btm btn-primary p-1'>Change</span>    
            "
           
             data-bs-trigger="click">${key}</span>
            `
        )
        
    }
    return text;
}

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
