import { createSlice } from '@reduxjs/toolkit';

const expOrAttPaginationSlice = createSlice({

    name: 'expOrAttPagination',
    initialState: {},

    reducers: {
        setExpOrAttPagination: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export default expOrAttPaginationSlice;