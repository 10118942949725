import { Button, Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import caution from "../icons/caution.json";
import { useTranslation } from "react-i18next";

export const ModalTranslateAll = ({ show, setShow, translateHandler }: any) => {
  const handleClose = () => setShow(false);
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={handleClose} size="sm" centered>
      <Modal.Body className="d-flex align-items-center flex-column">
        <Lottie animationData={caution} loop={true} style={{ width: 100 }} />
        <h5>{t("modals.are_you_sure")}</h5>
        <p className="text-center text-muted">
          {/* {t("modals.are_you_sure_you_want_to_delete")} */}
          Product translation may take several minutes.
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-center">
        <Button variant="light" size="sm" onClick={handleClose}>
          {t(`modals.close`)}
        </Button>
        <Button
          variant="success"
          size="sm"
          onClick={() => {
            handleClose();
            translateHandler();
          }}
        >
          {t(`global.translateAll`)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
