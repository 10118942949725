import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
import { Card, Form, Row } from "react-bootstrap";
import { ExportForm } from "./components/ExportForm";
import { IExport } from "./dto/IExports";
import { ExportService } from "./service";
import { ExportList } from "./components/ExportList";
import _debounce from "lodash/debounce";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { PageTemplate } from "../../shared/layout/PageTemplate";
import { ClientService } from "../clients/service";
import { SingleValue } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import expOrAttPaginationSlice from "../../store/category/expOrAttPagination";

const initialState = {
  id: 0,
  name: "",
  baseData: false,
  attributes: false,
  content: false,
};

export const ClientExport = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [exportsList, setExportsList] = useState<IExport[] | undefined>();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [exportId, setExportId] = useState<number | null>(null);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [currentExport, setCurrentExport] = useState<IExport | undefined>();
  const [clientList, setClientList] = useState<IExport[]>([]);
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [clientParams, setClientParams] = useState({
    page: 1,
    perPage: 10,
  });

  const [format, setFormat] = useState("");
  const [formatChoice, setFormatChoice] = useState<string>("");
  const [viewChoice, setViewChoice] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  dispatch(expOrAttPaginationSlice.actions.setExpOrAttPagination({}));
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const { exportFormatEnum } = useSelector((state: IAllStates) => state.enums);
  const { exportView } = useSelector((state: IAllStates) => state.enums);

  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPagination((prev) => ({ ...prev, [name]: value }));
    },
    200
  );

  const changeFilterClientHandler = (
    selectedSingle: SingleValue<IExport | null>,
    inputKey: string
  ) => {
    setPagination((prev) => ({ ...prev, [inputKey]: selectedSingle?.value }));
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (currentExport?.id) {
      ExportService.updateExport(
        {
          ...currentExport,
          client: currentExport?.client?.id
            ? currentExport?.client?.id
            : (currentExport?.client as any),
        },
        currentExport?.id
      )
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_updated")} ${response?.name}`);
          setCurrentExport(initialState);
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
        })
        .catch((error) => ErrorToast(error));
    } else {
      ExportService.createExport(currentExport)
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_created")} ${response?.name}`);
          setCurrentExport(initialState);
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
        })
        .catch((error) => ErrorToast(error));
    }
  };

  const editHandler = (exportId: number) => {
    ExportService.getExportById(exportId)
      .then((response) => {
        setCurrentExport(response);
        setIsMenuOpened(true);
        setExportId(+exportId!);
      })
      .catch((error) => ErrorToast(error));
  };
  const exportHandler = (expId: number) => {
    setExportId(expId as any);
  };

  const formatChangeHandler = (e: any) => {
    setFormatChoice(e.target.value);
  };

  const viewChangeHandler = (e: any) => {
    setViewChoice(e.target.value);
  };

  const onClickFormat = (e: any) => {
    ExportService.formatExport(
      formatChoice,
      viewChoice,
      exportId as unknown as number
    )
      .then((response) => {
        window.open(response?.path);
      })
      .catch((error) => ErrorToast(error));

    // submitExportHandler()
  };
  // const submitExportHandler = () => {
  //   format &&
  //     ExportService.formatExport(format as string, exportId as unknown as number)
  //       .then((response) => {
  //         window.open(response?.path)
  //         setIsExportpened(false);
  //       })
  //       .catch((error) => ErrorToast(error));
  // };
  const changeClientHandler = _debounce((targetValue: string) => {
    setClientParams((prev) => ({ ...prev, name: targetValue }));
  }, 200);

  useEffect(() => {
    ClientService.getAllClients({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setClientList(
          data?.map((client) => ({
            ...client,
            label: client.name,
            value: client.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, [clientParams]);

  useEffect(() => {
    ExportService.getAllExports(pagination)
      .then((response) => {
        const { data } = response;
        setTotalPages(Math.ceil(response?.count / response?.perPage));
        setExportsList(data);
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, triggerUpdate]);

  return (
    // <PageTemplate title={`${t("side_bar.client_export")}`}>
    <Col xs={12}>
      {accessRights?.includes("export_can_create") && !isMenuOpened && (
        <div className="card ">
          <div className="card-body d-flex align-items-center justify-content-between">
            <h4 className="card-title mg-b-0">
              {t("export_client.create_new_export")}
            </h4>
            <button
              className="btn btn-primary"
              onClick={() => setIsMenuOpened(true)}
            >
              {t("global.add")}
            </button>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <Form onSubmit={submitHandler}>
          <ExportForm
            setIsMenuOpened={setIsMenuOpened}
            state={currentExport}
            changeClientHandler={changeClientHandler}
            clientList={clientList}
            setState={setCurrentExport}
            accessRights={accessRights}
            exportId={exportId}
          />
        </Form>
      )}

      <ExportList
        page={page}
        accessRights={accessRights}
        handlePages={handlePages}
        totalPages={totalPages}
        exportsList={exportsList}
        setTriggerUpdate={setTriggerUpdate}
        clientList={clientList}
        changeFilterClientHandler={changeFilterClientHandler}
        changeClientHandler={changeClientHandler}
        changeFilterHandler={changeFilterHandler}
        editHandler={editHandler}
        exportHandler={exportHandler}
        exportFormatEnum={exportFormatEnum}
        onClickFormat={onClickFormat}
        exportView={exportView}
        formatChangeHandler={formatChangeHandler}
        viewChangeHandler={viewChangeHandler}
      />
    </Col>
    // </PageTemplate>
  );
};
