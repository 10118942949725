import React, { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import {
  ICategoryChildren,
  ICategoryDTO,
} from "../../category/values/dto/ICategory";
import { getWebsiteTranslation } from "../../../shared/functions/Functions";
import { getCorrectLanguage } from "../../../shared/functions/Functions";

interface ICrawlerFormProps {
  handleChange: any;
  state: any;
  closeHandler: any;
  setState: any;
  categories: any;
  selectedMainCategory: ICategoryChildren[];
  setSelectedMainCategory: React.Dispatch<SetStateAction<ICategoryChildren[]>>;
}

export const CrawlerForm = ({
  handleChange,
  state,
  closeHandler,
  setState,
  categories,
  selectedMainCategory,
  setSelectedMainCategory,
}: ICrawlerFormProps) => {
  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) {
    setState((prev: any) => ({
      ...prev,
      website: selectedSingle?.value,
    }));
  }

  function handleSelectSingle2(
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) {
    const selected = categories?.find(
      (category: ICategoryDTO) => category?.id === selectedSingle?.value
    )?.children;
    selected && setSelectedMainCategory(selected);
  }

  const handleSelectMainCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    setState((prev: any) => ({
      ...prev,
      category: selectedSingle?.value,
    }));
  };

  const { t } = useTranslation();
  const { websiteEnum } = useSelector((state: IAllStates) => state.enums);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  function handleSelectSingle3(selectedSingle: any) {
    setState((prev: any) => ({
      ...prev,
      language: selectedSingle?.map(
        (selectedSingle: { value: string }) => selectedSingle?.value
      ),
    }));
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div
                className={`col-md-${
                  !!selectedMainCategory?.length ? "2" : "3"
                } my-2`}
              >
                <label htmlFor="language" className="required-field">
                  {`${t("export_client.language")}`}
                </label>
                <Select
                  className="react-select-field"
                  theme={themeSelect}
                  key={state?.id}
                  defaultValue={state?.language?.map((language: string) => ({
                    value: language,
                    label: getCorrectLanguage(language, t),
                  }))}
                  onChange={(e) => {
                    handleSelectSingle3(e);
                  }}
                  isMulti={true}
                  options={(translationLanguageEnum as any)?.map(
                    (language: string) => ({
                      value: language,
                      label: getCorrectLanguage(language, t),
                    })
                  )}
                />
              </div>
              <div className="col-md-3 my-2">
                <label htmlFor="title" className="required-field">
                  {`${t("crawlers.main_category")}`}
                </label>
                <Select
                  className="react-select-field"
                  theme={themeSelect}
                  options={categories
                    ?.filter(
                      (category: { children: [] }) =>
                        !!category?.children?.length
                    )
                    ?.map((cat: { id: any; title: any }) => ({
                      value: cat?.id,
                      label: cat?.title,
                    }))}
                  key={state?.id}
                  defaultValue={
                    state?.parents && {
                      label: state?.parents?.find(
                        (parent: { level: number }) => parent?.level === 1
                      )?.title,
                      value: state?.parents?.find(
                        (parent: { level: number }) => parent?.level === 1
                      )?.id,
                    }
                  }
                  onChange={(e) => {
                    handleSelectSingle2(e);
                  }}
                />
              </div>
              {!!selectedMainCategory?.length && (
                <div className="col-md-2 my-2">
                  <label htmlFor="title" className="required-field">
                    {`${t("crawlers.category")}`}
                  </label>
                  <Select
                    className="react-select-field"
                    theme={themeSelect}
                    isClearable={true}
                    options={selectedMainCategory?.map((mainCat) => ({
                      label: mainCat?.title,
                      options: mainCat?.children?.map((subCat) => ({
                        label: subCat?.title,
                        value: subCat?.id,
                      })),
                    }))}
                    key={state?.id}
                    defaultValue={
                      state?.category?.id && {
                        label: state?.category?.title,
                        value: state?.category?.id,
                      }
                    }
                    onChange={(e) => {
                      handleSelectMainCategory(
                        e as SingleValue<{ label: string; value: number }>
                      );
                    }}
                  />
                </div>
              )}
              <div
                className={`col-md-${
                  !!selectedMainCategory?.length ? "2" : "3"
                } my-2`}
              >
                <label htmlFor="website" className="required-field">
                  {`${t("crawlers.website")}`}
                </label>
                <Select
                  className="react-select-field"
                  options={websiteEnum?.map((website) => ({
                    value: website,
                    label: getWebsiteTranslation(website, t),
                  }))}
                  theme={themeSelect}
                  onChange={(e) => {
                    handleSelectSingle(e);
                  }}
                  defaultValue={
                    state?.website && {
                      label: getWebsiteTranslation(state?.website, t),
                      value: state?.website,
                    }
                  }
                  key={state?.id}
                />
              </div>
              <div className={`col-md-3 my-2`}>
                <label htmlFor="url" className="required-field">
                  {`${t("crawlers.url")}`}
                </label>
                <input
                  type="text"
                  name="url"
                  required
                  value={state?.url}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>

              <div className="col-12 my-2 d-flex justify-content-end">
                <button className="btn btn-info" onClick={closeHandler}>
                  {t("global.cancel")}
                </button>
                <button className="btn btn-primary ms-3" type="submit">
                  {state?.id
                    ? `${t("global.update")}`
                    : `${t("global.create")}`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
