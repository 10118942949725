import { createSlice } from "@reduxjs/toolkit";

const contentFirstViewSlice = createSlice({
  name: "contentFirstView",
  initialState: true,

  reducers: {
    setContentFirstView: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export default contentFirstViewSlice;
