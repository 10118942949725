import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import Carousel from "react-bootstrap/Carousel";

const ImageContainer = ({ img }: any) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  const item1 = document.getElementsByClassName("carousel-control-prev");
  const item2 = document.querySelector("#carousel-control-next");

  return (
    <a
      className="gallery-image popup-image h-100 overflow-hidden"
      onClick={(ev) => {
        ev.preventDefault();
        toggle();
      }}
      href="#gallery"
    >
      <img
        className=""
        style={{ objectFit: "cover", height: "120px", width: "120px" }}
        src={img[0]?.path ?? "/images/jk-placeholder-image.jpg"}
        alt="Image One"
      />
      <Modal isOpen={open} toggle={toggle} size="large">
        <button type="button" className="btn fs-3" onClick={toggle}>
          ×
        </button>
        <Carousel>
          {img?.length ? (
            img?.map((item: any) => {
              return (
                <Carousel.Item interval={6000}>
                  <img
                    className="d-block w-100"
                    src={item?.path}
                    alt="Image One"
                  />
                </Carousel.Item>
              );
            })
          ) : (
            <Carousel.Item interval={6000}>
              <img
                className="d-block w-100"
                src={"/images/jk-placeholder-image.jpg"}
                alt="Image One"
              />
            </Carousel.Item>
          )}
        </Carousel>
      </Modal>
    </a>
  );
};

export default ImageContainer;
