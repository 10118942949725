import React, { SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import { useTranslation } from "react-i18next";
import { IAttributeValue } from "../dto/IAttributeValue";
import {
  getCorrectFormality,
  getCorrectLanguage,
} from "../../../../shared/functions/Functions";
import Select, { MultiValue, SingleValue } from "react-select";
import { themeSelect } from "../../../../shared/components/Select2";
import { ProductsService } from "../../../products/service";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { useLocation } from "react-router-dom";

interface IAttributeValueFormProps {
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClose: () => void;
  handleCreate: () => void;
  currentValue: IAttributeValue;
  isMenuOpened: boolean;
  setIsMenuOpened: React.Dispatch<SetStateAction<boolean>>;
  setCurrentValue: React.Dispatch<SetStateAction<IAttributeValue>>;
}

export const AttributeValueForm = ({
  changeHandler,
  handleCreate,
  isMenuOpened,
  handleClose,
  currentValue,
  setIsMenuOpened,
  setCurrentValue,
}: IAttributeValueFormProps) => {
  const { t } = useTranslation();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const { state: title }: any = useLocation();
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const [formality, setFormality] = useState(null);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [sourceLanguage, setSourceLanguage] = useState("");
  const attTitle = { state: title }?.state?.title;
  // const handleTranslate = () => {
  //   console.log(
  //     sourceLanguage,
  //     currentValue?.translation?.[sourceLanguage as string]
  //   );
  // };
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }
  const handleTranslate = () => {
    const langArr = translationLanguageEnum?.filter(
      (lang) => lang !== sourceLanguage
    );
    for (let i = 0; i < langArr?.length; i++) {
      ProductsService.postTranslation({
        text: currentValue?.translation?.[sourceLanguage],
        sourceLanguage: sourceLanguage,
        targetLanguage: langArr[i],
        formality: formality as any,
      })
        .then((response) => {
          const result = response;
          setCurrentValue((prev: any) => ({
            ...prev,
            translation: {
              ...prev?.translation,
              [langArr[i]]: result,
            },
          }));
          if (i === langArr?.length - 1) {
            SuccessToast(`${t("toasts.successfully_translated")}`);
          }
        })
        .catch((error) => ErrorToast(error));
    }
  };
  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setSourceLanguage(selectedSingle?.value as string);
  }

  return (
    <>
      {!isMenuOpened ? (
        <>
          {accessRights?.includes("attribute_can_create") && (
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="card-title mg-b-0">
                {t("attributes.create_new_value")}{" "}
                <span className="custom-bold">{attTitle}</span>
              </h4>
              <button
                className="btn btn-primary"
                onClick={() => setIsMenuOpened(true)}
              >
                {t("global.add")}
              </button>
            </div>
          )}
        </>
      ) : (
        <div>
          <div className="row">
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex">
                <Select
                  className="react-select-field me-2"
                  name="content"
                  theme={themeSelect}
                  placeholder={`${t("formality.select_formality")}`}
                  options={deeplFormality?.map((formality) => ({
                    value: formality,
                    label: getCorrectFormality(formality, t),
                  }))}
                  // key={state?.id}
                  onChange={(e) => {
                    handleSelectSingleFormality(e as any);
                  }}
                />

                <Select
                  className="custom_zindex react-select-field me-2"
                  theme={themeSelect}
                  placeholder="Translate from"
                  isClearable={true}
                  options={translationLanguageEnum?.map((lang) => ({
                    value: lang,
                    label: getCorrectLanguage(lang, t),
                  }))}
                  //   key={state?.id}
                  onChange={(e) => {
                    handleSelectSingle(e as any);
                  }}
                />
                <button className="btn btn-secondary" onClick={handleTranslate}>
                  {t("global.translate")}
                </button>
              </div>
            </div>
            {translationLanguageEnum?.map((language, index) => {
              return (
                <div className="col-md-3 my-1" key={`Language - ${index}`}>
                  <label htmlFor={`title-${language}`}>
                    {t("category.enterTitle")} {getCorrectLanguage(language, t)}
                  </label>
                  <input
                    type="text"
                    name={language}
                    id={`title-${language}`}
                    onChange={changeHandler}
                    value={currentValue?.translation?.[language] ?? ""}
                    className="form-control"
                  />
                </div>
              );
            })}
          </div>
          <div className="mt-2 d-flex justify-content-end">
            <button className="btn btn-info me-2" onClick={handleClose}>
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary" onClick={handleCreate}>
              {currentValue?.id
                ? `${t("global.update")}`
                : `${t("global.create")}`}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
