import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { CreateForm } from "./CreateForm";
// import "../../../assets/scss/scroll.scss";
import { ApiService } from "../service";
import { ApiStepper } from "./ApiStepper";
export const FirstStep = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<any>({
    id: null,
    name: "",
    workflow: null,
    clientProfile: null,
    language: [],
    exportBaseData: false,
    exportAttributes: false,
    exportContent: false,
  });

  const submitHandler = (event: any) => {
    ApiService.updateById(+id!, {
      ...state,
      workflow: state?.workflow?.value,
      clientProfile: state?.clientProfile?.value,
    })
      .then((response) => {
        SuccessToast(t(`toasts.successfully_updated`));
        // setState({});
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="card">
      <div className="card-body">
        <ApiStepper step={"SETTING_UP"} />
        <div className="tab-content">
          <div className="tab-pane active show" id="home1" role="tabpanel">
            <div className="d-flex">
              <div className="flex-grow-1 ms-2">
                <div className="col-12" id="scrollable-form">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      submitHandler(e);
                    }}
                  >
                    <CreateForm state={state} setState={setState} />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
