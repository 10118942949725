import { createSlice } from "@reduxjs/toolkit";

const clientIdSlice = createSlice({
  name: "clientId",
  initialState: 0,

  reducers: {
    setClientId: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export default clientIdSlice;
