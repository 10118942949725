import { ActualFileObject } from "filepond";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ProductsService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { IProducts } from "../dto/IProducts";
import { registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { ContentEditableEvent } from "react-simple-wysiwyg";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { BaseDataActions } from "../baseData/components/BaseDataActions";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const ProductsCreate = () => {
  const navigate = useNavigate();
  // IProducts
  const [currentProducts, setCurrentProducts] = useState<any>({
    category: null,
    brand: null,
    translation: {
      en: {
        title: "",
        subtitle: "",
        links: [""],
        photos: [""],
        documents: [
          {
            block: null,
            link: "",
          },
        ],
        videos: [""],
      },
      de: {
        title: "",
        subtitle: "",
        links: [""],
        photos: [""],
        documents: [
          {
            block: null,
            link: "",
          },
        ],
        videos: [""],
      },
      fr: {
        title: "",
        subtitle: "",
        links: [""],
        photos: [""],
        documents: [
          {
            block: null,
            link: "",
          },
        ],
        videos: [""],
      },
      it: {
        title: "",
        subtitle: "",
        links: [""],
        photos: [""],
        documents: [
          {
            block: null,
            link: "",
          },
        ],
        videos: [""],
      },
    },
    eanCode: "",
    articleNumber: "",
    website: "",
    crawlerUrl: "",
    sapId: "",
    sourceUrl: "",
  });
  const [currentTranslations, setCurrentTranslations] = useState<any>({
    en: {
      photos: [],
      documents: [],
      videos: [],
      links: [],
    },
    de: {
      photos: [],
      documents: [],
      videos: [],
      links: [],
    },
    fr: {
      photos: [],
      documents: [],
      videos: [],
      links: [],
    },
    it: {
      photos: [],
      documents: [],
      videos: [],
      links: [],
    },
  });
  const [newTranslations, setNewTranslations] = useState<any>({});
  const { t } = useTranslation();
  const [files, setFiles] = useState<ActualFileObject[]>([]);

  const categories = useSelector((state: IAllStates) => state.category);

  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | ContentEditableEvent
    >
  ) => {
    // @ts-ignore
    const { name, value } = event.target;
    setCurrentProducts((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleSelectMainCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>,
    inputKey: string
  ) => {
    setCurrentProducts((prev: any) => ({
      ...prev,
      [inputKey]: selectedSingle?.value,
    }));
  };
  const createProductsHandler = () => {
    const formData = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formData.append("files", files[i]);
    }
    const translationObject = {
      ["en"]: {
        ["photos"]: [
          ...currentProducts?.translation?.["en"]?.photos,
          ...currentTranslations?.en?.photos,
        ],
        ["documents"]: [
          ...currentProducts?.translation?.["en"]?.documents,
          ...currentTranslations?.en?.documents,
        ],
        ["videos"]: [
          ...currentProducts?.translation?.["en"]?.videos,
          ...currentTranslations?.en?.videos,
        ],
        ["title"]: currentProducts?.translation?.["en"]?.title,
        ["subtitle"]: currentProducts?.translation?.["en"]?.subtitle,
        ["links"]: [
          ...currentProducts?.translation?.["en"]?.links,
          ...currentTranslations?.en?.links,
        ],
        // ["link"]: currentProducts?.translation?.["en"]?.link,
      },
      ["de"]: {
        ["photos"]: [
          ...currentProducts?.translation?.["de"]?.photos,
          ...currentTranslations?.de?.photos,
        ],
        ["documents"]: [
          ...currentProducts?.translation?.["de"]?.documents,
          ...currentTranslations?.de?.documents,
        ],
        ["videos"]: [
          ...currentProducts?.translation?.["de"]?.videos,
          ...currentTranslations?.de?.videos,
        ],
        ["title"]: currentProducts?.translation?.["de"]?.title,
        ["subtitle"]: currentProducts?.translation?.["de"]?.subtitle,
        // ["link"]: currentProducts?.translation?.["de"]?.link,
        ["links"]: [
          ...currentProducts?.translation?.["de"]?.links,
          ...currentTranslations?.de?.links,
        ],
      },
      ["fr"]: {
        ["photos"]: [
          ...currentProducts?.translation?.["fr"]?.photos,
          ...currentTranslations?.fr?.photos,
        ],
        ["documents"]: [
          ...currentProducts?.translation?.["fr"]?.documents,
          ...currentTranslations?.fr?.documents,
        ],
        ["videos"]: [
          ...currentProducts?.translation?.["fr"]?.videos,
          ...currentTranslations?.fr?.videos,
        ],
        ["title"]: currentProducts?.translation?.["fr"]?.title,
        ["subtitle"]: currentProducts?.translation?.["fr"]?.subtitle,
        ["links"]: [
          ...currentProducts?.translation?.["fr"]?.links,
          ...currentTranslations?.fr?.links,
        ],
      },
      ["it"]: {
        ["photos"]: [
          ...currentProducts?.translation?.["it"]?.photos,
          ...currentTranslations?.it?.photos,
        ],
        ["documents"]: [
          ...currentProducts?.translation?.["it"]?.documents,
          ...currentTranslations?.it?.documents,
        ],
        ["videos"]: [
          ...currentProducts?.translation?.["it"]?.videos,
          ...currentTranslations?.it?.videos,
        ],
        ["title"]: currentProducts?.translation?.["it"]?.title,
        ["subtitle"]: currentProducts?.translation?.["it"]?.subtitle,
        ["links"]: [
          ...currentProducts?.translation?.["it"]?.links,
          ...currentTranslations?.it?.links,
        ],
      },
    };
    formData.append(
      "body",
      JSON.stringify({ ...currentProducts, translation: translationObject })
    );
    ProductsService.createProduct(formData)
      .then((response) => {
        navigate("/app/products/base-data");
        SuccessToast(`${t("toasts.successfully_created")} ${response?.title}`);
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    <BaseDataActions
      currentProduct={currentProducts as IProducts}
      setCurrentProduct={setCurrentProducts}
      currentTranslations={currentTranslations as any}
      setCurrentTranslations={setCurrentTranslations}
      handleSubmit={createProductsHandler}
      categories={categories}
      setFiles={setFiles}
      handleSelectMainCategory={handleSelectMainCategory}
      files={files}
      changeHandler={changeHandler}
      isEdit={false}
    />
  );
};
