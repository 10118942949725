import React, { SetStateAction, useEffect, useState } from "react";
import { PageTemplate } from "../../../../shared/layout/PageTemplate";
import { ContentEditableEvent } from "react-simple-wysiwyg";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import { ProductsService } from "../../../products/service";
import { IProducts, IProductsList } from "../../../products/dto/IProducts";
import { BaseDataActions } from "../../../products/baseData/components/BaseDataActions";
import { ActualFileObject } from "filepond";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../../shared/components/Select2";
import GalleryCardPreview from "../../../../shared/gallery/GalleryCardPreview";
import ImageContainer from "../../../../shared/gallery/ImageContainer";

export const EditCrawledBaseData = () => {
  const [currentProduct, setCurrentProduct] = useState<IProducts>();
  const [files, setFiles] = useState<ActualFileObject[]>([]);
  const categories = useSelector((state: IAllStates) => state.category);
  const [currentStatus, setCurrentStatus] = useState({});
  const { state: pagination }: any = useLocation();
  const location = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tab = "BASE_DATA";
  const [currentTranslations, setCurrentTranslations] = useState<any>({
    en: {
      photos: [],
      documents: [],
      videos: [],
    },
    de: {
      photos: [],
      documents: [],
      videos: [],
    },
    fr: {
      photos: [],
      documents: [],
      videos: [],
    },
    it: {
      photos: [],
      documents: [],
      videos: [],
    },
  });
  const { productStatus } = useSelector((state: IAllStates) => state.enums);
  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | ContentEditableEvent
    >
  ) => {
    // @ts-ignore
    const { value, name } = event.target;
    setCurrentProduct((prev: any) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    id &&
      tab &&
      ProductsService.getProductById(+id, tab)
        .then((response) => {
          setCurrentProduct(response);
          setCurrentStatus({ productStatus: response?.status });
        })
        .catch((error) => {
          ErrorToast(error);
          //novo
          navigate(-1);
        });
  }, [id]);
  const navContent = () => {
    navigate(`/app/pending-products/crawled/content/${id}`, {
      state: pagination,
    });
  };
  const navAttributes = () => {
    navigate(`/app/pending-products/crawled/attributes/${id}`, {
      state: pagination,
    });
  };
  const handleClose = () => {
    navigate("/app/pending-products/crawled", {
      state: (location.state as any)?.pagination,
    });
    // navigate("/app/pending-products/imported", { state: { pagination: pagination?.pagination ?? {} } });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formData.append("files", files[i]);
    }
    formData.append(
      "body",
      JSON.stringify({
        ...currentProduct,
        category: Number(currentProduct?.category)
          ? currentProduct?.category
          : (currentProduct?.category as any)?.id,
        brand: Number(currentProduct?.brand)
          ? currentProduct?.brand
          : (currentProduct?.brand as any)?.id,
      })
    );
    ProductsService.updateProductById(formData, Number(id))
      .then((response) => {
        // navigate(-1);
        SuccessToast(`${t("toasts.successfully_updated")} ${response.title}`);
      })
      .catch((error) => ErrorToast(error));
  };

  const handleSelectMainCategory = (
    selectedSingle: SingleValue<{ label: string; value?: number }>,
    inputKey: string
  ) => {
    setCurrentProduct((prev: any) => ({
      ...prev,
      [inputKey]: selectedSingle?.value,
    }));
  };
  const handleSelectStatus = (
    selectedSingle: SingleValue<{ label: string }>,
    inputKey: string
  ) => {
    setCurrentStatus({ [inputKey]: selectedSingle?.label });
  };
  const handleChangeStatus = (event: React.FormEvent) => {
    event.preventDefault();
    ProductsService.updateProductStatus(
      currentStatus,
      Number(currentProduct?.id)
    )
      .then((response) => {
        SuccessToast(`${t("products.change_staus")}`);
        if (response === "REJECTED") {
          navigate("/app/pending-products/crawled?page=1");
        } else {
          SuccessToast(`${t("products.change_staus")} ${response?.status}`);
          navigate(1);
        }
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    // <PageTemplate title={`${t("side_bar.crawled_product")}`}>
    <>
      <div className="col-md-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="d-flex align-itmes-end">
            <ul className="nav nav-pills nav-fill">
              <li className="nav-item">
                <span className="nav-link active">
                  {t("products.base_data_update")}
                </span>
              </li>
              <li onClick={() => navAttributes()}>
                <span className="nav-link cursor-pointer text-dark">
                  {t("products.attributes_update")}
                </span>
              </li>
              <li onClick={() => navContent()}>
                <span className="nav-link cursor-pointer text-dark">
                  {t("products.content_update")}
                </span>
              </li>
            </ul>
          </div>
          {currentProduct?.status && (
            <div className="d-flex">
              <Select
                className="react-select-field"
                theme={themeSelect}
                options={productStatus?.map((status) => ({
                  label: status ?? "-",
                  value: status,
                }))}
                defaultValue={{
                  label: currentProduct?.status,
                  value: currentProduct?.status,
                }}
                key={currentProduct?.id}
                onChange={(e: any) => {
                  handleSelectStatus(
                    e as SingleValue<{ label: string }>,
                    "productStatus"
                  );
                }}
              />
              <button
                className="btn btn-secondary btn-sm ms-2"
                onClick={handleChangeStatus}
              >
                {t("products.change_staus")}
              </button>
            </div>
          )}
          <>
            <ul className="nav nav-pills">
              <li className="nav-item">
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="nav-link active"
                  href={`${currentProduct?.crawlerUrl}`}
                >
                  {t("crawlers.url")}
                </a>
              </li>
            </ul>
          </>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-start">
        <button
          onClick={() => handleClose()}
          className="btn btn-info d-flex align-items-center mb-3"
        >
          <i className="ri-arrow-left-line me-1"></i>
          {t("products.back")}
        </button>
      </div>
      <div className="d-flex align-items-center mb-3">
        <div className="d-flex gap-3 align-items-center">
          <ImageContainer img={currentProduct?.productPictures ?? []} />
          {/* <GalleryCardPreview
            img={
              currentProduct?.productPictures[0]?.path ||
              "/images/jk-placeholder-image.jpg"
            }
          /> */}
          {/* <CustomImageHandler
            classes="avatar-lg rounded-circle me-2"
            path={currentProduct?.productPictures[1]?.path ?? ""}
            alt="User logo"
          /> */}
          <div>
            <h2>{currentProduct?.title}</h2>
            <p>{currentProduct?.eanCode}</p>
          </div>
        </div>
      </div>
      <BaseDataActions
        handleClose={handleClose}
        isCrawled={true}
        isEdit={true}
        currentProduct={currentProduct as IProductsList}
        currentTranslations={currentTranslations as any}
        setCurrentTranslations={setCurrentTranslations}
        setCurrentProduct={
          setCurrentProduct as React.Dispatch<
            SetStateAction<IProductsList | IProducts>
          >
        }
        changeHandler={changeHandler}
        handleSelectMainCategory={handleSelectMainCategory}
        files={files}
        setFiles={setFiles}
        handleSubmit={handleSubmit}
        categories={categories}
      />
    </>
    // </PageTemplate>
  );
};
