import React from "react";
import { IBlock } from "../dto/IBlock";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { getCorrectLanguage } from "../../../shared/functions/Functions";

interface IBlockFormProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  state: IBlock;
  setState: any;
}

export const BlockForm = ({
  handleChange,
  state,
  setState,
}: IBlockFormProps) => {
  const { t } = useTranslation();
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );

  const changeTranslationHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setState((prev: any) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [name]: value,
      },
    }));
  };

  return (
    <div className="col-12">
      <div className="row">
        {translationLanguageEnum?.map((language) => {
          return (
            <div className="col-md-4 my-2" key={language}>
              <label htmlFor="name">
                {t("global.name")}- {getCorrectLanguage(language, t)}
              </label>
              <input
                type="text"
                onChange={changeTranslationHandler}
                placeholder="Enter name..."
                className="form-control"
                name={language}
                value={state?.translation?.[language] ?? ""}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
