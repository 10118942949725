import { IBlock, IBlockQuery } from "./dto/IBlock";
import { BaseModel } from "../../model/shared/BaseModel";
import { BLOCK_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";

export const BlockService = {
    createBlock: (params: IBlock) => requests.post(`${BLOCK_URL}`, params),
    getAllBlocks: (params: IBlockQuery): Promise<BaseModel<IBlockQuery[]>> => requests.get(`${BLOCK_URL}`, params),
    getBlockById: (id: number) => requests.get(`${BLOCK_URL}/${id}`),
    updateBlockById: (id: number, params: IBlock) => requests.put(`${BLOCK_URL}/${id}`, params),
    deleteBlockById: (id: number) => requests.delete(`${BLOCK_URL}/${id}`)
}