import { createSlice } from "@reduxjs/toolkit";

const step3PaginationSlice = createSlice({
  name: "step3Pagination",
  initialState: {},

  reducers: {
    setStep3Pagination: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export default step3PaginationSlice;
