import { PageTemplate } from "../../shared/layout/PageTemplate";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { UserForm } from "./components/UserForm";
import { UserList } from "./components/UserList";
import { IUser } from "../../model/authentification/IRegister";
import { Form } from "react-bootstrap";
import { UserService } from "./service";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { IUserDTO } from "./dto/IUser";
import _debounce from "lodash/debounce";
import { multipartFormData } from "../../utils/helpers/api.services";
import { USER_URL } from "../../utils/helpers/api.routes";
import { SingleValue } from "react-select";
import { AccessService } from "../accessRights/service";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { ICompaniesDTO } from "../companies/dto/ICompanies";
import { CompanyService } from "../companies/service";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  roles: [],
  types: [],
  contact: {
    phone: "",
    country: "",
    city: "",
    zip: "",
    address: "",
  },
};

export const Users = () => {
  const imageRef = useRef<any>(null);
  const { t } = useTranslation();
  const [accessRightsList, setAccessRightsList] = useState([] as any);
  const [correctAccessRightList, setCorrectAccessRightList] = useState([]);
  const [userList, setUserList] = useState<IUserDTO[]>(undefined as any);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [triggerCorrectAccess, setTriggerCorrectAccess] = useState(false);
  const currentMainRole = JSON.parse(
    localStorage.getItem("currentUser") as string
  );
  const isManager = currentMainRole.roles.includes("ROLE_MANAGER");
  const isAdmin = currentMainRole.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentMainRole.roles.includes("ROLE_COMPANY_ADMIN");
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [companiesList, setCompaniesList] = useState<ICompaniesDTO[]>([]);
  const [page, setPage] = useState(1);
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  const changeUserHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    isContact = false
  ) => {
    const { value, name } = event.target;
    if (isContact) {
      setCurrentUser((prev: any) => ({
        ...prev,
        contact: {
          ...prev.contact,
          [name]: value,
        },
      }));
    } else {
      setCurrentUser((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const handleSelectCompany = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    setTriggerCorrectAccess((prev: boolean) => !prev);
    if (selectedSingle?.value) {
      setCurrentUser((prev: any) => ({
        ...prev,
        company: selectedSingle?.value,
      }));
    } else {
      setCurrentUser((prev: any) => ({
        ...prev,
        company: null,
      }));
      setCorrectAccessRightList([]);
    }
  };
  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPagination((prev) => ({ ...prev, [name]: value }));
    },
    200
  );
  const selectRoleHandler = (event: any) => {
    let updatedList: string[] = [...(currentUser?.roles ?? [])];
    if (event.target.checked) {
      updatedList = [...(currentUser?.roles ?? []), event.target.value];
    } else {
      //@ts-ignore
      updatedList.splice(currentUser!?.roles?.indexOf(event.target.value), 1);
    }
    setCurrentUser((prev: any) => ({ ...prev, roles: updatedList }));
  };
  const selectAccessHandler = (event: any) => {
    const { id } = event.target;
    const isAlreadySelected = (currentUser?.customRoles ?? [])?.some(
      (right: any) => right === +id
    );
    const selectedElements = !isAlreadySelected
      ? accessRightsList
          ?.filter((right: any) => right.id === +id)
          .map((right: any) => right.id)
          .concat(currentUser?.customRoles ?? [])
      : currentUser?.customRoles?.filter((role: number) => role !== +id);
    setCurrentUser((prev: any) => ({ ...prev, customRoles: selectedElements }));
  };
  const selectLanguagesHandler = (event: any) => {
    let updatedList: string[] = [...(currentUser?.languages ?? [])];
    if (event.target.checked) {
      updatedList = [...(currentUser?.languages ?? []), event.target.value];
    } else {
      updatedList.splice(
        currentUser!?.languages?.indexOf(event.target.value) as any,
        1
      );
    }
    setCurrentUser((prev: any) => ({ ...prev, languages: updatedList }));
  };
  const selectTypesHandler = (event: any) => {
    let updatedList: string[] = [...(currentUser?.copywriterRole ?? [])];
    if (event.target.checked) {
      updatedList = [
        ...(currentUser?.copywriterRole ?? []),
        event.target.value,
      ];
    } else {
      updatedList.splice(
        currentUser!?.copywriterRole?.indexOf(event.target.value) as any,
        1
      );
    }
    setCurrentUser((prev: any) => ({ ...prev, copywriterRole: updatedList }));
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (currentUser?.id) {
      // If we have ID in this state, that means that we are updating current user
      multipartFormData(
        imageRef?.current?.files?.[0],
        `${USER_URL}/${currentUser?.id}`,
        JSON.stringify({
          ...currentUser,
          company: (currentUser as any)?.company?.id,
          contact: (currentUser as any)?.contact ?? {},
        }),
        "put"
      )
        .then((response) => {
          const { data } = response;
          SuccessToast(
            `${t("toasts.successfully_updated")} ${data?.firstName} ${
              data?.lastName
            }!`
          );
          setIsMenuOpened(false);
          setCurrentUser(initialState);
        })
        .catch((error) => ErrorToast(error));
    } else {
      multipartFormData(
        imageRef?.current?.files?.[0],
        `${USER_URL}`,
        JSON.stringify({
          ...currentUser,
          isCopywriter: currentUser?.isCopywriter ?? false,
          contact: (currentUser as any)?.contact ?? {},
        }),
        // (currentUser) => ({ ...currentUser, isCopywriter: currentUser.isCopywriter ?? false })
        "post"
      )
        .then((response) => {
          const { data } = response;
          setIsMenuOpened(false);
          setCurrentUser(initialState);
          SuccessToast(
            `${t("toasts.successfully_created")} ${data?.firstName} ${
              data?.lastName
            }!`
          );
        })
        .catch((error) => ErrorToast(error));
    }
  };

  const editHandler = (userId: number) => {
    UserService.getUserById(userId)
      .then((response) => {
        setCurrentUser({
          ...response,
          customRoles: response.customRoles?.map((role: any) => role.id),
        });
        setIsMenuOpened(true);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };
  function handleSelectCountry(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setCurrentUser((prev: any) => ({
      ...prev,
      contact: {
        ...prev?.contact,
        country: selectedSingle?.value,
      },
    }));
  }

  const handleCancel = () => {
    setIsMenuOpened(false);
    setCurrentUser(initialState);
  };

  useEffect(() => {
    UserService.getAllUsers(pagination)
      .then((response) => {
        const { data } = response;
        setUserList(data);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [JSON.stringify(pagination), isMenuOpened, triggerUpdate]);

  useEffect(() => {
    AccessService.getAllAccessRights({
      page: 1,
      perPage: 0,
    }).then((response) => {
      setAccessRightsList(response?.data);
    });
  }, [triggerUpdate, pagination]);
  useEffect(() => {
    CompanyService.getAllCompanies({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCompaniesList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    if (isAdmin) {
      if ((currentUser as any)?.company) {
        AccessService.getAllAccessRights({
          company:
            (currentUser as any)?.company?.id ?? (currentUser as any)?.company,
          page: 1,
          perPage: 0,
        }).then((response) => {
          setCorrectAccessRightList(response?.data);
        });
      }
    } else if (isCompanyAdmin) {
      AccessService.getAllAccessRights({
        company: currentMainRole?.company?.id,
        page: 1,
        perPage: 0,
      }).then((response) => {
        setCorrectAccessRightList(response?.data);
      });
    } else if (
      currentMainRole.accessRights.includes("user_can_edit") &&
      currentMainRole?.company?.id
    ) {
      AccessService.getAllAccessRights({
        company: currentMainRole?.company?.id,
        page: 1,
        perPage: 0,
      }).then((response) => {
        setCorrectAccessRightList(response?.data);
      });
    } else {
      setCorrectAccessRightList([]);
    }
  }, [(currentUser as any)?.company]);
  return (
    // <PageTemplate title={t("side_bar.users")}>
    <>
      <div className="col-xl-12 mb-3">
        {accessRights?.includes("user_can_create") && !isMenuOpened && (
          <div className="card ">
            <div className="card-body d-flex align-items-center justify-content-between">
              <h4 className="card-title mg-b-0">
                {t("users.create_new_user")}
              </h4>
              <button
                className="btn btn-primary"
                onClick={() => setIsMenuOpened(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        )}
        {isMenuOpened && (
          <Form onSubmit={submitHandler}>
            <UserForm
              state={currentUser as IUser}
              setState={setCurrentUser as any}
              changeHandler={changeUserHandler}
              handleSelectCountry={handleSelectCountry}
              handleCancel={handleCancel}
              selectRoleHandler={selectRoleHandler}
              selectAccessHandler={selectAccessHandler}
              selectLanguagesHandler={selectLanguagesHandler}
              imageRef={imageRef}
              accessRightsList={accessRightsList}
              companiesList={companiesList}
              handleSelectCompany={handleSelectCompany}
              correctAccessRightList={correctAccessRightList}
              selectTypesHandler={selectTypesHandler}
            />
          </Form>
        )}
      </div>
      <div className="col-xl-12">
        <UserList
          accessRights={accessRights}
          userList={userList}
          page={page}
          setPagination={setPagination}
          totalPages={totalPages}
          handlePages={handlePages}
          editHandler={editHandler}
          setTriggerUpdate={setTriggerUpdate}
          changeFilterHandler={changeFilterHandler}
          companiesList={companiesList}
        />
      </div>
    </>
    // </PageTemplate>
  );
};
