import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import { IProductImported } from "../../dto/IProductImported";
import { ProductsPendingService } from "../../service";

interface DataFromExcelProps {
  currentExcelProductId: number;
}
const DataFromExcel = ({ currentExcelProductId }: DataFromExcelProps) => {
  const { t } = useTranslation();
  const [currentExcelProduct, setCurrentExcelProduct] =
    useState<IProductImported>();
  useEffect(() => {
    ProductsPendingService.getImportedProductsById(currentExcelProductId)
      .then((response) => {
        setCurrentExcelProduct(response);
      })
      .catch((error) => ErrorToast(error));
  }, [currentExcelProductId]);
  const arr = currentExcelProduct ? Object.entries(currentExcelProduct) : [];
  const filteredArr = arr.filter((item) => {
    return (
      item[0] !== "createdAt" &&
      item[0] !== "updatedAt" &&
      item[0] !== "isImported" &&
      item[0] !== "isCrawled" &&
      item[0] !== "website" &&
      item[0] !== "crawlerUrl"
    );
  });
  return (
    <>
      <Table className="align-middle mb-0 table-bordered">
        <thead className="table-light ">
          {filteredArr?.map((product) => {
            return (
              <tr key={product?.[0]}>
                <th className="align-middle" scope="col">
                  {t(`products.${product[0]}`)}
                </th>
                <th scope="col" className="break-th-text">
                  {product[1] ?? "-"}
                </th>
              </tr>
            );
          })}
          {/* {Object.keys(currentExcelProduct as IProductImported)?.map(
            (productKey) => {
              return (
                <tr>
                  <th scope="col">{t(`products.${productKey}`)}</th>
                  <th scope="col">
                    {(currentExcelProduct as any)?.[productKey] ?? "-"}
                  </th>
                </tr>
              );
            }
          )} */}
        </thead>
      </Table>
    </>
  );
};

export default DataFromExcel;
