import { IBlock, IBlockQuery } from "./dto/IBlock";
import { BaseModel } from "../../model/shared/BaseModel";
import { DOCUMENT_BLOCK_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";

export const DocumentBlockService = {
  createBlock: (params: IBlock) =>
    requests.post(`${DOCUMENT_BLOCK_URL}`, params),
  getAllBlocks: (params: IBlockQuery): Promise<BaseModel<IBlockQuery[]>> =>
    requests.get(`${DOCUMENT_BLOCK_URL}`, params),
  getBlockById: (id: number) => requests.get(`${DOCUMENT_BLOCK_URL}/${id}`),
  updateBlockById: (id: number, params: IBlock) =>
    requests.put(`${DOCUMENT_BLOCK_URL}/${id}`, params),
  deleteBlockById: (id: number) =>
    requests.delete(`${DOCUMENT_BLOCK_URL}/${id}`),
};
