import { createSlice } from '@reduxjs/toolkit';

const firstViewSlice = createSlice({

    name: 'firstView',
    initialState: false,

    reducers: {
        setFirstView: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export default firstViewSlice;