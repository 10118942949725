import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { PageTemplate } from "../../shared/layout/PageTemplate";
import { ContentService } from "./service";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { ContentList } from "./components/ContentList";
import { IExport } from "../exports/dto/IExports";
import { SingleValue } from "react-select";
import { Form } from "react-bootstrap";
import { ContentCreate } from "./components/ContentCreate";
import { ClientService } from "../clients/service";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";

export const Content = () => {
  const { t } = useTranslation();
  const [clientFilterId, setClientFilterId] = useState(null);
  const [termFilter, setTermFilter] = useState("");
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    title: "",
    client: null,
  });
  const [contentsList, setContentsList] = useState(undefined);
  const [exportsList, setExportsList] = useState<IExport[]>([]);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [currentContent, setCurrentContent] = useState<any>();
  const [clientList, setClientList] = useState<IExport[]>([]);
  const [clientParams, setClientParams] = useState({
    page: 1,
    perPage: 10,
  });
  const changeClientHandler = _debounce((targetValue: string) => {
    setClientParams((prev) => ({ ...prev, name: targetValue }));
  }, 200);

  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setTermFilter(value);
      setPagination({
        page: 1,
        perPage: 12,
        [name]: value,
        client: clientFilterId,
      } as any);
    },
    200
  );
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const changeFilterClientHandler = (
    selectedSingle: SingleValue<IExport | null>,
    inputKey: string
  ) => {
    if (Number(selectedSingle?.value)) {
      setClientFilterId(selectedSingle?.value as any);
      setPagination({
        page: 1,
        perPage: 12,
        title: termFilter,
        client: clientFilterId,
      } as any);
    } else {
      setClientFilterId(null);
      setPagination({
        page: 1,
        perPage: 12,
        title: termFilter,
        client: null,
      } as any);
    }
    setClientFilterId(selectedSingle?.value as any);
    setPagination((prev) => ({ ...prev, [inputKey]: selectedSingle?.value }));
  };
  const submitHandler = () => {
    ContentService.createContent(currentContent)
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_created")}`);
        setIsMenuOpened(false);
        setTriggerUpdate(!triggerUpdate);
        setCurrentContent({});
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    ContentService.getAllContents(pagination)
      .then((data) => {
        setContentsList(data?.data);
        setTotalPages(Math.ceil(data?.count / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  useEffect(() => {
    ClientService.getAllClients({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setClientList(
          data?.map((client) => ({
            ...client,
            label: client.name,
            value: client.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, [clientParams]);
  return (
    // <PageTemplate title={`${t("side_bar.content")}`}>
    <>
      {accessRights?.includes("content_page_can_create") && !isMenuOpened && (
        <div className="col-12">
          <div className="card">
            <div className="card-body d-flex align-items-center justify-content-between">
              <h4 className="card-title mg-b-0">
                {t("content.create_new_content")}
              </h4>
              <button
                className="btn btn-primary"
                onClick={() => setIsMenuOpened(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <ContentCreate
              setIsMenuOpened={setIsMenuOpened}
              state={currentContent}
              changeClientHandler={changeClientHandler}
              clientList={clientList}
              setState={setCurrentContent}
            />
          </Form>
        </div>
      )}
      <div className="col-12">
        <ContentList
          accessRights={accessRights}
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          contentsList={contentsList}
          setTriggerUpdate={setTriggerUpdate}
          clientList={clientList}
          changeFilterClientHandler={changeFilterClientHandler}
          changeFilterHandler={changeFilterHandler}
          changeClientHandler={changeClientHandler}
        />
      </div>
    </>
    // </PageTemplate>
  );
};
