import DualListBox from "react-dual-listbox";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
// KeyboardArrowUpIcon; KeyboardDoubleArrowUpIcon; KeyboardArrowDownIcon; KeyboardDoubleArrowDownIcon;
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useTranslation } from "react-i18next";
import { IAttributes } from "../../../attributes/names/dto/IAttributes";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CategoryService } from "../../service";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import i18n from "../../../../utils/translations/i18next";

interface ICategoryEditAttributesSwitcherProps {
  attributesList: IAttributes[];
  selectedValues: number[];
  changeHandler: (num: number[]) => void;
}

export const CategoryEditAttributesSwitcher = ({
  attributesList,
  selectedValues,
  changeHandler,
}: ICategoryEditAttributesSwitcherProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [currentCategoryName, setCurrentCategoryName] = useState();
  const currentLanguage = i18n?.language;
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }
  useEffect(() => {
    !!Number(id) &&
      CategoryService.getCategoryById(id as any)
        .then((response) => {
          setCurrentCategoryName(
            response?.translation?.[currentLanguage]?.title
          );
        })
        .catch((error) => ErrorToast(error));
  }, [id]);

  return (
    <>
      <h4 className="card-title mg-b-0">
        {t("attributes.selectForCategory")}
        {currentCategoryName}
      </h4>
      {windowWidth?.width > 575 ? (
        <DualListBox
          className="mt-4 h-listbox"
          options={attributesList as any}
          selected={selectedValues}
          icons={{
            moveLeft: <KeyboardArrowLeftIcon />,
            moveAllLeft: <KeyboardDoubleArrowLeftIcon />,
            moveRight: <KeyboardArrowRightIcon />,
            moveAllRight: <KeyboardDoubleArrowRightIcon />,
          }}
          onChange={changeHandler}
        />
      ) : (
        <DualListBox
          className="mt-4 h-listbox"
          options={attributesList as any}
          selected={selectedValues}
          icons={{
            moveLeft: <KeyboardArrowUpIcon />,
            moveAllLeft: <KeyboardDoubleArrowUpIcon />,
            moveRight: <KeyboardArrowDownIcon />,
            moveAllRight: <KeyboardDoubleArrowDownIcon />,
          }}
          onChange={changeHandler}
        />
      )}
    </>
  );
};
