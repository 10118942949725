import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { WorkflowStepper } from "./WorkflowStepper";
import WorkflowStep7Chart from "./WorkflowStep7Chart";
import { WorkflowService } from "../service";
import { WorkflowStep7List } from "./WorkflowStep7List";
import { useTranslation } from "react-i18next";

interface IInnerStatsInterface {
  done: number;
  total: number;
  percent: number;
}

interface IStatsInterface {
  baseData: IInnerStatsInterface;
  attributes: IInnerStatsInterface;
  copyWriting: IInnerStatsInterface;
  translation: IInnerStatsInterface;
  translatorsData: any[];
  copywritersData: any[];
}
const WorkflowStep7 = () => {
  const { t } = useTranslation();
  const [stats, setStats] = useState<IStatsInterface>();
  const { id } = useParams();
  useEffect(() => {
    WorkflowService.getWorkflowCharts(+id!).then((res) => setStats(res));
  }, []);

  return (
    <Card>
      <Card.Body>
        <WorkflowStepper id={id} step={"OVERVIEW"} />
        <div className="row mb-5">
          <WorkflowStep7Chart
            name={t("workflow.BASE_DATA")}
            iconClass={"ri-vip-crown-fill display-6 text-muted"}
            total={stats?.baseData.total}
            done={stats?.baseData.done}
          />
          <WorkflowStep7Chart
            name={t("workflow.ATTRIBUTES")}
            iconClass={"ri-vip-crown-fill display-6 text-muted"}
            total={stats?.attributes.total}
            done={stats?.attributes.done}
          />

          <WorkflowStep7Chart
            name={t("workflow.COPY_WRITING")}
            iconClass={"ri-vip-crown-fill display-6 text-muted"}
            total={stats?.copyWriting.total}
            done={stats?.copyWriting.done}
          />
          <WorkflowStep7Chart
            name={t("workflow.TRANSLATION")}
            iconClass={"ri-vip-crown-fill display-6 text-muted"}
            total={stats?.translation.total}
            done={stats?.translation.done}
          />
        </div>
        <WorkflowStep7List stats={stats} />
      </Card.Body>
    </Card>
  );
};

export default WorkflowStep7;
