import { ActualFileObject, FilePondFile } from "filepond";

import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { FilePond } from "react-filepond";
import { useNavigate, useParams } from "react-router-dom";
import {
  IMPORT_MAPPING_URL,
  WORKFLOW_URL,
} from "../../../utils/helpers/api.routes";
import { WorkflowStepper } from "./WorkflowStepper";
import { WorkflowService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import ArrowIcon from "../../../shared/icons/down-arrow.svg";
import { ClientService } from "../../clients/service";
import { IExport } from "../../exports/dto/IExports";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { IWorkflow } from "../dto/IWorkflow";
import { AttributesService } from "../../attributes/service";
import { IAttributes } from "../../attributes/names/dto/IAttributes";
import { BlockService } from "../../block/service";
import { IBlock } from "../../block/dto/IBlock";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { DocumentBlockService } from "../../blockDocument/service";
import { useTranslation } from "react-i18next";

export const handleStepNavigate = (
  status: string,
  id: number,
  navigate: any
) => {
  switch (status) {
    case "SETTING_UP":
      return navigate(`/app/workflow/step1/${id}`);
    case "IMPORT":
      return navigate(`/app/workflow/step2/${id}`);
    case "BASE_DATA":
      return navigate(`/app/workflow/step3/${id}`);
    case "ATTRIBUTES":
      return navigate(`/app/workflow/step4/${id}`);
    case "COPY_WRITING":
      return navigate(`/app/workflow/step5/${id}`);
    case "TRANSLATION":
      return navigate(`/app/workflow/step6/${id}`);
    case "OVERVIEW":
      return navigate(`/app/workflow/step7/${id}`);
    case "EXPORT":
      return navigate(`/app/workflow/step8/${id}`);
    default:
      return navigate(`/app/workflow/step1/${id}`);
  }
};
export const WorkflowStep2 = () => {
  const { t } = useTranslation();
  const [clientList, setClientList] = useState<IExport[]>([]);
  const [name, setName] = useState<string>("");
  const [selectName, setSelectName] = useState<any>({ value: 0, label: "" });
  const [names, setNames] = useState<any[]>([]);
  const [preview, setPreview] = useState([]);
  const [show, setShow] = useState(false);

  const translationValue = [
    "title",
    "subtitle",
    "description",
    "intro",
    "bullets",
    "photo",
    "video",
    "link",
    "document",
  ];
  const separators = [";", ",", "|"];
  // const identificator = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [identificator, setIdentificator] = useState<number[]>([1]);
  const getCorrectSeparator = (separator: string, t: any) => {
    switch (separator) {
      case ";":
        return `${t(`separator.${separator}`)}`;
      case ",":
        return `${t(`separator.${separator}`)}`;
      case "|":
        return `${t(`separator.${separator}`)}`;

      default:
        return `NULL - ${separator}`;
    }
  };
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [mappingFile, setMappingFile] = useState<File>();
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [blockList, setBlockList] = useState<IBlock[] | undefined>();
  const [documentBlockList, setDocumentBlockList] = useState<
    IBlock[] | undefined
  >();
  const addedBlockField = [{ value: 0, label: t("document_block.main_value") }];
  const [attributesList, setAttributesList] = useState<IAttributes[]>([]);
  const [mappingArray, setMappingArray] = useState<any[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [showResp, setShowResp] = useState(false);
  const [responseBack, setResponseBack] = useState({} as any);
  const [files, setFiles] = useState<ActualFileObject[]>([]);
  const [filesMapping, setFilesMapping] = useState<ActualFileObject[]>([]);
  const pondRef = useRef<FilePond>(null);
  const workflowDataRedux = useSelector((state: IAllStates) => state.workflow);
  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 30,
      minHeight: 30,
    }),
  };
  const uniqueOptions = [
    "ean",
    "article_number",
    "sap_id",
    "category",
    "brand",
  ];
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);
  };
  function handleSelectSingle2(selectedSingle: any, index: number) {
    setMappingArray((prev: any) => {
      const newItems = [...prev];
      newItems[index] = {
        ...newItems[index],
        ["langauge"]: selectedSingle?.map(
          (selectedSingle: { value: string }) => selectedSingle?.value
        ),
      };
      return newItems;
    });
    // setFirstStap((prev: any) => ({
    //   ...prev,
    //   ['langauge']: selectedSingle?.map(
    //     (selectedSingle: { value: string }) => selectedSingle?.value
    //   ),
    // }));
  }
  // const functionForCorretFields = (item: any) => {
  //   const arr = item?.translations
  //     ?.map((differentLang: any, index: number) =>
  //       Object?.keys(differentLang)?.filter((key: any) =>
  //         translationValue?.includes(key)
  //       )
  //     )
  //     ?.map((test: string) => test);
  //   return item?.translations?.map((field: any, index: number) =>
  //     arr[0]?.map((colName: string) => {
  //       console.log(field?.[colName]);

  //       <td>{field?.[colName]?.value ?? "-"}</td>;
  //     })
  //   );
  // };

  const functionForCorrectFields = (item: any): JSX.Element[] => {
    const arr = item?.translations?.map((differentLang: any) =>
      Object?.keys(differentLang)?.filter((key: any) =>
        translationValue?.includes(key)
      )
    );
    return (
      item?.translations?.map(
        (field: any, index: number) =>
          arr[0]?.map((colName: string, inx: number) => (
            <td
              key={`${colName}-${index}-${inx}`}
              style={{
                backgroundColor: `${
                  field?.[colName]?.isLoadedFromDatabase ? "#f8ed62" : "#90EE90"
                }`,
              }}
            >
              {Array?.isArray(field?.[colName]?.value)
                ? field?.[colName]?.value?.map((item: any) => {
                    return (
                      <span className="d-block">{item?.link ?? item}</span>
                    );
                  })
                : field?.[colName]?.value ?? ""}
            </td>
          )) ?? []
      ) ?? []
    );
  };
  const handleSelectMapping = (
    selectedSingle: SingleValue<{ label: string; value: any }>,
    index: number,
    field: string,
    columnName = ""
  ) => {
    if (selectedSingle?.value === undefined) {
      field === "mapTo"
        ? setMappingArray((prev: any) => {
            const newItems = [...prev];
            newItems[index] = {
              ...newItems[index],
              ["mapTo"]: null,
              ["value"]: null,
              ["language"]: null,
            };
            return newItems;
          })
        : setMappingArray((prev: any) => {
            const newItems = [...prev];
            newItems[index] = {
              ...newItems[index],
              [field]: null,
            };
            return newItems;
          });
    } else {
      setMappingArray((prev: any) => {
        const newItems = [...prev];
        newItems[index] = {
          ...newItems[index],
          [field]: selectedSingle?.value,
        };
        return newItems;
      });
    }
    if (columnName === "identificator") {
      const lastValue = identificator?.length;
      if (identificator[lastValue - 1] === selectedSingle?.value) {
        identificator?.push(selectedSingle?.value + 1);
      }
    }
  };
  const handleSelectName = (
    selectedSingle: SingleValue<{ label: number; value: string }>
  ) => {
    if (selectedSingle?.value === undefined) {
      setSelectName({ value: 0, label: "" });
    } else {
      setSelectName({
        value: selectedSingle?.value,
        label: selectedSingle?.label,
      });
      WorkflowService.getProfile(Number(selectedSingle?.value))
        .then((response) => {
          setMappingArray(response?.fields);
        })
        .catch((error) => ErrorToast(error));
    }
  };

  const handleProcess = () => {
    if (pondRef.current) {
      pondRef.current.processFiles();
    }
  };
  const handleSave = () => {
    WorkflowService.saveWorkflowImportById(Number(id))
      .then((response) => {
        const status = response?.status;
        handleStepNavigate(status, Number(id), navigate);
      })
      .catch((error) => ErrorToast(error));
  };
  const backNavigate = () => {
    navigate(`/app/workflow`);
  };
  function capitalizeFirstLetter(str: string) {
    if (!str) return str;
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  }
  useEffect(() => {
    ClientService.getAllClients({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setClientList(
          data?.map((client) => ({
            ...client,
            label: client.name,
            value: client.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    DocumentBlockService.getAllBlocks({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        const res = data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        const merged = [...addedBlockField, ...res];
        setDocumentBlockList(merged as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const languageVisible = (item: string) => {
    if (
      item === "ean" ||
      item === "article_number" ||
      item === "sap_id" ||
      item === "category" ||
      item === "brand"
    ) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    AttributesService.getAllAttributes({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const res = response?.data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.title,
          };
        });
        setAttributesList(res as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    BlockService.getAllBlocks({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        const resBlock = data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setBlockList(resBlock as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    WorkflowService.getAllImportMapping(Number(id))
      .then((response) => {
        setNames(response);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  const findUniqueInArray1 = (arr1: any[], arr2: any[]) => {
    return arr1.filter((item) => !arr2?.includes(item));
  };
  const findUniqueInArrayWithValue = (arr1: any[], arr2: any[]) => {
    return arr1.filter((item) => !arr2?.includes(item?.value));
  };
  const findUniqueInLangauge = (arr1: any[], arr2: any[]) => {
    const arrCorrect = arr2?.map((item: any) => {
      return item?.language;
    });
    return arr1.filter((item) => !arrCorrect?.includes(item));
  };
  const filterArrayDocumentValue = (arr1: any[], arr2: any[]): any[] => {
    // Mapa za brojanje pojavljivanja elemenata u arr2
    const countMap = new Map<any, number>();
    // Mapa sa brojem pojavljivanja elemenata iz arr2
    for (const item of arr2) {
      if (countMap.has(item)) {
        countMap.set(item, countMap.get(item)! + 1);
      } else {
        countMap.set(item, 1);
      }
    }
    // Uklanja elemente koji se pojavljuju 1 puta u arr2
    return arr1.filter((item) => countMap.get(item) !== 4);
  };
  //------------------------------------------------------------------------------
  const funcForRepeatLanguageField = (item: any) => {
    const sameFields = (mappingArray as any)?.filter(
      (itemMap: any) => itemMap.mapTo?.toLowerCase() == item?.mapTo
    );
    const allLangaugeFields = sameFields?.filter(
      (filed: any) => filed?.value === item?.value
    );
    if (allLangaugeFields?.length === 0) {
      return (translationLanguageEnum as any)?.map((language: string) => ({
        value: language,
        label: getCorrectLanguage(language, t),
      }));
    } else {
      return findUniqueInLangauge(translationLanguageEnum, allLangaugeFields);
    }
  };
  const funcForLanguageField = (item: any) => {
    const currentField = item?.mapTo;
    const sameFields = (mappingArray as any)?.filter(
      (itemMap: any) => itemMap.mapTo?.toLowerCase() == currentField
    );
    if (currentField === "block") {
      const allValueFields = sameFields
        ?.filter((item: any) => item?.value !== null)
        ?.map((item: any) => {
          return item?.value;
        });
      // if (allValueFields?.length === 0) {
      return (blockList as any)?.map((item: any) => ({
        value: item?.value,
        label: item?.label,
      }));
      // } else {
      //   return findUniqueInArrayWithValue(blockList as any[], allValueFields);
      // }
    } else if (currentField === "document") {
      const allValueFields = sameFields
        ?.filter((item: any) => item?.value !== null)
        ?.map((item: any) => {
          return item?.value;
        });

      // if (allValueFields?.length === 0) {
      return (documentBlockList as any)?.map((item: any) => ({
        value: item?.value,
        label: item?.label,
      }));
      // } else {
      //   return filterArrayDocumentValue(
      //     documentBlockList as any[],
      //     allValueFields
      //   );
      // }
    } else if (currentField === "attribute_new") {
      const allValueFields = sameFields
        ?.filter((item: any) => item?.value !== null)
        ?.map((item: any) => {
          return item?.value;
        });
      if (allValueFields?.length === 0) {
        return identificator;
      } else {
        return filterArrayDocumentValue(identificator as any[], allValueFields);
      }
    } else if (currentField === "attribute") {
      const allValueFields = sameFields
        ?.filter((item: any) => item?.value !== null)
        ?.map((item: any) => {
          return item?.value;
        });

      // if (allValueFields?.length === 0) {
      return (attributesList as any)?.map((item: any) => ({
        value: item?.value,
        label: item?.label,
      }));
      // } else {
      //   return findUniqueInArrayWithValue(
      //     attributesList as any[],
      //     allValueFields
      //   );
      // }
    } else {
      const allLangaugeFields = sameFields?.map((item: any) => {
        return item?.language;
      });
      if (allLangaugeFields?.length === 0) {
        return (translationLanguageEnum as any)?.map((language: string) => ({
          value: language,
          label: getCorrectLanguage(language, t),
        }));
      } else {
        return findUniqueInArray1(translationLanguageEnum, allLangaugeFields);
      }
    }
  };
  const documentLanguageFields = (item: any) => {
    const sameFields = (mappingArray as any)?.filter(
      (itemMap: any) => itemMap.mapTo?.toLowerCase() == "document"
    );
    const allLangaugeFields = sameFields?.filter(
      (filed: any) => filed?.value === item?.value
    );
    if (allLangaugeFields?.length === 0) {
      return (translationLanguageEnum as any)?.map((language: string) => ({
        value: language,
        label: getCorrectLanguage(language, t),
      }));
    } else {
      return findUniqueInLangauge(translationLanguageEnum, allLangaugeFields);
    }
  };
  const createImportMapping = (e: any) => {
    e.preventDefault();
    WorkflowService.createImportMapping(
      {
        name: name,
        fields: mappingArray,
      },
      Number(id)
    )
      .then((response) => {
        SuccessToast("Success create");
      })
      .catch((error) => ErrorToast(error));
  };

  const newUpdateImportMapping = (e: any) => {
    e.preventDefault();
    WorkflowService.newUpdateImportMapping(
      {
        name: name,
        fields: mappingArray,
      },
      Number(id)
    )
      .then((response) => {
        SuccessToast("Success updated");
      })
      .catch((error) => ErrorToast(error));
  };
  const newMapImportMapping = (e: any) => {
    e.preventDefault();
    WorkflowService.newMapImportMapping(
      {
        name: name,
        fields: mappingArray,
      },
      Number(id)
    )
      .then((response) => {
        setMappingArray(response?.fields);
        const sameFields = (response?.fields as any)?.filter(
          (itemMap: any) => itemMap.mapTo?.toLowerCase() == "attribute_new"
        );
        const numbers = sameFields?.map((item: any) => Number(item?.value));
        if (!numbers[0]) {
          return;
        } else {
          let uniqueSortedNumbers = [
            ...(new Set(numbers) as unknown as number[]),
          ].sort((a, b) => a - b);

          let nextNumber =
            uniqueSortedNumbers[uniqueSortedNumbers.length - 1] + 1;
          uniqueSortedNumbers.push(nextNumber);
          if (uniqueSortedNumbers) setIdentificator(uniqueSortedNumbers);
        }
      })
      .catch((error) => ErrorToast(error));
  };
  const updateImportMapping = (e: any) => {
    e.preventDefault();
    WorkflowService.updateImportMapping(
      {
        name: selectName?.label,
        fields: mappingArray,
      },
      Number(selectName?.value)
    )
      .then((response) => {
        SuccessToast("Success update");
      })
      .catch((error) => ErrorToast(error));
  };

  const handlePreview = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    mappingFile && formData.append("file", mappingFile);
    formData.append(
      "body",
      JSON.stringify({
        name: name,
        fields: mappingArray,
      })
    );
    WorkflowService.previewImportMapping(formData)
      .then((response) => {
        setPreview(response);
        handleShow();
      })
      .catch((error) => ErrorToast(error));
  };
  const handleImport = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    mappingFile && formData.append("file", mappingFile);
    formData.append(
      "body",
      JSON.stringify({
        name: name,
        fields: mappingArray,
      })
    );
    WorkflowService.importMapping(formData, Number(id))
      .then((response) => {
        SuccessToast("Success!");
      })
      .catch((error) => ErrorToast(error));
  };

  const getFilteredOptions = (backArr: string[]) => {
    const selectedValues = mappingArray
      .map((item) => item.mapTo)
      .filter(Boolean);
    return backArr
      .map((field: string) => ({
        value: field.toLowerCase(),
        label: capitalizeFirstLetter(field),
      }))
      .filter((option) => {
        return (
          !uniqueOptions.includes(option.value) ||
          !selectedValues.includes(option.value)
        );
      });
  };
  return (
    <>
      <Card>
        <Card.Body>
          <WorkflowStepper step={"IMPORT"} id={id}></WorkflowStepper>
          <Row>
            <div className="col-6">
              <FilePond
                className="my-0"
                files={files}
                allowReorder={true}
                labelFileProcessingError={`${t(
                  "toasts.filePondImport.error_mess"
                )}`}
                allowMultiple={true}
                server={{
                  process: (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort
                  ) => {
                    const formData = new FormData();
                    formData.append(fieldName, file, file.name);

                    const request = new XMLHttpRequest();
                    request.open("PUT", `${WORKFLOW_URL}/${id}/upload`);
                    localStorage.getItem("token");

                    request.setRequestHeader(
                      "app-version",
                      `${localStorage.getItem("appVersion")}` || ""
                    );
                    request.setRequestHeader(
                      "Authorization",
                      `Bearer ${localStorage.getItem("token")}` || ""
                    );
                    request.upload.onprogress = (e) => {
                      progress(e.lengthComputable, e.loaded, e.total);
                    };

                    request.onload = function (e: any) {
                      if (request.status >= 200 && request.status < 300) {
                        const response = JSON.parse(request?.response);
                        setResponseBack(response);
                        setShowResp(true);
                        load(request.responseText);
                      } else {
                        error("Error onload");
                      }
                    };

                    request.send(formData);

                    return {
                      abort: () => {
                        request.abort();

                        abort();
                      },
                    };
                  },
                }}
                onupdatefiles={(filesArr: FilePondFile[]) => {
                  setFiles(filesArr?.map((fileItem) => fileItem?.file));
                }}
                labelIdle={`${t("import.drag_and_drop_normal")}`}
              />
              {showResp && (
                <>
                  <div className="d-flex justify-content-center">
                    {responseBack?.message}
                  </div>
                  <div className="d-flex justify-content-center">
                    <p className="me-1">
                      {t("import.successfully_added_rows")}{" "}
                      {responseBack?.successfullyAddedRows}
                    </p>
                    <p className="ms-1">
                      {t("import.skipped")} {responseBack?.skipped}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="col-6">
              <FilePond
                ref={pondRef}
                className="my-0"
                files={filesMapping}
                allowReorder={true}
                instantUpload={false}
                allowMultiple={true}
                allowProcess={false}
                server={{
                  process: (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort
                  ) => {
                    const formData = new FormData();
                    formData.append("file", file, file.name);
                    setMappingFile(file as any);
                    const request = new XMLHttpRequest();
                    request.open(
                      "POST",
                      `${IMPORT_MAPPING_URL}mapping-recommendation/${id}`
                    );

                    request.setRequestHeader(
                      "app-version",
                      `${localStorage.getItem("appVersion")}` || ""
                    );
                    request.setRequestHeader(
                      "Authorization",
                      `Bearer ${localStorage.getItem("token")}` || ""
                    );

                    request.upload.onprogress = (e) => {
                      progress(e.lengthComputable, e.loaded, e.total);
                    };

                    request.onload = function () {
                      if (request.status >= 200 && request.status < 300) {
                        const response = JSON.parse(request.response);
                        setMappingArray(response);
                        load(request.responseText);
                      } else {
                        error("Error onload");
                      }
                    };

                    request.onerror = function () {
                      error("Error on request");
                    };

                    request.send(formData);

                    return {
                      abort: () => {
                        request.abort();
                        abort();
                      },
                    };
                  },
                }}
                onupdatefiles={(filesArr) => {
                  setFilesMapping(filesArr.map((fileItem) => fileItem.file));
                }}
                labelIdle={`${t("import.drag_and_drop_mapping")}`}
              />
            </div>
          </Row>

          <Row>
            <Col sm={10}>
              {!!mappingArray?.length && (
                <Row>
                  {mappingArray?.map((item: any, index: number) => (
                    <div className="col-md-2 d-flex flex-column my-3 mapping-import">
                      <textarea
                        // type="text"
                        value={item?.identifier + "-" + " " + item?.name}
                        className="w-100"
                        rows={1}
                        style={{
                          width: "100%",
                          height: "28px",
                          overflowY: "hidden",
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                        }}
                        disabled
                      />
                      <img
                        src={ArrowIcon}
                        style={{ height: "35px" }}
                        className="my-1"
                        alt="arrow-icon"
                      />
                      <Select
                        key={item?.mapTo}
                        styles={{
                          control: (base: any) => ({
                            ...base,
                            height: 30,
                            minHeight: 30,
                            backgroundColor: `${
                              item?.mapTo === null ? "#ffff89" : "#ffffff"
                            }`,
                          }),
                        }}
                        className="my-2"
                        theme={themeSelect}
                        placeholder={"Mapping value"}
                        isClearable={true}
                        defaultValue={
                          item?.mapTo !== null && {
                            value: item?.mapTo.toLowerCase(),
                            label: capitalizeFirstLetter(item?.mapTo),
                          }
                        }
                        onChange={(e) => {
                          handleSelectMapping(
                            e as any,
                            index as number,
                            "mapTo"
                          );
                        }}
                        options={getFilteredOptions(item.fields)}
                      />
                      {item?.mapTo === "attribute" ? (
                        <>
                          <Select
                            key={`attribute-${index}`}
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                height: 30,
                                minHeight: 30,
                                backgroundColor: `${
                                  item?.value === null ? "#ffff89" : "#ffffff"
                                }`,
                              }),
                            }}
                            className="my-2"
                            defaultValue={attributesList.find(
                              (attribute: any) =>
                                attribute?.value == item?.value
                            )}
                            theme={themeSelect}
                            placeholder={"Attribute"}
                            isClearable={true}
                            onChange={(e) => {
                              handleSelectMapping(
                                e as any,
                                index as number,
                                "value"
                              );
                            }}
                            options={
                              funcForLanguageField && funcForLanguageField(item)
                            }
                          />
                          {true && (
                            <Select
                              key={`${item?.mapTo}-language-${index}`}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30,
                                  backgroundColor: `${
                                    !item?.language ? "#ffff89" : "#ffffff"
                                  }`,
                                }),
                              }}
                              className="my-2"
                              defaultValue={
                                item?.language && {
                                  value: item?.language,
                                  label: getCorrectLanguage(item?.language, t),
                                }
                              }
                              theme={themeSelect}
                              placeholder={"Language"}
                              onChange={(e) => {
                                handleSelectMapping(
                                  e as any,
                                  index as number,
                                  "language"
                                );
                              }}
                              // options={(translationLanguageEnum as any)?.map(
                              //   (language: string) => ({
                              //     value: language,
                              //     label: getCorrectLanguage(language, t),
                              //   })
                              // )}
                              options={(
                                funcForRepeatLanguageField &&
                                funcForRepeatLanguageField(item)
                              )?.map((language: string) => ({
                                value: language,
                                label: getCorrectLanguage(language, t),
                              }))}
                            />
                          )}
                          {true && (
                            <Select
                              key={`${item?.mapTo}-separator-${index}`}
                              isClearable={true}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30,
                                  backgroundColor: `${
                                    !item?.separator ? "#ffff89" : "#ffffff"
                                  }`,
                                }),
                              }}
                              className="my-2"
                              defaultValue={
                                item?.separator && {
                                  value: item?.separator,
                                  label: getCorrectSeparator(
                                    item?.separator,
                                    t
                                  ),
                                }
                              }
                              theme={themeSelect}
                              placeholder={"Separator"}
                              onChange={(e) => {
                                handleSelectMapping(
                                  e as any,
                                  index as number,
                                  "separator"
                                );
                              }}
                              options={(separators as any)?.map(
                                (separator: string) => ({
                                  value: separator,
                                  label: getCorrectSeparator(separator, t),
                                })
                              )}
                            />
                          )}
                        </>
                      ) : item?.mapTo === "photo" ||
                        item?.mapTo === "video" ||
                        item?.mapTo === "link" ? (
                        <Select
                          key={`${item?.mapTo}-${index}`}
                          // styles={customStyles}
                          styles={{
                            control: (base: any) => ({
                              ...base,
                              height: 30,
                              minHeight: 30,
                              backgroundColor: `${
                                !item?.language ? "#ffff89" : "#ffffff"
                              }`,
                            }),
                          }}
                          className="my-2"
                          defaultValue={
                            item?.language && {
                              value: item?.language,
                              label: getCorrectLanguage(item?.language, t),
                            }
                          }
                          theme={themeSelect}
                          placeholder={"Language"}
                          onChange={(e) => {
                            handleSelectMapping(
                              e as any,
                              index as number,
                              "language"
                            );
                          }}
                          options={(translationLanguageEnum as any)?.map(
                            (language: string) => ({
                              value: language,
                              label: getCorrectLanguage(language, t),
                            })
                          )}
                        />
                      ) : item?.mapTo === "block" ? (
                        <>
                          <Select
                            key={`block-${index}`}
                            // styles={customStyles}
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                height: 30,
                                minHeight: 30,
                                backgroundColor: `${
                                  item?.value === null ? "#ffff89" : "#ffffff"
                                }`,
                              }),
                            }}
                            className="react-select-field my-2"
                            theme={themeSelect}
                            defaultValue={(blockList as any).find(
                              (block: any) => block?.value == item?.value
                            )}
                            placeholder={"Block"}
                            isClearable={true}
                            onChange={(e) => {
                              handleSelectMapping(
                                e as any,
                                index as number,
                                "value"
                              );
                            }}
                            options={
                              funcForLanguageField && funcForLanguageField(item)
                            }
                          />
                          {true && (
                            <Select
                              key={`${item?.mapTo}-${index}`}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30,
                                  backgroundColor: `${
                                    !item?.language ? "#ffff89" : "#ffffff"
                                  }`,
                                }),
                              }}
                              className="my-2"
                              defaultValue={
                                item?.language && {
                                  value: item?.language,
                                  label: getCorrectLanguage(item?.language, t),
                                }
                              }
                              theme={themeSelect}
                              placeholder={"Language"}
                              onChange={(e) => {
                                handleSelectMapping(
                                  e as any,
                                  index as number,
                                  "language"
                                );
                              }}
                              // options={(translationLanguageEnum as any)?.map(
                              //   (language: string) => ({
                              //     value: language,
                              //     label: getCorrectLanguage(language, t),
                              //   })
                              // )}
                              options={(
                                funcForRepeatLanguageField &&
                                funcForRepeatLanguageField(item)
                              )?.map((language: string) => ({
                                value: language,
                                label: getCorrectLanguage(language, t),
                              }))}
                            />
                          )}
                        </>
                      ) : item?.mapTo === "attribute_new" ? (
                        <>
                          <Select
                            key={`attribute-new-${index}`}
                            // styles={customStyles}
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                height: 30,
                                minHeight: 30,
                                backgroundColor: `${
                                  item?.value === null ? "#ffff89" : "#ffffff"
                                }`,
                              }),
                            }}
                            className="react-select-field my-2"
                            theme={themeSelect}
                            // defaultValue={(identificator as any).find(
                            //   (block: any) => block == item?.value
                            // )}
                            defaultValue={
                              !!item?.value && {
                                value: Number(item?.value),
                                label: item?.value,
                              }
                            }
                            placeholder={"Identificator"}
                            isClearable={true}
                            onChange={(e) => {
                              handleSelectMapping(
                                e as any,
                                index as number,
                                "value",
                                "identificator"
                              );
                            }}
                            options={(
                              funcForLanguageField && funcForLanguageField(item)
                            )?.map((identificator: string) => ({
                              value: identificator,
                              label: identificator,
                            }))}
                          />
                          {true && (
                            <Select
                              key={`${item?.mapTo}-language-${index}`}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30,
                                  backgroundColor: `${
                                    !item?.language ? "#ffff89" : "#ffffff"
                                  }`,
                                }),
                              }}
                              className="my-2"
                              defaultValue={
                                item?.language && {
                                  value: item?.language,
                                  label: getCorrectLanguage(item?.language, t),
                                }
                              }
                              theme={themeSelect}
                              placeholder={"Language"}
                              onChange={(e) => {
                                handleSelectMapping(
                                  e as any,
                                  index as number,
                                  "language"
                                );
                              }}
                              options={(
                                funcForRepeatLanguageField &&
                                funcForRepeatLanguageField(item)
                              )?.map((language: string) => ({
                                value: language,
                                label: getCorrectLanguage(language, t),
                              }))}
                            />
                          )}
                          {true && (
                            <Select
                              key={`${item?.mapTo}-separator-${index}`}
                              isClearable={true}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30,
                                  backgroundColor: `${
                                    !item?.separator ? "#ffff89" : "#ffffff"
                                  }`,
                                }),
                              }}
                              className="my-2"
                              defaultValue={
                                item?.separator && {
                                  value: item?.separator,
                                  label: getCorrectSeparator(
                                    item?.separator,
                                    t
                                  ),
                                }
                              }
                              theme={themeSelect}
                              placeholder={"Separator"}
                              onChange={(e) => {
                                handleSelectMapping(
                                  e as any,
                                  index as number,
                                  "separator"
                                );
                              }}
                              options={(separators as any)?.map(
                                (separator: string) => ({
                                  value: separator,
                                  label: getCorrectSeparator(separator, t),
                                })
                              )}
                            />
                          )}
                        </>
                      ) : item?.mapTo === "document" ? (
                        <>
                          {
                            <Select
                              key={`document-${index}`}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30,
                                  backgroundColor: `${
                                    item?.value === null ? "#ffff89" : "#ffffff"
                                  }`,
                                }),
                              }}
                              className="react-select-field my-2"
                              theme={themeSelect}
                              defaultValue={(documentBlockList as any).find(
                                (block: any) => block?.value == item?.value
                              )}
                              placeholder={capitalizeFirstLetter(item?.mapTo)}
                              isClearable={true}
                              onChange={(e) => {
                                handleSelectMapping(
                                  e as any,
                                  index as number,
                                  "value"
                                );
                              }}
                              options={
                                funcForLanguageField &&
                                funcForLanguageField(item)
                              }
                            />
                          }
                          {true && (
                            <>
                              <Select
                                key={`languageDocument-${index}`}
                                styles={{
                                  control: (base: any) => ({
                                    ...base,
                                    height: 30,
                                    minHeight: 30,
                                    backgroundColor: `${
                                      item?.language ? "#ffffff" : "#ffff89"
                                    }`,
                                  }),
                                }}
                                className="react-select-field my-2"
                                theme={themeSelect}
                                placeholder={"Language"}
                                onChange={(e) => {
                                  handleSelectMapping(
                                    e as any,
                                    index as number,
                                    "language"
                                  );
                                }}
                                defaultValue={
                                  item?.language && {
                                    value: item?.language,
                                    label: getCorrectLanguage(
                                      item?.language,
                                      t
                                    ),
                                  }
                                }
                                options={(translationLanguageEnum as any)?.map(
                                  (language: string) => ({
                                    value: language,
                                    // label: getCorrectLanguage(language, t),
                                    label: getCorrectLanguage(language, t),
                                  })
                                )}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        item?.mapTo !== null &&
                        languageVisible(item?.mapTo) && (
                          <Select
                            key={`language-${index}`}
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                height: 30,
                                minHeight: 30,
                                backgroundColor: `${
                                  item?.language === null
                                    ? "#ffff89"
                                    : "#ffffff"
                                }`,
                              }),
                            }}
                            className="react-select-field my-2"
                            theme={themeSelect}
                            placeholder={"Language"}
                            onChange={(e) => {
                              handleSelectMapping(
                                e as any,
                                index as number,
                                "language"
                              );
                            }}
                            defaultValue={
                              item?.language && {
                                value: item?.language,
                                label: getCorrectLanguage(item?.language, t),
                              }
                            }
                            options={(
                              funcForLanguageField && funcForLanguageField(item)
                            )?.map((language: string) => ({
                              value: language,
                              label: getCorrectLanguage(language, t),
                            }))}
                          />
                        )
                      )}
                    </div>
                  ))}
                </Row>
              )}
            </Col>
            {filesMapping?.length !== 0 && (
              <Col
                sm={2}
                className="border py-3"
                style={{ height: "fit-content" }}
              >
                <div className="d-flex justify-content-end">
                  <Button
                    variant="info"
                    onClick={handleProcess}
                    disabled={filesMapping?.length === 0}
                    className="mx-1 "
                  >{`${t("global.scan")}`}</Button>
                </div>
                <Col md={12}>
                  <Select
                    key={workflowDataRedux?.id}
                    className="react-select-field my-2"
                    theme={themeSelect}
                    isDisabled={true}
                    defaultValue={{
                      label: workflowDataRedux?.client?.name,
                      value: workflowDataRedux?.client?.id,
                    }}
                  />
                </Col>
                <Col md={12} className="d-flex justify-content-end">
                  <Button
                    disabled={!!!mappingArray?.length}
                    variant="warning"
                    className="mx-1"
                    onClick={(e: any) => newUpdateImportMapping(e)}
                  >{`${t("global.update")}`}</Button>
                  <Button
                    disabled={!!!mappingArray?.length}
                    variant="success"
                    className="ms"
                    onClick={(e: any) => newMapImportMapping(e)}
                  >{`${t("global.map")}`}</Button>
                </Col>

                <div className="d-flex justify-content-end">
                  <div className="my-3">
                    <Button
                      disabled={!!!mappingArray?.length}
                      variant="info"
                      className="me-1"
                      onClick={(e: any) => {
                        handleImport(e);
                      }}
                    >{`${t("side_bar.import")}`}</Button>
                    <Button
                      disabled={!!!mappingArray?.length}
                      variant="primary"
                      className="mx"
                      onClick={(e: any) => {
                        handlePreview(e);
                      }}
                    >{`${t("global.preview")}`}</Button>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Card.Body>
        <Card.Footer className="border-top-0">
          <div className="col-12  d-flex justify-content-end ">
            <Button
              variant="info"
              onClick={() => {
                backNavigate();
              }}
            >
              {`${t("block.back")}`}
            </Button>
            <Button
              variant="success"
              className="ms-3"
              onClick={() => handleSave()}
            >
              {t("global.markAsComplete")}
            </Button>
          </div>
        </Card.Footer>
      </Card>
      <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Body className="">
          <Table className="table mb-0 table-import">
            <thead className="table-light">
              <tr>
                <th scope="col">{`${t("global.id")}`}</th>
                <th scope="col" className="text-nowrap">{`${t(
                  "products.articleNumber"
                )}`}</th>

                <th scope="col">{`${t("products.eanCode")}`}</th>
                <th scope="col" className="text-nowrap">{`${t(
                  "products.sapId"
                )}`}</th>
                <th scope="col">{`${t("products.brand")}`}</th>
                <th scope="col">{`${t("products.category")}`}</th>
                <th scope="col" style={{ minWidth: "100px" }}>{`${t(
                  "products.attributes"
                )}`}</th>
                {(translationLanguageEnum as any)?.map(
                  (item: any, index: number) =>
                    translationValue?.map((transaltion: any, index: number) => {
                      return (
                        <th
                          scope={`${
                            transaltion === "description" ||
                            transaltion ||
                            "intro" ||
                            transaltion ||
                            "bullets"
                              ? ""
                              : "row"
                          }`}
                          style={{
                            minWidth: `${
                              transaltion === "description" ||
                              transaltion ||
                              "intro" ||
                              transaltion ||
                              "bullets"
                                ? "300px"
                                : "100px"
                            }`,
                          }}
                        >
                          {item}
                          {"- "}
                          {capitalizeFirstLetter(transaltion)}
                        </th>
                      );
                    })
                )}
              </tr>
            </thead>
            <tbody className="align-middle">
              {preview?.map((item: any, index: number) => {
                return (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: `${item?.id ? "#f8ed62" : "#90EE90"}`,
                    }}
                  >
                    <td style={{ height: "50px" }}>{item?.id ?? null}</td>
                    <td
                      style={{
                        backgroundColor: `${
                          item?.articleNumber?.isLoadedFromDatabase
                            ? "#f8ed62"
                            : "#90EE90"
                        }`,
                        height: "50px",
                      }}
                    >
                      {item?.articleNumber?.value}
                    </td>

                    <td
                      style={{
                        backgroundColor: `${
                          item?.eanCode?.isLoadedFromDatabase
                            ? "#f8ed62"
                            : "#90EE90"
                        }`,
                        height: "50px",
                      }}
                    >
                      {item?.eanCode?.value}
                    </td>

                    <td
                      style={{
                        backgroundColor: `${
                          item?.sapId?.isLoadedFromDatabase
                            ? "#f8ed62"
                            : "#90EE90"
                        }`,
                        height: "50px",
                      }}
                    >
                      {item?.sapId?.value}
                    </td>
                    <td
                      style={{
                        backgroundColor: `${
                          item?.brand?.isLoadedFromDatabase
                            ? "#f8ed62"
                            : "#90EE90"
                        }`,
                        height: "50px",
                      }}
                    >
                      {item?.brand?.value?.name}
                    </td>
                    <td
                      style={{
                        backgroundColor: `${
                          item?.category?.isLoadedFromDatabase
                            ? "#f8ed62"
                            : "#90EE90"
                        }`,
                        minWidth: "150px",
                      }}
                    >
                      {item?.category?.value?.translations?.map(
                        (category: any) => (
                          <span className="d-block">
                            {category?.lang + "- " + (category?.title ?? "")}
                          </span>
                        )
                      )}
                    </td>
                    <td
                    // style={{
                    //   backgroundColor: `${
                    //     item?.category?.isLoadedFromDatabase
                    //       ? "#f8ed62"
                    //       : "#90EE90"
                    //   }`,
                    //   minWidth: "150px",
                    // }}
                    >
                      {item?.attributes?.map((attribute: any) => {
                        return attribute?.values?.map((value: any) => {
                          return (
                            <span className="d-block">
                              {attribute?.language}
                              {"- "}
                              {value}
                            </span>
                          );
                        });
                      })}
                      {/* {item?.category?.value?.translations?.map(
                        (category: any) => (
                          <span className="d-block">
                            {category?.lang + "- " + (category?.title ?? "")}
                          </span>
                        )
                      )} */}
                    </td>
                    {functionForCorrectFields(item)}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end">
          <Button variant="secondary" onClick={handleClose}>
            {t(`modals.close`)}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleClose();
              // completeHandler();
            }}
          >
            {t(`global.accept`)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
