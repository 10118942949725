import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { PageTemplate } from "../../../../shared/layout/PageTemplate";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import { ContentView } from "../../../products/content/components/ContentView";
import { IProducts, IProductsList } from "../../../products/dto/IProducts";
import { ProductsService } from "../../../products/service";
import DataFromExcel from "./DataFromExcel";

export const ViewContentImported = () => {
  const [currentProduct, setCurrentProduct] = useState<IProducts>();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    id &&
      ProductsService.getViewProductsById(+id)
        .then((response) => {
          setCurrentProduct(response);
        })
        .catch((error) => ErrorToast(error));
  }, [id]);
  return (
    // <PageTemplate title={`${t("products.content_view")}`}>
    <>
      <div className="col-md-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="d-flex align-itmes-end">
            <ul className="nav nav-pills nav-fill">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={`/app/pending-products/imported/view/base-data/${id}`}
                >
                  {t("products.base_data_view")}
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to={`/app/pending-products/imported/view/attributes/${id}`}
                >
                  {t("products.attributes_view")}
                </Link>
              </li>
              <li>
                <span className="nav-link active">
                  {t("products.content_view")}
                </span>
              </li>
            </ul>
          </div>
          <>
            <button
              className="btn btn-primary d-flex align-items-center me-2"
              data-bs-toggle="offcanvas"
              data-bs-target={`#offcanvasRight-${id}`}
              aria-controls={`offcanvasRight-${id}`}
            >
              <i className="ri-information-line me-1" />
              {`${t("global.info")}`}
            </button>
            <div
              className="offcanvas offcanvas-end"
              id={`offcanvasRight-${id}`}
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header">
                <h5 id="offcanvasRightLabel">{t("products.info_excel")}</h5>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <DataFromExcel
                  currentExcelProductId={id as unknown as number}
                />
              </div>
            </div>
          </>
        </div>
      </div>
      <ContentView currentProduct={currentProduct as IProductsList} />
    </>
    // </PageTemplate>
  );
};
