import Lottie from "lottie-react";
import searchIcon from "../icons/searchIcon.json";
import { useTranslation } from "react-i18next";

export const LoadingAnimation = () => {
    const { t } = useTranslation();
    return (
        <div className="d-flex align-items-center justify-content-center flex-column w-100">
            <Lottie animationData={searchIcon} loop={true} style={{ width: 100 }} />
        </div>
    );
};
