import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { PageTemplate } from "../../../../shared/layout/PageTemplate";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import { IProducts, IProductsList } from "../../dto/IProducts";
import { ProductsService } from "../../service";
import { ContentView } from "./ContentView";

export const ContentViewActions = () => {
  const [currentProduct, setCurrentProduct] = useState<IProducts>();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    id &&
      ProductsService.getViewProductsById(+id)
        .then((response) => {
          setCurrentProduct(response);
        })
        .catch((error) => ErrorToast(error));
  }, [id]);
  return (
    // <PageTemplate title={`${t("products.content_view")}`}>
    <>
      <div className="col-md-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="d-flex align-itmes-end">
            <ul className="nav nav-pills nav-fill">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={`/app/products/view/base-data/${id}`}
                >
                  {t("products.base_data_view")}
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to={`/app/products/view/attributes/${id}`}
                >
                  {t("products.attributes_view")}
                </Link>
              </li>
              <li>
                <span className="nav-link active">
                  {t("products.content_view")}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-md-12 mb-3 d-flex align-items-center justify-content-between">
        <div>
          <h2>{currentProduct?.title}</h2>
        </div>
      </div>
      <ContentView currentProduct={currentProduct as IProductsList} />
    </>
    // </PageTemplate>
  );
};
