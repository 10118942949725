import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { t } from "i18next";
import { Card, Col, Row } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  formatDateWorkflow,
  getCorrectLanguage,
} from "../../../shared/functions/Functions";
import { themeSelect } from "../../../shared/components/Select2";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import {
  ICategoryChildren,
  ICategoryDTO,
} from "../../category/values/dto/ICategory";
import { UserService } from "../../users/service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { ClientService } from "../../clients/service";

interface IWorkflowFormProps {
  isContent?: boolean;
  firstStap?: any;
  setFirstStap: React.Dispatch<SetStateAction<any | undefined>>;
  workflowStatus: string[];
  categories: any;
  selectedMainCategory: ICategoryChildren[];
  setSelectedMainCategory: React.Dispatch<SetStateAction<ICategoryChildren[]>>;
}

export const ContentWorkflowCreate = ({
  isContent = false,
  firstStap,
  setFirstStap,
  workflowStatus,
  categories,
  selectedMainCategory,
  setSelectedMainCategory,
}: IWorkflowFormProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [copywriterList, setCopywriterList] = useState([]);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [clientList, setClientList] = useState<any[]>([]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setFirstStap((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  function handleSelectSingle3(
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) {
    const selected = categories?.find(
      (category: ICategoryDTO) => category?.id === selectedSingle?.value
    )?.children;
    selected && setSelectedMainCategory(selected);
  }
  function handleSelectSingle(
    selectedSingle: SingleValue<{
      label: string;
      value: string;
      nesto: string;
    }>,
    title: string
  ) {
    // setSourceLanguage(selectedSingle?.value as string);
    setFirstStap((prev: any) => ({
      ...prev,
      [title]: selectedSingle?.value as string,
    }));
  }
  function handleSelectSingle2(selectedSingle: any, title: string) {
    setFirstStap((prev: any) => ({
      ...prev,
      [title]: selectedSingle?.map(
        (selectedSingle: { value: string }) => selectedSingle?.value
      ),
    }));
  }

  function handleDate(date: Date) {
    const value = new Date(date);
    value.setHours(12);
    setStartDate(value as unknown as Date);
    setFirstStap((prev: any) => ({
      ...prev,
      deadline: value,
      deadlineData: value,
    }));
  }
  const handleSelectMainCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    setFirstStap((prev: any) => ({
      ...prev,
      category: selectedSingle?.value,
    }));
  };
  function handleSelectSingle4(selectedSingle: SingleValue<any | null>) {
    setFirstStap((prev: any) => ({
      ...prev,
      language: prev?.language?.filter(
        (lang: string) => lang !== selectedSingle?.value
      ),
      sourceLang: selectedSingle?.value as string,
    }));
  }
  useEffect(() => {
    UserService.getAllUsers({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setCopywriterList(
          data?.filter((copywriter) => copywriter.isCopywriter) as any
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    ClientService.getAllClients({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setClientList(
          data?.map((client) => ({
            ...client,
            label: client.name,
            value: client.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <>
      <Row>
        <Col md={4}>
          <label htmlFor="name" className="required-field">
            {`${t("global.name")}`}
          </label>
          <input
            type="text"
            name="name"
            placeholder="Type..."
            className="form-control"
            value={(firstStap?.name as string) ?? ""}
            required
            onChange={changeHandler}
          />
        </Col>

        <Col md={4}>
          <label htmlFor="name" className="">
            {`${t("global.manager")}`}
          </label>
          <Select
            key={firstStap?.id}
            className="react-select-field"
            theme={themeSelect}
            isMulti={true}
            defaultValue={firstStap?.copywriters?.map((value: any) => ({
              label:
                value?.firstName?.concat(" ", value?.lastName) +
                  ` (${value.copywriterAssignment})` ?? "-",
              value: value?.id,
            }))}
            onChange={(e) => handleSelectSingle2(e as any, "copywriters")}
            options={copywriterList?.map((value: any) => ({
              label:
                value?.firstName?.concat(" ", value?.lastName) +
                  ` (${value.copywriterAssignment})` ?? "-",
              value: value?.id,
            }))}
          />
        </Col>
        <div className="col-md-4">
          <label htmlFor="language" className="">
            {`${t("import.deadline_date")}`}
          </label>
          <div className="datepicker-container">
            <DatePicker
              // key={firstStap?.id}
              value={
                firstStap?.id &&
                (firstStap?.deadline
                  ? formatDateWorkflow(firstStap?.deadline as any)
                  : null)
              }
              className="date-picker"
              selected={startDate}
              onChange={(date) => handleDate(date as any)}
              dateFormat="dd/MM/yyyy"
              isClearable
              placeholderText="Choose deadline date..."
            />
          </div>
        </div>
      </Row>
      <Row className="mb-3">
        <Col md={4} className="mt-3">
          <label htmlFor="language" className="">
            {`${t("export_client.language")}`}
          </label>
          <Select
            key={firstStap?.id}
            className="react-select-field"
            theme={themeSelect}
            value={firstStap?.language?.map((language: string) => ({
              value: language,
              label: getCorrectLanguage(language, t),
            }))}
            onChange={(e) => {
              handleSelectSingle2(e as any, "language");
            }}
            isMulti={true}
            options={(translationLanguageEnum as any)
              ?.map((language: string) => ({
                value: language,
                label: getCorrectLanguage(language, t),
              }))
              .filter((lang: { label: string; value: string }) => {
                return lang.value !== firstStap?.sourceLang;
              })}
          />
        </Col>
        <Col md={4} className="mt-3">
          <label htmlFor="client" className="required-field">
            {`${t("export_client.client")}`}
          </label>
          <Select
            key={firstStap?.id}
            className="react-select-field"
            theme={themeSelect}
            defaultValue={
              firstStap?.client && {
                label: firstStap?.client?.name,
                value: firstStap?.client?.id,
              }
            }
            onChange={(e) => {
              handleSelectSingle(e as any, "client");
            }}
            options={clientList as any}
          />
        </Col>
        <Col md={4} className="mt-3">
          <label htmlFor="language" className="required-field">
            {`${t("global.source_lang")}`}
          </label>
          <Select
            isDisabled={firstStap?.id ? true : false}
            className="react-select-field"
            theme={themeSelect}
            key={firstStap?.id}
            defaultValue={
              firstStap?.sourceLang && {
                label: getCorrectLanguage(firstStap?.sourceLang, t),
                value: firstStap?.sourceLang,
              }
            }
            onChange={(e) => {
              handleSelectSingle4(e as any);
            }}
            options={(translationLanguageEnum as any)?.map(
              (language: string) => ({
                value: language,
                label: getCorrectLanguage(language, t),
              })
            )}
          />
        </Col>
      </Row>
    </>
  );
};
