import { BaseModel } from "../../model/shared/BaseModel";
import { CONTENT_WORKFLOW_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";

export const ContentWorkflowService = {
  createWorkflow: (params: any) =>
    requests.post(`${CONTENT_WORKFLOW_URL}`, params),
  getAllWorkflows: (params: any): Promise<BaseModel<any[]>> =>
    requests.get(`${CONTENT_WORKFLOW_URL}`, params),
  updateWorkflowById: (id: number, params: any) =>
    requests.put(`${CONTENT_WORKFLOW_URL}/${id}`, params),
  deleteWorkflowById: (id: number) =>
    requests.delete(`${CONTENT_WORKFLOW_URL}/${id}`),
  getWorkflowById: (id: number) =>
    requests.get(`${CONTENT_WORKFLOW_URL}/${id}`),
  createBase: (params: any, workflowId: number) =>
    requests.post(`${CONTENT_WORKFLOW_URL}/base/${workflowId}`, params),
  getAllContents: (
    params: any,
    workflowId: number
  ): Promise<BaseModel<any[]>> =>
    requests.get(`${CONTENT_WORKFLOW_URL}/base/${workflowId}`, params),
  updateContentBaseById: (workflowId: number, contentId: number, params: any) =>
    requests.put(
      `${CONTENT_WORKFLOW_URL}/base/${workflowId}/${contentId}`,
      params
    ),
  getContentBaseById: (workflowId: number, contentId: number) =>
    requests.get(`${CONTENT_WORKFLOW_URL}/base/${contentId}/${workflowId}`),
  assignCopywriter: (id: number, payload: any) =>
    requests.put(`${CONTENT_WORKFLOW_URL}/base/assign-multiple/${id}`, payload),
  deleteContentById: (workflowId: number, contentId: number) =>
    requests.delete(`${CONTENT_WORKFLOW_URL}/base/${workflowId}/${contentId}`),
  getAllCopywrite: (params: any, id: number): Promise<BaseModel<any[]>> =>
    requests.get(`${CONTENT_WORKFLOW_URL}/copy-write/${id}`, params),
  getCopywriteById: (workflowId: number, copywriteId: number, step: string) =>
    requests.get(
      `${CONTENT_WORKFLOW_URL}/${
        step === "COPY_WRITING" ? "copy-write" : "translation"
      }/${workflowId}/${copywriteId}`
    ),
  updateCopywriteById: (
    params: any,
    workflowId: number,
    copywriteId: number,
    step: string
  ) =>
    requests.put(
      `${CONTENT_WORKFLOW_URL}/${
        step === "COPY_WRITING" ? "copy-write" : "translation"
      }/${workflowId}/${copywriteId}`,
      params
    ),
  getNextCopywriteContentWorkflow: (workflowId: number, step: string) =>
    requests.get(
      `${CONTENT_WORKFLOW_URL}/${
        step === "COPY_WRITING" ? "copy-write" : "translation"
      }/next/${workflowId}`
    ),
  getAllTranslation: (params: any, id: number): Promise<BaseModel<any[]>> =>
    requests.get(`${CONTENT_WORKFLOW_URL}/translation/${id}`, params),
  getTranslationById: (workflowId: number, copywriteId: number) =>
    requests.get(
      `${CONTENT_WORKFLOW_URL}/translation/${workflowId}/${copywriteId}`
    ),
  assignTranslator: (id: number, payload: any) =>
    requests.put(
      `${CONTENT_WORKFLOW_URL}/translation/assign-multiple/${id}`,
      payload
    ),
  saveWorkflowBaseById: (id: number) =>
    requests.put(`${CONTENT_WORKFLOW_URL}/base/save/${id}`),
  saveWorkflowCopywritingById: (id: number) =>
    requests.put(`${CONTENT_WORKFLOW_URL}/copy-write/save/${id}`),
  saveWorkflowTranslationById: (id: number) =>
    requests.put(`${CONTENT_WORKFLOW_URL}/translation/save/${id}`),
  export: (view: string, format: string, id: number) =>
    requests.get(
      `${CONTENT_WORKFLOW_URL}/export/export/${format}/${view}/${id}`
    ),
  getWorkflowCharts: (id: number) =>
    requests.get(`${CONTENT_WORKFLOW_URL}/overview/${id}`),
  workflowComplete: (id: number) =>
    requests.put(`${CONTENT_WORKFLOW_URL}/export/save/${id}`),
  changeFinishedStatus: (id: number, payload: any, status: string) =>
    requests.put(
      `${CONTENT_WORKFLOW_URL}/${status}/product-status-change/${id}`,
      payload
    ),
  changeFinishedStatusCopyWrite: (id: number, payload: any) =>
    requests.put(
      `${CONTENT_WORKFLOW_URL}/copy-write/product-status-change/${id}`,
      payload
    ),
  deleteFile: (id: number) =>
    requests.delete(`${CONTENT_WORKFLOW_URL}/base/delete-file/${id}`),
};
