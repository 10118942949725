import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sidebarLinks } from "./helpers/SideBarLinks";
import { SideBarItemsWithLabels } from "./SideBarItemsWithLabels";

export function SideBar() {
  const location = useLocation();
  const { t } = useTranslation();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  return (
    <>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <Link to="/app/dashboard" className="logo logo-light">
            <span className="logo-sm">
              <img src="/images/logo/ciib_white.svg" alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src="/images/logo/ciib_white.svg" alt="" height="30" />
            </span>
          </Link>
          {process.env.REACT_APP_TESTING_MODE === "true" && (
            <h6 className="text-white">TEST</h6>
          )}
          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line" />
          </button>
        </div>
        <div id="scrollbar">
          <div className="container-fluid">
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              {Object.values(sidebarLinks).map((sidebarItem) => {
                return (
                  <SideBarItemsWithLabels
                    sidebarItem={sidebarItem}
                    t={t}
                    location={location}
                    isUserAdmin={currentUser?.roles?.some(
                      (role: string) => role === "ROLE_ADMIN"
                    )}
                    key={sidebarItem?.title}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
