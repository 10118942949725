import React, { useEffect, useState } from "react";
import { Pagination } from "../../../shared/paginations/Paginations";
import { Col, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EmptyState } from "../../../shared/components/EmptyState";
import { PageTemplate } from "../../../shared/layout/PageTemplate";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { IProductsList } from "../../products/dto/IProducts";
import { ProductsPendingService } from "../service";
import _debounce from "lodash/debounce";
import { SingleValue } from "react-select";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { useLocation, useNavigate } from "react-router";
import { ProductsFilters } from "../../products/components/ProductsFilters";
import { ProductsCardView } from "../../products/components/ProductsCardView";
import { ProductsListView } from "../../products/components/ProductsListView";
import { ProductsService } from "../../products/service";
import { IBrand } from "../../brand/dto/IBrand";
import { BrandService } from "../../brand/service";
import { CategoryServicee } from "../../../store/category/categoryService";
import categorySlice from "../../../store/category/categoryRedux";
import { useDispatch } from "react-redux";
import { LoadingAnimation } from "../../../shared/components/Loading";

interface IPagination {
  page: number;
}

export const CrawledProducts = () => {
  const toForLink = "/app/pending-products/crawled/base-data/";
  const dispatch = useDispatch();
  const location = useLocation();
  const nameOfTable = "pending";
  const [isListView, setIsListView] = useState(true);
  const { t } = useTranslation();
  const { search } = useLocation();
  const mainTable = false;
  const [categoryFilterId, setCategoryFilterId] = useState(null);
  const [brandsList, setBrandsList] = useState<IBrand[]>([]);
  const [brandId, setBrandId] = useState(null);
  const [termFilter, setTermFilter] = useState("");
  const currentPage =
    (location.state as unknown as IPagination)?.page ??
    Number(search.split("=").at(-1) ? search.split("=").at(-1) : 1);
  const [pagination, setPagination] = useState({
    ...(location.state as unknown as IPagination),
    perPage: 12,
  });
  const [page, setPage] = useState(currentPage);
  const [totalPages, setTotalPages] = useState(0);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [productsList, setProductsList] = useState<
    IProductsList[] | undefined
  >();
  const handlePages = (updatePage: number) => {
    navigate(`${location.pathname}?page=${updatePage}`, {
      state: {
        ...pagination,
        page: updatePage,
      },
    });
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const categories = useSelector((state: IAllStates) => state.category);
  const navigate = useNavigate();
  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setTermFilter(value);
      navigate(location.pathname, {
        state: {
          ...pagination,
          [name]: value,
        },
      });
      setPagination((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    },
    200
  );

  const handleSelectMainCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value as number) {
      setCategoryFilterId(selectedSingle?.value as any);
      setPagination((prev: any) => ({
        ...prev,
        category: selectedSingle?.value as number,
        categoryData: selectedSingle,
      }));
      navigate(location.pathname, {
        state: {
          ...pagination,
          category: selectedSingle?.value as number,
          categoryData: selectedSingle,
        },
      });
    } else {
      setCategoryFilterId(null);
      setPagination((prev: any) => ({
        ...prev,
        category: null,
        categoryData: null,
      }));
    }
  };
  const handleSelectStatus = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    if (selectedSingle?.value as string) {
      setPagination({
        page: 1,
        perPage: 12,
        status: selectedSingle?.value as string,
      } as any);
    }
  };
  const deleteHandler = (product: IProductsList) => {
    product?.id &&
      ProductsService.deleteProductById(product?.id)
        .then((response) => {
          setTriggerUpdate((prev) => !prev);
          SuccessToast(`${t("toasts.successfully_deleted")} ${product?.title}`);
        })
        .catch((error) => ErrorToast(error));
  };

  // const editClickHandler = (e, id: number) => {
  //   navigate(`/app/pending-products/crawled/base-data/${id}`, {
  //     state: { pagination: pagination },
  //   });
  //   // navigate(`/app/pending-products/crawled/base-data/${id}`);
  // };
  const editClickHandler = (e: any, id: number) => {
    if (e.type === "click") {
      navigate(`/app/pending-products/crawled/base-data/${id}`, {
        state: { pagination: pagination },
      });
    } else if (e.type === "contextmenu") {
      window.open(`/app/pending-products/crawled/base-data/${id}`, "_blank");
    }
  };
  const viewClickHandler = (id: number) => {
    navigate(`/app/pending-products/crawled/view/base-data/${id}`);
  };
  useEffect(() => {
    ProductsPendingService.getAllCrawledProducts(pagination)
      .then((response) => {
        const { data } = response;
        setProductsList(data);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, triggerUpdate, currentPage]);
  // useEffect(() => {
  //   if (currentPage) {
  //     setPagination((prev) => ({ ...prev, page: currentPage }));
  //     setPage(currentPage);
  //   }
  // }, [currentPage]);

  const handleSelectBrand = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value as number) {
      setBrandId(selectedSingle?.value as any);
      setPagination((prev: any) => ({
        ...prev,
        brand: selectedSingle?.value as number,
        brandData: selectedSingle,
      }));
      navigate(location.pathname, {
        state: {
          ...pagination,
          brand: selectedSingle?.value as number,
        },
      });
    } else {
      setBrandId(null);
      setPagination((prev: any) => ({
        ...prev,
        brand: null,
      }));
    }
  };
  useEffect(() => {
    BrandService.getAllBrands({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setBrandsList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    CategoryServicee.getAllCategories()
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    if (location.state) {
      navigate(location.pathname, { state: {} });
    }
  }, [location?.pathname]);

  return (
    // <PageTemplate title={`${t("side_bar.crawled_products")}`}>
    <Col xs={12}>
      <ProductsFilters
        listWithoutCat={true}
        mainTable={mainTable}
        setTriggerUpdate={setTriggerUpdate}
        handleSelectStatus={handleSelectStatus}
        changeFilterHandler={changeFilterHandler}
        handleSelectMainCategory={handleSelectMainCategory}
        categories={categories}
        brandsList={brandsList}
        handleSelectBrand={handleSelectBrand}
        setPagination={setPagination}
        triggerUpdate={triggerUpdate}
        pagination={pagination}
      />
      <div className="d-flex w-100 align-items-center justify-content-end mb-3">
        <button
          className={`btn btn${!isListView ? "-soft" : ""}-info btn-sm`}
          onClick={() => setIsListView(!isListView)}
        >
          <i className="ri-list-check " />
        </button>
        <button
          className={`btn btn${isListView ? "-soft" : ""}-info btn-sm ms-2`}
          onClick={() => setIsListView(!isListView)}
        >
          <i className="ri-bank-card-line" />
        </button>
      </div>
      {!isListView ? (
        <div className="row gy-4">
          {!!productsList?.length ? (
            productsList?.map((product) => (
              <div className="col-sm-6 col-xl-3" key={product?.id}>
                <ProductsCardView
                  nameOfTable={nameOfTable}
                  key={product?.id}
                  product={product}
                  deleteHandler={deleteHandler}
                  onEdit={editClickHandler}
                  onView={viewClickHandler}
                />
              </div>
            ))
          ) : (
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <EmptyState />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="card">
          <div className="card-body">
            {productsList == undefined ? (
              <LoadingAnimation />
            ) : productsList.length === 0 ? (
              <EmptyState />
            ) : (
              <div className="table-responsive">
                <Table className="align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">{`${t("global.id")}`}</th>
                      <th scope="col">{`${t("global.aenCode")}`}</th>
                      <th scope="col">{`${t("products.product")}`}</th>
                      <th scope="col">{`${t("products.category")}`}</th>
                      <th scope="col">{`${t("products.createdAt")}`}</th>
                      <th scope="col">{`${t("global.actions")}`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productsList?.map((product) => (
                      <ProductsListView
                        toForLink={toForLink}
                        nameOfTable={"crawled"}
                        key={product?.id}
                        deleteHandler={deleteHandler}
                        onEdit={editClickHandler}
                        onView={viewClickHandler}
                        product={product}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-end ">
            {totalPages <= 1 ? (
              ""
            ) : (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
        </div>
      </div>
    </Col>
    // </PageTemplate>
  );
};
