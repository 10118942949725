import React, { useEffect, useState } from "react";
import { ContentStepper } from "./ContentStepper";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { LoadingAnimation } from "../../../shared/components/Loading";
import { EmptyState } from "../../../shared/components/EmptyState";
import { Button, Card, Form, Row, Col, Table } from "react-bootstrap";
import { ContentWorkflowService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { Pagination } from "../../../shared/paginations/Paginations";
import { EditCopywrite } from "./EditCopywrite";

export const ContentStep3 = () => {
  const { search } = useLocation();

  const [currentContent, setCurrentContent] = useState({});
  const [copywriterList, setCopywriterList] = useState([]);
  const [otherDataBar, setOtherDataBar] = useState({} as any);
  const [currentWorkflowStatus, setCurrentWorkflowStatus] = useState("");
  const [currentSourceLang, setCurrentSourceLang] = useState("");
  const [contentCopywrite, setContentCopywrite] = useState({} as any);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser.roles.includes("ROLE_COMPANY_ADMIN");
  const [copywriteId, setCopywriteId] = useState(0 as number);
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentPage = Number(
    +(search.split("=").at(-1) ?? 0) !== 0 ? search.split("=").at(-1) : 1
  );
  const [pagination, setPagination] = useState({
    page: currentPage,
    perPage: 10,
  });
  const [page, setPage] = useState(currentPage);
  const [totalPages, setTotalPages] = useState(0);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [updateCopywriteList, setUpdateCopywriteList] = useState(false);
  const handlePages = (updatePage: number) => {
    // navigate(`?page=${updatePage}`);
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (copywriteId: number) => {
    setCopywriteId(copywriteId);
    ContentWorkflowService.getCopywriteById(+id!, copywriteId, "COPY_WRITING")
      .then((res) => {
        setContentCopywrite(res);
      })
      .catch((err) => ErrorToast(err));
  };
  const checkAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues(copywriterList?.map((product: any) => product.id));
    } else {
      setCheckedValues([]);
    }
  };
  const checkForMainRoles = () => {
    if (isAdmin || isCompanyAdmin || isManager) {
      return true;
    } else {
      return false;
    }
  };
  const handlePerPage = (e: any) => {
    setPagination((prev) => ({ ...prev, page: 1, perPage: e.value }));
  };
  const changeFinishHandler = async (finishedOrUnfinished: boolean) => {
    id &&
      (await ContentWorkflowService.changeFinishedStatusCopyWrite(Number(id), {
        isDone: finishedOrUnfinished,
        contents: checkedValues,
      })
        .then((response) => {
          if (response) {
            setTriggerUpdate((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_updated_status"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };
  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues((prev) => [...prev, +e.target.value]);
    } else {
      setCheckedValues((prev) =>
        prev.filter((value) => value !== +e.target.value)
      );
    }
  };
  const changeFilterHandler = (name: string, value: string) => {
    if ((pagination as any)?.[name] === value) {
      setPagination((prev) => ({ ...prev, [name]: "", page: 1 }));
    } else {
      setPagination((prev) => ({ ...prev, [name]: value, page: 1 }));
    }
  };
  useEffect(() => {
    ContentWorkflowService.getAllCopywrite(pagination, +id!)
      .then((response) => {
        const { data, otherData, workflow }: any = response;
        setCurrentWorkflowStatus(workflow?.status);
        setCopywriterList(data as any);
        setOtherDataBar(otherData?.copywriting);
        setCurrentSourceLang(workflow?.sourceLang);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
        setPage(response?.page);
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, currentPage, triggerUpdate, updateCopywriteList]);
  return (
    <>
      <Card>
        <Card.Body>
          <ContentStepper
            changeFilterHandler={changeFilterHandler}
            step={"COPY_WRITING"}
            id={id}
            showNextButton={true}
            setCurrentContent={setCurrentContent}
            setCopywriteId={setCopywriteId}
            copywriteId={copywriteId}
            showMarkAsComplete={
              currentWorkflowStatus === "COPY_WRITING" &&
              (isAdmin || isCompanyAdmin || isManager)
            }
            isDisabledComplete={true}
            precent={otherDataBar?.percent}
            handlePerPage={handlePerPage}
          />
          <div className="card bg-light overflow-hidden shadow-none">
            <div className="card-body">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="mb-0">
                    {`${t("workflow.current")}`}:{" "}
                    <b className="text-success">
                      {otherDataBar?.percent ?? 0}%
                    </b>
                  </h6>
                </div>
                <div className="flex-shrink-0">
                  <h6 className="mb-0">
                    {otherDataBar?.done ?? 0}/{otherDataBar?.total ?? 0}{" "}
                    {`${t("workflow.finished")}`}
                  </h6>
                </div>
              </div>
            </div>
            <div className="progress">
              {/*//@ts-ignore*/}
              <div
                className="progress-bar bg-success"
                style={{ width: `${otherDataBar?.percent ?? 0}%` }}
                aria-valuenow={otherDataBar?.percent as number}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      {copywriteId !== 0 ? (
        <EditCopywrite
          step={"COPY_WRITING"}
          copywriteId={copywriteId}
          setCurrentContent={setCurrentContent}
          currentContent={currentContent}
          setCopywriteId={setCopywriteId}
          setUpdateCopywriteList={setUpdateCopywriteList}
          currentSourceLang={currentSourceLang}
          // contentCopywrite={contentCopywrite}
        />
      ) : (
        <div className="card">
          <div className="card-body">
            {copywriterList == undefined ? (
              <LoadingAnimation />
            ) : copywriterList?.length === 0 ? (
              <EmptyState />
            ) : (
              <div className="table-responsive">
                {checkForMainRoles() && (
                  <div className="d-flex justify-content-start align-items-center mb-1">
                    <button
                      onClick={() => changeFinishHandler(true)}
                      className="btn  btn-success d-flex align-items-center me-2"
                      disabled={
                        // translatorData?.user ||
                        !checkedValues?.length
                      }
                    >
                      <i className="ri-share-box-fill me-1" />
                      {t("global.finished")}
                    </button>
                    <button
                      onClick={() => changeFinishHandler(false)}
                      className="btn  btn-danger d-flex align-items-center me-2"
                      disabled={
                        // translatorData?.user ||
                        !checkedValues?.length
                      }
                    >
                      <i className="ri-share-box-fill me-1" />
                      {t("global.unfinished")}
                    </button>
                  </div>
                )}

                <Table className="align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      {checkForMainRoles() && (
                        <th scope="col">
                          <input
                            className="form-check-input m-0 cursor-pointer"
                            checked={
                              checkedValues.length === copywriterList?.length
                            }
                            type="checkbox"
                            onChange={checkAll}
                          />
                        </th>
                      )}

                      <th scope="col">{`${t("global.id")}`}</th>
                      <th scope="col">{`${t("global.title")}`}</th>
                      <th scope="col">{t("global.finished")}</th>
                      <th scope="col">{`${t("global.assign")}`}</th>
                      <th scope="col">{`${t("global.action")}`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {copywriterList?.map((copywrite: any) => {
                      return (
                        <tr>
                          {checkForMainRoles() && (
                            <td>
                              <input
                                type="checkbox"
                                className="form-check-input m-0 cursor-pointer"
                                onChange={(e) =>
                                  checkboxHandler && checkboxHandler(e)
                                }
                                value={copywrite?.id}
                                checked={checkedValues?.includes(
                                  Number(copywrite?.id)
                                )}
                              />
                            </td>
                          )}

                          <td>{copywrite.id}</td>
                          <td>{copywrite?.title ?? ""}</td>
                          <>
                            {copywrite?.otherData && (
                              <td>
                                <span
                                  className={`badge badge-outline-${
                                    copywrite?.otherData?.isCopyWritingDone
                                      ? "success"
                                      : "danger"
                                  } badge-soft-${
                                    copywrite?.otherData?.isCopyWritingDone
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {copywrite?.otherData?.isCopyWritingDone
                                    ? "Yes"
                                    : "No"}
                                </span>
                              </td>
                            )}
                          </>
                          <td>
                            {copywrite?.otherData?.copyWriter
                              ? copywrite?.otherData?.copyWriter?.firstName.concat(
                                  ` ${copywrite?.otherData?.copyWriter?.lastName}`
                                )
                              : "-"}
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-center">
                              {
                                // accessRights?.includes(
                                //   "brand_can_update"
                                // )
                                true && (
                                  <button
                                    className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                    onClick={() =>
                                      editHandler(copywrite?.id as number)
                                    }
                                    // onClick={() => editHandler(brand?.id as number)}
                                  >
                                    <i className="ri-edit-2-fill me-1" />
                                    {`${t("global.edit")}`}
                                  </button>
                                )
                              }
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-end ">
              {totalPages! <= 1 ? (
                ""
              ) : (
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
