import React, { ChangeEvent, useEffect, useState } from "react";
import { CategoryServicee } from "../../../store/category/categoryService";
import categorySlice from "../../../store/category/categoryRedux";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useDispatch, useSelector } from "react-redux";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { IAllStates } from "../../../store/rootReducer";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ListItemIcon, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { EmptyState } from "../../../shared/components/EmptyState";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ClientMappingService } from "../service";

export default function CreateMapping() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentClient, setCurrentClient] = useState<any | undefined>();
  const [arrayOfCategoryName, setArrayOfCategoryName] = useState<string[]>([]);
  const categoryList = useSelector((state: IAllStates) => state.category);
  const [arrayOfCategory, setArrayOfCategory] = useState<number[]>([]);
  const [idMainCategory, setIdMainCategory] = useState(0);
  const handleCategoryArray = (id: number, title: string) => {
    if (arrayOfCategory.includes(id)) {
      const removeItem = arrayOfCategory.filter((number) => number !== id);
      const removeItemName = arrayOfCategoryName.filter(
        (name: string) => name !== title
      );
      setArrayOfCategory(removeItem);
      setArrayOfCategoryName(removeItemName);
    } else {
      setArrayOfCategory([...arrayOfCategory, id]);
      setArrayOfCategoryName([...arrayOfCategoryName, title]);
    }
  };
  const handleAllCategoryArray = (ids: number[], titles: string[]) => {
    if (
      arrayOfCategory.length > ids?.length
        ? ids.some((element: number) => arrayOfCategory.includes(element))
        : arrayOfCategory.some((element: number) => ids.includes(element)) &&
          arrayOfCategory.length > 0
    ) {
      const removeItems = arrayOfCategory.filter((item) => !ids.includes(item));
      const removeItemNames = arrayOfCategoryName.filter(
        (item) => !titles.includes(item)
      );
      setArrayOfCategory(removeItems);
      setArrayOfCategoryName(removeItemNames);
    } else {
      setArrayOfCategory([...arrayOfCategory, ...ids]);
      setArrayOfCategoryName([...arrayOfCategoryName, ...titles]);
    }
  };
  const handleMainCategoryArray = (ids: any[], titles: any[]) => {
    let checkedIds = [] as number[];
    for (let i = 0; i < ids?.length; i++) {
      checkedIds = [...checkedIds, ...ids[i]];
    }
    let checkedTitles = [] as string[];
    for (let i = 0; i < titles?.length; i++) {
      checkedTitles = [...checkedTitles, ...titles[i]];
    }
    if (
      arrayOfCategory.length > checkedIds?.length
        ? checkedIds.some((element: number) =>
            arrayOfCategory.includes(element)
          )
        : arrayOfCategory.some((element: number) =>
            checkedIds.includes(element)
          ) && arrayOfCategory.length > 0
    ) {
      const removeItems = arrayOfCategory.filter(
        (item) => !checkedIds.includes(item)
      );
      const removeItemNames = arrayOfCategoryName.filter(
        (item) => !checkedTitles.includes(item)
      );
      setArrayOfCategory(removeItems);
      setArrayOfCategoryName(removeItemNames);
    } else {
      let selectedValue = [] as number[];
      for (let i = 0; i < ids?.length; i++) {
        selectedValue = [...selectedValue, ...ids[i]];
      }
      let selectedValueName = [] as string[];
      for (let i = 0; i < titles?.length; i++) {
        selectedValueName = [...selectedValueName, ...titles[i]];
      }
      setArrayOfCategory([...arrayOfCategory, ...selectedValue]);
      setArrayOfCategoryName([...arrayOfCategoryName, ...selectedValueName]);
    }

    // if (
    //   arrayOfCategory.length > ids?.length
    //     ? ids.some((element: number) => arrayOfCategory.includes(element))
    //     : arrayOfCategory.some((element: number) => ids.includes(element)) &&
    //       arrayOfCategory.length > 0
    // ) {
    //   const removeItems = arrayOfCategory.filter((item) => !ids.includes(item));
    //   const removeItemNames = arrayOfCategoryName.filter(
    //     (item) => !titles.includes(item)
    //   );
    //   setArrayOfCategory(removeItems);
    //   setArrayOfCategoryName(removeItemNames);
    // } else {
    //   setArrayOfCategory([...arrayOfCategory, ...ids]);
    //   setArrayOfCategoryName([...arrayOfCategoryName, ...titles]);
    // }
  };
  const handleNodeSelect = (event: any, nodeId: any) => {
    setIdMainCategory(nodeId);
  };
  useEffect(() => {
    CategoryServicee.getAllCategories()
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    ClientMappingService.createClientMapping({
      id: id,
      name: currentClient?.name,
      categories: arrayOfCategory,
    })
      .then((response) => {
        if (response?.id) {
          setCurrentClient({});
          navigate(-1);
          SuccessToast(`${t("toasts.successfully_created")} ${response.name}`);
        }
      })
      .catch((error) => ErrorToast(error));
  };
  function mainCatColor(array: any[]) {
    let test = [] as number[];
    for (let i = 0; i < array?.length; i++) {
      if (!!array[i]?.length) {
        test = [...test, ...array[i]];
      }
    }
    return test?.map((item: any) => item?.id);
  }
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setCurrentClient({
      ...currentClient,
      [name]: value,
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className={`col-md-6 my-3`}>
          <label htmlFor="name" className="required-field">
            {`${t("global.name")}`}
          </label>
          <input
            type="text"
            name="name"
            required
            value={currentClient?.name}
            onChange={handleChange}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="card" style={{ minHeight: "240px" }}>
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              onNodeSelect={handleNodeSelect}
            >
              {!!categoryList?.length ? (
                categoryList?.map((category1: any) => {
                  return (
                    <TreeItem
                      nodeId={category1?.id as unknown as string}
                      label={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body1">
                            {category1.title + ` (${category1?.total})` ?? ""}
                          </Typography>
                          <ListItemIcon>
                            <CheckIcon
                              style={{
                                display: `${
                                  !!category1?.children?.length
                                    ? category1?.children.map(
                                        (category3: any) =>
                                          !!category3?.children?.length
                                            ? "block"
                                            : "none"
                                      )
                                    : "none"
                                }`,
                                color: `${
                                  mainCatColor(
                                    category1?.children?.map((item: any) =>
                                      item?.children?.map((child: any) => child)
                                    )
                                  ).every((element: any) =>
                                    arrayOfCategory.includes(element)
                                  )
                                    ? "#1de60f"
                                    : "#757575"
                                }`,
                              }}
                              onClick={() =>
                                handleMainCategoryArray(
                                  category1?.children.map((category3: any) =>
                                    category3?.children.map(
                                      (category: any) => category?.id
                                    )
                                  ),
                                  category1?.children.map((category3: any) =>
                                    category3?.children.map(
                                      (category: any) => category?.title
                                    )
                                  )
                                )
                              }
                            />
                            {/* )} */}
                            {/* Replace with your desired icon */}
                          </ListItemIcon>
                        </div>
                      }
                    >
                      {!!category1?.children?.length && (
                        <>
                          {category1?.children?.map((category2: any) => {
                            return (
                              <TreeItem
                                nodeId={category2?.id as unknown as string}
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography variant="body1">
                                      {category2.title +
                                        ` (${category2?.total})` ?? ""}
                                    </Typography>
                                    <ListItemIcon>
                                      <CheckIcon
                                        style={{
                                          marginRight: "55px",
                                          display: `${
                                            !!category2?.children?.length
                                              ? "block"
                                              : "none"
                                          }`,
                                          color: `${
                                            category2?.children.every(
                                              (element: any) =>
                                                arrayOfCategory.includes(
                                                  element?.id
                                                )
                                            )
                                              ? "#1de60f"
                                              : "#757575"
                                          }`,
                                        }}
                                        onClick={() =>
                                          handleAllCategoryArray(
                                            category2?.children.map(
                                              (category3: any) => category3?.id
                                            ),
                                            category2?.children.map(
                                              (category3: any) =>
                                                category3?.title
                                            )
                                          )
                                        }
                                      />
                                      {/* )} */}
                                      {/* Replace with your desired icon */}
                                    </ListItemIcon>
                                  </div>
                                }
                              >
                                {!!category2?.children?.length &&
                                  category2?.children?.map((category3: any) => {
                                    return (
                                      <TreeItem
                                        nodeId={
                                          category3?.id as unknown as string
                                        }
                                        label={
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography variant="body1">
                                              {category3.title +
                                                ` (${category3?.total})` ?? ""}
                                            </Typography>
                                            <ListItemIcon>
                                              {/* {category3?.total === 0 && ( */}
                                              <CheckIcon
                                                style={{
                                                  marginRight: "80px",
                                                  color: `${
                                                    arrayOfCategory.includes(
                                                      category3?.id
                                                    )
                                                      ? "#1de60f"
                                                      : "#757575"
                                                  }`,
                                                }}
                                                onClick={() =>
                                                  handleCategoryArray(
                                                    category3?.id,
                                                    category3?.title
                                                  )
                                                }
                                              />
                                              {/* )} */}
                                              {/* Replace with your desired icon */}
                                            </ListItemIcon>
                                          </div>
                                        }
                                      ></TreeItem>
                                    );
                                  })}
                              </TreeItem>
                            );
                          })}
                        </>
                      )}
                    </TreeItem>
                  );
                })
              ) : (
                <TreeItem nodeId={"1"} label="Applications">
                  <EmptyState />
                </TreeItem>
              )}
            </TreeView>
          </div>
        </div>
        <div className="col-6">
          {" "}
          <textarea
            value={arrayOfCategoryName?.join("\n")}
            disabled={true}
            className="w-100"
            placeholder="Empty..."
            rows={12}
          ></textarea>
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-info me-3 d-flex align-items-center"
              onClick={() => navigate(-1)}
            >
              <i className="ri-arrow-left-line me-1" />
              {t("attributes.back")}
            </button>
            <button
              type="submit"
              className="btn btn-success d-flex align-items-center"
              // onClick={() => navigate(-1)}
            >
              {t("workflow.proceed")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
