import { createSlice } from '@reduxjs/toolkit';

const secondViewPaginationSlice = createSlice({
    name: 'secondViewPagination',
    initialState: {},
    reducers: {
        setSecondViewPagination: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});
export default secondViewPaginationSlice;