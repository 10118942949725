import { createSlice } from "@reduxjs/toolkit";

const changeLocationSlice = createSlice({
  name: "changeLocation",
  initialState: false,

  reducers: {
    setChangeLocation: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export default changeLocationSlice;
