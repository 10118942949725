import { requests } from "../../utils/helpers/api.services";
import { CATEGORY_URL } from "../../utils/helpers/api.routes";
import {
  ICategoryContent,
  ICategoryCreate,
  ICategoryCreateAttribute,
  ICategoryVariationList,
  ICategoryVariationParams,
} from "./values/dto/ICategory";

export const CategoryService = {
  deleteCategoryById: (id: number) => requests.delete(`${CATEGORY_URL}/${id}`),
  getCategoryById: (id: number) => requests.get(`${CATEGORY_URL}/${id}`),
  addCategory: (params: ICategoryCreate) =>
    requests.post(`${CATEGORY_URL}`, params),
  updateCategoryById: (params: ICategoryCreate) =>
    requests.put(`${CATEGORY_URL}/${(params as any)?.category?.id}`, params),
  updateCategoryContentById: (params: ICategoryContent) =>
    requests.put(
      `${CATEGORY_URL}/content/${(params as any)?.category?.id}`,
      params
    ),
  createCategoryAttributes: (params: ICategoryCreateAttribute) =>
    requests.post(`${CATEGORY_URL}/attribute`, params),
  getCategoryAttributes: (id: number) =>
    requests.get(`${CATEGORY_URL}/attribute/${id}`),
  getCategoryAttributesValue: (id: number) =>
    requests.get(`${CATEGORY_URL}/attribute/value/${id}`),
  createVariation: (params: ICategoryVariationParams) =>
    requests.post(`${CATEGORY_URL}/variation`, params),
  getAllVariations: (params: ICategoryVariationList) =>
    requests.get(`${CATEGORY_URL}/variation/list`, params),
  getVariation: (id: number) => requests.get(`${CATEGORY_URL}/variation/${id}`),
  updateVariation: (params: ICategoryVariationParams, id: number) =>
    requests.put(`${CATEGORY_URL}/variation/${id}`, params),
  deleteVariation: (id: number) =>
    requests.delete(`${CATEGORY_URL}/variation/${id}`),
  addNewVaraiton: (id: number) =>
    requests.put(`${CATEGORY_URL}/variation/chat-gpt/${id}`),
  addCloneVaraiton: (id: number) =>
    requests.put(`${CATEGORY_URL}/variation/clone-category-variation/${id}`),
  addNewAiVaraiton: (id: number) =>
    requests.put(`${CATEGORY_URL}/variation/generate-category-variation/${id}`),
  multiAssign: (params: any) =>
    requests.put(`${CATEGORY_URL}/multi-assign`, params),
  multiDelete: (params: any) =>
    requests.post(`${CATEGORY_URL}/multi-delete`, params),
};
