import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { PageTemplate } from "../../../../shared/layout/PageTemplate";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import { ContentView } from "../../../products/content/components/ContentView";
import { IProducts, IProductsList } from "../../../products/dto/IProducts";
import { ProductsService } from "../../../products/service";

export const ViewContentCrawled = () => {
  const [currentProduct, setCurrentProduct] = useState<IProducts>();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    id &&
      ProductsService.getViewProductsById(+id)
        .then((response) => {
          setCurrentProduct(response);
        })
        .catch((error) => ErrorToast(error));
  }, [id]);
  return (
    // <PageTemplate title={`${t("products.content_view")}`}>
    <>
      <div className="col-md-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="d-flex align-itmes-end">
            <ul className="nav nav-pills nav-fill">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={`/app/pending-products/crawled/view/base-data/${id}`}
                >
                  {t("products.base_data_view")}
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to={`/app/pending-products/crawled/view/attributes/${id}`}
                >
                  {t("products.attributes_view")}
                </Link>
              </li>
              <li>
                <span className="nav-link active">
                  {t("products.content_view")}
                </span>
              </li>
            </ul>
          </div>
          <>
            <ul className="nav nav-pills">
              <li className="nav-item">
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="nav-link active"
                  href={`${currentProduct?.crawlerUrl}`}
                >
                  {t("crawlers.url")}
                </a>
              </li>
            </ul>
          </>
        </div>
      </div>
      <ContentView currentProduct={currentProduct as IProductsList} />
    </>
    // </PageTemplate>
  );
};
