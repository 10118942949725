import { AttributesActions } from "./AttributesActions";
import React, { SetStateAction, useState } from "react";
import { IAttributes } from "../dto/IAttributes";
import { AttributesService } from "../../service";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export const AttributesUpdate = () => {
    const [currentAttribute, setCurrentAttribute] = useState<IAttributes>()
    const navigate = useNavigate();
    const handleSubmit = (event: React.FormEvent, id?: number) => {
        event.preventDefault();
        AttributesService.updateAttributeById(Number(id), currentAttribute as IAttributes).then(response => {
            if (response?.id) {
                SuccessToast(`${t("toasts.successfully_updated")} ${response?.title}`)
                navigate(-1);
            }
        }).catch(error => ErrorToast(error))
    }
    return (
        <AttributesActions currentAttribute={currentAttribute as IAttributes}
            setCurrentAttribute={setCurrentAttribute as React.Dispatch<SetStateAction<IAttributes>>}
            handleSubmit={handleSubmit} />
    )
}