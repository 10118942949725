import React, { useEffect, useState } from "react";
import { SingleValue } from "react-select";
import { ICompanyDetails } from "../dto/ICompanyDetails";
import { IAllStates } from "../../../store/rootReducer";
import { themeSelect } from "../../../shared/components/Select2";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CustomImageHandler } from "../../../shared/components/CustomImageHandler";

interface ICompanyDetailsFormProps {
  changeHandler: (
    event: React.ChangeEvent<HTMLInputElement>,
    isContact?: boolean
  ) => void;
  state: ICompanyDetails | undefined;
  fileRef: any;
  handleSelectCountry: (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => void;
  isCompanyAdmin: boolean;
}

export const CompanyDetailsForm = ({
  state,
  changeHandler,
  fileRef,
  handleSelectCountry,
  isCompanyAdmin,
}: ICompanyDetailsFormProps) => {
  const [rightForEdit, setRightForEdit] = useState(true);
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [imagePath, setImagePath] = useState<string>("");
  const { t } = useTranslation();
  const { country } = useSelector((state: IAllStates) => state.enums);

  function handleImageInput(evt: any) {
    if (evt.target.files.length > 0) {
      setImagePath(URL.createObjectURL(evt.target.files[0]));
    }
  }
  useEffect(() => {
    if (accessRights?.includes("company_details_can_edit")) {
      setRightForEdit(false);
    }
  }, [accessRights]);
  return (
    <div className="row">
      <div className="col-md-3">
        <div className="card">
          <div className="card-body">
            <div className="text-center">
              <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                {state?.logo?.path || imagePath ? (
                  <CustomImageHandler
                    path={
                      !!imagePath?.length ? imagePath : `${state?.logo?.path}`
                    }
                    classes="rounded-circle avatar-xl img-thumbnail user-profile-image"
                    alt="user-profile-image"
                  />
                ) : (
                  <img
                    src="/assets/images/img-placeholder.png"
                    className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                    alt="user-profile-image"
                  />
                )}
                {!rightForEdit && (
                  <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <input
                      id="profile-img-file-input"
                      type="file"
                      accept="image/*"
                      ref={fileRef}
                      className="profile-img-file-input"
                      onChange={handleImageInput}
                    />
                    <label
                      htmlFor="profile-img-file-input"
                      className="profile-photo-edit avatar-xs"
                    >
                      <span className="avatar-title rounded-circle bg-light text-body">
                        <i className="ri-camera-fill" />
                      </span>
                    </label>
                  </div>
                )}
              </div>

              <div>
                <h5 className="fs-16 mb-1">{state?.title}</h5>
                <p className="text-muted mb-0">{state?.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-9">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 my-2">
                <label htmlFor="title" className="required-field">
                  {t("companies.title")}
                </label>
                <input
                  disabled={rightForEdit}
                  type="text"
                  name="title"
                  required
                  value={state?.title}
                  placeholder={t("companies.title")}
                  onChange={changeHandler}
                  className="form-control"
                />
              </div>
              <div className="col-md-6 my-2">
                <label htmlFor="lastName" className="required-field">
                  {t("companies.email")}
                </label>
                <input
                  disabled={rightForEdit}
                  type="email"
                  name="email"
                  placeholder={t("companies.email")}
                  required
                  value={state?.email}
                  onChange={changeHandler}
                  className="form-control"
                />
              </div>
              <div className="col-md-3 my-2">
                <label htmlFor="phone">{t("companies.phone")}</label>
                <input
                  disabled={rightForEdit}
                  type="tel"
                  name="phone"
                  value={state?.contact?.phone}
                  placeholder={t("companies.phone")}
                  onChange={(e) => changeHandler(e, true)}
                  className="form-control"
                />
              </div>
              <div className="col-md-3 my-2">
                <label htmlFor="country">{t("companies.country")}</label>
                <Select
                  className="react-select-field"
                  isDisabled={rightForEdit}
                  theme={themeSelect}
                  options={country?.map((singleCountry: string) => ({
                    value: singleCountry,
                    label: getCorrectLanguage(singleCountry, t),
                  }))}
                  key={state?.id}
                  defaultValue={
                    state?.contact?.country && {
                      label: getCorrectLanguage(state?.contact?.country, t),
                      value: state?.contact?.country,
                    }
                  }
                  onChange={(e) => {
                    handleSelectCountry(
                      e as SingleValue<{ label: string; value: string }>
                    );
                  }}
                />
              </div>
              <div className="col-md-3 my-2">
                <label htmlFor="city">{t("companies.city")}</label>
                <input
                  disabled={rightForEdit}
                  type="text"
                  name="city"
                  value={state?.contact?.city}
                  placeholder={t("companies.city")}
                  onChange={(e) => changeHandler(e, true)}
                  className="form-control"
                />
              </div>
              <div className="col-md-3 my-2">
                <label htmlFor="zip">{t("companies.zip")}</label>
                <input
                  disabled={rightForEdit}
                  type="text"
                  name="zip"
                  value={state?.contact?.zip}
                  placeholder={t("companies.zip")}
                  onChange={(e) => changeHandler(e, true)}
                  className="form-control"
                />
              </div>
              <div className="col-md-3 my-2">
                <label htmlFor="address">{t("companies.address")}</label>
                <input
                  disabled={rightForEdit}
                  type="text"
                  name="address"
                  value={state?.contact?.address}
                  placeholder={t("companies.address")}
                  onChange={(e) => changeHandler(e, true)}
                  className="form-control"
                />
              </div>
              {!rightForEdit && (
                <div className="col-12 my-2 d-flex justify-content-end">
                  <button className="btn btn-primary ms-3" type="submit">
                    {t("global.update")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
