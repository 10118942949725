import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { SetStateAction } from "react";
import { IUser } from "../../../model/authentification/IRegister";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { getCorrectLanguage } from "../../../shared/functions/Functions";

interface IUserFieldsProps {
  handleChange: (e: any, isContact?: boolean) => void;
  visibilityPassword: boolean;
  setVisibility: React.Dispatch<SetStateAction<boolean>>;
  state: IUser;
  handleSelect2: (
    selectedSingle: SingleValue<{
      label: string;
      value: number | undefined | string;
    }>,
    inputKey: string
  ) => void;
}

export const UserFields = ({
  handleChange,
  visibilityPassword,
  setVisibility,
  state,
  handleSelect2,
}: IUserFieldsProps) => {
  const { t } = useTranslation();
  const { country } = useSelector((state: IAllStates) => state.enums);

  return (
    <div className="p-2 mt-4">
      <div className="mb-3">
        <label htmlFor="firstName" className="form-label">
          {t("register.firstName")}
        </label>
        <input
          name="firstName"
          onChange={handleChange}
          type="text"
          value={state?.firstName}
          className="form-control"
          id="firstName"
          placeholder={t("register.firstName")}
        />
      </div>

      <div className="mb-3">
        <label className="form-label" htmlFor="lastName">
          {t("register.lastName")}
        </label>
        <input
          value={state?.lastName}
          name="lastName"
          onChange={handleChange}
          type="text"
          className="form-control pe-5"
          placeholder={t("register.lastName")}
          id="lastName"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          {t("register.email")}
        </label>
        <input
          value={state?.email}
          name="email"
          onChange={handleChange}
          type="email"
          className="form-control"
          id="email"
          placeholder={t("register.email")}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="password-input">
          {t("register.password")}
        </label>
        <div className="position-relative auth-pass-inputgroup mb-3">
          <input
            value={state?.password}
            name="password"
            onChange={handleChange}
            type={`${visibilityPassword ? "text" : "password"}`}
            className="form-control pe-5"
            placeholder={t("register.password")}
            id="password-input"
          />
          <button
            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
            type="button"
            id="password-addon"
            onClick={() => setVisibility(!visibilityPassword)}
          >
            {visibilityPassword ? (
              <i className="ri-eye-fill align-middle" />
            ) : (
              <i className="ri-eye-off-fill" />
            )}
          </button>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="phone" className="form-label">
          {t("register.phone")}
        </label>
        <input
          value={state.contact?.phone}
          name="phone"
          onChange={(e) => handleChange(e, true)}
          type="tel"
          className="form-control"
          id="phone"
          placeholder={t("register.phone")}
        />
      </div>
      <div>
        <label htmlFor="country" className="form-label">
          {t("clients.country")}
        </label>
        <Select
          name="country"
          theme={themeSelect}
          className="mb-3 react-select-field"
          isClearable
          placeholder={`${t("clients.selectCountry")}`}
          options={country?.map((singleCountry) => ({
            value: singleCountry,
            label: getCorrectLanguage(singleCountry, t),
          }))}
          onChange={(e) => {
            handleSelect2(
              e as SingleValue<{ label: string; value: string }>,
              "country"
            );
          }}
        />
      </div>
      <div className="mb-3">
        <Row>
          <Col>
            <label htmlFor="city" className="form-label">
              {t("register.city")}
            </label>
            <input
              value={state.contact?.city}
              name="city"
              onChange={(e) => handleChange(e, true)}
              type="text"
              className="form-control"
              id="city"
              placeholder={t("register.city")}
            />
          </Col>
          <Col>
            <label htmlFor="zip" className="form-label">
              {t("register.zip")}
            </label>
            <input
              value={state.contact?.zip}
              name="zip"
              onChange={(e) => handleChange(e, true)}
              type="number"
              className="form-control"
              id="zip"
              placeholder={t("register.zip")}
            />
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <label htmlFor="address" className="form-label">
          {t("register.address")}
        </label>
        <input
          value={state.contact?.address}
          name="address"
          onChange={(e) => handleChange(e, true)}
          type="text"
          className="form-control"
          id="address"
          placeholder={t("register.address")}
        />
      </div>
    </div>
  );
};
