import { Card, Col, Pagination, Row } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import React, { SetStateAction, useEffect, useState } from "react";
import { IExport } from "../dto/IExports";
import { useTranslation } from "react-i18next";
import { themeSelect } from "../../../shared/components/Select2";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { LoadingAnimation } from "../../../shared/components/Loading";
import { EmptyState } from "../../../shared/components/EmptyState";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { ExportService } from "../service";
import { ProductsListView } from "../../products/components/ProductsListView";
import { ExportFormList } from "./ExportFormList";
interface IExportFormProps {
  setIsMenuOpened: React.Dispatch<SetStateAction<boolean>>;
  state: IExport | undefined;
  setState: React.Dispatch<SetStateAction<IExport | undefined>>;
  clientList: IExport[];
  changeClientHandler: (value: string) => void;
  accessRights: string[];
  exportId: number | null;
}

export const ExportForm = ({
  setIsMenuOpened,
  state,
  setState,
  changeClientHandler,
  clientList,
  accessRights,
  exportId,
}: IExportFormProps) => {
  const { t } = useTranslation();
  const CheckBoxArray = ["baseData", "attributes", "content"];
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
  const [exportProducts, setExportProducts] = useState<any[]>([]);

  function handleSelectSingle(selectedSingle: SingleValue<IExport | null>) {
    setState((prev: any) => ({
      ...prev,
      client: selectedSingle?.value,
    }));
  }

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const checkBoxHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string
  ) => {
    setState((prev: any) => ({
      ...prev,
      [item]: event.target.checked,
    }));
  };

  const changeFilterHandler = () => {};

  function handleSelectSingle2(selectedSingle: any) {
    setState((prev: any) => ({
      ...prev,
      language: selectedSingle?.map(
        (selectedSingle: { value: string }) => selectedSingle?.value
      ),
    }));
  }

  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }

  useEffect(() => {
    ExportService.getAllExportProducts({ page: 1, perPage: 0 }, +exportId!)
      .then((res) => setExportProducts(res.data))
      .catch((err) => console.log(err));
  }, [exportId]);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {`${t("global.name")}`}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={state?.name}
              required
              onChange={changeHandler}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="client" className="required-field">
              {`${t("export_client.client")}`}
            </label>
            <Select
              className="react-select-field"
              theme={themeSelect}
              defaultValue={{
                label: state?.client?.name,
                value: state?.client?.id,
              }}
              onInputChange={(e) => changeClientHandler(e)}
              key={state?.id}
              onChange={(e) => {
                handleSelectSingle(e);
              }}
              options={clientList as any}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="language" className="required-field">
              {`${t("export_client.language")}`}
            </label>
            <Select
              className="react-select-field"
              theme={themeSelect}
              key={state?.id}
              defaultValue={state?.language?.map((language: string) => ({
                value: language,
                label: getCorrectLanguage(language, t),
              }))}
              onChange={(e) => {
                handleSelectSingle2(e);
              }}
              isMulti={true}
              options={(translationLanguageEnum as any)?.map(
                (language: string) => ({
                  value: language,
                  label: getCorrectLanguage(language, t),
                })
              )}
            />
          </Col>
          <div className="col-md-4 my-2">
            <label htmlFor="roles">{t("export_client.productType")}</label>
            {CheckBoxArray.map((item) => (
              <div className="d-flex align-items-center mb-1">
                <input
                  className="form-check-input m-0 cursor-pointer"
                  defaultValue={state?.item as any}
                  checked={!!state?.[item as keyof IExport]}
                  type="checkbox"
                  onChange={(e) => checkBoxHandler(e, item)}
                />
                <label className="m-0 px-3">{t(`export_client.${item}`)}</label>
              </div>
            ))}
          </div>
        </Row>
      </Card.Body>
      <Card.Footer className="border-top-0">
        <div className="col-12  d-flex justify-content-end">
          <button
            className="btn btn-info"
            onClick={() => {
              setIsMenuOpened(false);
              setState({});
            }}
          >
            {t("global.cancel")}
          </button>
          <button className="btn btn-primary ms-3" type="submit">
            {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
          </button>
        </div>
      </Card.Footer>
    </Card>
  );
};
