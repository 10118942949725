import { createSlice } from '@reduxjs/toolkit';

const lightOrDarkModeSlice = createSlice({

    name: 'lightOrDarkMode',
    initialState: "",

    reducers: {
        setLightOrDarkMode: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export default lightOrDarkModeSlice;