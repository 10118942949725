import React, { ChangeEvent, useEffect, useState } from "react";
import { ProductsService } from "../../service";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import { Spinner } from "react-bootstrap";
import {
  formatDate,
  getCorrectLanguage,
} from "../../../../shared/functions/Functions";
import Select, { SingleValue } from "react-select";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import { themeSelect } from "../../../../shared/components/Select2";
import { useTranslation } from "react-i18next";
import { ChatGptPromptService } from "../../../chatGptPrompt/service";

interface IMessage {
  content: string;
  role: "assistant" | "user";
  sent?: Date;
}

export const ChatGptContent = ({ messages, setMessages }: any) => {
  const [userMessage, setUserMessage] = useState("");
  const [promptLang, setPromptLang] = useState("");
  const [promptsList, setPromptsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  // const [messages, setMessages] = useState<IMessage[]>([]);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setUserMessage(value);
  }
  function handleSelectSingle(selectedSingle: SingleValue<any | null>) {
    setPromptLang(selectedSingle?.value);
  }
  function handleSelectSingleTitle(selectedSingle: SingleValue<any | null>) {
    setUserMessage(selectedSingle?.value);
    // setPromptLang(selectedSingle?.value);
  }
  function sendMessage(event: any) {
    event.preventDefault();
    setIsLoading(true);
    setUserMessage("");
    setMessages((prev: any) =>
      prev.concat([{ content: userMessage, role: "user", sent: new Date() }])
    );
    ProductsService.chatGptMessages({
      messages: !messages?.length
        ? [
            {
              role: "user",
              content: userMessage as string,
            },
          ]
        : messages
            ?.map((message: any) => ({
              content: message.content,
              role: message.role,
            }))
            .concat([{ content: userMessage, role: "user" }]),
    })
      .then((response) => {
        const res = {
          ...response.message,
          sent: new Date(),
        };
        setMessages((prev: any) => prev.concat([res]));
      })
      .catch((error) => ErrorToast(error))
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    ChatGptPromptService.getAllPrompts({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        setPromptsList(response?.data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-5">
          <Select
            className="react-select-field custom_zindex"
            placeholder="Prompt language"
            theme={themeSelect}
            onChange={(e) => {
              handleSelectSingle(e as any);
            }}
            isClearable={true}
            options={(translationLanguageEnum as any)?.map(
              (language: string) => ({
                value: language,
                label: getCorrectLanguage(language, t),
              })
            )}
          />
        </div>
        {promptLang && (
          <div className="col-7">
            <Select
              isClearable={true}
              className="react-select-field custom_zindex"
              placeholder="Prompt title"
              theme={themeSelect}
              onChange={(e) => {
                handleSelectSingleTitle(e as any);
              }}
              options={(promptsList as any)
                ?.filter((prompt: any) => prompt?.lang === promptLang)
                ?.map((promptLang: any) => ({
                  value: promptLang?.text as string,
                  label: promptLang?.title as string,
                }))}
            />
          </div>
        )}
      </div>
      <div className="chat-input-section p-3 p-lg-4 d-flex flex-column justify-content-center align-items-end">
        <div className="chat w-100">
          {messages?.map((message: any, index: any) => {
            const isMy = message?.role === "user";
            return (
              <React.Fragment key={index}>
                <div
                  key={index}
                  className={`my-2 ${
                    isMy
                      ? "d-flex justify-content-end flex-column align-items-end"
                      : ""
                  }`}
                >
                  <span className="text-muted font-date">
                    {formatDate(
                      message?.sent
                        ? message?.sent?.toISOString()
                        : new Date().toISOString()
                    )}
                  </span>

                  <span
                    dangerouslySetInnerHTML={{
                      __html: message?.content ?? "-",
                    }}
                    className={isMy ? "my" : "notMy"}
                  >
                    {/* {message?.content ?? "-"} */}
                  </span>
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <form
          id="chatinput-form"
          className=" w-100"
          onSubmit={(e) => sendMessage(e)}
        >
          <div className="d-flex align-items-center">
            <div className="col">
              <div className="chat-input-feedback">Please Enter a Message</div>
              {/* <span className="form-control message chat-input bg-light border-light w-100" role="textbox"
                onChange={(e) => handleChange(e as any)}
                contentEditable
                id="chat-input"
              >
                {userMessage}
              </span> */}
              <textarea
                role="textbox"
                value={userMessage}
                onChange={(e) => handleChange(e as any)}
                disabled={isLoading}
                className="form-control message chat-input bg-light border-light"
                id="chat-input"
                placeholder="Type your message..."
              />
            </div>
            <div className="col-auto ms-1">
              <div className="chat-input-links ms-2">
                <div className="links-list-item">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn btn-primary"
                  >
                    {isLoading ? (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status"></div>
                      </div>
                    ) : (
                      <i className="ri-send-plane-2-fill" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
