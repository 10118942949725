import { createSlice } from "@reduxjs/toolkit";

const workflowSlice = createSlice({
  name: "workflowSlice",
  initialState: {},
  reducers: {
    setWorkflowData: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export default workflowSlice;

//napraviti redux slice za cuvanje id od izabranog producta u workflow
// kada god dodje user na listu taj id setovati na nulu
// obratiti paznju na next logiku
