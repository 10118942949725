import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { SetStateAction, useEffect, useState } from "react";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { Pagination } from "../../../shared/paginations/Paginations";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { EmptyState } from "../../../shared/components/EmptyState";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { IGlossary } from "../dto/IGlossary";
import { GlossaryService } from "../service";
import { LoadingAnimation } from "../../../shared/components/Loading";

interface IGlossaryListProps {
  accessRights: string[];
  glossaryList: any;
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  totalPages: number;
  page: number;
  handlePages: (page: number) => void;
  editHandler: (crawlerId: number) => void;
}

export const GlossaryList = ({
  accessRights,
  glossaryList,
  totalPages,
  page,
  handlePages,
  setTriggerUpdate,
  editHandler,
}: IGlossaryListProps) => {
  const { t } = useTranslation();
  const [selectedGlossary, setSelectedGlossary] = useState<IGlossary>();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const deleteHandler = () => {
    selectedGlossary?.id &&
      GlossaryService.deleteGlossary(selectedGlossary?.id)
        .then((response) => {
          if (response === "") {
            SuccessToast(
              `${t("toasts.successfully_deleted")} ${selectedGlossary?.word}`
            );
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  return (
    <>
      {/* <div className="card"> */}
      {/* <div className="card-header pb-0"> */}
      {!!glossaryList?.length &&
        <div className="d-flex justify-content-between ">
          <h4 className="card-title mg-b-0 mt-3">
            {`${t("glossary.list_of_glossary")}`}
          </h4>
        </div>
      }

      {/* </div> */}
      <div className="card-body">
        {glossaryList == undefined ? (
          <LoadingAnimation />
        ) : (
          (glossaryList?.length === 0) ? <EmptyState /> :
            <div className="table-responsive">
              <Table className="align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">{`${t("global.id")}`}</th>
                    <th scope="col">{`${t("global.language")}`}</th>
                    <th scope="col">{`${t("glossary.word")}`}</th>
                    <th scope="col">{`${t("glossary.alternative")}`}</th>
                    <th scope="col">{`${t("global.client")}`}</th>
                    {(accessRights?.includes("glossary_can_edit") ||
                      accessRights?.includes("glossary_can_delete")) && (
                        <th scope="col">{`${t("global.actions")}`}</th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {glossaryList?.map((glossary: any) => {
                    return (
                      <tr key={glossary?.id}>
                        <td>
                          <Link to="#" className="fw-medium">
                            #{glossary?.id}
                          </Link>
                        </td>
                        <td>{getCorrectLanguage(glossary?.language, t)}</td>
                        <td>{glossary?.word ?? "-"}</td>
                        <td>{glossary?.alternative ?? "-"}</td>
                        <td>{glossary?.client?.name ?? "-"}</td>
                        {(accessRights?.includes("glossary_can_edit") ||
                          accessRights?.includes("glossary_can_delete")) && (
                            <td>
                              <div className="d-flex align-items-center justify-content-center">
                                {accessRights?.includes("glossary_can_edit") && (
                                  <button
                                    className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                    onClick={() => editHandler(glossary?.id)}
                                  >
                                    <i className="ri-edit-2-fill me-1" />
                                    {`${t("global.edit")}`}
                                  </button>
                                )}
                                {accessRights?.includes("glossary_can_delete") && (
                                  <button
                                    className="btn btn-sm btn-danger d-flex align-items-center"
                                    onClick={() => {
                                      handleShow();
                                      setSelectedGlossary(glossary as any);
                                    }}
                                  >
                                    <i className="ri-delete-bin-fill me-1" />
                                    {`${t("global.delete")}`}
                                  </button>
                                )}
                              </div>
                            </td>
                          )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
        )}

        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedGlossary?.word}
          deleteHandler={deleteHandler}
          product={selectedGlossary}
          selectedProduct={selectedGlossary}
        />
      </div>
      {/* </div> */}
    </>
  );
};

