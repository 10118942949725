interface IRemoveImageButtonProps {
    className?: string;
    onClick?: () => void
}

export const RemoveImageButton = ({className, onClick}: IRemoveImageButtonProps) => {
    return (
        <button onClick={onClick}
                className={`filepond--file-action-button filepond--action-remove-item remove-image_button ${className}`}
                type="button" data-align="left"
        >
            <svg width="26" height="26" viewBox="0 0 26 26"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.586 13l-2.293 2.293a1 1 0 0 0 1.414 1.414L13 14.414l2.293 2.293a1 1 0 0 0 1.414-1.414L14.414 13l2.293-2.293a1 1 0 0 0-1.414-1.414L13 11.586l-2.293-2.293a1 1 0 0 0-1.414 1.414L11.586 13z"
                    fill="currentColor" fillRule="nonzero"></path>
            </svg>
        </button>
    )
}