import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Pagination } from "../../../shared/paginations/Paginations";
import { ICompaniesDTO } from "../dto/ICompanies";
import { useTranslation } from "react-i18next";
import React, { SetStateAction, useState } from "react";
import { ICompany } from "../../../model/authentification/IRegister";
import { CompanyService } from "../service";
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
} from "../../../shared/toasters/toasters";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { EmptyState } from "../../../shared/components/EmptyState";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { CustomImageHandler } from "../../../shared/components/CustomImageHandler";
import { LoadingAnimation } from "../../../shared/components/Loading";

interface ICompaniesListProps {
  companiesList: ICompaniesDTO[] | undefined;
  changeFilterHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  editHandler: (companyId: number) => void;
  page: number;
  totalPages: number;
  handlePages: (updateNumber: number) => void;
}

export const CompaniesList = ({
  companiesList,
  changeFilterHandler,
  setTriggerUpdate,
  editHandler,
  page,
  totalPages,
  handlePages,
}: ICompaniesListProps) => {
  const { t } = useTranslation();
  const [selectedCompany, setSelectedCompany] = useState<ICompany>();
  const [show, setShow] = useState(false);
  const isAdmin = useSelector((state: IAllStates) =>
    state?.auth?.roles?.some((role) => role === "ROLE_ADMIN")
  );
  const currentCompanyId = useSelector(
    (state: IAllStates) => state.auth?.company?.id
  );
  const handleShow = (company: ICompany) => {
    if (isAdmin && currentCompanyId === company?.id) {
      WarningToast(`You are not able to delete this company!`);
    } else {
      setSelectedCompany(company);
      setShow(true);
    }
  };

  const deleteHandler = () => {
    selectedCompany?.id &&
      CompanyService.deleteCompanyById(selectedCompany?.id)
        .then((response) => {
          if (response === "") {
            SuccessToast(
              `${t("toasts.successfully_deleted")} ${selectedCompany.title}`
            );
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">
            {`${t("companies.list_of_companies")}`}
          </h4>
        </div>
      </div>
      <div className="card-body">
        <div className="d-flex row justify-content-end">
          <div className="col-12 col-sm-6 col-md-4">
            <input
              className="form-control mb-3"
              name={"title"}
              onChange={changeFilterHandler}
              placeholder={`${t("companies.searchTitle")}...`}
              type="email"
              maxLength={30}
            />
          </div>
        </div>
        {companiesList == undefined ? (
          <LoadingAnimation />
        ) : (
          (companiesList?.length === 0) ? <EmptyState /> :
            <div className="table-responsive">
              <Table className="align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">{`${t("global.id")}`}</th>
                    <th scope="col">{`${t("companies.title")}`}</th>
                    <th scope="col">{`${t("companies.email")}`}</th>
                    <th scope="col">{`${t("global.action")}`}</th>
                  </tr>
                </thead>
                <tbody>
                  {companiesList?.map((company) => {
                    return (
                      <tr key={company?.id}>
                        <td>
                          <Link to="#" className="fw-medium">
                            #{company?.id}
                          </Link>
                        </td>
                        <td>
                          <td className="text-start">
                            <CustomImageHandler
                              classes="avatar-xs rounded-circle me-2"
                              path={company?.logo?.path ?? ""}
                              alt="User logo"
                            />
                            {company?.title}
                          </td>
                        </td>
                        <td>{company?.email}</td>
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="btn btn-sm btn-warning d-flex align-items-center"
                              onClick={() => editHandler(company?.id)}
                            >
                              <i className="ri-edit-2-fill me-1" />
                              {`${t("global.edit")}`}
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
        )}

        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedCompany?.title}
          deleteHandler={deleteHandler}
          selectedProduct={selectedCompany}
        />
      </div>
    </div>
  );
};

