import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { useTranslation } from "react-i18next";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { Button, Modal } from "react-bootstrap";
import { WorkflowService } from "../../workflow/service";
import { ContentStepper } from "./ContentStepper";
import { ContentWorkflowService } from "../service";

export const ContentStep6 = () => {
  const { id } = useParams();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { exportFormatEnum } = useSelector((state: IAllStates) => state.enums);
  const { exportView } = useSelector((state: IAllStates) => state.enums);
  const [formatChoice, setFormatChoice] = useState<string>("");
  const [viewChoice, setViewChoice] = useState<string>("");
  const arr1 = ["xlsx_v2"];
  const arr4 = arr1.concat(exportFormatEnum);
  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  const formatChangeHandler = (e: any) => {
    setFormatChoice(e.target.value);
  };

  const viewChangeHandler = (e: any) => {
    setViewChoice(e.target.value);
  };

  const submitHandler = () => {
    ContentWorkflowService.export(viewChoice, formatChoice, +id!)
      .then((res) => {
        window.open(res?.path);
      })
      .catch((err) => console.log(err));
  };

  const completeHandler = () => {
    ContentWorkflowService.workflowComplete(+id!)
      .then((res) => SuccessToast(t(`toasts.successfully_completed`)))
      .catch((err) => ErrorToast(err));
  };
  return (
    <>
      <Card>
        <Card.Body>
          <ContentStepper step={"EXPORT"} id={id}></ContentStepper>
          <div className="row ">
            <div className="col-6 ">
              <h5>{t("workflow.format")}:</h5>
              {exportFormatEnum?.map((format: string, idx: number) => (
                <div className="form-check">
                  <input
                    // disabled={format === "csv" || format === "docx"}
                    className="form-check-input"
                    type="radio"
                    name="format"
                    id={`flexRadioDefault${idx}`}
                    value={format}
                    onChange={formatChangeHandler}
                  />
                  <label className="form-check-label" htmlFor="format">
                    {format.toUpperCase()}
                  </label>
                </div>
              ))}
            </div>
            <div className="col-6">
              <h5>{t("workflow.view")}:</h5>
              {exportView?.map((view: string, idx: number) => (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="view"
                    value={view}
                    id={`flexRadioDefault${idx}`}
                    onChange={viewChangeHandler}
                  />
                  <label className="form-check-label" htmlFor="view">
                    {view.toUpperCase().replace("_", " ")}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex gap-3">
            <button
              className="btn btn-sm btn-secondary  d-flex align-items-center ms-auto "
              type="button"
              onClick={submitHandler}
              aria-expanded="false"
            >
              {`${t("global.export")}`}
            </button>{" "}
            <button
              className="btn btn-sm btn-secondary  d-flex align-items-center "
              type="button"
              onClick={handleOpen}
              aria-expanded="false"
            >
              {`${t("global.complete")}`}
            </button>
          </div>
          <Modal show={show} onHide={handleClose} size="sm" centered>
            <Modal.Body className="d-flex align-items-center flex-column">
              <h5>{t("modals.are_you_sure")}</h5>
              {/* <p
                    className="text-center text-muted modal-title-break"
                    dangerouslySetInnerHTML={{
                        __html: t(`modals.are_you_sure_you_want_to_delete`, {
                            itemName: title,
                        }),
                    }}
                /> */}
              {/* <p
                    className="text-center text-muted modal-title-break"
                    dangerouslySetInnerHTML={{
                        __html: t(`modals.are_you_sure_approve_selected`)
                    }}
                /> */}
              <p className="text-center text-muted">
                {t(`modals.are_you_sure_complete_workflow`)}
              </p>
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center">
              <Button variant="light" size="sm" onClick={handleClose}>
                {t(`modals.close`)}
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  handleClose();
                  completeHandler();
                }}
              >
                {t(`global.complete`)}
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      </Card>
    </>
  );
};
