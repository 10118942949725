import { Card, Col, Row } from "react-bootstrap";
import { SetStateAction } from "react";
import { IBrand } from "../dto/IBrand";
import { useTranslation } from "react-i18next";

interface IBrandFormProps {
  closeHandler: any;
  state: IBrand | undefined;
  setState: React.Dispatch<SetStateAction<IBrand | undefined>>;
}

export const BrandForm = ({
  state,
  setState,
  closeHandler,
}: IBrandFormProps) => {
  const { t } = useTranslation();

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {`${t("global.name")}`}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={state?.name}
              required
              onChange={changeHandler}
            />
          </Col>
          <div className="col-12 my-2 d-flex justify-content-end">
            <button
              className="btn btn-info"
              onClick={() => {
                closeHandler();
              }}
            >
              {`${t("global.cancel")}`}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
};
