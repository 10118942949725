import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { IClientsDTO } from "../dto/IClients";
import React, { SetStateAction, useEffect, useState } from "react";
import { ClientService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { Pagination } from "../../../shared/paginations/Paginations";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { EmptyState } from "../../../shared/components/EmptyState";
import { LoadingAnimation } from "../../../shared/components/Loading";

interface IClientsListProps {
  accessRights: string[];
  clientsList: IClientsDTO[] | undefined;
  changeFilterHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  editHandler: (clientId: number) => void;
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  page: number;
  totalPages: number;
  handlePages: (updateNumber: number) => void;
  setPagination: React.Dispatch<
    SetStateAction<{ page: number; perPage: number }>
  >;
}

export const ClientsList = ({
  accessRights,
  clientsList,
  changeFilterHandler,
  editHandler,
  page,
  totalPages,
  handlePages,
  setTriggerUpdate,
  setPagination,
}: IClientsListProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState<
    IClientsDTO | undefined
  >();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
  const navigateToMapping = (id: number) => {
    navigate(`/app/clients/mapping-list/${id}`);
  };
  const navigateToImportMapping = (id: number) => {
    navigate(`/app/clients/import-mapping/${id}`);
  };
  const deleteHandler = () => {
    selectedClient?.id &&
      ClientService.deleteClientById(selectedClient?.id)
        .then((response) => {
          if (response === "") {
            SuccessToast(
              `${t("toasts.successfully_deleted")} ${selectedClient.name}`
            );
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  function handleSelect2(
    selectedSingle: SingleValue<{
      label: string;
      value: number | undefined | string;
    }>,
    inputKey: string
  ) {
    setPagination((prev) => ({ ...prev, [inputKey]: selectedSingle?.value }));
  }

  const { country } = useSelector((state: IAllStates) => state.enums);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }
  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">
            {`${t("clients.listOfClients")}`}
          </h4>
        </div>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-end">
          <input
            className="form-control mb-3 me-1"
            name={"name"}
            onChange={changeFilterHandler}
            placeholder={`${t("clients.searchName")}...`}
            type="text"
            style={{ width: `${windowWidth?.width > 767 ? `25%` : `50%`}` }}
            maxLength={30}
          />
          <Select
            theme={themeSelect}
            className={`${
              windowWidth?.width > 767 ? `w-25` : `w-50`
            } ms-1 react-select-field`}
            isClearable
            placeholder={`${t("clients.country")}`}
            options={country?.map((singleCountry) => ({
              value: singleCountry,
              label: getCorrectLanguage(singleCountry, t),
            }))}
            onChange={(e) => {
              handleSelect2(
                e as SingleValue<{ label: string; value: string }>,
                "country"
              );
            }}
          />
        </div>
        {clientsList == undefined ? (
          <LoadingAnimation />
        ) : clientsList.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">{`${t("global.id")}`}</th>
                  <th scope="col">{`${t("global.name")}`}</th>
                  <th scope="col">{`${t("clients.company")}`}</th>
                  <th scope="col">{`${t("clients.country")}`}</th>
                  {(accessRights?.includes("client_can_edit") ||
                    accessRights?.includes("client_can_delete")) && (
                    <th scope="col">{`${t("global.actions")}`}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {clientsList?.map((client) => {
                  return (
                    <tr key={client?.id}>
                      <td>
                        <Link to="#" className="fw-medium">
                          #{client?.id}
                        </Link>
                      </td>
                      <td>{client?.name}</td>
                      <td>
                        {client?.company?.title ? client?.company?.title : "-"}
                      </td>
                      <td>
                        {client?.country
                          ? getCorrectLanguage(client?.country, t)
                          : "-"}
                      </td>
                      {(accessRights?.includes("client_can_edit") ||
                        accessRights?.includes("client_can_delete") ||
                        accessRights?.includes("client_can_mappings_edit")) && (
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            {accessRights?.includes(
                              "client_can_mappings_edit"
                            ) && (
                              <button
                                className="btn btn-sm btn-success d-flex align-items-center me-2"
                                // onClick={() => navigateToMapping(client?.id)}
                                onClick={() =>
                                  navigateToImportMapping(client?.id)
                                }
                              >
                                <i className="ri-upload-line me-1" />
                                {/* {`${t("global.edit")}`} */}
                                Import
                              </button>
                            )}
                            {accessRights?.includes(
                              "client_can_mappings_edit"
                            ) && (
                              <button
                                className="btn btn-sm btn-secondary d-flex align-items-center me-2"
                                onClick={() => navigateToMapping(client?.id)}
                              >
                                <i className="ri-magic-fill me-1" />
                                {/* {`${t("global.edit")}`} */}
                                Portfolio
                              </button>
                            )}

                            {accessRights?.includes("client_can_edit") && (
                              <button
                                className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                onClick={() => editHandler(client?.id)}
                              >
                                <i className="ri-edit-2-fill me-1" />
                                {`${t("global.edit")}`}
                              </button>
                            )}
                            {accessRights?.includes("client_can_delete") && (
                              <button
                                className="btn btn-sm btn-danger d-flex align-items-center"
                                onClick={() => {
                                  handleShow();
                                  setSelectedClient(client);
                                }}
                              >
                                <i className="ri-delete-bin-fill me-1" />
                                {`${t("global.delete")}`}
                              </button>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedClient?.name}
          deleteHandler={deleteHandler}
          selectedProduct={selectedClient}
        />
      </div>
    </div>
  );
};
