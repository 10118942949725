import { IAccessRights } from "../dto/IAccessRights";
import { Row, Table } from "react-bootstrap";
import { t } from "i18next";
import { Link, useNavigate } from "react-router-dom";
import { EmptyState } from "../../../shared/components/EmptyState";
import { Key, SetStateAction, useState } from "react";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AccessService } from "../service";
import { Pagination } from "../../../shared/paginations/Paginations";
import { LoadingAnimation } from "../../../shared/components/Loading";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { ICompaniesDTO } from "../../companies/dto/ICompanies";

interface IAccessRightsListProps {
  accessList: any;
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  page: number;
  totalPages: number;
  handlePages: (updateNumber: number) => void;
  setPagination: React.Dispatch<SetStateAction<any>>;
  companiesList: ICompaniesDTO[];
}

export const AccessRightsList = ({
  companiesList,
  accessList,
  setTriggerUpdate,
  page,
  totalPages,
  handlePages,
  setPagination,
}: IAccessRightsListProps) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [selectedRole, setSelectedRole] = useState({} as any);
  const editHandler = (role: number) => {
    navigate(`/admin/access-rights/${role}`);
  };
  const deleteHandler = () => {
    selectedRole?.id &&
      AccessService.deleteRightById(selectedRole?.id)
        .then((response) => {
          if (response === "") {
            SuccessToast(
              `${t("toasts.successfully_deleted")} ${selectedRole?.name}`
            );
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const handleSelectCompany = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    setPagination((prev: any) => ({ ...prev, company: selectedSingle?.value }));
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-4 ms-auto mb-1">
            <Select
              className="react-select-field"
              theme={themeSelect}
              isClearable={true}
              placeholder={"Company"}
              options={companiesList?.map((option) => ({
                label: option.title ?? "-",
                value: option.id,
              }))}
              onChange={(e) => {
                handleSelectCompany(e as any);
              }}
            />
          </div>
        </div>

        {accessList == undefined ? (
          <LoadingAnimation />
        ) : accessList.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">{`${t("access_rights.role_name")}`}</th>
                  <th scope="col">{`${t("global.company")}`}</th>
                  <th scope="col">{`${t("global.action")}`}</th>
                </tr>
              </thead>
              <tbody>
                {accessList?.map(
                  (roles: any, index: Key | null | undefined) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="flex-grow-1">
                            <h5 className="fs-14 mb-1">
                              <span
                                onClick={() => editHandler(roles?.id as number)}
                                className="text-dark cursor-pointer"
                              >
                                {roles?.name}
                              </span>
                            </h5>
                          </div>
                        </td>
                        <td>{roles?.company?.title ?? "-"}</td>
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="btn btn-sm btn-warning d-flex align-items-center me-2"
                              onClick={() => editHandler(roles?.id as number)}
                            >
                              <i className="ri-edit-2-fill me-1" />
                              {`${t("global.edit")}`}
                            </button>
                            <button
                              className="btn btn-sm btn-danger d-flex align-items-center"
                              onClick={() => {
                                setSelectedRole(roles);
                                handleShow();
                              }}
                            >
                              <i className="ri-delete-bin-fill me-1" />
                              {`${t("global.delete")}`}
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
          </div>
        )}
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      </div>
      <ConfirmDelete
        show={show}
        setShow={setShow}
        itemName={selectedRole?.name}
        deleteHandler={deleteHandler}
        selectedProduct={selectedRole}
      />
    </div>
  );
};
