import { Button, Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import trashIcon from "../icons/trashIcon.json";
import { useTranslation } from "react-i18next";

export const ConfirmDeleteColumn = ({
  itemName,
  show,
  setShow,
  deleteHandler,
  columnId,
}: any) => {
  const title = itemName?.replaceAll("_", "-");
  const handleClose = () => setShow(false);
  const { t } = useTranslation();
  const deleteHandleLogic = () => {
    deleteHandler(columnId);
  };
  return (
    <Modal show={show} onHide={handleClose} size="sm" centered>
      <Modal.Body className="d-flex align-items-center flex-column">
        <Lottie animationData={trashIcon} loop={true} style={{ width: 100 }} />
        <h5>{t("modals.are_you_sure")}</h5>
        <p
          className="text-center text-muted modal-title-break"
          dangerouslySetInnerHTML={{
            __html: t(`modals.are_you_sure_you_want_to_delete`, {
              itemName: title,
            }),
          }}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-center">
        <Button variant="light" size="sm" onClick={handleClose}>
          {t(`modals.close`)}
        </Button>
        <Button
          variant="danger"
          size="sm"
          onClick={() => {
            handleClose();
            deleteHandleLogic();
          }}
        >
          {t(`modals.delete`)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
