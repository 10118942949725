import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IBlock } from "../dto/IBlock";
import { BlockService } from "../../block/service";
import React, { SetStateAction, useState } from "react";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { Pagination } from "../../../shared/paginations/Paginations";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { EmptyState } from "../../../shared/components/EmptyState";
import { formatDate } from "../../../shared/functions/Functions";
import { LoadingAnimation } from "../../../shared/components/Loading";

interface IClientsListProps {
  accessRights: string[];
  blocksList: IBlock[] | undefined;
  changeFilterHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  editHandler: (clientId: number) => void;
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  page: number;
  totalPages: number;
  handlePages: (updateNumber: number) => void;
  setPagination: React.Dispatch<
    SetStateAction<{ page: number; perPage: number }>
  >;
}
export const BlockList = ({
  accessRights,
  blocksList,
  changeFilterHandler,
  editHandler,
  page,
  totalPages,
  handlePages,
  setTriggerUpdate,
  setPagination,
}: IClientsListProps) => {
  const { t } = useTranslation();
  const [selectedBlock, setSelectedBlock] = useState<IBlock | undefined>();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const deleteHandler = () => {
    selectedBlock?.id &&
      BlockService.deleteBlockById(selectedBlock?.id)
        .then((response) => {
          if (response === "") {
            SuccessToast(
              `${t("toasts.successfully_deleted")} ${selectedBlock.name}`
            );
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div className="d-flex justify-content-between">
            <h4 className="card-title mg-b-0">
              {`${t("block.listOfBlocks")}`}
            </h4>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <div className="col-12 col-sm-6 col-md-4">
              <input
                className="form-control mb-3"
                name={"name"}
                onChange={changeFilterHandler}
                placeholder={`${t("clients.searchName")}...`}
                type="text"
                maxLength={30}
              />
            </div>
          </div>
          {blocksList == undefined ? (
            <LoadingAnimation />
          ) : (
            (blocksList.length === 0) ? <EmptyState /> :
              <div className="table-responsive">
                <Table className="align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">{`${t("global.id")}`}</th>
                      <th scope="col">{`${t("global.name")}`}</th>
                      <th scope="col">{`${t("global.created_at")}`}</th>
                      {(accessRights?.includes("block_can_update") ||
                        accessRights?.includes("block_can_delete")) && (
                          <th scope="col">{`${t("global.actions")}`}</th>
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {blocksList?.map((block) => {
                      return (
                        <tr key={block?.id}>
                          <td>
                            <Link to="#" className="fw-medium">
                              #{block?.id}
                            </Link>
                          </td>
                          <td>{block?.name}</td>
                          <td>{formatDate(block?.createdAt)}</td>
                          {(accessRights?.includes("block_can_update") ||
                            accessRights?.includes(
                              "block_can_delete"
                            )) && (
                              <td>
                                <div className="d-flex align-items-center justify-content-center">
                                  {accessRights?.includes(
                                    "block_can_update"
                                  ) && (
                                      <button
                                        className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                        onClick={() => editHandler(block?.id as number)}
                                      >
                                        <i className="ri-edit-2-fill me-1" />
                                        {`${t("global.edit")}`}
                                      </button>
                                    )}
                                  {accessRights?.includes(
                                    "block_can_delete"
                                  ) && (
                                      <button
                                        className="btn btn-sm btn-danger d-flex align-items-center"
                                        onClick={() => {
                                          handleShow();
                                          setSelectedBlock(block);
                                        }}
                                      >
                                        <i className="ri-delete-bin-fill me-1" />
                                        {`${t("global.delete")}`}
                                      </button>
                                    )}
                                </div>
                              </td>
                            )}
                          {/* <td>
                            <div className="d-flex align-items-center justify-content-center">
                              <button
                                className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                onClick={() => editHandler(block?.id as number)}
                              >
                                <i className="ri-edit-2-fill me-1" />
                                {`${t("global.edit")}`}
                              </button>
                              <button
                                className="btn btn-sm btn-danger d-flex align-items-center"
                                onClick={() => {
                                  handleShow();
                                  setSelectedBlock(block);
                                }}
                              >
                                <i className="ri-delete-bin-fill me-1" />
                                {`${t("global.delete")}`}
                              </button>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
          )}

          <div className="d-flex justify-content-end ">
            {totalPages <= 1 ? (
              ""
            ) : (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
          <ConfirmDelete
            show={show}
            setShow={setShow}
            itemName={selectedBlock?.name}
            deleteHandler={deleteHandler}
            selectedProduct={setSelectedBlock}
          />
        </div>
      </div>
    </>
  );
};

