import { PageTemplate } from "../../../shared/layout/PageTemplate";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { ProductsService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { ProductsCardView } from "../components/ProductsCardView";
import Col from "react-bootstrap/Col";
import { ProductsFilters } from "../components/ProductsFilters";
import { ProductsForm } from "../components/ProductsForm";
import { IProductsList } from "../dto/IProducts";
import { Pagination } from "../../../shared/paginations/Paginations";
import _debounce from "lodash/debounce";
import { SingleValue } from "react-select";
import { ProductsListView } from "../components/ProductsListView";
import { Table } from "react-bootstrap";
import { EmptyState } from "../../../shared/components/EmptyState";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { useLocation, useNavigate } from "react-router";
import { BrandService } from "../../brand/service";
import { IBrand } from "../../brand/dto/IBrand";
import { CategoryService } from "../../category/service";
import { IAttributes } from "../../attributes/names/dto/IAttributes";
import categorySlice from "../../../store/category/categoryRedux";
import { CategoryServicee } from "../../../store/category/categoryService";
import { LoadingAnimation } from "../../../shared/components/Loading";
import { ConfirmExportAll } from "../../../shared/components/ConfirmExportAll";
import { ExportService } from "../../exports/service";
import Select from "react-select";
import { IExport } from "../../exports/dto/IExports";
import expOrAttPaginationSlice from "../../../store/category/expOrAttPagination";
import secondViewPagination from "../../../store/category/secondViewPagination";

interface IPagination {
  page: number;
  attributeValues: any;
  listOfSpecificAtt: any;
  brandsDefaultValue: any;
  listOfBrands: any;
}
const perPageOptions = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
  {
    label: 500,
    value: 500,
  },
];

export const ProductsContent = () => {
  const toForLink = "/app/products/edit-content/";
  const dispatch = useDispatch();
  dispatch(expOrAttPaginationSlice.actions.setExpOrAttPagination({}));
  const [isListView, setIsListView] = useState(true);
  const { t } = useTranslation();
  const productsTable = true;
  const { search } = useLocation();
  const [categoryFilterId, setCategoryFilterId] = useState(null);
  const [addedWayFilter, setAddedWayFilter] = useState("");
  const [termFilter, setTermFilter] = useState("");
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportsList, setExportsList] = useState<IExport[]>([]);
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const secondViewPagination = useSelector(
    (state: IAllStates) => state.secondViewPagination as any
  );
  const [exportData, setExportData] = useState<{
    products: number[];
    clientExport: number | null;
  }>({ products: [], clientExport: null });
  const mainTable = true;
  const location = useLocation();
  const currentPage =
    (location.state as unknown as IPagination)?.page ??
    // secondViewPagination?.pagination?.page ??
    Number(search.split("=").at(-1) ? search.split("=").at(-1) : 1);
  const [pagination, setPagination] = useState({
    ...((location.state as unknown as IPagination) ??
      secondViewPagination?.pagination),
    // page: currentPage,
    perPage: 10,
  });
  const tab = "CONTENT";
  const nameOfTable = "content";
  const [page, setPage] = useState(currentPage);
  const [totalPages, setTotalPages] = useState(0);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [brandsList, setBrandsList] = useState<IBrand[]>([]);
  const [brandId, setBrandId] = useState(null);
  const [listOfBrands, setListOfBrands] = useState();
  const [productsList, setProductsList] = useState<
    IProductsList[] | undefined
  >();

  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const handlePages = (updatePage: number) => {
    navigate(`?page=${updatePage}`);
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const categories = useSelector((state: IAllStates) => state.category);
  const navigate = useNavigate();
  const [listAttribute, setListAttribute] = useState<IAttributes[]>([]);

  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      navigate(location.pathname, {
        state: {
          ...pagination,
          [name]: value,
        },
      });
      setPagination((prev: any) => ({
        ...prev,
        page: 1,
        [name]: value,
      }));
    },
    200
  );
  const handlePerPage = (e: any) => {
    setPagination((prev) => ({ ...prev, page: 1, perPage: e.value }));
  };

  const exportProduct = () => {
    ExportService.exportProducts({
      clientExport: exportData.clientExport!,
      products: checkedValues,
    });
  };

  const handleSelectMainCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value as number) {
      setCategoryFilterId(selectedSingle?.value as any);
      showListAttribute(selectedSingle?.value as any);
      setPagination((prev: any) => ({
        ...prev,
        page: 1,
        category: selectedSingle?.value as number,
        categoryData: {
          label: selectedSingle?.label,
          value: selectedSingle?.value,
        },
      }));
      navigate(location.pathname, {
        state: {
          ...pagination,
          category: selectedSingle?.value as number,
          categoryData: selectedSingle,
        },
      });
    } else {
      setCategoryFilterId(null);
      setPagination((prev: any) => ({
        ...prev,
        category: null,
        categoryData: null,
      }));
    }
  };
  const checkAll = (e: any) => {
    if (e.target.checked) {
      const mapped = productsList?.map((product) => product.id);
      setCheckedValues(mapped as number[]);
    } else {
      setCheckedValues([]);
    }
  };

  const selectExportHandler = (e: any) => {
    setExportData((prev) => ({ ...prev, clientExport: +e.value }));
  };
  const showListAttribute = (categoryId: any) => {
    CategoryService.getCategoryAttributesValue(categoryId)
      .then((response) => {
        setListAttribute(response);
        setPagination((prev: any) => ({
          ...prev,
          listOfSpecificAtt: response,
        }));
      })
      .catch((error) => ErrorToast(error));
  };
  const handleSelectStatus = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    if (selectedSingle?.value as string) {
      setAddedWayFilter(selectedSingle?.value as string);
      setPagination((prev: any) => ({
        ...prev,
        page: 1,
        addedWay: selectedSingle?.value as string,
      }));
      navigate(location.pathname, {
        state: {
          ...pagination,
          addedWay: selectedSingle?.value as string,
        },
      });
    } else {
      setAddedWayFilter("");
      setPagination((prev: any) => ({
        ...prev,
        addedWay: "",
      }));
    }
  };
  const handleSelectBrand = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value as number) {
      setBrandId(selectedSingle?.value as any);
      setPagination((prev: any) => ({
        ...prev,
        page: 1,
        brand: selectedSingle?.value as number,
        brandData: selectedSingle,
      }));
      navigate(location.pathname, {
        state: {
          ...pagination,
          brand: selectedSingle?.value as number,
        },
      });
    } else {
      setBrandId(null);
      setPagination((prev: any) => ({
        ...prev,
        brand: null,
      }));
    }
  };
  const checkboxHandler = (e: any) => {
    if (e.target.checked) {
      setCheckedValues((prev) => [...prev, +e.target.value]);
    } else {
      setCheckedValues((prev) =>
        prev.filter((value) => value !== +e.target.value)
      );
    }
  };

  const deleteHandler = (product: IProductsList) => {
    product?.id &&
      ProductsService.deleteProductById(product?.id)
        .then((response) => {
          setTriggerUpdate((prev) => !prev);
          SuccessToast(`${t("toasts.successfully_deleted")} ${product?.title}`);
        })
        .catch((error) => ErrorToast(error));
  };

  const viewClickHandler = (id: number) => {
    navigate(`/app/products/view/content/${id}`);
  };

  // const editClickHandler = (id: number) => {
  //   navigate(`/app/products/edit-content/${id}`, {
  //     state: { pagination: pagination },
  //   });
  //   // navigate(`/app/products/edit-content/${id}`);
  // };
  const editClickHandler = (e: any, id: number) => {
    if (e.type === "click") {
      navigate(`/app/products/edit-content/${id}`, {
        state: { pagination: pagination },
      });
    } else if (e.type === "contextmenu") {
      window.open(`/app/products/edit-content/${id}`, "_blank");
    }
  };
  const handleChangeCategory = (name: string, value: string) => {
    setPagination((prev) => ({ ...prev, page: 1, [name]: value }));
  };
  useEffect(() => {
    ProductsService.getAllProducts(
      {
        perPage: pagination.perPage,
        attributeValues: pagination?.attributeValues,
        category: (pagination as any)?.category,
        page: pagination?.page,
        addedWay: (pagination as any)?.addedWay,
        eanCode: (pagination as any)?.eanCode,
        title: (pagination as any)?.title,
        brands: (pagination as any)?.brands,
        id: (pagination as any)?.id,
      } as any,
      tab
    )
      .then((response) => {
        const { data } = response;
        setProductsList(data);
        setPage(response?.page);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, triggerUpdate, currentPage]);
  // useEffect(() => {
  //   if (currentPage) {
  //     setPagination((prev) => ({ ...prev, page: currentPage }));
  //     setPage(currentPage);
  //   }
  // }, [currentPage]);
  // useEffect(() => {
  //   BrandService.getAllBrands({
  //     page: 1,
  //     perPage: 0,
  //   })
  //     .then((response) => {
  //       const { data } = response;
  //       setBrandsList(data);
  //     })
  //     .catch((error) => ErrorToast(error));
  // }, []);
  useEffect(() => {
    BrandService.getAllBrands({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        if (pagination?.listOfBrands === undefined) {
          const { data } = response;
          setBrandsList(data);
          setListOfBrands(data as any);
          // setPagination((prev: any) => ({
          //   ...prev,
          //   listOfBrands: data,
          // }));
        }
        // else {
        //   const { brandsDefaultValue, listOfBrands } = pagination;

        //   const filteredArr = listOfBrands.filter((brand: any) =>
        //     !brandsDefaultValue.some((defValue: any) => defValue.value === brand.id));
        //   // setBrandsList(data);
        //   setPagination((prev: any) => ({
        //     ...prev,
        //     listOfBrands: filteredArr,
        //   }));
        // }
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    CategoryServicee.getAllCategories()
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    ExportService.getAllExports({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setExportsList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    if (location.state) {
      navigate(location.pathname, { state: {} });
    }
  }, [location?.pathname]);

  return (
    // <PageTemplate title={`${t("side_bar.products_content")}`}>
    <Col xs={12}>
      {accessRights?.includes("product_can_create") && <ProductsForm />}
      <ProductsFilters
        listWithoutCat={true}
        productsTable={productsTable}
        listAttribute={listAttribute}
        setTriggerUpdate={setTriggerUpdate}
        mainTable={mainTable}
        handleSelectStatus={handleSelectStatus}
        changeFilterHandler={changeFilterHandler}
        handleSelectMainCategory={handleSelectMainCategory}
        categories={categories}
        brandsList={listOfBrands ?? brandsList}
        handleSelectBrand={handleSelectBrand}
        handleChangeCategory={handleChangeCategory}
        categoryFilterId={categoryFilterId as any}
        setPagination={setPagination}
        pagination={pagination}
      />
      <div className="d-flex w-100 align-items-center justify-content-end mb-3">
        <button
          className={`btn btn${!isListView ? "-soft" : ""}-info  btn-sm`}
          onClick={() => setIsListView(!isListView)}
        >
          <i className="ri-list-check " />
        </button>
        <button
          className={`btn btn${isListView ? "-soft" : ""}-info btn-sm ms-2`}
          onClick={() => setIsListView(!isListView)}
        >
          <i className="ri-bank-card-line" />
        </button>
      </div>
      {!isListView ? (
        <div className="row gy-4">
          {!!productsList?.length ? (
            productsList?.map((product) => (
              <div className="col-sm-6 col-xl-3" key={product?.id}>
                <ProductsCardView
                  nameOfTable={nameOfTable}
                  key={product?.id}
                  product={product}
                  deleteHandler={deleteHandler}
                  onEdit={editClickHandler}
                  onView={viewClickHandler}
                />
              </div>
            ))
          ) : (
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <EmptyState />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="card">
          <div className="card-body">
            {productsList == undefined ? (
              <LoadingAnimation />
            ) : productsList.length === 0 ? (
              <EmptyState />
            ) : (
              <div className="table-responsive">
                <div className="d-flex align-items-center gap-2 mb-3">
                  <h6 className="m-0 p-0 ">Export:</h6>
                  <Select
                    className="react-select-field"
                    onChange={selectExportHandler}
                    options={exportsList.map((exportItem) => ({
                      label: exportItem.name,
                      value: exportItem.id,
                    }))}
                  />
                  <button
                    className="btn  btn-secondary d-flex align-items-center me-2"
                    onClick={() => setShowExportModal(true)}
                    disabled={checkedValues?.length === 0}
                  >
                    <i className="ri-share-box-fill me-1" />
                    {`${t("global.export")}`}
                  </button>
                  <h6 className="m-0 p-0 ">{t("workflow.per_page")}:</h6>
                  <Select
                    className="react-select-field"
                    onChange={handlePerPage}
                    placeholder={`${t("workflow.per_page")}:`}
                    options={perPageOptions}
                    defaultValue={perPageOptions[0]}
                  />
                </div>
                <Table className="align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">
                        <input
                          className="form-check-input m-0 cursor-pointer"
                          // value={maxId}
                          checked={
                            checkedValues.length === productsList?.length
                          }
                          type="checkbox"
                          onChange={checkAll}
                        />
                      </th>
                      <th scope="col">{`${t("global.id")}`}</th>
                      <th scope="col">{`${t("global.aenCode")}`}</th>
                      <th scope="col">{`${t("products.product")}`}</th>
                      <th scope="col">{`${t("products.category")}`}</th>
                      <th scope="col">{`${t("products.createdAt")}`}</th>
                      <th scope="col">{`${t("global.actions")}`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productsList?.map((product) => (
                      <ProductsListView
                        toForLink={toForLink}
                        nameOfTable={nameOfTable}
                        key={product?.id}
                        deleteHandler={deleteHandler}
                        onEdit={editClickHandler}
                        onView={viewClickHandler}
                        product={product}
                        checkedValues={checkedValues}
                        handleCheck={checkboxHandler}
                      />
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex align-items-center justify-content-start mt-3">
                  {/* <button
                    disabled={disabledBtn}
                    className="btn btn-sm btn-danger d-flex align-items-center me-2"
                    onClick={() => setShowDeleteModal(true)}
                    disabled={checkedValues?.length === 0}
                  > */}
                  {/* <i className="ri-delete-bin-fill me-1" />
                    {`${t("global.delete")}`}
                  </button> */}
                  <ConfirmExportAll
                    show={showExportModal}
                    handleApprove={exportProduct}
                    setShow={setShowExportModal}
                    // itemName={selectedProduct?.title}
                    // deleteHandler={deleteHandler}
                    // selectedProduct={selectedProduct}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-end ">
            {totalPages <= 1 ? (
              ""
            ) : (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
        </div>
      </div>
    </Col>
    // </PageTemplate>
  );
};
