// @ts-nocheck
import { useState, useEffect } from "react";
import React from "react";
// import { Editor } from "@tinymce/tinymce-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// eslint-disable-next-line @typescript-eslint/no-redeclare
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "../../../utils/translations/i18next";
import { IAllStates } from "../../../store/rootReducer";
import _debounce from "lodash/debounce";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { useNavigate, useParams } from "react-router-dom";
import { ContentService } from "../service";
import { ContentWorkflowService } from "../../contentWorkflow/service";
import "../../../ckeditor.css";

interface IContent {
  triggerContentUpdate: any;
}
interface ICounts {
  [nameField: string]: {
    words: number;
    characters: number;
  };
}
interface IEditorCounter {
  [key: string]: ICounts;
}
const editorConfigurationCustom = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "findAndReplace",
      "alignment",
      "heading",
      "textPartLanguage",
      "|",
      "bold",
      "italic",
      "underline",
      "link",
      "|",
      "horizontalLine",
      "fontColor",
      "fontBackgroundColor",
      "fontSize",
      "highlight",
      "selectAll",
      "pageBreak",
      "strikethrough",
      "specialCharacters",
      "restrictedEditingException",
      "numberedList",
      "bulletedList",
      "indent",
      "outdent",
      "removeFormat",
      "subscript",
      "superscript",
      "HtmlEmbed",
      "sourceEditing",
      "insertTable",
      "|",
      "glossary-scan",
      // "glossary-ignore",
      "glossary-change",
    ],
    shouldNotGroupWhenFull: true, // optional, to prevent grouping when the toolbar is full
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
      "toggleTableCaption",
    ],
  },
  removePlugins: ["Title", "Markdown"], // remove the title plugin from the editor
};

export const DisabledContentEditor = ({
  triggerContentUpdate,
  copywriteId,
  defaultTabDisabled,
}: IContent | any) => {
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const isContentStepTranslation =
    window.location.pathname?.includes("translation");
  const { id } = useParams();
  const [currentContent, setCurrentContent] = useState({});
  const { t } = useTranslation();
  const currentLanguage = i18n?.language;

  const [activeTab, setActiveTab] = useState(defaultTabDisabled);
  const changeActiveTab = (tab: string) => {
    setActiveTab(tab);
  };
  const [wordCount, setWordCount] = useState<IEditorCounter>();
  const updateWordsCound = _debounce(
    ({ wordsCount, charactersCount, language }, nameField) => {
      setWordCount((prev) => ({
        ...prev,
        [language]: {
          ...prev?.[language],
          [nameField]: {
            ...prev?.[language]?.[nameField],
            words: wordsCount,
            characters: charactersCount,
          },
        },
      }));
    },
    500
  );
  useEffect(() => {
    if (id && isContentStepTranslation) {
      ContentWorkflowService.getTranslationById(+id!, copywriteId)
        .then((res) => {
          setCurrentContent(res);
        })
        .catch((err) => ErrorToast(err));
    } else {
      ContentService.getContent(Number(id))
        .then((response) => {
          setCurrentContent(response);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id, triggerContentUpdate]);
  return (
    <>
      <ul
        className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
        role="tablist"
      >
        {translationLanguageEnum?.map((language) => {
          return (
            <li
              className="nav-item"
              key={language}
              onClick={() => changeActiveTab(language)}
            >
              <a
                className={`nav-link ${activeTab === language ? "active" : ""}`}
                data-bs-toggle="tab"
                href={`#${language}`}
                role="tab"
                aria-selected={language === currentLanguage}
              >
                {t(`products.content_${language}`)}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="tab-content">
        {translationLanguageEnum?.map((language) => {
          return (
            <>
              <div
                className={`tab-pane  ${
                  activeTab === language ? "active" : ""
                }`}
                id={language}
                role="tabpanel"
              >
                <div className="row">
                  <div className="col-md-12 my-2">
                    <label className="mt-3 required-field" htmlFor="content">
                      {t("global.title")}
                    </label>
                    <div className="d-flex  align-items-center justify-content-between">
                      <div className="col-md-6">
                        <input
                          disabled={true}
                          type="text"
                          name="title"
                          className="form-control"
                          value={
                            currentContent?.translation?.[language]?.title ?? ""
                          }
                          required
                          //   onChange={(e) =>
                          //     changeVariationTranslationHandler(e, language)
                          //   }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <label className="m-0 required-field" htmlFor="content">
                        {t("products.content")}
                      </label>
                    </div>
                    <CKEditor
                      config={editorConfigurationCustom}
                      editor={Editor}
                      disabled={true}
                      data={currentContent?.translation?.[language]?.text ?? ""}
                      onReady={(editor) => {
                        editor.ui.view.element.setAttribute(
                          "id",
                          String(Math.random().toString(16).slice(2))
                        );
                        const wordsCount =
                          editor.plugins.get("WordCount").words;
                        const charactersCount =
                          editor.plugins.get("WordCount").characters;
                        setWordCount((prev) => ({
                          ...prev,
                          [language]: {
                            ...prev?.[language],
                            text: {
                              ...prev?.[language]?.["text"],
                              words: wordsCount,
                              characters: charactersCount,
                            },
                          },
                        }));
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        // changeHandlerEditor(data, language as string, "text");
                        const wordsCount =
                          editor.plugins.get("WordCount").words;
                        const charactersCount =
                          editor.plugins.get("WordCount").characters;
                        updateWordsCound(
                          {
                            wordsCount,
                            charactersCount,
                            language,
                          },
                          "text"
                        );
                      }}
                    />
                    <div className="d-flex justify-content-start text-align-center mt-3">
                      <div className="ck ck-word-count">
                        <div className="ck-word-count__words">
                          Words: {wordCount?.[language]?.["text"]?.words}
                        </div>
                        <div className="ck-word-count__characters">
                          Characters:{" "}
                          {wordCount?.[language]?.["text"]?.characters}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
