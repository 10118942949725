import { createSlice } from '@reduxjs/toolkit';

const initialVersionSlice: IAppVersion = {
    openRefreshModal: false,
};

export interface IAppVersion {
    openRefreshModal: boolean;
}

export const appVersionSlice = createSlice({
    name: 'loadingSlice',
    initialState: initialVersionSlice,
    reducers: {
        setReloadModal: (state, action) => {
            state.openRefreshModal = action.payload;
            return state;
        },
    },
});