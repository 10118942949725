import { Button, Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import approveAll from "../icons/approveAll.json";
import { useTranslation } from "react-i18next";

export const ConfirmApproveAll = ({
    // itemName,
    show,
    setShow,
    handleApprove,
    // selectedProduct,
}: any) => {
    // const title = itemName?.replaceAll("_", "-");
    const handleClose = () => setShow(false);
    const { t } = useTranslation();
    return (
        <Modal show={show} onHide={handleClose} size="sm" centered>
            <Modal.Body className="d-flex align-items-center flex-column">
                <Lottie animationData={approveAll} loop={true} style={{ width: 100 }} />
                <h5>{t("modals.are_you_sure")}</h5>
                {/* <p
                    className="text-center text-muted modal-title-break"
                    dangerouslySetInnerHTML={{
                        __html: t(`modals.are_you_sure_you_want_to_delete`, {
                            itemName: title,
                        }),
                    }}
                /> */}
                {/* <p
                    className="text-center text-muted modal-title-break"
                    dangerouslySetInnerHTML={{
                        __html: t(`modals.are_you_sure_approve_selected`)
                    }}
                /> */}
                <p className="text-center text-muted">{t(`modals.are_you_sure_approve_selected`)}</p>
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center">
                <Button variant="light" size="sm" onClick={handleClose}>
                    {t(`modals.close`)}
                </Button>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                        handleClose();
                        handleApprove();
                    }}
                >
                    {t(`modals.approve`)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
