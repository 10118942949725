import React, { useEffect, useRef, useState } from "react";
import { PageTemplate } from "../../shared/layout/PageTemplate";
import { useTranslation } from "react-i18next";
import { ICompanyDetails } from "./dto/ICompanyDetails";
import { CompanyDetailsService } from "./service";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { CompanyDetailsForm } from "./components/CompanyDetailsForm";
import { SingleValue } from "react-select";
import { multipartFormData } from "../../utils/helpers/api.services";
import { COMPANY_URL } from "../../utils/helpers/api.routes";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";

export const CompanyDetails = () => {
  const fileRef = useRef<any>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useSelector((state: IAllStates) => state.auth);

  const [currentCompanyDetails, setCurrentCompanyDetails] =
    useState<ICompanyDetails>();

  const submitHandler = (event: any) => {
    event.preventDefault();
    multipartFormData(
      fileRef?.current?.files?.[0],
      `${COMPANY_URL}/admin-update-company`,
      JSON.stringify(currentCompanyDetails),
      "put",
      "logo"
    )
      .then((response) => {
        SuccessToast(
          `${t("toasts.successfully_updated")} ${response?.data.title}`
        );
        navigate("/app/dashboard");
      })
      .catch((error) => ErrorToast(error));
  };

  function handleSelectCountry(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setCurrentCompanyDetails((prev: any) => ({
      ...prev,
      contact: {
        ...prev.contact,
        country: selectedSingle?.value,
      },
    }));
  }

  const changeCompanyHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    isContact = false
  ) => {
    const { value, name } = event.target;
    if (isContact) {
      setCurrentCompanyDetails((prev: any) => ({
        ...prev,
        contact: {
          ...prev.contact,
          [name]: value,
        },
      }));
    } else {
      setCurrentCompanyDetails((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const isCompanyAdmin = JSON.parse(
    localStorage.getItem("currentUser") as string
  )?.roles?.some(
    (role: string) => role === "ROLE_COMPANY_ADMIN" || role === "ROLE_ADMIN"
  );

  useEffect(() => {
    CompanyDetailsService.getMyCompanyDetails()
      .then((response) => {
        setCurrentCompanyDetails(response as unknown as ICompanyDetails);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <div className="col-12">
      <Form onSubmit={submitHandler}>
        <CompanyDetailsForm
          state={currentCompanyDetails}
          changeHandler={changeCompanyHandler}
          isCompanyAdmin={isCompanyAdmin}
          handleSelectCountry={handleSelectCountry}
          fileRef={fileRef}
        />
      </Form>
    </div>
  );
};
