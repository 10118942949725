import { Header } from "./Header";
import { SideBar } from "./SideBar";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Confirm505Err } from "../components/Confirm505Err";

interface iPageTemplate {
    title?: string;
    children?: React.ReactElement;
    linkBaseData?: boolean;
    linkAttributes?: boolean;
    linkContent?: boolean;
    link1?: string;
    link2?: string;
    productTitle?: string;
}

export function PageTemplate({
    children,
    title,
    linkBaseData,
    link1,
    link2,
    linkContent,
    linkAttributes,
    productTitle,
}: iPageTemplate) {
    const { t } = useTranslation();
    const body = document.querySelector("body");
    const { id } = useParams();
    const [productsTitle, setProductsTtitle] = useState(false);

    const handleTitle = () => {
        if (
            linkBaseData === true ||
            linkAttributes === true ||
            linkContent === true
        ) {
            setProductsTtitle(true);
        }
    };
    const closeMenu = () => {
        body!.classList.remove("vertical-sidebar-enable");
    };

    useEffect(() => {
        handleTitle();
    }, []);

    return (
        <>
            <Confirm505Err />
            <div className="layout-wrapper">
                <Header />
                <SideBar />
                <div className="vertical-overlay" onClick={closeMenu}></div>

                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    {!productsTitle ? (
                                        <>
                                            <div
                                                className="page-title-box d-sm-flex align-items-center justify-content-between">
                                                <h4 className="mb-sm-0">{title}</h4>
                                                <div className="page-title-right">
                                                    <ol className="breadcrumb m-0">
                                                        <li className="breadcrumb-item">
                                                            <a>{t("side_bar.app")}</a>
                                                        </li>
                                                        <li className="breadcrumb-item active">
                                                            {title?.toUpperCase()}
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {linkBaseData && (
                                                <>
                                                    <div
                                                        className="page-title-box d-sm-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-end">
                                                            <ul className="nav nav-pills nav-fill">
                                                                <li className="nav-item">
                                                                    <span
                                                                        className="nav-link  active">{title}</span>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <Link
                                                                        className="nav-link"
                                                                        to={`/app/products/attributes/${id}`}
                                                                    >
                                                                        {link1}
                                                                    </Link>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <Link
                                                                        className="nav-link"
                                                                        to={`/app/products/content/${id}`}
                                                                    >
                                                                        {link2}
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div>
                                                            <span className="fw-bold">{productTitle}</span>
                                                        </div>
                                                        <div className="page-title-right">
                                                            <ol className="breadcrumb m-0">
                                                                <li className="breadcrumb-item">
                                                                    <a>{t("side_bar.app")}</a>
                                                                </li>
                                                                <li className="breadcrumb-item active">
                                                                    {title?.toUpperCase()}
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {linkAttributes && (
                                                <>
                                                    <div
                                                        className="page-title-box d-sm-flex align-items-center justify-content-between">
                                                        <ul className="nav nav-pills nav-fill">
                                                            <li className="nav-item">
                                                                <Link
                                                                    className="nav-link"
                                                                    to={`/app/products/base-data/${id}`}
                                                                >
                                                                    {link1}
                                                                </Link>
                                                            </li>
                                                            <li className="nav-item">
                                                                <span className="nav-link active">{title}</span>
                                                            </li>
                                                            <li className="nav-item">
                                                                <Link
                                                                    className="nav-link"
                                                                    to={`/app/products/content/${id}`}
                                                                >
                                                                    {link2}
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <span className="fw-bold">{productTitle}</span>
                                                        </div>
                                                        <div className="page-title-right">
                                                            <ol className="breadcrumb m-0">
                                                                <li className="breadcrumb-item">
                                                                    <a>{t("side_bar.app")}</a>
                                                                </li>
                                                                <li className="breadcrumb-item active">
                                                                    {title?.toUpperCase()}
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {linkContent && (
                                                <>
                                                    <div
                                                        className="page-title-box d-sm-flex align-items-center justify-content-between">
                                                        <ul className="nav nav-pills nav-fill">
                                                            <li className="nav-item">
                                                                <Link
                                                                    className="nav-link"
                                                                    to={`/app/products/base-data/${id}`}
                                                                >
                                                                    {link1}
                                                                </Link>
                                                            </li>
                                                            <li className="nav-item">
                                                                <Link
                                                                    className="nav-link"
                                                                    to={`/app/products/attributes/${id}`}
                                                                >
                                                                    {link2}
                                                                </Link>
                                                            </li>
                                                            <li className="nav-item">
                                                                <span className="nav-link active">{title}</span>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <span className="fw-bold">{productTitle}</span>
                                                        </div>
                                                        <div className="page-title-right">
                                                            <ol className="breadcrumb m-0">
                                                                <li className="breadcrumb-item">
                                                                    <a>{t("side_bar.app")}</a>
                                                                </li>
                                                                <li className="breadcrumb-item active">
                                                                    {title?.toUpperCase()}
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">{children}</div>
                    </div>
                </div>
            </div>
        </>


    );
}
