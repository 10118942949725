import * as React from "react";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import { EmptyState } from "../../../../shared/components/EmptyState";
import { Table } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { LoadingAnimation } from "../../../../shared/components/Loading";
import { useEffect, useState } from "react";
import { ActualFileObject, FilePondFile } from "filepond";
import { FilePond } from "react-filepond";
import { IMPORT_URL } from "../../../../utils/helpers/api.routes";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../../shared/components/Select2";
import {
  getCorrectFormality,
  getCorrectLanguage,
} from "../../../../shared/functions/Functions";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ListItemIcon from "@mui/material/ListItemIcon";
import { CategoryService } from "../../service";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import categorySlice from "../../../../store/category/categoryRedux";
import CategoryAssignModal from "./CategoryAssignModal";
import { Button, Modal } from "react-bootstrap";
import { ConfirmDelete } from "../../../../shared/components/ConfirmDelete";
import { margin } from "@mui/system";
import { ProductsService } from "../../../products/service";
import { CategoryServicee } from "../../../../store/category/categoryService";

// const valueList = [
//   { id: 1, name: "lorem" },
//   { id: 2, name: "bojan" },
//   { id: 3, name: "test" },
//   { id: 4, name: "profa" },
// ];

export default function CategoryAssign() {
  const newCategories: any[] = [];
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleShow = (itemId: number, itemName: string) => {
    setShow(true);
    setEditCatId(itemId);
    setEditCatName(itemName);
  };
  const [arrayOfCategory, setArrayOfCategory] = useState<number[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [triggerListCategory, setTriggerListCategory] =
    useState<boolean>(false);
  const categoryList = useSelector((state: IAllStates) => state.category);
  const [sourceLanguage, setSourceLanguage] = useState(
    localStorage.getItem("language")
  );
  const [sourceModalLanguage, setSourceModalLanguage] = useState("");
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const [showForm, setShowForm] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<any>();
  const [newCatName, setNewCatName] = useState("");
  const [editCatId, setEditCatId] = useState(0);
  const [editCatName, setEditCatName] = useState("");
  const [files, setFiles] = useState<ActualFileObject[]>([]);
  const [categoriesTextArea, setCategoriesTextArea] = useState("");
  const [idMainCategory, setIdMainCategory] = useState(0);
  const [valueList, setValueList] = useState<any[]>([]);
  const token = localStorage.getItem("token");
  const [showResp, setShowResp] = useState(false);
  const [responseBack, setResponseBack] = useState({} as any);
  const handleClose = () => setShowEditModal(false);
  const [formality, setFormality] = useState(null);
  const [filterParams, setFilterParams] = useState({
    title: "",
  });
  const handleNodeSelect = (event: any, nodeId: any) => {
    setIdMainCategory(nodeId);
  };
  function handleSelectSingle1(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setSourceLanguage(selectedSingle?.value as string);
  }

  const handleTextArea = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCategoriesTextArea(value as any);
  };
  const addCatTextArea = () => {
    const chekNumberOfList = valueList.length;
    const stringArray = (categoriesTextArea as unknown as string).split("\n");
    stringArray.map((item: string, index: number) => {
      if (item != "") {
        if (chekNumberOfList === 0) {
          valueList.push({ id: index + 1, name: item });
        } else {
          valueList.push({ id: chekNumberOfList + index + 1, name: item });
        }
      }
    });
    setTriggerListCategory((prev: boolean) => !prev);
    setCategoriesTextArea("");
  };
  const removeItemNewCategory = (testId: number) => {
    setValueList(valueList.filter((item: any) => item.id !== testId));
    setTriggerListCategory((prev: boolean) => !prev);
  };
  const ListComponent = React.useCallback(() => {
    return (
      <NewListCategory
        valueList={valueList}
        removeItemNewCategory={removeItemNewCategory}
        t={t}
        showEditItem={showEditItem}
      />
    );
  }, [triggerListCategory]);

  const deleteCategoryHandler = (categoryId: number, categoryTitle: string) => {
    CategoryService.deleteCategoryById(categoryId)
      .then((response) => {
        // setTriggerUpdate((prev) => !prev);
        dispatch(categorySlice.actions.setCategory(response));
        SuccessToast(`${t("toasts.successfully_deleted")} ${categoryTitle}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const showEditItem = (itemId: number, itemName: string) => {
    setShowEditModal(true);
    setEditCatId(itemId);
    setNewCatName(itemName);
  };
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }
  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setSourceModalLanguage(selectedSingle?.value as string);
  }
  const changeTranslationHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => {
    const { name, value } = event.target;
    setCurrentCategory((prev: any) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [language]: {
          ...prev?.translation?.[language],
          [name]: value,
        },
      },
      parent: 0,
    }));
  };
  const handleEditItem = () => {
    const newState = valueList.map((obj) => {
      // :point_down: if id equals 2, update country property
      if (obj.id === editCatId) {
        return { ...obj, name: newCatName };
      }
      // :point_down: otherwise return the object as is
      return obj;
    });
    setValueList(newState);
    setTriggerListCategory((prev: boolean) => !prev);
  };
  const handleTranslate = () => {
    const langArr = translationLanguageEnum?.filter(
      (lang) => lang !== sourceModalLanguage
    );
    for (let i = 0; i < langArr?.length; i++) {
      ProductsService.postTranslation({
        text: currentCategory?.translation?.[sourceModalLanguage]?.title,
        sourceLanguage: sourceModalLanguage,
        targetLanguage: langArr[i],
        formality: formality as any,
      })
        .then((response) => {
          const result = response;
          setCurrentCategory((prev: any) => ({
            ...prev,
            translation: {
              ...prev?.translation,
              [langArr[i]]: {
                ...prev?.translation?.[langArr[i]],
                title: result,
              },
            },
            parent: 0,
          }));
          if (i === langArr?.length - 1) {
            SuccessToast(`${t("toasts.successfully_translated")}`);
          }
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const handleSubmit = () => {
    setIsLoading(true);
    valueList.map((item) => newCategories.push(item.name));
    CategoryService.multiAssign({
      lang: sourceLanguage,
      assignTo: idMainCategory,
      newCategories: newCategories,
    })
      .then((response) => {
        SuccessToast("successfully_created");
        dispatch(categorySlice.actions.setCategory(response));
        setValueList([]);
        setTriggerListCategory((prev: boolean) => !prev);
      })
      .catch((error) => ErrorToast(error))
      .finally(() => setIsLoading(false));
  };
  const getCategoryById = (categoryId: number) => {
    CategoryService.getCategoryById(categoryId)
      .then((response) => {
        setCurrentCategory(response);
      })
      .catch((error) => ErrorToast(error));
  };
  const handleCategoryArray = (id: number) => {
    if (arrayOfCategory.includes(id)) {
      const removeItem = arrayOfCategory.filter((number) => number !== id);
      setArrayOfCategory(removeItem);
    } else {
      setArrayOfCategory([...arrayOfCategory, id]);
      // arrayOfCategory.push(id);
    }
  };
  const multiDelete = () => {
    CategoryService.multiDelete({
      categories: arrayOfCategory,
    })
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_deleted")}`);
        dispatch(categorySlice.actions.setCategory(response));
        setArrayOfCategory([]);
      })
      .catch((error) => ErrorToast(error));
  };
  const updateCategoryById = () => {
    currentCategory &&
      CategoryService.updateCategoryById({
        ...currentCategory,
        title: currentCategory?.title
          ? currentCategory?.title
          : (currentCategory as any)?.category?.title,
      })
        .then((response) => {
          setShowForm(false);
          //@ts-ignore
          SuccessToast(`${t("toasts.successfully_updated")}`);
          dispatch(categorySlice.actions.setCategory(response));
          setCurrentCategory({});
          // setTriggerUpdate((prev) => !prev);
        })
        .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    CategoryServicee.getAllCategories(filterParams as any)
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, [filterParams]);
  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex justify-content-between">
                <h4 className="card-title mg-b-0">
                  {/* {`${t("block.listOfBlocks")}`} */}
                  Select one category
                </h4>
              </div>
            </div>
            <div className="card-body">
              <div className="text-end mb-2">
                <button
                  disabled={arrayOfCategory?.length === 0}
                  className="btn btn-danger btn-sm"
                  onClick={() => multiDelete()}
                >
                  Delete
                </button>
              </div>
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeSelect={handleNodeSelect}
              >
                {!!categoryList?.length ? (
                  categoryList?.map((category1: any) => {
                    return (
                      <TreeItem
                        nodeId={category1?.id as unknown as string}
                        label={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body1">
                              {category1.title + ` (${category1?.total})` ?? ""}
                            </Typography>
                            <ListItemIcon>
                              <EditIcon
                                style={{
                                  marginRight: "10px",
                                }}
                                onClick={() => {
                                  setShowForm(true);
                                  getCategoryById(category1?.id);
                                }}
                              />
                              {category1?.total === 0 && (
                                <DeleteIcon
                                  style={{
                                    color: `${
                                      arrayOfCategory.includes(category1?.id)
                                        ? "#E60F0F"
                                        : "#757575"
                                    }`,
                                  }}
                                  onClick={() =>
                                    handleCategoryArray(category1?.id)
                                  }
                                />
                              )}
                            </ListItemIcon>
                          </div>
                        }
                      >
                        {!!category1?.children?.length && (
                          <>
                            {category1?.children?.map((category2: any) => {
                              return (
                                <TreeItem
                                  nodeId={category2?.id as unknown as string}
                                  label={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography variant="body1">
                                        {category2.title +
                                          ` (${category2?.total})` ?? ""}
                                      </Typography>
                                      <ListItemIcon>
                                        <EditIcon
                                          style={{
                                            marginRight: "10px",
                                          }}
                                          onClick={() => {
                                            setShowForm(true);
                                            getCategoryById(category2?.id);
                                          }}
                                        />
                                        {category2?.total === 0 && (
                                          <DeleteIcon
                                            style={{
                                              color: `${
                                                arrayOfCategory.includes(
                                                  category2?.id
                                                )
                                                  ? "#E60F0F"
                                                  : "#757575"
                                              }`,
                                            }}
                                            onClick={() =>
                                              handleCategoryArray(category2?.id)
                                            }
                                          />
                                        )}
                                        {/* Replace with your desired icon */}
                                      </ListItemIcon>
                                    </div>
                                  }
                                >
                                  {!!category2?.children?.length &&
                                    category2?.children?.map(
                                      (category3: any) => {
                                        return (
                                          <TreeItem
                                            nodeId={
                                              category3?.id as unknown as string
                                            }
                                            label={
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <Typography variant="body1">
                                                  {category3.title +
                                                    ` (${category3?.total})` ??
                                                    ""}
                                                </Typography>
                                                <ListItemIcon>
                                                  <EditIcon
                                                    style={{
                                                      marginRight: "10px",
                                                    }}
                                                    onClick={() => {
                                                      setShowForm(true);
                                                      getCategoryById(
                                                        category3?.id
                                                      );
                                                    }}
                                                  />
                                                  {category3?.total === 0 && (
                                                    <DeleteIcon
                                                      style={{
                                                        color: `${
                                                          arrayOfCategory.includes(
                                                            category3?.id
                                                          )
                                                            ? "#E60F0F"
                                                            : "#757575"
                                                        }`,
                                                      }}
                                                      onClick={() =>
                                                        handleCategoryArray(
                                                          category3?.id
                                                        )
                                                      }
                                                    />
                                                  )}
                                                  {/* Replace with your desired icon */}
                                                </ListItemIcon>
                                              </div>
                                            }
                                          ></TreeItem>
                                        );
                                      }
                                    )}
                                </TreeItem>
                              );
                            })}
                          </>
                        )}
                      </TreeItem>
                    );
                  })
                ) : (
                  <TreeItem nodeId={"1"} label="Applications">
                    <EmptyState />
                  </TreeItem>
                )}
              </TreeView>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <div className="col-4 mb-2">
                <label htmlFor="lang">Language</label>
                <Select
                  className="w-translate react-select-field"
                  name="content"
                  theme={themeSelect}
                  defaultValue={{
                    label: getCorrectLanguage(sourceLanguage as string, t),
                    value: sourceLanguage,
                  }}
                  options={translationLanguageEnum?.map((lang) => ({
                    value: lang,
                    label: getCorrectLanguage(lang, t),
                  }))}
                  onChange={(e) => {
                    handleSelectSingle1(e as any);
                  }}
                />
              </div>
              <div className="col-12">
                <label htmlFor="value">New categories</label>
                <textarea
                  value={categoriesTextArea}
                  onChange={handleTextArea as any}
                  className="w-100"
                  placeholder="Type text."
                  rows={10}
                ></textarea>
                <div className="my-2 d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => addCatTextArea()}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="col-12">
                {" "}
                <div className="card-header pb-0">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-0">
                      {/* {`${t("block.listOfBlocks")}`} */}
                      List of new categories
                    </h4>
                  </div>
                </div>
                <ListComponent />
              </div>
              <div className="">
                <div className="row">
                  <div className="my-2 d-flex justify-content-end">
                    <button
                      className="btn btn-info me-2"
                      onClick={() => navigate(-1)}
                    >
                      {t("global.cancel")}
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => handleSubmit()}
                      type="submit"
                    >
                      {isLoading ? (
                        <Spinner animation="border" size="sm" role="status" />
                      ) : (
                        <span> {t("products.save")}</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showForm}
        onHide={() => setShowForm(false)}
        size="xl"
        centered
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="h3 mb-0">
              {currentCategory?.hasOwnProperty("allowChildren")
                ? `${t("category.update")}`
                : `${t("category.create_new_category")}`}
            </p>
            <div className="d-flex">
              <Select
                className="react-select-field mx-2"
                name="content"
                theme={themeSelect}
                placeholder={`${t("formality.select_formality")}`}
                options={deeplFormality?.map((formality) => ({
                  value: formality,
                  label: getCorrectFormality(formality, t),
                }))}
                // key={state?.id}
                onChange={(e) => {
                  handleSelectSingleFormality(e as any);
                }}
              />
              <Select
                className="custom_zindex react-select-field me-2"
                theme={themeSelect}
                placeholder="Translate from"
                isClearable={true}
                options={translationLanguageEnum?.map((lang) => ({
                  value: lang,
                  label: getCorrectLanguage(lang, t),
                }))}
                //   key={state?.id}
                onChange={(e) => {
                  handleSelectSingle(e as any);
                }}
              />
              <button className="btn btn-secondary" onClick={handleTranslate}>
                {t("global.translate")}
              </button>
            </div>
          </div>
          <div className="row">
            {translationLanguageEnum?.map((language) => (
              <div className="col-md-6 my-1" key={language}>
                <label htmlFor="title">
                  {t("category.enterTitle")} {getCorrectLanguage(language, t)}
                </label>
                <input
                  type="text"
                  name="title"
                  value={
                    (currentCategory as any)?.translation?.[language]?.title ??
                    ""
                  }
                  onChange={(e) =>
                    changeTranslationHandler(e, language as string)
                  }
                  className="form-control"
                />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={() => setShowForm(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => updateCategoryById()}>
            {currentCategory?.hasOwnProperty("allowChildren")
              ? `${t("global.update")}`
              : `${t("category.save")}`}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditModal} onHide={handleClose} size="lg" centered>
        <Modal.Body className="d-flex align-items-center flex-column">
          <label htmlFor="name-edit" className="me-auto">
            {t(`global.name`)}
          </label>
          <input
            value={newCatName}
            id="name-edit"
            type="text"
            onChange={(e) => setNewCatName(e.target.value)}
            className="form-control"
            name="name"
          />
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end">
          <Button variant="light" size="sm" onClick={handleClose}>
            {t(`modals.close`)}
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              handleEditItem();
              handleClose();
            }}
          >
            {t(`global.edit`)}
          </Button>
        </Modal.Footer>
      </Modal>
      <>
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={editCatName && editCatName}
          deleteHandler={() =>
            deleteCategoryHandler(editCatId as number, editCatName as string)
          }
          selectedProduct={editCatId}
        />
      </>
    </>
  );
}

const NewListCategory = ({
  valueList,
  t,
  removeItemNewCategory,
  showEditItem,
}: any) => {
  return (
    <div className="card-body">
      {valueList == undefined ? (
        <LoadingAnimation />
      ) : valueList.length === 0 ? (
        <EmptyState />
      ) : (
        <div className="table-responsive">
          <Table className="align-middle table-nowrap mb-0">
            <thead className="table-light">
              <tr className="">
                <th scope="col-9 text-start">{`${t("global.name")}`}</th>
                <th scope="col-3">{`${t("global.actions")}`}</th>
              </tr>
            </thead>
            <tbody>
              {valueList?.map((item: any) => {
                return (
                  <tr key={item?.id}>
                    <td className="col-9 text-start">{item?.name}</td>

                    <td className="col-3">
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          onClick={() =>
                            showEditItem(
                              item?.id as number,
                              item?.name as string
                            )
                          }
                          className="btn btn-sm btn-warning d-flex align-items-center me-2"
                        >
                          <i className="ri-edit-2-fill" />
                        </button>
                        <button
                          onClick={() =>
                            removeItemNewCategory(item?.id as number)
                          }
                          className="btn btn-sm btn-danger d-flex align-items-center"
                        >
                          <i className="ri-delete-bin-fill" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};
