import { PageTemplate } from "../../../../shared/layout/PageTemplate";
import { useTranslation } from "react-i18next";
import React, { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AttributesService } from "../../service";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import { IAttributes } from "../dto/IAttributes";
import { Form } from "react-bootstrap";
import {
  getCorrectFormality,
  getCorrectLanguage,
} from "../../../../shared/functions/Functions";
import Select, { MultiValue, SingleValue } from "react-select";
import { themeSelect } from "../../../../shared/components/Select2";
import { ProductsService } from "../../../products/service";

interface IAttributesActionsProps {
  currentAttribute: IAttributes;
  setCurrentAttribute: React.Dispatch<SetStateAction<IAttributes>>;
  handleSubmit: (event: React.FormEvent, id?: number) => void;
}

export const AttributesActions = ({
  currentAttribute,
  setCurrentAttribute,
  handleSubmit,
}: IAttributesActionsProps) => {
  const { id } = useParams();
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const [formality, setFormality] = useState(null);

  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [sourceLanguage, setSourceLanguage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const changeTranslationHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setCurrentAttribute((prev: any) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [name]: value,
      },
    }));
  };
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }

  const handleTranslate = () => {
    const langArr = translationLanguageEnum?.filter(
      (lang) => lang !== sourceLanguage
    );
    for (let i = 0; i < langArr?.length; i++) {
      ProductsService.postTranslation({
        text: currentAttribute?.translation?.[sourceLanguage],
        sourceLanguage: sourceLanguage,
        targetLanguage: langArr[i],
        formality: formality as any,
      })
        .then((response) => {
          const result = response;
          setCurrentAttribute((prev: any) => ({
            ...prev,
            translation: {
              ...prev?.translation,
              [langArr[i]]: result,
            },
          }));
          if (i === langArr?.length - 1) {
            SuccessToast(`${t("toasts.successfully_translated")}`);
          }
        })
        .catch((error) => ErrorToast(error));
    }
  };
  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setSourceLanguage(selectedSingle?.value as string);
  }
  useEffect(() => {
    if (!!Number(id)) {
      AttributesService.getAttributeById(Number(id))
        .then((response) => {
          setCurrentAttribute(response);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);

  return (
    // <PageTemplate title={`${t("side_bar.attributes")}`}>
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <button
              onClick={() => navigate(-1)}
              className="btn-info btn mb-3 d-flex align-items-center"
            >
              <i className="ri-arrow-left-line me-2" />
              {t("attributes.back")}
            </button>
            <div className="d-flex">
              <Select
                className="react-select-field me-2"
                name="content"
                theme={themeSelect}
                placeholder={`${t("formality.select_formality")}`}
                options={deeplFormality?.map((formality) => ({
                  value: formality,
                  label: getCorrectFormality(formality, t),
                }))}
                // key={state?.id}
                onChange={(e) => {
                  handleSelectSingleFormality(e as any);
                }}
              />

              <Select
                className="custom_zindex react-select-field me-2"
                theme={themeSelect}
                placeholder="Translate from"
                isClearable={true}
                options={translationLanguageEnum?.map((lang) => ({
                  value: lang,
                  label: getCorrectLanguage(lang, t),
                }))}
                //   key={state?.id}
                onChange={(e) => {
                  handleSelectSingle(e as any);
                }}
              />
              <button className="btn btn-secondary" onClick={handleTranslate}>
                {t("global.translate")}
              </button>
            </div>
          </div>

          <Form onSubmit={(e) => handleSubmit(e, Number(id))}>
            <div className="row">
              {translationLanguageEnum?.map((language) => {
                return (
                  <div className="col-md-3 my-2" key={language}>
                    <label htmlFor="title">
                      {t("attributes.title")}- {getCorrectLanguage(language, t)}
                    </label>
                    <input
                      type="text"
                      onChange={changeTranslationHandler}
                      placeholder="Enter title..."
                      className="form-control"
                      name={language}
                      value={currentAttribute?.translation?.[language] ?? ""}
                    />
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary" type="submit">
                {t("global.submit")}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    // </PageTemplate>
  );
};
