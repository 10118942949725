import { ICrawlersDTO, ICrawlersQuery } from "./dto/ICrawler";
import { BaseModel } from "../../model/shared/BaseModel";
import { CRAWLER_CONTROLLER, CRAWLER_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";
import { ICrawler } from "../../model/authentification/IRegister";

export const CrawlerService = {
    createCrawler: (params: ICrawler) => requests.post(`${CRAWLER_URL}`, params),
    getAllCrawlers: (params: ICrawlersQuery): Promise<BaseModel<ICrawlersDTO[]>> => requests.get(`${CRAWLER_URL}`, params),
    getCrawlerById: (id: number) => requests.get(`${CRAWLER_URL}/${id}`),
    updateCrawlerById: (id: number, params: ICrawler) => requests.put(`${CRAWLER_URL}/${id}`, params),
    deleteCrawlerById: (id: number) => requests.delete(`${CRAWLER_URL}/${id}`),
    crawlerStart: (id: number) => requests.get(`https://ciib-crawler.itmedia.io/crawler/${id}/start`),
     crawlerStop: (id: number) => requests.get(`https://ciib-crawler.itmedia.io/crawler/${id}/stop`),
}