import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ICrawlersDTO } from "../dto/ICrawler";
import React, { SetStateAction, useEffect, useState } from "react";
import { ICrawler } from "../../../model/authentification/IRegister";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { CrawlerService } from "../service";
import { Pagination } from "../../../shared/paginations/Paginations";
import { themeSelect } from "../../../shared/components/Select2";
import {
  ICategoryChildren,
  ICategoryDTO,
} from "../../category/values/dto/ICategory";
import Select, { SingleValue } from "react-select";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { getWebsiteTranslation } from "../../../shared/functions/Functions";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { EmptyState } from "../../../shared/components/EmptyState";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { LoadingAnimation } from "../../../shared/components/Loading";

interface ICrawlersListProps {
  crawlerList: ICrawlersDTO[] | undefined;
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  setPagination: React.Dispatch<SetStateAction<any>>;
  changeFilterHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  editHandler: (crawlerId: number) => void;
  page: number;
  totalPages: number;
  handlePages: (updateNumber: number) => void;
  state: ICrawler | undefined;
  categories: any;
}

interface IShowButtons {
  [key: number]: boolean;
}

export const CrawlerList = ({
  crawlerList,
  categories,
  setPagination,
  setTriggerUpdate,
  editHandler,
  changeFilterHandler,
  page,
  totalPages,
  handlePages,
  state,
}: ICrawlersListProps) => {
  const { t } = useTranslation();
  const { websiteEnum } = useSelector((state: IAllStates) => state.enums);
  const [selectedCrawler, setSelectedCrawler] = useState<ICrawler>();
  const [showStopButton, setShowStopButton] = useState<IShowButtons>();
  const [selectedMainCategory, setSelectedMainCategory] = useState<
    ICategoryChildren[]
  >([]);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [businessUnit, setBusinessUnit] = useState([]);
  const handleShow = () => setShow(true);
  function handleSelectSingle2(
    selectedSingle: SingleValue<{ label: string; value: number | undefined }>
  ) {
    const selected = categories?.find(
      (category: ICategoryDTO) => category?.id === selectedSingle?.value
    )?.children;
    setBusinessUnit(selected);
    selected && setSelectedMainCategory(selected);
    if (selected === undefined) {
      setPagination({
        page: 1,
        perPage: 12,
      } as any);
    }
  }

  function handleSelectMainCategory(
    selectedSingle: SingleValue<{ label: string; value: number | undefined }>,
    name: string
  ) {
    setPagination((prev: any) => ({
      ...prev,
      [name]: selectedSingle?.value,
    }));
  }

  const deleteHandler = () => {
    selectedCrawler?.id &&
      CrawlerService.deleteCrawlerById(selectedCrawler?.id)
        .then((response) => {
          if (response === "") {
            SuccessToast(
              `${t("toasts.successfully_deleted")} ${selectedCrawler?.website}`
            );
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };

  const changeColorOnButton = (crawlerId: number) => {
    setShowStopButton((prev) => ({
      ...prev,
      [crawlerId]: !prev?.[crawlerId],
    }));
  };
  const crawlerStart = (crawlerId: number) => {
    CrawlerService.crawlerStart(crawlerId as number)
      .then((response) => {
        SuccessToast("start");
        setTriggerUpdate((prev) => !prev);
      })
      .catch((error) =>
        // ErrorToast(error)
        console.log(error)
      );
  };
  const crawlerStop = (crawlerId: number) => {
    CrawlerService.crawlerStop(crawlerId as number)
      .then((response) => {
        SuccessToast("stop");
        setTriggerUpdate((prev) => !prev);
      })
      .catch((error) =>
        // ErrorToast(error)
        console.log(error)
      );
  };
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     navigate(1);
  //
  //     // window.location.reload();
  //   }, 2000);
  //   return () => clearInterval(interval);
  // }, []);
  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">
            {`${t("crawlers.listOfCrawlers")}`}
          </h4>
        </div>
      </div>
      <div className="card-body">
        <div className="row mb-3 justify-content-start">
          <div className="col-md-3">
            <Select
              className="react-select-field"
              placeholder={t("products.select_main_category")}
              theme={themeSelect}
              options={categories?.map(
                (cat: { id: number; title: string }) => ({
                  value: cat?.id,
                  label: cat?.title,
                })
              )}
              isClearable={true}
              key={state?.id}
              onChange={(e) => {
                handleSelectSingle2(e as any);
              }}
            />
          </div>
          {!!selectedMainCategory?.length && businessUnit && (
            <div className="col-md-3 ">
              <Select
                className="react-select-field"
                placeholder={t("products.select_category")}
                theme={themeSelect}
                isClearable={true}
                options={selectedMainCategory?.map((mainCat) => ({
                  label: mainCat?.title,
                  options: mainCat?.children?.map((subCat) => ({
                    label: subCat?.title,
                    value: subCat?.id,
                  })),
                }))}
                onChange={(e) => {
                  handleSelectMainCategory(
                    e as SingleValue<{ label: string; value: number }>,
                    "category"
                  );
                }}
              />
            </div>
          )}
          <div className="col-md-3">
            <Select
              className="react-select-field"
              theme={themeSelect}
              options={websiteEnum?.map((site) => ({
                value: site,
                label: getWebsiteTranslation(site, t),
              }))}
              isClearable={true}
              placeholder={t("crawlers.website")}
              key={state?.id}
              onChange={(e) => {
                handleSelectMainCategory(
                  e as SingleValue<{ label: string; value: number }>,
                  "website"
                );
              }}
            />
          </div>
        </div>
        {crawlerList == undefined ? (
          <LoadingAnimation />
        ) : crawlerList?.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">{`${t("global.id")}`}</th>
                  <th scope="col">{`${t("export_client.languages")}`}</th>
                  <th scope="col">{`${t("crawlers.category")}`}</th>
                  <th scope="col">{`${t("crawlers.website")}`}</th>
                  <th scope="col">{`${t("crawlers.url")}`}</th>
                  <th scope="col">{`${t("global.actions")}`}</th>
                </tr>
              </thead>
              <tbody>
                {crawlerList?.map((crawler: any) => {
                  return (
                    <tr key={crawler?.id}>
                      <td>
                        <Link to="#" className="fw-medium">
                          #{crawler?.id}
                        </Link>
                      </td>
                      <td>
                        {!!crawler?.language?.length
                          ? crawler?.language
                              ?.map(
                                (language: string) =>
                                  `${getCorrectLanguage(language, t)}`
                              )
                              .join(", ")
                          : "-"}
                      </td>
                      <td>{crawler?.category?.title ?? "-"}</td>
                      <td>
                        {getWebsiteTranslation(crawler?.website, t) ?? "-"}
                      </td>
                      <td className="text-long">{crawler?.url ?? "-"}</td>
                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          {
                            crawler?.status !== "TASK_IN_PROGRESS" ? (
                              <button
                                className="btn btn-sm btn-secondary d-flex align-items-center me-2"
                                onClick={() => {
                                  crawlerStart(crawler?.id);
                                  changeColorOnButton(crawler?.id);
                                }}
                              >
                                <i className="ri-play-circle-fill me-1"></i>
                                {`${t("global.start")}`}
                              </button>
                            ) : (
                              <button
                                className="btn btn-sm btn-danger d-flex align-items-center me-2"
                                onClick={() => {
                                  crawlerStop(crawler?.id);
                                  changeColorOnButton(crawler?.id);
                                }}
                              >
                                <i className="ri-stop-circle-fill me-1"></i>
                                {`${t("global.stop")}`}
                              </button>
                            )
                            /* {!showStopButton?.[crawler?.id] ? (
                              <button
                                className="btn btn-sm btn-secondary d-flex align-items-center me-2"
                                onClick={() => {
                                  crawlerStart(crawler?.id);
                                  changeColorOnButton(crawler?.id);
                                }}
                              >
                                <i className="ri-play-circle-fill me-1"></i>
                                {`${t("global.start")}`}
                              </button>
                            ) : (
                              <button
                                className="btn btn-sm btn-danger d-flex align-items-center me-2"
                                onClick={() => {
                                  crawlerStop(crawler?.id);
                                  changeColorOnButton(crawler?.id);
                                }}
                              >
                                <i className="ri-stop-circle-fill me-1"></i>
                                {`${t("global.stop")}`}
                              </button>
                            )} */
                          }
                          <button
                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                            onClick={() => editHandler(crawler?.id as number)}
                          >
                            <i className="ri-edit-2-fill me-1" />
                            {`${t("global.edit")}`}
                          </button>
                          <button
                            className="btn btn-sm btn-danger d-flex align-items-center"
                            onClick={() => {
                              setSelectedCrawler(crawler);
                              handleShow();
                            }}
                          >
                            <i className="ri-delete-bin-fill me-1" />
                            {`${t("global.delete")}`}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      </div>
      <ConfirmDelete
        show={show}
        setShow={setShow}
        itemName={selectedCrawler?.url}
        deleteHandler={deleteHandler}
        selectedProduct={selectedCrawler}
      />
    </div>
  );
};
