import { USER_URL } from "../../utils/helpers/api.routes";
import { BaseModel } from "../../model/shared/BaseModel";
import { IUserDTO, IUserQuery } from "./dto/IUser";
import { requests } from "../../utils/helpers/api.services";

export const UserService = {
  getAllUsers: (params: IUserQuery): Promise<BaseModel<IUserDTO[]>> =>
    requests.get(`${USER_URL}`, params),
  getWorkflowUsers: (params: any) =>
    requests.get(`${USER_URL}/workflow`, params),
  getUserById: (id: number) => requests.get(`${USER_URL}/${id}`),
  deleteUserById: (id: number) => requests.delete(`${USER_URL}/${id}`),
  updateUserStatusByID: (id: number) =>
    requests.put(`${USER_URL}/change-active-status/${id}`),
};
