import { BaseModel } from "../../model/shared/BaseModel";
import { API_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";

export const ApiService = {
  create: (params: any) => requests.post(`${API_URL}`, params),
  getAll: (params: any): Promise<BaseModel<any[]>> =>
    requests.get(`${API_URL}`, params),
  getById: (id: number) => requests.get(`${API_URL}/${id}`),
  updateById: (id: number, params: any) =>
    requests.put(`${API_URL}/${id}`, params),
  deleteById: (id: number) => requests.delete(`${API_URL}/${id}`),
  export: (view: string, format: string, id: number) =>
    requests.post(`${API_URL}/excel/${id}`, {
      view: view,
      format: format,
    }),
};
