import React, { useEffect, useState } from "react";
import { ContentUpdate } from "../../products/content/components/ContentUpdate";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { WorkflowStepper } from "./WorkflowStepper";
import step6PaginationSlice from "../../../store/workflow/step6PaginationSlice";
import { WorkflowService } from "../service";
import { IWorkflow } from "../dto/IWorkflow";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { t } from "i18next";
interface IProgressData {
  done: number;
  total: number;
  percent: number;
}
interface IProgressBar {
  assigment: IProgressData;
  translation: IProgressData;
}
const WorkflowStep6Edit = () => {
  const { id, productId } = useParams();
  const dispatch = useDispatch();
  const isTranslator = true;
  const [staticsView2, setStaticsView2] = useState<any[]>([]);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const localWorkflowLang = localStorage.getItem("workflowLang");
  const isManager = currentUser.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser.roles.includes("ROLE_COMPANY_ADMIN");

  const progressArr = ["assigment", "translation"];
  const [currentWorkflow, setCurrentWorkflow] = useState<IWorkflow | null>(
    null
  );
  const [progressBarData, setProgressBarData] = useState<
    IProgressBar | undefined
  >(undefined);
  const stepPagination = useSelector(
    (state: IAllStates) => state.step6Pagination as any
  );
  const [pagination, setPagination] = useState({
    ...(stepPagination as unknown as any),
  });
  const handlePerPage = (e: any) => {
    dispatch(
      step6PaginationSlice.actions.setStep6Pagination({
        ...(stepPagination as any),
        page: 1,
        perPage: e.value,
      })
    );
  };
  const funcForFirstLoading = (firstLang: string) => {
    if (!pagination?.lang) {
      setPagination({ ...(stepPagination as unknown as any), lang: firstLang });
    } else {
      return;
    }
  };
  const changeFilterHandler = (name: string, value: string) => {
    if ((stepPagination as any)?.[name] === value) {
      dispatch(
        step6PaginationSlice.actions.setStep6Pagination({
          ...(stepPagination as any),
          [name]: "",
        })
      );
    } else {
      dispatch(
        step6PaginationSlice.actions.setStep6Pagination({
          ...(stepPagination as any),
          [name]: value,
        })
      );
    }
  };
  useEffect(() => {
    +productId! &&
      WorkflowService.getTranslationProducts(stepPagination, +id!)
        .then((response: any) => {
          const { currentLanguage, workflow, assigment, translation } =
            (response as any) ?? {};
          localStorage.setItem(
            "workflowLang",
            response?.nextProduct?.nextProduct?.recommendedLanguage ??
              currentLanguage
          );
          setCurrentWorkflow(workflow as IWorkflow);
          setStaticsView2(response?.statistics ?? []);
          setProgressBarData({
            assigment,
            translation,
          });
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
  }, []);
  //remove api
  return (
    <div className="row">
      <div className="card">
        <div className="card-body">
          <WorkflowStepper
            step={"TRANSLATION"}
            funcForFirstLoading={funcForFirstLoading}
            handlePerPage={handlePerPage}
            id={id}
            changeFilterHandler={changeFilterHandler}
            precent={progressBarData?.["translation"]?.percent}
            // showNextButton={isTranslator}
            showMarkAsComplete={
              currentWorkflow?.status === "TRANSLATION" &&
              (isAdmin || isCompanyAdmin || isManager)
            }
            status={stepPagination?.status}
            assigned={!isTranslator && stepPagination?.assigned}
            // setSelectedProductId={!selectedProductId && setSelectedProductId}
            showFilters={false}
          />
          {progressArr?.map((data) => {
            if (data === "assigment" && isTranslator) {
              return null;
            }
            return (
              <div
                className="card bg-light overflow-hidden shadow-none"
                key={data}
              >
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h6 className="mb-0">
                        {t("workflow.current")} {data}:
                        <b className="text-success">
                          {" "}
                          {
                            progressBarData?.[data as keyof IProgressBar]
                              ?.percent
                          }
                          %
                        </b>
                      </h6>
                    </div>
                    <div className="flex-shrink-0">
                      <h6 className="mb-0">
                        {progressBarData?.[data as keyof IProgressBar]?.done}/
                        {progressBarData?.[data as keyof IProgressBar]?.total}{" "}
                        {t("workflow.finished")}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="progress">
                  {/*//@ts-ignore*/}
                  <div
                    className="progress-bar bg-success"
                    style={{
                      width: `${
                        progressBarData?.[data as keyof IProgressBar]
                          ?.percent ?? 0
                      }%`,
                    }}
                    aria-valuenow={
                      progressBarData?.[data as keyof IProgressBar]
                        ?.percent as number
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>
            );
          })}
          {staticsView2?.map((data) => {
            return (
              <div
                className="card bg-light overflow-hidden shadow-none"
                key={data?.lang}
              >
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h6 className="mb-0">
                        {t("workflow.current")} {data?.lang}:
                        <b className="text-success"> {data?.percent}%</b>
                      </h6>
                    </div>
                    <div className="flex-shrink-0">
                      <h6 className="mb-0">
                        {data?.done} {t("workflow.finished")}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="progress">
                  {/*//@ts-ignore*/}
                  <div
                    className="progress-bar bg-success"
                    style={{ width: `${data?.percent ?? 0}%` }}
                    aria-valuenow={data?.percent as number}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>
            );
          })}
          {localWorkflowLang !== undefined && (
            <ContentUpdate
              translationLangTab={localWorkflowLang as string}
              selectedProductId={productId as any}
              isWorkflowUpdate={true}
              isTranslator={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkflowStep6Edit;
