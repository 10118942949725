import { PageTemplate } from "../../../shared/layout/PageTemplate";
import { useTranslation } from "react-i18next";
import { AttributesList } from "./components/AtributesList";
import { useEffect, useState } from "react";
import { AttributesService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { IAttributes } from "./dto/IAttributes";
import _debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import expOrAttPaginationSlice from "../../../store/category/expOrAttPagination";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";

export const Attributes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [listOfAttributes, setListOfAttributes] = useState<
    IAttributes[] | undefined
  >();
  const [paginate, setPaginate] = useState({
    page: 1,
    perPage: 10,
  });
  const dispatch = useDispatch();
  dispatch(expOrAttPaginationSlice.actions.setExpOrAttPagination({}));
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePages = (updatePage: number) => {
    setPaginate({ ...paginate, page: updatePage });
    setPage(updatePage);
  };

  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPaginate((prev) => ({ ...prev, [name]: value }));
    },
    200
  );

  useEffect(() => {
    AttributesService.getAllAttributes(paginate)
      .then((response) => {
        setListOfAttributes(undefined);
        const { data } = response;
        setTotalPages(Math.ceil(response?.count / response?.perPage));
        setListOfAttributes(data);
      })
      .catch((error) => ErrorToast(error));
  }, [paginate]);

  return (
    // <PageTemplate title={`${t("side_bar.attributes")}`}>
    <div className="col-xl-12">
      <div className="card">
        <div className="card-body d-flex align-items-center justify-content-between">
          <div>
            <h4 className="card-title mg-b-0">
              {t("attributes.create_new_attribute")}
            </h4>
          </div>
          <div>
            {accessRights?.includes("attribute_can_manage") && (
              <button
                className="btn btn-secondary me-2"
                onClick={() => navigate("/admin/attributes/assign")}
              >
                {t("global.manage")}
              </button>
            )}
            {accessRights?.includes("attribute_can_create") && (
              <button
                className="btn btn-primary"
                onClick={() => navigate("/admin/attributes/create")}
              >
                {t("global.create")}
              </button>
            )}
          </div>
        </div>
      </div>
      <AttributesList
        listOfAttributes={listOfAttributes}
        page={page}
        totalPages={totalPages}
        changeFilterHandler={changeFilterHandler}
        handlePages={handlePages}
      />
    </div>
    // </PageTemplate>
  );
};
