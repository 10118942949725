import React, { useEffect, useState } from "react";
import { Pagination } from "../../../shared/paginations/Paginations";
import { Col, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EmptyState } from "../../../shared/components/EmptyState";
import { PageTemplate } from "../../../shared/layout/PageTemplate";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { IProductsList } from "../../products/dto/IProducts";
import { ProductsPendingService } from "../service";
import _debounce from "lodash/debounce";
import { SingleValue } from "react-select";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { useLocation, useNavigate } from "react-router";
import { ProductsFilters } from "../../products/components/ProductsFilters";
import { ProductsCardView } from "../../products/components/ProductsCardView";
import { ProductsListView } from "../../products/components/ProductsListView";
import { ProductsService } from "../../products/service";
import { IBrand } from "../../brand/dto/IBrand";
import { BrandService } from "../../brand/service";
import { CategoryServicee } from "../../../store/category/categoryService";
import categorySlice from "../../../store/category/categoryRedux";
import { useDispatch } from "react-redux";
import { ConfirmApproveAll } from "../../../shared/components/ConfirmApproveAll";
import { ConfirmDeleteAll } from "../../../shared/components/ConfirmDeleteAll";
import { LoadingAnimation } from "../../../shared/components/Loading";

interface IPagination {
  page: number;
}

export const ImportedProducts = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const toForLink = "/app/pending-products/imported/base-data/";
  const [isListView, setIsListView] = useState(true);
  const { t } = useTranslation();
  const { search, state: passedPagination }: any = useLocation();
  const [categoryFilterId, setCategoryFilterId] = useState(null);
  const [brandsList, setBrandsList] = useState<IBrand[]>([]);
  const [brandId, setBrandId] = useState(null);
  const [emptyList, setEmptyList] = useState(false);
  const mainTable = false;
  const status = true;
  // const [checkChangeFilter, setCheckChangeFilter] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const currentPage =
    (location.state as unknown as IPagination)?.page ??
    Number(search.split("=").at(-1) ? search.split("=").at(-1) : 1);
  const [pagination, setPagination] = useState({
    ...(location.state as unknown as IPagination),
    // page: currentPage,
    perPage: 12,
  });
  const nameOfTable = "pending";
  const [infoButton, setInfoButton] = useState(true);
  const [page, setPage] = useState(currentPage);
  const [totalPages, setTotalPages] = useState(0);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [productsList, setProductsList] = useState<
    IProductsList[] | undefined
  >();
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const handlePages = (updatePage: number) => {
    navigate(`${location.pathname}?page=${updatePage}`, {
      state: {
        ...pagination,
        page: updatePage,
      },
    });
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const maxId =
    productsList &&
    (productsList?.map((product) => product.id) as number[]).sort(
      (a, b) => b - a
    )[0] + 1;
  const categories = useSelector((state: IAllStates) => state.category);
  const navigate = useNavigate();

  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPagination({
        ...pagination,
        [name]: value,
      });
      navigate(location.pathname, {
        state: {
          ...pagination,
          [name]: value,
        },
      });
      setTriggerUpdate(!triggerUpdate);
    },
    200
  );
  const handleSelectMainCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value as number) {
      setCategoryFilterId(selectedSingle?.value as any);
      setPagination({
        ...pagination,
        category: selectedSingle?.value as number,
        categoryData: selectedSingle,
      } as any);
      navigate(location.pathname, {
        state: {
          ...pagination,
          category: selectedSingle?.value as number,
          categoryData: selectedSingle,
        },
      });
    } else {
      setCategoryFilterId(null);
      setPagination({
        ...pagination,
        category: null,
        categoryData: null,
      } as any);
    }
  };
  const handleSelectStatus = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    if (selectedSingle?.value as string) {
      setPagination({
        ...pagination,
        status: selectedSingle?.value as string,
      } as any);
    }
  };
  const deleteHandler = (product: IProductsList) => {
    product?.id &&
      ProductsService.deleteProductById(product?.id)
        .then((response) => {
          setTriggerUpdate((prev) => !prev);
          SuccessToast(`${t("toasts.successfully_deleted")} ${product?.title}`);
        })
        .catch((error) => ErrorToast(error));
  };

  // const editClickHandler = (id: number) => {
  //   navigate(`/app/pending-products/imported/base-data/${id}`, {
  //     state: { pagination: pagination },
  //   });
  // };
  const editClickHandler = (e: any, id: number) => {
    if (e.type === "click") {
      navigate(`/app/pending-products/imported/base-data/${id}`, {
        state: { pagination: pagination },
      });
    } else if (e.type === "contextmenu") {
      window.open(`/app/pending-products/imported/base-data/${id}`, "_blank");
    }
  };
  const viewClickHandler = (id: number) => {
    navigate(`/app/pending-products/imported/view/base-data/${id}`);
  };

  useEffect(() => {
    ProductsPendingService.getAllImportedProducts(pagination)
      .then((response) => {
        const { data } = response;
        const { empty }: any = response;
        setProductsList(data);
        setEmptyList(empty);
        setPage(response?.page);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
        setPage(currentPage);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, JSON.stringify(pagination)]);

  const handleSelectBrand = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value as number) {
      setBrandId(selectedSingle?.value as any);
      setPagination({
        ...pagination,
        brand: selectedSingle?.value as number,
        brandData: selectedSingle,
      } as any);
      navigate(location.pathname, {
        state: {
          ...pagination,
          brand: selectedSingle?.value as number,
        },
      });
    } else {
      setBrandId(null);
      setPagination({
        ...pagination,
        brand: null,
      } as any);
    }
  };

  useEffect(() => {
    BrandService.getAllBrands({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setBrandsList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    CategoryServicee.getAllCategories()
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);

  const handleChangeDate = (value: Date[], name: string) => {
    setPagination({ ...pagination, [name]: value, page: 1 });
    navigate(location.pathname, {
      state: {
        ...pagination,
        [name]: value,
      },
    });
    // navigate("/admin/platform")
    setTriggerUpdate(!triggerUpdate);
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked: isChecked, value } = event.target;
    if (isChecked) {
      if ((value as unknown as number) == maxId) {
        setCheckedValues([]);
        productsList?.map((product) => {
          setCheckedValues((prev: any) => prev.concat(Number(product.id)));
        });
      } else {
        setCheckedValues((prev: any) => prev.concat(Number(value)));
      }
    } else if (isChecked === false && (value as unknown as number) == maxId) {
      setCheckedValues([]);
    } else {
      setCheckedValues((prev) => prev.filter((item) => item !== +value));
    }
  };

  const handleApprove = () => {
    ProductsPendingService.postMultiselectProduct({
      productStatus: "APPROVED",
      products: checkedValues,
    })
      .then((response) => {
        if (response) {
          SuccessToast("Success approved");
          setShowApproveModal(false);
          setCheckedValues([]);
          setTriggerUpdate((prev) => !prev);
        }
      })
      .catch((error) => ErrorToast(error));
  };
  const handleDelete = () => {
    ProductsPendingService.postMultiselectProduct({
      productStatus: "REJECTED",
      products: checkedValues,
    })
      .then((response) => {
        if (response) {
          SuccessToast("Success deleted");
          setShowDeleteModal(false);
          setCheckedValues([]);
          setTriggerUpdate((prev) => !prev);
        }
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    if (location.state) {
      navigate(location.pathname, { state: {} });
    }
  }, [location?.pathname]);
  return (
    // <PageTemplate title={`${t("side_bar.imported_products")}`}>
    <Col xs={12}>
      <ProductsFilters
        listWithoutCat={true}
        mainTable={mainTable}
        handleSelectStatus={handleSelectStatus}
        changeFilterHandler={changeFilterHandler}
        handleSelectMainCategory={handleSelectMainCategory}
        categories={categories}
        brandsList={brandsList}
        handleSelectBrand={handleSelectBrand}
        handleChangeDate={handleChangeDate}
        showDate={true}
        pagination={pagination}
        setPagination={setPagination}
        triggerUpdate={triggerUpdate}
        setTriggerUpdate={setTriggerUpdate}
      />
      <div className="d-flex w-100 align-items-center justify-content-end mb-3">
        <button
          className={`btn btn${!isListView ? "-soft" : ""}-info btn-sm`}
          onClick={() => setIsListView(!isListView)}
        >
          <i className="ri-list-check " />
        </button>
        <button
          className={`btn btn${isListView ? "-soft" : ""}-info btn-sm ms-2`}
          onClick={() => setIsListView(!isListView)}
        >
          <i className="ri-bank-card-line" />
        </button>
      </div>
      {!isListView ? (
        <div className="row gy-4s">
          {!!productsList?.length ? (
            productsList?.map((product) => (
              <div className="col-sm-6 col-xl-3" key={product?.id}>
                <ProductsCardView
                  nameOfTable={nameOfTable}
                  key={product?.id}
                  product={product}
                  deleteHandler={deleteHandler}
                  onEdit={editClickHandler}
                  onView={viewClickHandler}
                />
              </div>
            ))
          ) : (
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <EmptyState />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              {!emptyList ? (
                !!productsList?.length ? (
                  <>
                    <Table className="align-middle table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">
                            <input
                              className="form-check-input m-0 cursor-pointer"
                              value={maxId}
                              checked={
                                checkedValues.length === productsList?.length
                              }
                              type="checkbox"
                              onChange={(e) => handleCheck(e)}
                            />
                          </th>
                          <th scope="col">{`${t("global.id")}`}</th>
                          <th scope="col">{`${t("global.aenCode")}`}</th>
                          <th scope="col">{`${t("products.product")}`}</th>
                          <th scope="col">{`${t("global.status")}`}</th>
                          <th scope="col">{`Sap`}</th>
                          <th scope="col">{`${t("products.category")}`}</th>
                          <th scope="col">{`${t("products.createdAt")}`}</th>
                          <th scope="col">{`${t("global.actions")}`}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productsList?.map((product) => (
                          <ProductsListView
                            toForLink={toForLink}
                            checkedValues={checkedValues}
                            nameOfTable={nameOfTable}
                            infoButton={infoButton}
                            key={product?.id}
                            handleCheck={handleCheck}
                            deleteHandler={deleteHandler}
                            onEdit={editClickHandler}
                            product={product}
                            onView={viewClickHandler}
                            status={status}
                          />
                        ))}
                      </tbody>
                    </Table>

                    <div className="d-flex align-items-center justify-content-start mt-3">
                      <button
                        className="btn btn-sm btn-secondary d-flex align-items-center me-2"
                        onClick={() => setShowApproveModal(true)}
                        disabled={checkedValues?.length === 0}
                      >
                        <i className="ri-share-box-fill me-1" />
                        {`${t("global.approve")}`}
                      </button>
                      <button
                        // disabled={disabledBtn}
                        className="btn btn-sm btn-danger d-flex align-items-center me-2"
                        onClick={() => setShowDeleteModal(true)}
                        disabled={checkedValues?.length === 0}
                      >
                        <i className="ri-delete-bin-fill me-1" />
                        {`${t("global.delete")}`}
                      </button>
                      <ConfirmApproveAll
                        show={showApproveModal}
                        handleApprove={handleApprove}
                        setShow={setShowApproveModal}
                        // itemName={selectedProduct?.title}
                        // deleteHandler={deleteHandler}
                        // selectedProduct={selectedProduct}
                      />
                      <ConfirmDeleteAll
                        show={showDeleteModal}
                        handleDelete={handleDelete}
                        setShow={setShowDeleteModal}
                        // itemName={selectedProduct?.title}
                        // deleteHandler={deleteHandler}
                        // selectedProduct={selectedProduct}
                      />
                    </div>
                  </>
                ) : productsList === undefined ? (
                  <LoadingAnimation />
                ) : (
                  <EmptyState />
                )
              ) : (
                <div className="d-flex align-items-center justify-content-center flex-column w-100">
                  {/* <Lottie animationData={searchIcon} loop={true} style={{ width: 100 }} /> */}
                  <h5 className="mt-2">{t("empty_state.choose_filter")}</h5>
                  <p className="text-muted">
                    {t("empty_state.please_choose_filter")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-end ">
            {totalPages <= 1 ? (
              ""
            ) : (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
        </div>
      </div>
    </Col>
    // </PageTemplate>
  );
};

// useEffect(() => {
//   if (currentPage) {
//     setPagination((prev) => ({ ...prev, page: currentPage }));
//     setPage(currentPage);
//   }
// }, [currentPage]);
